import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Redirect, Route, Switch } from 'components';

import Course from './Course';
import CreateCourseForm from './Course/CreateCourseForm';
import Courses from './Courses';
import Organism from './Organism';
import CreateOrganismForm from './Organism/CreateOrganismForm';
import Organisms from './Organisms';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/courses`} exact component={Courses} />
      <Route path={`${path}/organisms`} exact component={Organisms} />

      <Route path={`${path}/courses/new`} exact component={CreateCourseForm} />

      <Route
        path={`${path}/organisms/new`}
        exact
        component={CreateOrganismForm}
      />
      <Route path={`${path}/courses/:id`} exact component={Course} />
      <Route path={`${path}/organisms/:id`} exact component={Organism} />

      <Redirect to={`${path}/courses`} />
    </Switch>
  );
}
