import React from 'react';

import { getDisplayName } from 'helpers/hoc';
import { usePaginatedQuery } from 'helpers/hooks';

/**
 * /!\ props have changed from the original code! Do not use {...paginationProps} anymore
 * Higher-order component (HOC) that provides pagination functionality to a wrapped component.
 *
 * @param WrappedComponent - The component to wrap and provide pagination functionality to.
 * @returns A new component that wraps the provided component and injects pagination-related props.
 * @see usePaginatedQuery to see additional props that can be passed to the HOC via the component's props.
 *
 * @example
 *
 * const ParentComponent = () => {
 *   return (<MyComponent defaultFilter={{ active: true }} countPerPage={5} />);
 * }
 *
 * const MyComponent = ({
 *  page,
 *  countPerPage,
 *  queryParams: { search, userFilters },
 *  setNextPageParams,
 *  setPreviousPageParams,
 *  setQueryParams,
 *  isFetching,
 *  hasError,
 *  data,
 *  }) => {
 *    <DatatableWrapper
 *      collectionInfo={collectionInfo}
 *      isFetching={isFetching}
 *      hasError={hasError}
 *      page={page}
 *      countPerPage={countPerPage}
 *      search={search}
 *      getNextPage={setNextPageParams}
 *      getPreviousPage={setPreviousPageParams}
 *      onQueryParamsChange={setQueryParams}
 *      withSearch
 *      withUserMultiFilters
 *      userFilters={userFilters}
 *   />
 *  }
 * })
 *
 * export default withPagination(MyComponent);
 *
 * @remarks
 * The wrapped component will receive the following props in addition to its own props:
 * - `data`: The paginated data.
 * - `isLoading`: A boolean indicating if the data is currently being loaded.
 * - `error`: An error object if there was an error loading the data.
 * - `fetchNextPage`: A function to fetch the next page of data.
 * - `fetchPreviousPage`: A function to fetch the previous page of data.
 * - `hasNextPage`: A boolean indicating if there is a next page of data.
 * - `hasPreviousPage`: A boolean indicating if there is a previous page of data.
 */

const withPagination = (WrappedComponent: React.ComponentType<any>) => {
  const WithPagination = props => {
    const paginatedQuery = usePaginatedQuery(props);

    return <WrappedComponent {...props} {...paginatedQuery} />;
  };

  WithPagination.displayName = `withPagination(${getDisplayName(
    WrappedComponent
  )})`;

  return WithPagination;
};

export default withPagination;
