import React, { CSSProperties, ReactNode } from 'react';

type Props = {
  minCardWidth: number;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
};

/**
 * The layout will place as much cards as possible on a line,
 * having a width of `minCardWidth`.
 *
 * The remaining space on the right will be distributed evenly on cards,
 * so their width can exceed `minCardWidth`.
 *
 * If the container size is below `minCardWidth`,
 * the cards will be shrinked below.
 */
const GridCardLayout = ({
  children,
  minCardWidth,
  className,
  style,
}: Props) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fill, minmax(min(${minCardWidth}px, 100%), 1fr))`,
        gap: '1rem',
      }}
    >
      {children}
    </div>
  );
};

export default GridCardLayout;
