import React, { Fragment, ReactNode } from 'react';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { Box, Flex, Text, Title } from 'components';

type Value = {
  color: string;
  label: string;
  value: ValueType | null | undefined;
  unit?: string;
};

type Props = {
  title?: string;
  values: Value[];
  valueFormatter?: (line: Value) => ReactNode;
};

const ChartTooltip = ({
  title,
  values,
  valueFormatter: propValueFormatter,
}: Props) => {
  const valueFormatter =
    propValueFormatter ||
    ((line: Value) => (
      <Fragment>
        ({line.value}
        {line.unit && line.unit})
      </Fragment>
    ));

  return (
    <Box size="small" className="chart-tooltip p-0">
      {title && (
        <Title
          size={7}
          weight="semibold"
          color="white-bis"
          className="chart-tooltip-title"
        >
          {title}
        </Title>
      )}

      {values.map((line, index) => (
        <Flex key={index} className="chart-tooltip-value">
          <div
            className="circle"
            style={{
              backgroundColor: line.color,
            }}
          />
          <Text size={7} weight="semibold" color="grey">
            {line.label} {valueFormatter(line)}
          </Text>
        </Flex>
      ))}
    </Box>
  );
};

export default ChartTooltip;
