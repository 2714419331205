import React, { ReactNode } from 'react';

import type {
  PaginationProps,
  SearchChangeMethod,
  SortChangeMethod,
  UserFilterChangeMethod,
} from 'lib/dataLoader/pagination/types';
import type { CollectionInfo, UserFilterSegment } from 'models';

import {
  formatFilterToParamsHash,
  formatParamsHashToFilterName,
} from 'helpers/filter';
import { __ } from 'helpers/i18n';
import { computePageAndSearchDisplayValues } from 'helpers/pagination';

import {
  DefaultErrorNotification,
  FilterBar,
  Level,
  LevelItem,
  LevelLeft,
  LevelRight,
  Pagination,
  SearchBar,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import UserFilter from 'scenes/components/UserFilter';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

type HeaderRendererParams = {
  searchPlaceholder?: string;
  search?: string;
  onSearchChange?: SearchChangeMethod;
  onQueryParamsChange?: (queryParams: { [key: string]: any }) => void;
  sort?: 'asc' | 'desc' | undefined | null;
  onSortChange?: SortChangeMethod;
  filters?: Array<{
    param: string;
    label: string;
  }>;
  activeFilter: string | undefined | null;
  onFilterChange?: (filterParam: string) => void;
  userFilters?: ActiveFilters | '' | null;
  userFilter?: UserFilterSegment | null;
  onUserFilterChange?: UserFilterChangeMethod;
  withUserMultiFilters?: boolean;
  withSearch?: boolean;
  hideUserFilters?: boolean;
};

// TODO: Remove this Omit and optional onSearchChange and onFilterChange when updating all withPagination
type Props = Omit<PaginationProps, 'onSearchChange' | 'onFilterChange'> & {
  onSearchChange?: PaginationProps['onSearchChange'];
  onFilterChange?: PaginationProps['onFilterChange'];
} & {
  collectionInfo: CollectionInfo | undefined | null;
  totalCountRenderer?: (totalCount?: number | null) => string;
  isFetching: boolean;
  hasError: boolean;
  searchPlaceholder?: string;
  children: ReactNode;
  showTotalRecordCount: boolean;
  filters?: Array<{
    param: string;
    label: string;
  }>;
  renderHeader: (params: HeaderRendererParams) => ReactNode;
  renderNoRecord: ((filter?: string | null) => ReactNode) | undefined | null;
  renderNoResult: ((filter?: string | null) => ReactNode) | undefined | null;
  withUserMultiFilters?: true;
  onQueryParamsChange?: (queryParams: { [key: string]: any }) => void;
  withSearch?: boolean;
  hideUserFilters?: boolean;
};

export default class DatatableWrapper extends React.Component<Props> {
  static defaultProps = {
    showTotalRecordCount: true,
    withUserMultiFilters: false,
    renderHeader: ({
      searchPlaceholder,
      search,
      onSearchChange,
      filters,
      activeFilter,
      onFilterChange,
      userFilters,
      userFilter,
      onUserFilterChange,
      withUserMultiFilters,
      onQueryParamsChange,
      withSearch,
      hideUserFilters,
    }: HeaderRendererParams) => {
      return (
        <React.Fragment>
          <Level isMobile style={{ marginBottom: 16 }}>
            <LevelLeft>
              {(!!onSearchChange || withSearch) && (
                <LevelItem>
                  <SearchBar
                    value={search}
                    placeholder={searchPlaceholder}
                    onChange={
                      // TODO: multifilters: remove this if condition onSearchChange when FF is enabled for everyone
                      onSearchChange
                        ? onSearchChange
                        : search =>
                            onQueryParamsChange &&
                            onQueryParamsChange({ search })
                    }
                    style={{ width: '220px' }}
                  />
                </LevelItem>
              )}
              <LevelItem>
                {withUserMultiFilters && !hideUserFilters ? (
                  <UserMultiFilters
                    onChange={onQueryParamsChange}
                    userFilters={userFilters}
                  />
                ) : (
                  // TODO: multifilters: remove this if condition when FF is enabled for everyone
                  !!onUserFilterChange &&
                  !hideUserFilters && (
                    <UserFilter
                      segment={userFilter}
                      onChange={onUserFilterChange}
                    />
                  )
                )}
              </LevelItem>
            </LevelLeft>
            <LevelRight style={{ textAlign: 'end' }}>
              {filters && filters.length > 0 && (
                <FilterBar
                  display="link"
                  leftText={__('Show')}
                  filters={filters}
                  activeFilter={activeFilter}
                  onFilterChange={onFilterChange || (() => {})}
                />
              )}
            </LevelRight>
          </Level>
        </React.Fragment>
      );
    },
    renderNoRecord: null,
    renderNoResult: null,
  };

  handleFilterChange = (newFilterParam: string) => {
    // TODO: multifilters: remove this if condition onFilterChange when FF is enabled for everyone
    if (this.props.onFilterChange) {
      this.props.onFilterChange(formatFilterToParamsHash(newFilterParam));
    } else if (this.props.onQueryParamsChange) {
      this.props.onQueryParamsChange({
        filter: formatFilterToParamsHash(newFilterParam),
      });
    }
  };

  shouldRenderNoRecord = () => {
    const { isFetching, hasError, collectionInfo, userFilter, filter, search } =
      this.props;

    return (
      !isFetching &&
      !hasError &&
      !!collectionInfo &&
      collectionInfo.totalRecordCount === 0 &&
      !userFilter &&
      (!filter || filter.all) &&
      !search
    );
  };

  shouldRenderNoResult = () => {
    const { isFetching, collectionInfo } = this.props;

    return (
      !isFetching && !!collectionInfo && collectionInfo.totalRecordCount === 0
    );
  };

  renderContent = (activeFilter: string | null | undefined) => {
    const { hasError, renderNoResult, children } = this.props;

    if (hasError) {
      return <DefaultErrorNotification />;
    }

    if (!!renderNoResult && this.shouldRenderNoResult()) {
      return renderNoResult(activeFilter);
    }

    return children;
  };

  render() {
    const {
      isFetching,
      searchPlaceholder,
      nextPageLink,
      getNextPage,
      previousPageLink,
      getPreviousPage,
      collectionInfo,
      totalCountRenderer,
      showTotalRecordCount,
      page: optimisticPage,
      search: optimisticSearch,
      onSearchChange,
      filters,
      onUserFilterChange,
      userFilter,
      renderHeader,
      renderNoRecord,
      filter,
      withUserMultiFilters,
      withSearch,
      userFilters,
      onQueryParamsChange,
      hideUserFilters,
    } = this.props;

    const activeFilter = formatParamsHashToFilterName(filter);

    if (!!renderNoRecord && this.shouldRenderNoRecord()) {
      return renderNoRecord(activeFilter);
    }

    const { search, page } = computePageAndSearchDisplayValues(
      {
        search: optimisticSearch,
        page: optimisticPage,
      },
      collectionInfo,
      isFetching
    );

    return (
      <div className="datatable-wrapper">
        {renderHeader({
          searchPlaceholder,
          search,
          onSearchChange,
          filters,
          activeFilter,
          onFilterChange: this.handleFilterChange,
          userFilter,
          userFilters,
          onUserFilterChange,
          withUserMultiFilters,
          withSearch,
          onQueryParamsChange,
          hideUserFilters,
        })}

        {this.renderContent(activeFilter)}

        <Pagination
          collectionInfo={collectionInfo}
          totalCountRenderer={totalCountRenderer}
          page={page}
          previousPageLink={previousPageLink}
          getPreviousPage={getPreviousPage}
          nextPageLink={nextPageLink}
          getNextPage={getNextPage}
          isFetching={isFetching}
          showTotalRecordCount={showTotalRecordCount}
        />
      </div>
    );
  }
}
