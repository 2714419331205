import * as React from 'react';

import classNames from 'helpers/classNames';

type Props = {
  completion: number | null | undefined;
  className?: string;
};

export default function CompletionProgressBar({
  completion,
  className,
}: Props) {
  return (
    <progress
      className={classNames(
        'progress is-small is-primary completion-progress',
        className
      )}
      value={completion === null ? undefined : completion}
      max="100"
    >
      {completion} %
    </progress>
  );
}
