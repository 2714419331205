import { omit } from 'lodash';
import React, { Fragment } from 'react';
import { compose } from 'redux';

import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';
import type { PaginatedCollection, PeopleReviewCycle } from 'models';
import type { ComponentType } from 'react';

import compositeKey from 'helpers/compositeKey';
import { __, date, n__ } from 'helpers/i18n';
import { pathToPeopleReviewCycleReviews } from 'helpers/paths';

import { withDeprecatedPagination } from 'lib/dataLoader';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  FetchContainer,
  SimpleTable,
  Text,
} from 'components';

import StatusTag from 'scenes/components/StatusTag';
import ReviewEmptyState from 'scenes/components/userReview/ListEmptyState/index';

type Props = WithPaginationProps;
type AfterPaginateProps = Props & PaginationProps;

type AfterConnectProps = {
  peopleReviewCycleCollection: PaginatedCollection<PeopleReviewCycle>;
  isFetching: boolean;
  hasError: boolean;
} & AfterPaginateProps;

const PeopleReviewCycleListWithPagination = ({
  search,
  page,
  filter,
  countPerPage,
  peopleReviewCycleCollection,
  isFetching,
  hasError,
  onSearchChange,
  onFilterChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
}: AfterConnectProps) => {
  const peopleReviewCycles = peopleReviewCycleCollection
    ? peopleReviewCycleCollection.items
    : [];

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={
          peopleReviewCycleCollection
            ? omit(peopleReviewCycleCollection, 'items')
            : null
        }
        totalCountRenderer={(totalCount?: number | null) => {
          return n__(
            '%1 people review campaign',
            '%1 people review campaigns',
            totalCount || 0
          );
        }}
        search={search}
        page={page}
        countPerPage={countPerPage}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        filters={[
          { param: 'ongoing', label: __('Active or draft') },
          { param: 'finalized', label: __('Finalized') },
          { param: 'all', label: __('PeopleReviewCycle|All') },
        ]}
        filter={filter}
        searchPlaceholder={__('Search')}
        isFetching={isFetching}
        hasError={hasError}
        renderNoResult={() => (
          <ReviewEmptyState
            title={__('No review campaign matches your search')}
            inBoxList
          />
        )}
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleTable
              rows={peopleReviewCycles}
              columns={[
                {
                  header: __('Name'),
                  cell: campaign => (
                    <Text linkTo={pathToPeopleReviewCycleReviews(campaign.id)}>
                      {campaign.name}
                    </Text>
                  ),
                },
                {
                  header: __('Status'),
                  cell: campaign => <StatusTag status={campaign.status} />,
                },
                {
                  header: __('Date'),
                  cell: campaign => (
                    <Fragment>
                      <Text color="info">
                        {campaign.launchedAt
                          ? __('ReviewCycle|Launched on:')
                          : __('ReviewCycle|Created on:')}
                      </Text>{' '}
                      {date(campaign.launchedAt || campaign.createdAt)}
                    </Fragment>
                  ),
                  isNarrow: true,
                },
              ]}
              keyFn={peopleReviewCycle => peopleReviewCycle.id}
              className="mb-4"
              rowClassName="test-people-review-cycle-row"
            />
          )}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({ page, countPerPage, search, filter }: AfterPaginateProps) =>
      get('people_review_cycles', {
        page,
        countPerPage,
        search,
        filter,
      }),
    hydrate: {
      peopleReviewCycleCollection: {
        items: {},
      },
    },
    cacheKey: ({ page, countPerPage, search, filter }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter }),
  })
)(PeopleReviewCycleListWithPagination);
