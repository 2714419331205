import React from 'react';

import { UserReviewSummary } from 'models';

import { __ } from 'helpers/i18n';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import AILogo from 'scenes/components/AILogo';

type Props = {
  summary: UserReviewSummary;
  onClose: () => void;
};

const SummaryModal = ({ summary, onClose }: Props) => {
  return (
    <ModalCard onClose={onClose} isActive isLarge>
      <ModalCardHead>
        <ModalCardTitle>
          {__("Review's key points")}
          <AILogo className="ml-4" />
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <Text className="whitespace-pre-wrap">{summary.content}</Text>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default SummaryModal;
