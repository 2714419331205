import React, { useContext } from 'react';

import type {
  DropdownQuestionBlock,
  LegacyQuestionBlock,
  MultipleScaleQuestionBlock,
  UserFilterSegment,
} from 'models';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import {
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Select,
  Text,
} from 'components';

import UserFilter from 'scenes/components/UserFilter';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

import { UserFiltersContext } from '.';
import AnswersExplorer from './AnswersExplorer';

type Props = {
  reviewCycleId: string;
  block:
    | DropdownQuestionBlock
    | LegacyQuestionBlock
    | MultipleScaleQuestionBlock;
  defaultFilter?: {
    [filter: string]: boolean;
  };
  userFilter: UserFilterSegment | undefined | null;
  option: string | undefined | null;
  criterion?: string;
  onClose: () => void;
};

const FeedbackPerOptionModal = ({
  reviewCycleId,
  block,
  defaultFilter,
  userFilter,
  option: defaultOption,
  criterion,
  onClose,
}: Props) => {
  const [option, setOption] = React.useState(defaultOption);
  const { userFilters } = useContext(UserFiltersContext);

  return (
    <ModalCard
      isActive
      isLarge
      onClose={onClose}
      refreshContentOnOpening={false}
      className="feedback-per-option-modal"
    >
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>
          {__('Details on the question "%1"', block.content.title || '')}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        {block.blockType === 'multipleScaleQuestion' && (
          <div>
            <Text>{__('Criterion:')} </Text>
            <Text weight="semibold">{criterion}</Text>
          </div>
        )}
        <div
          style={
            block.blockType === 'multipleScaleQuestion'
              ? { display: 'flex', flexDirection: 'column' }
              : {}
          }
        >
          <Text>
            {!!option
              ? __('The participants listed below responded:')
              : __(
                  'The participants listed below did not choose any answer in the dropdown'
                )}{' '}
          </Text>
          {!!option && block.blockType === 'multipleScaleQuestion' ? (
            <Select
              value={{ value: option, label: option }}
              options={block.ratingOptions.map(option => ({
                value: option.label,
                label: option.label,
              }))}
              onChange={option => {
                invariant(
                  option && !Array.isArray(option),
                  'Option must exist and cannot be an array'
                );
                setOption(option.value);
              }}
              isMulti={false}
              isClearable={false}
              inModal
            />
          ) : (
            <Text weight="semibold">{option}</Text>
          )}
        </div>

        {!!userFilter && (
          <UserFilter segment={userFilter} onChange={() => {}} disabled />
        )}
        {!!userFilters && (
          <UserMultiFilters
            userFilters={userFilters}
            onChange={() => {}}
            disabled
            className="mb-2"
          />
        )}
        <AnswersExplorer
          block={block}
          reviewCycleId={reviewCycleId}
          userFilter={userFilter}
          userFilters={userFilters}
          defaultFilter={defaultFilter}
          criterion={criterion}
          option={option}
        />
      </ModalCardBody>
      <ModalCardFooter />
    </ModalCard>
  );
};

export default FeedbackPerOptionModal;
