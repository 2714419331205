import { truncate } from 'lodash';
import React from 'react';
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Text,
} from 'recharts';

import { RadarOption, RadarPoint } from 'models/RadarChart';

import classNames from 'helpers/classNames';

import { DesignSystem, Hoverable, Link } from 'components';

type Props = {
  axisLabels: Array<string>;
  data: Array<RadarPoint>;
  axisDataKey: string;
  radarOptions: Array<RadarOption>;
  height?: number;
  outerRadius?: number;
  adjustTextPositionCoefficient?: number;
  className?: string;
  onCriterionClick?: (criterion: string) => void;
};

const MAX_POLAR_ANGLE__LABEL_LENGTH = 52;
const MAX_POLAR_RADIUS_LABEL_LENGTH = 28;

function renderCustomPolarRadiusAxis({ label, payload, index, x, y, ...rest }) {
  const xPosition = x + 8;
  const yPosition = y - 4;

  return (
    <text {...rest} x={xPosition} y={yPosition}>
      {truncate(label, { length: MAX_POLAR_RADIUS_LABEL_LENGTH })}
    </text>
  );
}

function renderPolarAngleAxis({
  payload,
  x,
  y,
  cx,
  cy,
  adjustTextPositionCoefficient,
  onCriterionClick,
  ...rest
}) {
  // We move the text away from the center
  const xPosition = x + (x - cx) / adjustTextPositionCoefficient;
  const yPosition = y + (y - cy) / adjustTextPositionCoefficient;

  const maxWidth = Math.min(2 * cx - xPosition, xPosition);

  const TextNode = (
    <Hoverable
      componentType="g"
      render={isHovered => (
        <Text
          {...rest}
          x={xPosition}
          y={yPosition}
          width={maxWidth}
          verticalAnchor="middle"
        >
          {truncate(payload.value, {
            length: isHovered
              ? payload.value.length
              : MAX_POLAR_ANGLE__LABEL_LENGTH,
          })}
        </Text>
      )}
    />
  );

  return (
    <g>
      <title>{payload.value}</title>
      {!!onCriterionClick ? (
        <Link
          onClick={() => onCriterionClick(payload.value)}
          hasInheritedColor
          disabled={!onCriterionClick}
        >
          {TextNode}
        </Link>
      ) : (
        TextNode
      )}
    </g>
  );
}

export default function CustomRadarChart({
  data,
  axisLabels,
  axisDataKey,
  radarOptions,
  height = 330,
  outerRadius = 90,
  adjustTextPositionCoefficient = 3,
  className,
  onCriterionClick,
}: Props) {
  // the 0th index is the element in the center of the chart we don't display it.
  // we put the first label in the next axis point
  const labels = ['', ...axisLabels];

  if (!data.length) return null;

  return (
    <DesignSystem version={2} style={{ height }}>
      <div
        className={classNames(
          'radar-chart-container test-radar-chart-container',
          className
        )}
        style={{ width: '100%', height: '100%' }}
      >
        <div
          className="hidden test-radar-chart-data"
          data-test-radarchartdata={JSON.stringify(data)}
        />
        <ResponsiveContainer className="radar-chart test-radar-chart">
          <RadarChart data={data} outerRadius={outerRadius}>
            <PolarGrid gridType="circle" />
            <PolarAngleAxis
              dataKey={axisDataKey}
              tick={props =>
                renderPolarAngleAxis({
                  ...props,
                  adjustTextPositionCoefficient: adjustTextPositionCoefficient,
                  onCriterionClick: onCriterionClick,
                })
              }
            />

            <PolarRadiusAxis
              angle={90}
              type="number"
              domain={[0, axisLabels.length]}
              tickCount={axisLabels.length + 1}
              tick={({ index, ...props }) =>
                renderCustomPolarRadiusAxis({ ...props, label: labels[index] })
              }
            />

            {radarOptions.map(({ name, dataKey, color }) => (
              <Radar
                key={dataKey}
                name={name}
                dataKey={dataKey}
                stroke={color}
                fill={color}
                fillOpacity={0.4}
              />
            ))}

            <Legend
              iconType="circle"
              align="right"
              verticalAlign="top"
              iconSize={8}
            />
          </RadarChart>
        </ResponsiveContainer>
      </div>
    </DesignSystem>
  );
}
