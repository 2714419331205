import { truncate } from 'lodash';
import React from 'react';

import { __ } from 'helpers/i18n';

import { Text, Tooltip } from 'components';

type Props = {
  direction: 'x' | 'y';
  dimensionName: string;
  labels: string[];
};

const MAX_LABEL_LENGTHS = { x: 55, y: 35 };

const Axis = ({ direction, dimensionName, labels }: Props) => (
  <div className={`heatmap__${direction}-axis`}>
    <span
      className={`heatmap__arrow-line heatmap__arrow-line--${direction}-axis`}
    />
    <div className={`heatmap__labels--${direction}-axis`}>
      {labels.map((label, index) => {
        const truncatedLabel = truncate(label, {
          length: MAX_LABEL_LENGTHS[direction],
        });
        const isTruncated = truncatedLabel.length < label.length;

        return (
          <div key={index}>
            <Text preset="13s7">
              {isTruncated ? (
                <Tooltip content={label} style={{ maxWidth: '400px' }}>
                  {truncatedLabel}
                </Tooltip>
              ) : (
                label
              )}
            </Text>
            <Text preset="13s7" color="light">
              {__('Score: %1', index + 1)}
            </Text>
          </div>
        );
      })}
    </div>
    <Text
      preset="14bs6"
      className={`heatmap__dimension_name--${direction}-axis`}
    >
      {dimensionName}
    </Text>
  </div>
);

export default Axis;
