import { flatten, sortBy } from 'lodash';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { CSSProperties } from 'react';

import type { User } from 'models';

import classNames from 'helpers/classNames';

import { DiscreteSlider } from 'components';

import { Marks, Points } from './DiscreteSlider.types';

type Props = {
  labels: Array<string>;
  usersBySelectedLabel: { [key: string]: User[] };
  hideLabels?: boolean;
  className?: string;
  testClassName?: string;
  style?: CSSProperties;
  hasWhiteRail?: boolean;
};

const DiscreteSliderResults = ({
  labels,
  usersBySelectedLabel,
  hideLabels = false,
  className,
  testClassName,
  style,
  hasWhiteRail,
}: Props) => {
  let marks: Marks = {};

  labels.forEach((label, index) => {
    marks[index] = label;
  });

  let points: Points = {};

  Object.keys(usersBySelectedLabel).forEach(label => {
    if (!labels.includes(label)) {
      return;
    }

    const users = usersBySelectedLabel[label];

    const value = Object.keys(marks).find(key => marks[key] === label);

    points[`point${value}`] = {
      defaultValue: value,
      handleValues: [], // This array will contain a number of element equal to the quantity of user who responded to this label
      handleStyles: [],
      trackStyles: [],
    };

    users.forEach((user, index) => {
      const baseOffset = 12;
      const offset = index * baseOffset - ((users.length - 1) * baseOffset) / 2;

      points[`point${value}`]['handleValues'].push(value);

      points[`point${value}`]['handleStyles'].push({
        backgroundImage: `url(${user.avatarUrl})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        marginLeft: `${offset}px`,
        borderColor: 'transparent',
      });

      points[`point${value}`]['trackStyles'].push({
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        backgroundImage: 'none',
      });
    });
  });

  if (Object.keys(points).length === 0) {
    return (
      <DiscreteSlider
        labels={labels}
        hideLabels={hideLabels}
        isDisabled
        selectedLabel={null}
        className={className}
        testClassName={testClassName}
        style={style}
        hasWhiteRail={hasWhiteRail}
      />
    );
  }

  const sortedPointsByValue = sortBy(points, point => point['defaultValue']);

  const handleValues = flatten(
    sortedPointsByValue.map(point => point['handleValues'])
  );
  const handleStyles = flatten(
    sortedPointsByValue.map(point => point['handleStyles'])
  );

  // As we tweak the Slider to show various results on 1 slider using range, when we are in the
  // case we have only 1 response we need to double it to simulate 2 dots/values
  if (handleStyles.length === 1) {
    handleStyles.push(handleStyles[0]);
  }

  if (hideLabels) {
    Object.keys(marks).forEach(key => {
      marks[key] = null;
    });
  }

  return (
    <div
      className={classNames(
        'discrete-slider test-discrete-slider-result',
        className,
        testClassName,
        { 'has-white-rail': hasWhiteRail }
      )}
      style={style}
    >
      <Slider
        range
        dots
        min={0}
        max={Math.max(0, labels.length - 1)}
        count={Math.max(1, handleValues.length - 1)}
        marks={hideLabels ? undefined : marks}
        value={handleValues}
        disabled
        handleStyle={handleStyles}
        trackStyle={flatten(
          sortedPointsByValue.map(point => point['trackStyles'])
        )}
      />
    </div>
  );
};

export default DiscreteSliderResults;
