import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { DropdownIndicatorProps, components } from 'react-select';

import { UserFilterSegment, UserFilterSegmentValue } from 'models';

import store from 'config/store';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n/index';

import { hydrateFromResponse } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Icon, Select } from 'components';

import { Option } from './Option';
import {
  formatOptionToSegmentValue,
  formatSegmentEligibilityToOption,
  formatSegmentToOption,
} from './formater';

export type OptionType = {
  value: UserFilterSegmentValue;
  label: string;
  segment: UserFilterSegment;
  disabled: boolean;
  disabledReason: string | null;
};

type Props = {
  onChange: (segments: Array<UserFilterSegment>) => any;
  autoAddRuleId: string;
  selectedSegments: Array<UserFilterSegment>;
  reviewCycleId: string;
  triggerRefreshCount: number;
  isMulti: boolean;
};

type State = {
  defaultOptions: Array<OptionType>;
  isLoadingDefaultOptions: boolean;
  isMounted: boolean;
};

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon name="search" />
  </components.DropdownIndicator>
);

export default function UserSegmentsPicker({
  onChange,
  autoAddRuleId,
  reviewCycleId,
  selectedSegments,
  triggerRefreshCount,
  isMulti,
}: Props) {
  const selectedOptions = selectedSegments.map(formatSegmentToOption);
  const [state, setState] = useState<State>({
    defaultOptions: [],
    isLoadingDefaultOptions: true,
    isMounted: false,
  });
  const { defaultOptions, isLoadingDefaultOptions, isMounted } = state;

  const handleChange = (OptionType: OptionType[] | OptionType | null) => {
    if (!OptionType) return onChange([]);

    return onChange(
      Array.isArray(OptionType)
        ? OptionType.map(formatOptionToSegmentValue)
        : [formatOptionToSegmentValue(OptionType)]
    );
  };
  const dispatch = useAppDispatch();

  const loadOptions = useCallback(
    async (search: string): Promise<Array<OptionType>> => {
      if (search === '') {
        setState(prevState => ({
          ...prevState,
          isLoadingDefaultOptions: true,
        }));
      }
      const res = await dispatch(
        get(
          `review_cycles/${reviewCycleId}/auto_add_rules/${autoAddRuleId}/segment_eligibilities`,
          { search },
          {
            withDefaultErrorHandling: true,
          }
        )
      );
      const eligibilities = hydrateFromResponse(
        store.getState().data,
        res.response.body,
        { autoAddRuleSegmentEligibility: { segment: {} } },
        res.response.body.data.id
      );
      const options = eligibilities.map(formatSegmentEligibilityToOption);
      if (search === '') {
        setState(prevState => ({
          ...prevState,
          defaultOptions: options,
          isLoadingDefaultOptions: false,
        }));
      }
      return options;
    },
    [dispatch, autoAddRuleId, reviewCycleId]
  );

  useEffect(() => {
    setState(prevState => ({ ...prevState, isMounted: true }));
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    loadOptions('');
  }, [triggerRefreshCount, loadOptions, isMounted]);

  const debouncedSearch = debounce(
    async (search, callback) => {
      const options = await loadOptions(search);
      callback(options);
    },
    200,
    {
      leading: false,
      trailing: true,
    }
  );

  return (
    <Select
      className="segments-select"
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      onChange={handleChange}
      placeholder={__('Select value(s)')}
      value={selectedOptions}
      hideSelectedOptions={false}
      isOptionDisabled={(option: OptionType) => option.disabled}
      captureMenuScroll
      isMulti={isMulti}
      inModal
      isClearable
      components={{
        Option: Option,
        DropdownIndicator,
      }}
      isAsync
      isLoading={isLoadingDefaultOptions}
      loadingMessage={() => __('Loading values…')}
      loadOptions={debouncedSearch}
      defaultOptions={defaultOptions}
      cacheOptions={!isLoadingDefaultOptions}
    />
  );
}
