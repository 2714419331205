import React, { Fragment } from 'react';

import type { FeedbackableItem, FeedbackableSkillsDomain } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { assert } from 'helpers/invariant';

import { Text } from 'components';

import FeedbackZone from '../../components/FeedbackZone';
import ClosedAnswers from './components/ClosedAnswers';
import OpenAnswer from './components/OpenAnswer';

type Props = {
  feedbackableDomain: FeedbackableItem<FeedbackableSkillsDomain>;
  persistAction: (
    evaluationId: string | null,
    feedbackableId: string,
    feedbackableType: string,
    text: string | undefined,
    rating: string | null | undefined
  ) => (dispatch: AppDispatch) => Promise<void>;
};

const OverallDomainFeedbackZone = ({
  feedbackableDomain,
  persistAction,
}: Props) => {
  const dispatch = useAppDispatch();
  const ratingOptions = feedbackableDomain.answers[0]?.ratings || [];
  const ratingEnabled = feedbackableDomain.answers.some(
    answer => answer.ratingEnabled
  );
  const domainOpenedAnswer = feedbackableDomain.answers.find(
    answer => answer.status === 'opened'
  );
  const domainClosedAnswers = feedbackableDomain.answers.filter(
    answer => answer.status === 'shared'
  );

  return (
    <Fragment>
      {ratingEnabled && (
        <Fragment>
          <div className="block bg-highlight-gray br-2 rounded-lg mb-4 lg:grid lg:grid-cols-12 lg:mt-2 lg:pb-4">
            <Text
              preset="13bs7"
              className="mt-3 ml-2 lg:col-span-12 lg:mb-0 lg:ml-4"
            >
              {__('Domain overall assessment')}
            </Text>
            {!!domainOpenedAnswer ? (
              <OpenAnswer
                autoSaveUid={compositeKey({
                  type: feedbackableDomain.type,
                  id: feedbackableDomain.id,
                  field: 'rating',
                })}
                rating={domainOpenedAnswer.rating}
                ratingOptions={ratingOptions}
                closedAnswers={domainClosedAnswers}
                renderTooltipContent={user => user.fullName}
                persistFeedback={(rating: string | null) =>
                  dispatch(
                    persistAction(
                      assert(
                        domainOpenedAnswer.evaluationId,
                        'EvaluationId should be defined'
                      ),
                      feedbackableDomain.item.id,
                      feedbackableDomain.item.type,
                      undefined,
                      rating
                    )
                  )
                }
                isOverallRating
              />
            ) : (
              <ClosedAnswers
                ratingOptions={ratingOptions}
                closedAnswers={domainClosedAnswers}
                isOverallRating
              />
            )}
          </div>
        </Fragment>
      )}
      <FeedbackZone
        feedbackableItem={feedbackableDomain}
        persistAction={persistAction}
      />
    </Fragment>
  );
};

export default OverallDomainFeedbackZone;
