import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { User } from 'models';
import type { AppDispatch } from 'redux/actions';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';
import { addToken } from 'helpers/url';

import { createUrl } from 'lib/api';
import { post } from 'redux/actions/api';

import { FetchContainer, MenuItem } from 'components';

import ReactivateUserModal from './ReactivateUserModal';
import SuspendUserModal from './SuspendUserModal';
import UserListItem from './UserListItem';

type Props = {
  users: Array<User>;
  isFetching: boolean;
  hasError: boolean;
  displayActions: boolean;
};

type State = {
  userToSuspend: User | undefined | null;
  userToReactivate: User | undefined | null;
  userToAnonymize: User | undefined | null;
};

type AfterConnectProps = {
  sendResetPasswordEmail: (email: string) => Promise<void>;
  sendInvitationEmail: (userId: string, email: string) => Promise<void>;
  sendLoginInstructionsEmail: (userId: string, email: string) => Promise<void>;
  anonymizeUser: (user: User) => Promise<void>;
  createAccessCode: (user: User) => void;
} & Props;

class UsersList extends React.Component<AfterConnectProps, State> {
  state = {
    userToSuspend: null,
    userToReactivate: null,
    userToAnonymize: null,
  };

  shouldComponentUpdate(
    { users: nextUsers, isFetching: nextIsFetching }: AfterConnectProps,
    {
      userToSuspend: nextUserToSuspend,
      userToReactivate: nextUserToReactivate,
      userToAnonymize: nextUserToAnonymize,
    }: State
  ) {
    const { users, isFetching } = this.props;
    const { userToSuspend, userToReactivate, userToAnonymize } = this.state;

    return !(
      users === nextUsers &&
      isFetching === nextIsFetching &&
      userToSuspend === nextUserToSuspend &&
      userToReactivate === nextUserToReactivate &&
      userToAnonymize === nextUserToAnonymize
    );
  }

  getActionsForUser(user: User): Array<ReactElement<typeof MenuItem>> {
    const menu: Array<ReactElement<typeof MenuItem>> = [];
    const { displayActions } = this.props;

    if (!displayActions) {
      return menu;
    }

    const { email } = user;

    if (can({ perform: 'suspend', on: user })) {
      menu.push(
        <MenuItem
          onClick={() => this.showSuspendModalForUser(user)}
          key="suspend"
        >
          {__('Suspend')}
        </MenuItem>
      );
    }

    if (can({ perform: 'reactivate', on: user })) {
      menu.push(
        <MenuItem
          onClick={() => this.showReactivateModalForUser(user)}
          key="reactivate"
        >
          {__('Reactivate')}
        </MenuItem>
      );
    }

    if (can({ perform: 'generate_access_code', on: user })) {
      menu.push(
        <MenuItem
          onClick={() => this.props.createAccessCode(user)}
          key="generate_access_code"
          testClassName={`test-user-list-action-generate-access-code-${user.id}`}
        >
          {__('Create access code')}
        </MenuItem>
      );
    }

    if (can({ perform: 'send_reset_password_email', on: user })) {
      menu.push(
        <MenuItem
          onClick={() => this.props.sendResetPasswordEmail(email)}
          key="reset_password_instruction"
        >
          {__('Send reset password instructions email')}
        </MenuItem>
      );
    }

    if (can({ perform: 'resend_invitation_email', on: user })) {
      menu.push(
        <MenuItem
          onClick={() => this.props.sendInvitationEmail(user.id, email)}
          key="resend_invitation"
        >
          {__('Re-send invitation email')}
        </MenuItem>
      );
    }

    if (can({ perform: 'send_invitation_email', on: user })) {
      menu.push(
        <MenuItem
          onClick={() => this.props.sendInvitationEmail(user.id, email)}
          key="send_invitation"
        >
          {__('Send invitation email')}
        </MenuItem>
      );
    }

    if (can({ perform: 'send_login_instructions_email', on: user })) {
      menu.push(
        <MenuItem
          onClick={() => this.props.sendLoginInstructionsEmail(user.id, email)}
          key="send_invitation"
        >
          {__('Send login instructions email')}
        </MenuItem>
      );
    }

    if (can({ perform: 'anonymize', on: user })) {
      menu.push(
        <MenuItem
          onClick={() => this.displayAnonymizeUserModal(user)}
          key="anonymize"
          isDanger
        >
          {__('Anonymize')}
        </MenuItem>
      );
    }

    return menu;
  }

  showSuspendModalForUser = (user: User) => {
    this.setState({
      userToSuspend: user,
    });
  };

  showReactivateModalForUser = (user: User) => {
    this.setState({
      userToReactivate: user,
    });
  };

  displayAnonymizeUserModal = (user: User) => {
    const displayName = user?.fullName || '';

    confirmAsync(
      __("Anonymize %1's data", displayName),
      <span style={{ marginBottom: 16 }}>
        <span>
          <b>
            {__(
              'Once anonymized, personal information of this employee will be blanked and will not be recoverable.'
            )}
          </b>
        </span>
        <br />
        <span>
          {__(
            'The feedback, objectives, reviews and non-personal data produced by %1 will be kept.',
            displayName
          )}
        </span>
      </span>,
      {
        onConfirm: async () => this.props.anonymizeUser(user),
        confirmLabel: __('Anonymize data'),
        sideEffects: [__("Anonymize permanently %1's data", displayName)],
        sideEffectsLabel: __(
          'To confirm you have understood, pleack check the box below:'
        ),
        irreversibleWarningLabel: __(
          'This action cannot be undone %1',
          <b>{__('even by Elevo support')}</b>
        ),
      }
    );
  };

  dismissModal = () => {
    this.setState({
      userToSuspend: null,
      userToReactivate: null,
      userToAnonymize: null,
    });
  };

  render() {
    const { users, isFetching, hasError } = this.props;
    const { userToSuspend, userToReactivate } = this.state;

    return (
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="overlay"
        render={() => {
          return (
            <React.Fragment>
              {users &&
                users.length > 0 &&
                users.map(user => (
                  <UserListItem
                    key={user.id}
                    user={user}
                    manager={user.manager}
                    actions={this.getActionsForUser(user)}
                  />
                ))}

              <SuspendUserModal
                isActive={!!userToSuspend}
                onDismiss={this.dismissModal}
                user={userToSuspend}
              />

              <ReactivateUserModal
                isActive={!!userToReactivate}
                onDismiss={this.dismissModal}
                user={userToReactivate}
              />
            </React.Fragment>
          );
        }}
      />
    );
  }
}

function getAccessCode(id: string) {
  const url = createUrl(`users/${id}/generate_access_code`);

  window.location.replace(addToken(url));
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendResetPasswordEmail: async (email: string) =>
    dispatch(
      post(
        'users/password/reset',
        { user: { login: email } },
        {
          successMessage: __(
            'An email has been sent to %1 allowing to reset their password.',
            email
          ),
          errorMessage: __('Failed to send reset password instructions'),
        }
      )
    ),
  sendInvitationEmail: async (userId: string, email: string) =>
    dispatch(
      post(`users/${userId}/send_invitation`, undefined, {
        successMessage: __('An invitation email has been sent to %1.', email),
        errorMessage: __('Failed to send invitation email.'),
      })
    ),
  sendLoginInstructionsEmail: async (userId: string, email: string) =>
    dispatch(
      post(`users/${userId}/send_login_instructions`, undefined, {
        successMessage: __('An invitation email has been sent to %1.', email),
        errorMessage: __('Failed to send invitation email.'),
      })
    ),
  anonymizeUser: async (user: User) =>
    dispatch(
      post(`users/${user.id}/anonymize`, undefined, {
        successMessage: __('%1 has been anonymized.', user.fullName),
        errorMessage: __('Failed to anonymize %1.', user.fullName),
      })
    ),
  createAccessCode: (user: User) => getAccessCode(user.id),
});

export default compose(connect(null, mapDispatchToProps))(
  UsersList
) as React.ComponentType<Props>;
