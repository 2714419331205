import React, { ReactNode } from 'react';

import { type TrainingPlanValidatedBudgetStats } from 'models';

import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { CompletionProgressBar, Text } from 'components';

type Props = { link: ReactNode; stats: TrainingPlanValidatedBudgetStats };

const DeprecatedStats = ({ stats, link }: Props) => {
  const withCompletion = stats.provisionedBudgetCents;

  const rawCompletionPercentage =
    ((stats.usedBudgetCents || 0) / (stats.provisionedBudgetCents || 1)) * 100;
  const roundedCompletionPercentage = parseFloat(
    rawCompletionPercentage.toFixed(1)
  );

  const topRow = () => {
    if (withCompletion) {
      return (
        <div className="flex justify-between">
          <div className="flex">
            <Text preset="14bs6">{__('Remaining budget')}</Text>
          </div>
          <div className="flex">
            <Text
              preset="18bs5"
              color="primary"
              testClassName="test-remaining-budget-amount"
            >
              {formatMoney(stats.remainingBudgetCents, stats.currency)}
            </Text>
          </div>
        </div>
      );
    }

    return (
      <div className="flex">
        <Text preset="14bs6">{__('Budget spent')}</Text>
      </div>
    );
  };

  const bottomRow = () => {
    let leftText: ReactNode = null;

    if (withCompletion) {
      leftText = (
        <Text className="remaining-budget-completion-text">
          {__('%1% spent', roundedCompletionPercentage)}
        </Text>
      );
    } else {
      leftText = (
        <Text
          preset="18bs5"
          color="primary"
          testClassName="test-budget-spent-amount"
        >
          {formatMoney(stats.usedBudgetCents, stats.currency)}
        </Text>
      );
    }

    return (
      <div className="flex justify-between">
        <div className="flex">{leftText}</div>
        <div className="flex align-right">{link}</div>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      {topRow()}

      {withCompletion ? (
        <CompletionProgressBar
          className="remaining-budget-completion-bar my-2"
          completion={roundedCompletionPercentage}
        />
      ) : (
        <div className="h-2" />
      )}

      {bottomRow()}
    </div>
  );
};

export default DeprecatedStats;
