import React, { useContext, useState } from 'react';

import { SkillsDomain } from 'models';

import { FormErrors, handleFormErrors } from 'helpers/api';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { htmlSuccessNotice } from 'redux/actions';
import { post, put } from 'redux/actions/api';

import {
  Button,
  Field,
  FieldError,
  Input,
  Label,
  Link,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import { DataContext } from '..';
import DeletionConfirmationModal from './DeletionConfirmationModal';
import SkillAreasPicker from './SkillAreasPicker';

type Props = {
  onClose: () => void;
  domain: SkillsDomain | null;
};

type ElementDataType = Required<Omit<SkillsDomain, 'id'>>;

const SkillDomainManagementModal = ({ onClose, domain }: Props) => {
  const dispatch = useAppDispatch();

  const [elementData, setElementData] = useState<ElementDataType>({
    title: domain?.title || '',
    areaIds: domain?.areaIds || [],
    matricesCount: domain?.matricesCount || 0,
  });
  const [errors, setErrors] = useState<FormErrors>();
  const [deletionRequested, setDeletionRequested] = useState(false);

  const { setShouldRefetchSkills } = useContext(DataContext);

  const resetModal = () => {
    onClose();
    setShouldRefetchSkills(true);
  };

  const createElement = () =>
    handleFormErrors(async () => {
      await dispatch(post('skills/domains', elementData));
      dispatch(
        htmlSuccessNotice(
          __(
            '<b>The skills domain %1 has been successfully created.</b><br/>You can now add it to skills matrices or link skills to it from the skills library.',
            elementData.title
          )
        )
      );

      resetModal();
    }, setErrors);

  const updateElement = () =>
    handleFormErrors(async () => {
      await dispatch(
        put(`skills/domains/${(domain as SkillsDomain).id}`, elementData)
      );
      dispatch(
        htmlSuccessNotice(
          __(
            '<b>The skills domain %1 has been successfully updated.</b><br/>The updates have been applied to existing matrices and will be reflected in upcoming evaluations.',
            elementData.title
          )
        )
      );

      resetModal();
    }, setErrors);

  if (deletionRequested && !!domain) {
    return (
      <DeletionConfirmationModal
        domain={domain}
        onClose={() => {
          resetModal();
        }}
      />
    );
  }

  return (
    <ModalCard
      isActive
      onClose={onClose}
      isLarge
      refreshContentOnOpening
      nonEscapable
    >
      <ModalCardHead>
        <ModalCardTitle>
          {!!domain
            ? __('Update the skills domain')
            : __('Create a skills domain')}
        </ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody overflowVisible>
        <Field>
          <Label>{__('Domain title')}</Label>
          <Input
            value={elementData.title}
            placeholder={__('Domain title')}
            onChange={(title: string) =>
              setElementData({ ...elementData, title })
            }
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
          <FieldError>{errors?.title}</FieldError>
        </Field>

        <Field className="mb-3">
          <Label>{__('Add skills to the domain')}</Label>
          <div className="mt-4">
            <SkillAreasPicker
              domainId={domain?.id || null}
              areaIds={elementData.areaIds}
              onUpdate={(areaIds: Array<string>) =>
                setElementData({ ...elementData, areaIds })
              }
            />
          </div>
        </Field>
        {!!domain && (
          <div className="flex justify-center m-4">
            <Text color="danger">
              <Link
                hasInheritedColor
                onClick={() => setDeletionRequested(true)}
              >
                {__('Delete the domain')}
              </Link>
            </Text>
          </div>
        )}
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
        <Button
          color="primary"
          disabled={(elementData.title || '').length === 0}
          onClick={!!domain ? updateElement : createElement}
        >
          {!!domain ? __('Update the domain') : __('Create the domain')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default SkillDomainManagementModal;
