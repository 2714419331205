import React from 'react';

import type { SkillsStatsDevelopmentNeedsAreaData } from 'models';

import classNames from 'helpers/classNames';
import { colorStyleFromCellData, getColorThresholds } from 'helpers/heatmap';
import { __, n__ } from 'helpers/i18n';

import {
  BoxListItem,
  Column,
  Columns,
  Flex,
  Icon,
  Percentage,
  Text,
  Title,
  Tooltip,
} from 'components';

type Props = {
  id: string;
  row: SkillsStatsDevelopmentNeedsAreaData;
  ratingOptions: Array<{
    label: string;
    position: number;
    id: number | 'none';
  }>;
  setSelectedRating: (string) => void;
};

const tooltipContent = (skillAreaTitle, value, percent) => {
  return (
    <div>
      <Title size={6} className="tooltip-title" color="white-bis">
        {__('Some reviewers indicated they cannot evaluate %1', skillAreaTitle)}
      </Title>
      <Text>
        {n__(
          '%1 employee, representing %2% of those reviewed, was not rated in this skill',
          '%1 employees, representing %2% of those reviewed, were not rated in this skill',
          value,
          percent
        )}
      </Text>
    </div>
  );
};

const Item = ({ id, row, ratingOptions, setSelectedRating }: Props) => {
  const skillAreaTitle = row.areaTitle;

  // non-dynamic color thresholds with a fixed base of 100(%)
  const colorThresholds = getColorThresholds([[{ value: 100 }]]);

  const countsByRating = row.countsByRating;
  const noneValue = countsByRating.none || 0;
  const totalCount = Object.values(countsByRating).reduce((a, b) => a + b);
  const totalCountWithoutNones = totalCount - noneValue;
  const nonePercent =
    totalCount && totalCount > 0
      ? Math.ceil((noneValue * 100) / totalCount)
      : 0;

  return (
    <BoxListItem key={id}>
      <Columns contentVerticallyAligned>
        <Column isVerticallyCentered>
          <Flex className="is-size-7 has-text-weight-semibold text-ellipsis">
            <Tooltip
              triggerClassName="line-clamp-1 pr-2 grow"
              content={skillAreaTitle}
              placement="top"
            >
              {skillAreaTitle}
            </Tooltip>
            {noneValue > 0 && (
              <Tooltip
                content={tooltipContent(skillAreaTitle, noneValue, nonePercent)}
                triggerClassName="shrink-0"
              >
                <Icon size="large" className="has-text-light" name="info" />
              </Tooltip>
            )}
          </Flex>
        </Column>
        {ratingOptions.map((ratingOption, colIndex) => {
          const cellValue =
            totalCountWithoutNones && totalCountWithoutNones > 0
              ? Math.ceil(
                  ((countsByRating[ratingOption.id] || 0) * 100) /
                    totalCountWithoutNones
                )
              : 0;
          const colorStyle = colorStyleFromCellData(
            { value: cellValue },
            colorThresholds
          );

          return (
            <Column key={colIndex}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                className={classNames('flex justify-center rounded-lg p-2', {
                  'cursor-pointer transition duration-200 hover:brightness-90':
                    cellValue !== 0,
                })}
                style={colorStyle}
                onClick={() => {
                  if (cellValue === 0) {
                    return;
                  }

                  setSelectedRating({
                    rating: cellValue,
                    ratingLabel: ratingOption.label,
                    skillAreaTitle: skillAreaTitle,
                    skillAreaId: id,
                  });
                }}
              >
                <Text preset="13bs7">
                  <Percentage value={cellValue} />
                </Text>
              </div>
            </Column>
          );
        })}
      </Columns>
    </BoxListItem>
  );
};

export default Item;
