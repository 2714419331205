import React from 'react';

import { type Props as ColumnProps } from 'components/layout/grid/Column';

import { Title3 } from '../';
import { Column, Columns } from './grid';

type Props = ColumnProps & {
  children: React.ReactNode;
};

export const DeprecatedPageHeader = ({ children, style }: Props) => (
  <Columns style={style}>{children}</Columns>
);

export const DeprecatedPageHeaderTitle = ({
  children,
  ...columnProps
}: Props) => (
  <Column {...columnProps}>
    <Title3>{children}</Title3>
  </Column>
);

export const DeprecatedPageHeaderActions = ({
  children,
  ...columnProps
}: Props) => (
  <Column isNarrow {...columnProps}>
    {children}
  </Column>
);
