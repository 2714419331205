import React, { Fragment } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

import { TrainingOrganism } from 'models/TrainingOrganism';

import { __, date } from 'helpers/i18n';
import { Match, pathToTrainingOrganisms } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ContentContainer, FetchContainer, PageHeader, Text } from 'components';

import EditOrganismForm from './EditOrganismForm';

type RouterProps = RouteComponentProps & {
  match: Match<{
    id: string;
  }>;
};

type Props = RouterProps;

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    organism: TrainingOrganism;
  };

const Organism = ({ organism, isFetching, hasError }: AfterDataLoaderProps) => {
  const lastUpdateInformation = () => {
    return (
      <Text preset="13s7" color="soften" className="mb-4">
        {__('Updated on %1', date(organism.updatedAt, 'LL'))}
      </Text>
    );
  };

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        return (
          <Fragment>
            <PageHeader
              title={organism.name}
              withBackButton
              backButtonProps={{
                target: pathToTrainingOrganisms(),
                children: __('Back to training organizations'),
              }}
              description={lastUpdateInformation()}
            />
            <ContentContainer>
              <EditOrganismForm organism={organism} />
            </ContentContainer>
          </Fragment>
        );
      }}
    />
  );
};

export default compose(
  newDataLoader({
    fetch: ({ match }: Props) => {
      return get(`training/organisms/${match.params.id}`);
    },
    hydrate: { organism: {} },
  })
)(Organism) as React.ComponentType<Props>;
