import React, { Fragment } from 'react';

import type {
  FeedbackableItem,
  FeedbackableSkillsDomain,
  SkillsCareerLevelBlock,
  SkillsCareerLevelBlockContent,
  SkillsCareerLevelExpectationBlock,
  SkillsLevel,
  SkillsUserCareerLevel,
  User,
} from 'models';

import store from 'config/store';

import can from 'helpers/can';
import resolveRelationships from 'helpers/data/resolveRelationships';
import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { concatenatedSkillLevelWithMatrixTitle } from 'helpers/models/skills/skillLevel';
import { pathToMatrix } from 'helpers/paths';

import { Box, FeatureFlagged, Flex, Link } from 'components';

import BlockDetails from 'scenes/components/review/BlockDetails';

import CurrentCareerLevelManagementButton from './CurrentCareerLevelManagementButton';
import DomainBlockContent from './DomainBlockContent';
import ExpectationBlockContent from './ExpectationBlockContent';
import SkillsSummaryBoard from './SkillsSummaryBoard';
import useCareerLevelFromBlockContent from './hooks/useCareerLevelFromBlockContent';
import usePersistAction from './hooks/usePersistAction';
import useReviewedSkillsLevel from './hooks/useReviewedSkillsLevel';
import useUserReviewFromParams from './hooks/useUserReview';

type Props = {
  content: SkillsCareerLevelBlockContent;
  reviewee: User;
  isPreview: boolean;
  withoutSectionSeparator: boolean;
  refreshBlock: (reviewBlockId: string) => Promise<unknown>;
  refreshShareability: () => Promise<void>;
};

function SkillsCareerLevelBlockContentComponent({
  content,
  reviewee,
  isPreview,
  withoutSectionSeparator,
  refreshBlock,
  refreshShareability,
}: Props) {
  const {
    id,
    title,
    description,
    feedbackableItems,
    richTextEnabled,
    reviewBlockId,
  } = content;
  invariant(reviewBlockId, 'ReviewBlockId must de defined');

  const persistAction = usePersistAction(refreshShareability, isPreview);
  const currentCareerLevel: SkillsUserCareerLevel | null =
    useCareerLevelFromBlockContent(id);
  const reviewedSkillsLevel: SkillsLevel | null =
    useReviewedSkillsLevel(reviewBlockId);
  const user = useActiveUser();
  const userReview = useUserReviewFromParams();

  // For preview
  const feedbackableCareerLevelBlock = feedbackableItems.find(
    fi => fi.item.type === 'skillsCareerLevel'
  ) as FeedbackableItem<SkillsCareerLevelBlock>;

  // For real review
  const hydrateDomain = (
    feedbackableItem: FeedbackableItem<SkillsCareerLevelExpectationBlock>
  ) =>
    ({
      ...feedbackableItem,
      item: resolveRelationships(store.getState().data, feedbackableItem.item, {
        domain: {},
      }),
    } as FeedbackableItem<SkillsCareerLevelExpectationBlock>);

  const feedbackableCareerLevelExpectationBlocks = (
    feedbackableItems.filter(
      fi => fi.item.type === 'skillsCareerLevelExpectationBlock'
    ) as FeedbackableItem<SkillsCareerLevelExpectationBlock>[]
  ).map(hydrateDomain);

  const feedbackableItemsGroupedByDomain: {
    [domainTitle: string]: {
      expectations: FeedbackableItem<SkillsCareerLevelExpectationBlock>[];
      feedbackableDomain: FeedbackableItem<FeedbackableSkillsDomain>;
    };
  } = isPreview
    ? {}
    : feedbackableCareerLevelExpectationBlocks.reduce((hash, value) => {
        const domainTitle = value.item.domain?.title;
        if (domainTitle !== undefined) {
          hash[domainTitle] = hash[domainTitle] || {
            expectations: [],
            feedbackableDomain: null,
          };
          hash[domainTitle].expectations.push(value);
        }
        return hash;
      }, {});

  const feedbackableDomainBlocks = feedbackableItems.filter(
    fi => fi.item.type === 'feedbackableSkillsDomain'
  ) as FeedbackableItem<FeedbackableSkillsDomain>[];

  if (!isPreview) {
    feedbackableDomainBlocks.forEach(feedbackableDomain => {
      feedbackableItemsGroupedByDomain[
        feedbackableDomain.item.title
      ].feedbackableDomain = feedbackableDomain;
    });
  }

  const careerMessage = (): React.ReactNode => {
    const isReviewee = user.id === reviewee.id;

    const clickableMatrixTitle = () => (
      <b>
        <Link to={pathToMatrix(reviewedSkillsLevel.matrixId)}>
          {concatenatedSkillLevelWithMatrixTitle(reviewedSkillsLevel)}
        </Link>
      </b>
    );

    if (isReviewee && reviewedSkillsLevel) {
      return __(
        'You are being evaluated on the %1 skills matrix',
        clickableMatrixTitle()
      );
    }
    if (isReviewee && !reviewedSkillsLevel) {
      return __('You are not associated with a skills matrix.');
    }
    if (!isReviewee && reviewedSkillsLevel) {
      return __(
        '%1 is being evaluated on the %2 skills matrix',
        reviewee.fullName,
        clickableMatrixTitle()
      );
    }
    return (
      <p>
        {__('No skills matrix is associated to %1.', reviewee.fullName)}
        <br />
        {__(
          'To evaluate this employee’s skills, it is necessary to associate them with a skills matrix.'
        )}
      </p>
    );
  };

  const shouldDisplaySummaryBoard =
    !!userReview && userReview.isReleased && !!reviewedSkillsLevel;
  const canUpdateCareer =
    !isPreview && can({ perform: 'update_career', on: reviewee });

  const buildMatrixVisualizationAndLevelManagementContent = () => (
    <Box testClassName="test-skill-matrix-career-message">
      <Flex verticalAlign style={{ justifyContent: 'space-between' }}>
        <div className="mr-2">{careerMessage()}</div>
        {canUpdateCareer && (
          <CurrentCareerLevelManagementButton
            revieweeId={reviewee.id}
            revieweeFullName={reviewee.fullName}
            careerLevel={currentCareerLevel}
            refreshShareability={refreshShareability}
            refreshBlock={() => refreshBlock(reviewBlockId)}
          />
        )}
      </Flex>
    </Box>
  );
  const displayedDomains = new Set();

  return (
    <Fragment>
      <BlockDetails
        id={id}
        section={__('Skills')}
        title={title || __('Skills evaluation')}
        description={description}
        testClassName="test-skills-career-level-title-block"
        withRichText={richTextEnabled}
        withoutSectionSeparator={withoutSectionSeparator}
        withReadMore
      />
      <FeatureFlagged flag="skillsSummaryBoard" inverseDefaultBehavior>
        {buildMatrixVisualizationAndLevelManagementContent()}
      </FeatureFlagged>

      <FeatureFlagged flag="skillsSummaryBoard">
        {shouldDisplaySummaryBoard ? (
          <SkillsSummaryBoard userReview={userReview} />
        ) : (
          buildMatrixVisualizationAndLevelManagementContent()
        )}
      </FeatureFlagged>

      {isPreview ? (
        <ExpectationBlockContent
          key={feedbackableCareerLevelBlock.id}
          feedbackableItem={feedbackableCareerLevelBlock}
          persistAction={persistAction}
          isPreview
        />
      ) : (
        <Fragment>
          {feedbackableCareerLevelExpectationBlocks.map(item => {
            const domainTitle = item.item.domain?.title;

            if (domainTitle === undefined) {
              return (
                <ExpectationBlockContent
                  key={item.id}
                  feedbackableItem={item}
                  persistAction={persistAction}
                  isPreview={false}
                />
              );
            } else if (!displayedDomains.has(domainTitle)) {
              displayedDomains.add(domainTitle);

              return (
                <DomainBlockContent
                  key={domainTitle}
                  feedbackableDomain={
                    feedbackableItemsGroupedByDomain[domainTitle]
                      .feedbackableDomain
                  }
                  feedbackableItems={
                    feedbackableItemsGroupedByDomain[domainTitle].expectations
                  }
                  persistAction={persistAction}
                />
              );
            }
            return null;
          })}
        </Fragment>
      )}
    </Fragment>
  );
}

export default SkillsCareerLevelBlockContentComponent as React.ComponentType<Props>;
