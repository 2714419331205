import classNames from 'classnames';
import React from 'react';

import tailwindTheme from 'config/tailwindTheme';

import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { FlatPieChart, Testable } from 'components';
import { Data } from 'components/FlatPieChart';

type Props = {
  provisionedAmountCents: number;
  validatedAmountCents: number;
  forecastAmountCents?: number;
  currency: string;
  className?: string;
  testClassName?: string;
};

const BudgetVisualizer = ({
  validatedAmountCents,
  forecastAmountCents,
  provisionedAmountCents,
  currency,
  className,
  testClassName,
}: Props) => {
  const remaining =
    provisionedAmountCents - validatedAmountCents - (forecastAmountCents || 0);

  const data: Array<Data> = [
    {
      value: validatedAmountCents,
      color: tailwindTheme.colors.green[500],
      label: __('Validated|MasculineSingular'),
      testClassName: 'test-validated-budget',
    },
  ];
  if (forecastAmountCents !== undefined) {
    data.push({
      value: forecastAmountCents || null,
      color: tailwindTheme.colors.green[300],
      label: __('Forecast|MasculineSingular'),
      testClassName: 'test-forecast-budget',
    });
  }
  data.push({
    value: Math.abs(remaining),
    color:
      remaining >= 0
        ? tailwindTheme.colors.basic[200]
        : tailwindTheme.colors.red[400],
    label:
      remaining >= 0 ? __('Remaining|MasculineSingular') : __('Off-budget'),
    testClassName: remaining >= 0 ? 'test-remaining-budget' : 'test-off-budget',
  });

  return (
    <div className={classNames(className, testClassName)}>
      <FlatPieChart
        legendClassName="mt-1"
        height={8}
        valuePositionInLegend="bottom"
        formatValue={value => (
          <Testable name="test-amount">
            {value ? <b>{formatMoney(value, currency)}</b> : '-'}
          </Testable>
        )}
        data={data}
      />
    </div>
  );
};

export default BudgetVisualizer;
