import React from 'react';

import type { Tag, TagContext } from 'models';
import type { ComponentType } from 'react';

import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer, Select } from 'components';

type TagOption = {
  value: string;
  label: string;
};

type BaseProps = {
  context: TagContext;
  onTagsChange: (value: Tag[]) => void;
  className?: string;
  placeholder?: string;
  preventCreation?: boolean;
};

type Props =
  | (BaseProps & { selectedTags: Tag[] })
  | (BaseProps & { selectedTagLabels: string[] });

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    tags: Array<Tag>;
  };

const convertTagToOptions = (tags: Tag[]): TagOption[] =>
  tags.map(
    tag =>
      ({
        value: tag.label,
        label: tag.label,
      } as TagOption)
  );
const convertOptionsToTag = (options: TagOption[]): Tag[] =>
  options.map(option => ({ label: option.label } as Tag));

const TagPicker = ({
  tags,
  onTagsChange,
  className,
  placeholder,
  preventCreation,
  isFetching,
  hasError,
  ...otherProps
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const selectedTags =
          'selectedTags' in otherProps
            ? otherProps.selectedTags
            : tags.filter(tag =>
                otherProps.selectedTagLabels.includes(tag.label)
              );
        const value = convertTagToOptions(selectedTags);
        const options = convertTagToOptions(tags);
        const onChange = (options: TagOption[]) => {
          const newTags: Tag[] = convertOptionsToTag(options);

          return onTagsChange(newTags);
        };

        return (
          <Select
            value={value}
            options={options}
            onChange={options => onChange(!!options ? options : [])}
            placeholder={placeholder || __('Select...')}
            noOptionsMessage={__('No tag found')}
            formatCreateLabel={(newLabel: string) =>
              __('Create "%1"', newLabel)
            }
            isMulti
            inModal
            isClearable
            isCreatable={!preventCreation}
            className={className}
          />
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ context }) => get('tags', { context }),
  hydrate: { tags: {} },
})(TagPicker) as ComponentType<Props>;
