import React from 'react';

import {
  Flex,
  Icon,
  Number,
  Percentage,
  StrictlySanitizedHtml,
  Text,
  Tooltip,
} from 'components';

type Props = {
  color: string;
  mainNumber?: number;
  mainPercentage?: number;
  title: string;
  tooltipTitle?: string;
  tooltipIsLight?: boolean;
  description: string;
};

const StatsGridItem = ({
  color,
  mainNumber,
  mainPercentage,
  title,
  tooltipTitle,
  tooltipIsLight,
  description,
}: Props) => {
  return (
    <Flex direction="row" className="gap-8">
      <Flex
        className="gap-1"
        style={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
        direction="column"
      >
        <Text preset="24bs2" className={`stat-percent is-${color}`}>
          {(!!mainNumber || mainNumber === 0) && <Number value={mainNumber} />}
          {(!!mainPercentage || mainPercentage === 0) && (
            <Percentage value={mainPercentage} />
          )}
        </Text>
      </Flex>
      <Flex direction="column" style={{ whiteSpace: 'nowrap' }}>
        <Text preset="18bs5">
          {title}
          {!!tooltipTitle && (
            <Tooltip
              content={
                <StrictlySanitizedHtml
                  componentType="span"
                  html={tooltipTitle}
                />
              }
              triggerClassName="max-w-[340px]"
              isLight={!!tooltipIsLight}
            >
              <Icon style={{ marginLeft: 8 }} name="info" />
            </Tooltip>
          )}
        </Text>
        <StrictlySanitizedHtml componentType="div" html={description} />
      </Flex>
    </Flex>
  );
};

export default StatsGridItem;
