import React, { ReactNode } from 'react';

import { Icon, Text } from '.';

type Props = {
  message: ReactNode;
};

const SoftErrorNotification = ({ message }: Props) => (
  <div className="flex items-center">
    <Icon name="warning" size="small" className="mr-2 has-text-danger" />
    <Text color="danger">{message}</Text>
  </div>
);

export default SoftErrorNotification;
