import { flatten, isEmpty } from 'lodash';
import React, {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { __ } from 'helpers/i18n';

import {
  Badge,
  Box,
  Button,
  Icon,
  Popover,
  StrictlySanitizedHtml,
  Text,
  Tooltip,
} from 'components';

import { DataContext } from './DataContext';
import FilterList from './FilterList';
import { ActiveFilters, FilterableField } from './types';

const Filters = () => {
  const {
    filterableFields,
    fetchFilterSegments,
    onFiltersChange,
    filters,
    setFilterSegments,
    disabled,
    className,
  } = useContext(DataContext);

  const [activeFilters, setActiveFilters] = useState<ActiveFilters>(
    !!filters ? filters : {}
  );

  const [selectedFilter, setSelectedFilter] = useState<FilterableField | null>(
    null
  );

  const onFilterChange = (filter: FilterableField | null) => {
    setFilterSegments(null);
    setSelectedFilter(filter);
    if (!!filter && !!fetchFilterSegments) {
      fetchFilterSegments(filter);
    }
  };

  useEffect(() => {
    if (!!onFiltersChange) {
      onFiltersChange({
        // Here the empty string is needed to keep the query string in the URL.
        // Empty objects are removed from the query string
        userFilters: isEmpty(activeFilters) ? '' : activeFilters,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  const count = Object.keys(activeFilters).length;

  const tooltipContent = useMemo((): ReactNode => {
    const filterSlugs = Object.keys(activeFilters);
    const filters = filterSlugs.map(slug =>
      flatten(filterableFields).find(filter => filter.slug === slug)
    );

    return (
      <Fragment>
        {filters.map(filter => {
          if (!filter) return null;

          return (
            <StrictlySanitizedHtml
              componentType="p"
              key={filter.slug}
              html={__(
                '<b>%1</b>: %2',
                filter.label,
                activeFilters[filter.slug]?.label
              )}
            />
          );
        })}
      </Fragment>
    );
  }, [activeFilters, filterableFields]);

  return disabled ? (
    <Button className={`py-0 cursor-default ${className}`}>
      <div className="py-2">
        <Text preset="14s6" className={count > 0 ? 'font-bold' : 'font-normal'}>
          {__('Filters')}
        </Text>
      </div>
      {count > 0 && (
        <Tooltip isLight placement="bottom" content={tooltipContent}>
          <Badge count={count} className="ml-2" />
        </Tooltip>
      )}
    </Button>
  ) : (
    <Popover
      inBox={false}
      renderTrigger={(onTrigger, isOpen) => (
        <Button
          className={`py-0 h-8 ${className}`}
          onClick={() => {
            if (selectedFilter && !isOpen) {
              setSelectedFilter(null);
            }
            onTrigger();
          }}
        >
          <div className="py-2">
            <Icon name="filter_list" className="mr-2" />
            <Text
              preset="14s6"
              className={count > 0 ? 'font-bold' : 'font-normal'}
            >
              {__('Filter')}
            </Text>
          </div>
          {count > 0 && (
            <Tooltip isLight placement="bottom" content={tooltipContent}>
              <Badge count={count} className="ml-2" />
            </Tooltip>
          )}
        </Button>
      )}
      render={() => (
        <Box
          className="w-64 max-h-[19.5rem] overflow-auto shadow pb-0 border-b-[16px] border-solid border-ui-bg"
          size="small"
        >
          <FilterList
            selectedFilter={selectedFilter}
            onFilterChange={onFilterChange}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
          />
        </Box>
      )}
    />
  );
};

export default Filters;
