// Comes from https://wikiki.github.io/form/checkradio/
import React, { CSSProperties } from 'react';

import type { CheckboxColor, CheckboxSize } from 'components/types/checkbox';

import { bulmaColorClassName, bulmaSizeClassName } from 'helpers/bulma';
import classNames from 'helpers/classNames';

export type Props = {
  id?: string;
  isChecked: boolean;
  disabled?: boolean;

  color?: CheckboxColor;
  size?: CheckboxSize;

  className?: string;
  name?: string;
  inputLabel?: any;
  onChange?: () => any;
  style?: CSSProperties;
};

function Radio({
  id,
  isChecked,
  disabled,
  color,
  size,
  className,
  name,
  inputLabel,
  onChange,
  style,
}: Props) {
  const mergedClassName = classNames(
    'is-checkradio',
    'test-is-checkradio',
    bulmaColorClassName(color),
    bulmaSizeClassName(size),
    className
  );

  return (
    <input
      id={id}
      type="radio"
      name={name}
      aria-label={inputLabel}
      checked={isChecked}
      className={mergedClassName}
      disabled={disabled}
      style={style}
      onChange={onChange}
    />
  );
}

Radio.defaultProps = {
  size: 'normal',
};

export default Radio;
