import React from 'react';
import { connect } from 'react-redux';

import type { ReviewCycle } from 'models/ReviewCycle';
import type { AppDispatch } from 'redux/actions/types';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import { wait } from 'helpers/time';

import { get, post } from 'redux/actions/api';

import { Button, ConfirmationModal, Icon, Testable, Text } from 'components';

import ParticipantsChooser from 'scenes/admin/reviewCycles/components/ParticipantsChooser';

type Props = {
  reviewCycle: ReviewCycle;
};

type AfterConnectProps = Props & {
  resetPlannedParticipants: () => Promise<void>;
  addParticipants: (participantIds: Array<string>) => Promise<void>;
};

type State = {
  isModalOpen: boolean;
};

class AddParticipantsButton extends React.Component<AfterConnectProps, State> {
  state = {
    isModalOpen: false,
  };

  closeModal = () => this.setState({ isModalOpen: false });

  openModal = async () => {
    await this.props.resetPlannedParticipants();
    this.setState({ isModalOpen: true });
  };

  submit = async () => {
    try {
      await this.props.addParticipants(
        this.props.reviewCycle.plannedParticipantIds
      );
    } finally {
      this.closeModal();
    }
  };

  render() {
    const { reviewCycle } = this.props;
    const disabled =
      !can({ perform: 'add_participants', on: reviewCycle }) ||
      ['ended', 'failed'].includes(reviewCycle.status);

    return (
      <React.Fragment>
        <Testable name="test-add-participants-button">
          <Text color="darkest-grey" weight="normal">
            {reviewCycle.userReviewsCount}
          </Text>

          {!disabled && (
            <Button
              onClick={this.openModal}
              size="small"
              color="secondary"
              className="no-underline ml-2"
            >
              <Icon size="tiny" className="mr-1" name="add_circle" />
              {__('Add')}
            </Button>
          )}
        </Testable>

        <ConfirmationModal
          isActive={this.state.isModalOpen}
          confirmDisabled={reviewCycle.plannedParticipantIds.length === 0}
          onConfirm={this.submit}
          onCancel={this.closeModal}
          cancelLabel={__('Cancel')}
          confirmLabel={__('Add participants')}
          title={__('Select participants')}
          refreshContentOnOpening
          isLarge
        >
          <p>
            {reviewCycle.visibleForReviewee
              ? __(
                  'Participants will receive an invitation email once validated.'
                )
              : __(
                  'Reviewers will receive an invitation email once validated.'
                )}{' '}
            {reviewCycle.addDirectReportsAsPeersByDefault &&
              __(
                'Their direct reports will automatically be selected and validated as peers.'
              )}
          </p>
          <div style={{ margin: '1rem 0' }}>
            <ParticipantsChooser
              showBulkActions={false}
              reviewCycle={reviewCycle}
            />
          </div>
        </ConfirmationModal>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: Props
): Omit<AfterConnectProps, keyof Props> => ({
  resetPlannedParticipants: () =>
    dispatch(
      post(
        `review_cycles/${ownProps.reviewCycle.id}/planned_participants/clear`
      )
    ),

  addParticipants: async (participantIds: string[]) => {
    await dispatch(
      post(
        `review_cycles/${ownProps.reviewCycle.id}/add_participants`,
        { participantIds },
        {
          errorMessage: __('The participants could not be added.'),
        }
      )
    );

    // this is a trick to avoid pooling screen when < 10 participants to add
    if (participantIds.length <= 10) await wait(5000);

    return dispatch(get(`review_cycles/${ownProps.reviewCycle.id}`));
  },
});

export default connect(null, mapDispatchToProps)(AddParticipantsButton);
