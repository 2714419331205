import React, { useContext, useEffect, useState } from 'react';

import type { PeopleReviewCycleShow, UserFilterSegment } from 'models';

import { history } from 'config/history';

import can from 'helpers/can';
import { useMultiSelect } from 'helpers/hooks';
import queryString from 'helpers/queryString';

import { parsePaginationParams } from 'lib/dataLoader/pagination/paginationParams';

import { ActiveFilters } from 'components/Filters/types';

import UserFilter from 'scenes/components/UserFilter';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

import { DataContext } from '../index';
import OldPeopleUserReviewListWithPagination from './OldPeopleUserReviewListWithPagination';
import PeopleGroupList from './PeopleGroupList';
import PeopleUserReviewListWithPagination from './PeopleUserReviewListWithPagination';

type Props = {
  peopleReviewCycle: PeopleReviewCycleShow;
};

const Participants = ({ peopleReviewCycle }: Props) => {
  const urlQueryParams = () =>
    parsePaginationParams(queryString.parse(window.location.search));
  const { withUserMultiFilters } = useContext(DataContext);
  // TODO: multifilters: remove this state when FF is enabled for everyone
  const [userFilter, setUserFilter] = useState<UserFilterSegment | null>(
    urlQueryParams().userFilter || undefined
  );

  const peopleGroupIdsFromParams = withUserMultiFilters
    ? urlQueryParams().queryParams?.peopleGroupIds
    : urlQueryParams().query?.peopleGroupIds;

  const [selectedPeopleGroupIds, togglePeopleGroupSelection] =
    useMultiSelect<string>(peopleGroupIdsFromParams || []);

  useEffect(() => {
    if (withUserMultiFilters) return;
    const params = {
      ...urlQueryParams(),
      query: { peopleGroupIds: selectedPeopleGroupIds },
      page: 1,
    };
    history.replace(
      history.location.pathname + '?' + queryString.stringify(params)
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPeopleGroupIds.join(',')]);

  const onUserFilterChange = (newUserFilter: UserFilterSegment | null) => {
    setUserFilter(newUserFilter);
    const params = { ...urlQueryParams(), userFilter: newUserFilter, page: 1 };
    history.replace(
      history.location.pathname + '?' + queryString.stringify(params)
    );
  };

  const [userFilters, setUserFilters] = useState<ActiveFilters | ''>(
    urlQueryParams().queryParams?.userFilters || ''
  );

  const [shouldRefetchReviews, setShouldRefetchReviews] = useState(false);

  const canEditPeopleGroups = can({
    perform: 'edit_groups',
    on: peopleReviewCycle,
  });

  return (
    <React.Fragment>
      {withUserMultiFilters ? (
        <div className="mb-4">
          <UserMultiFilters
            onChange={({ userFilters }) => setUserFilters(userFilters)}
            userFilters={userFilters}
          />
        </div>
      ) : (
        // TODO: multifilters: remove this condition when FF is enabled for everyone
        <UserFilter
          segment={userFilter}
          onChange={onUserFilterChange}
          className="mb-6"
        />
      )}

      <PeopleGroupList
        cycleId={peopleReviewCycle.id}
        selectedPeopleGroupIds={selectedPeopleGroupIds}
        togglePeopleGroupSelection={togglePeopleGroupSelection}
        userFilterSegment={userFilter}
        userFilters={userFilters}
        canEditPeopleGroups={canEditPeopleGroups}
        setShouldRefetchReviews={setShouldRefetchReviews}
      />
      {withUserMultiFilters ? (
        <PeopleUserReviewListWithPagination
          shouldRefetchReviews={shouldRefetchReviews}
          peopleReviewCycle={peopleReviewCycle}
          setShouldRefetchReviews={setShouldRefetchReviews}
          additionalQueryParams={{
            userFilters,
            peopleGroupIds: selectedPeopleGroupIds,
          }}
        />
      ) : (
        // TODO: multifilters: remove this condition and component when FF is enabled for everyone
        <OldPeopleUserReviewListWithPagination
          paginationType="url"
          defaultPaginationParams={{ filter: { all: true } }}
          peopleReviewCycle={peopleReviewCycle}
          shouldRefetchReviews={shouldRefetchReviews}
          setShouldRefetchReviews={setShouldRefetchReviews}
        />
      )}
    </React.Fragment>
  );
};

export default Participants;
