import classNames from 'classnames';
import React, { ReactNode } from 'react';

import { Text } from 'components';
import Icon, { MaterialIconName } from 'components/Icon';

type TagIconProps = {
  name: MaterialIconName;
  onClick?: () => void;
  className?: string;
};

const TagIcon = ({ name, onClick, className }: TagIconProps) => {
  if (onClick) {
    return (
      <button onClick={onClick} className={className}>
        <Icon name={name} />
      </button>
    );
  } else {
    return <Icon name={name} onClick={onClick} className={className} />;
  }
};

type Props = {
  children: ReactNode;
  leftIcon?: TagIconProps;
  rightIcon?: TagIconProps;
  className?: string;
  testClassName?: string;
};

const Tag = ({
  children,
  leftIcon,
  rightIcon,
  className,
  testClassName,
}: Props) => {
  return (
    <Text
      preset="13s7"
      className={classNames('tag-elevo', className, testClassName)}
    >
      {leftIcon && <TagIcon {...leftIcon} className="mr-1" />}
      {children}
      {rightIcon && <TagIcon {...rightIcon} className="ml-1" />}
    </Text>
  );
};

export default Tag;
