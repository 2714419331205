import React from 'react';

import type { UserFilterChangeMethod } from 'lib/dataLoader/pagination/types';

import { UserFilterSegment } from 'models';

import { __, n__ } from 'helpers/i18n';

import { Flex, LevelItem, Select, StrictlySanitizedHtml } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import UserFilter from 'scenes/components/UserFilter';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

type SelectOption = {
  value: any;
  label: any;
};

type Props = {
  totalCount: number | null;
  matrixSelectValues: SelectOption[];
  selectedMatrixId: null | string;
  setSelectedMatrixId: (string) => void;
  levelSelectValues: SelectOption[];
  selectedLevelId: null | string;
  setSelectedLevelId: (string) => void;
  userFilter: UserFilterSegment | null;
  setUserFilter: UserFilterChangeMethod;
  userFilters?: ActiveFilters;
  withUserMultiFilters?: boolean;
  setUserFilters?: (ActiveFilters) => void;
};

const FiltersRenderer = ({
  totalCount,
  selectedMatrixId,
  matrixSelectValues,
  setSelectedMatrixId,
  selectedLevelId,
  levelSelectValues,
  setSelectedLevelId,
  userFilter,
  setUserFilter,
  userFilters,
  withUserMultiFilters,
  setUserFilters,
}: Props) => {
  const onMatrixChange = option => {
    setSelectedLevelId(null);
    setSelectedMatrixId(option?.value);
  };
  return (
    <React.Fragment>
      <Flex>
        <Select
          value={
            matrixSelectValues.find(
              option => option.value === selectedMatrixId
            ) || null
          }
          placeholder={__('Select a skills matrix')}
          options={matrixSelectValues}
          onChange={onMatrixChange}
          className="w-1/3 mr-4"
          isDisabled={matrixSelectValues.length < 1}
        />
        <Select
          value={
            levelSelectValues.find(
              option => option.value === selectedLevelId
            ) || null
          }
          placeholder={__('All levels combined')}
          options={levelSelectValues}
          onChange={option => setSelectedLevelId(option?.value)}
          className="w-1/3"
          isClearable
          isDisabled={!selectedMatrixId}
        />
        <LevelItem className="ml-4">
          {withUserMultiFilters ? (
            <UserMultiFilters
              userFilters={userFilters}
              onChange={({ userFilters }) =>
                setUserFilters && setUserFilters(userFilters)
              }
            />
          ) : (
            <UserFilter segment={userFilter} onChange={setUserFilter} />
          )}
        </LevelItem>
      </Flex>
      {!!selectedMatrixId && totalCount !== null && (
        <div className="mt-4">
          <StrictlySanitizedHtml
            html={n__(
              '<b>%1 employee</b> matching your filters',
              '<b>%1 employees</b> matching your filters',
              totalCount
            )}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default FiltersRenderer;
