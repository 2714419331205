import React from 'react';

import type { PeopleUserReview } from 'models';

import { pathToPeopleUserReview } from 'helpers/paths';

import { Column, Columns } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import PeopleUserReviewStatusTag from 'scenes/components/peopleUserReview/PeopleUserReviewStatusTag';

type Props = { peopleUserReview: PeopleUserReview };

const PeopleUserReviewListItem = ({ peopleUserReview }: Props) => (
  <Columns>
    <Column className="py-2">
      <UserAvatar
        user={peopleUserReview.user}
        size="small"
        withJobTitle
        link={pathToPeopleUserReview(peopleUserReview.id)}
      />
    </Column>
    <Column isNarrow className="w-[170px] py-2">
      <PeopleUserReviewStatusTag
        isLocked={peopleUserReview.isLocked}
        status={peopleUserReview.status}
      />
    </Column>
  </Columns>
);

export default PeopleUserReviewListItem;
