import React, { Fragment, useState } from 'react';

import type { ObjectivePeriod, TeamObjectivePeriod } from 'models/index';
import type { CSSProperties } from 'react';

import { __ } from 'helpers/i18n/index';

import { Button, Icon } from 'components/index';

import { default as AddTeamModal } from './AddTeamModal';

type Props = {
  objectivePeriod: ObjectivePeriod;
  teamObjectivePeriods: Array<TeamObjectivePeriod>;
  style?: CSSProperties;
};

const AddTeamButton = ({
  objectivePeriod,
  teamObjectivePeriods,
  style,
}: Props) => {
  const [isAddTeamModalVisible, setIsAddTeamModalVisible] = useState(false);

  return (
    <Fragment>
      <Button
        onClick={() => setIsAddTeamModalVisible(true)}
        size="small"
        color="secondary"
        style={style}
      >
        <Icon size="small" name="add" className="mr-1" />
        <span>{__('Add a team')}</span>
      </Button>
      {isAddTeamModalVisible && (
        <AddTeamModal
          onClose={() => setIsAddTeamModalVisible(false)}
          objectivePeriod={objectivePeriod}
          disabledTeamIds={teamObjectivePeriods.map(
            teamObjectivePeriod => teamObjectivePeriod.team.id
          )}
        />
      )}
    </Fragment>
  );
};

export default AddTeamButton;
