import React from 'react';

import { ParticipantFundingItem } from 'models/FundingItem';

import store from 'config/store';

import { handleFormErrors } from 'helpers/api';
import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { hydrateFromStore } from 'lib/dataLoader';
import { post } from 'redux/actions/api';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import FundingSection from '../../components/FundingSection';

type Props = {
  sessionId: string;
  periodSlug: string;
  participantIds: Array<string>;
  onClose: () => void;
  onAfterUpdate: () => Promise<unknown>;
  defaultCurrency: string;
};

const BulkUpdateParticipantFundingItems = ({
  sessionId,
  periodSlug,
  participantIds,
  onClose,
  onAfterUpdate,
  defaultCurrency,
}: Props) => {
  const onlyParticipant =
    participantIds.length === 1
      ? hydrateFromStore(
          store.getState().data,
          { resourceType: 'trainingParticipant', id: participantIds[0] },
          {
            trainingParticipant: {
              fundingItems: { fundingSource: {} },
              user: {},
            },
          }
        )
      : null;

  const [errors, setErrors] = React.useState({});
  const [fundingItems, setFundingItems] = React.useState<
    Array<ParticipantFundingItem>
  >(onlyParticipant?.fundingItems || []);

  const dispatch = useAppDispatch();
  const bulkUpdateRequests = async () => {
    await handleFormErrors(
      async () => {
        await dispatch(
          post(
            `training/sessions/${sessionId}/participants/bulk_update_funding_items`,
            {
              participantIds,
              fundingItemsAttributes: fundingItems
                .filter(item => !item._destroy)
                .map(item => ({
                  ...item,
                  fundingSourceId: item.fundingSource.id,
                })),
            }
          )
        );
        onClose();
        return onAfterUpdate();
      },
      setErrors,
      true
    );
  };

  return (
    <ModalCard isActive isLarge refreshContentOnOpening onClose={onClose}>
      <ModalCardHead>
        <ModalCardTitle>
          {!!onlyParticipant
            ? __('Change the funding for %1', onlyParticipant.user.fullName)
            : n__(
                'Change the funding for %1 participant',
                'Change the funding for %1 participants',
                participantIds.length
              )}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <FundingSection
          periodSlug={periodSlug}
          fundingItems={fundingItems}
          errors={errors}
          onChange={setFundingItems}
          defaultCurrency={defaultCurrency}
        />
      </ModalCardBody>
      <ModalCardFooter>
        <Button onClick={onClose} color="secondary">
          {__('Cancel')}
        </Button>
        <Button onClick={bulkUpdateRequests} color="primary">
          {__('Change funding')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default BulkUpdateParticipantFundingItems;
