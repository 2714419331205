import React from 'react';
import { compose } from 'redux';

import { BaseTrainingCourse, PaginatedCollection } from 'models';

import colors from 'styles/colors';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { pathToHome, pathToTrainingsCatalogCourse } from 'helpers/paths';

import {
  DataLoaderProvidedProps,
  PaginationProps,
  newDataLoader,
} from 'lib/dataLoader';
import withStatePagination from 'lib/dataLoader/pagination/StatePaginationFactory';
import { get } from 'redux/actions/api';

import { EmptyStateWithIcon, InfiniteFetchContainer, Link } from 'components';
import GridCardLayout from 'components/layout/GridCardLayout';

import CourseCard from './CourseCard';

type Props = {
  search?: string;
};

type AfterPaginateProps = Props & PaginationProps;
type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    trainingCoursesCollection: PaginatedCollection<BaseTrainingCourse>;
  };

const CoursesList = ({
  trainingCoursesCollection,
  getNextPage,
  isFetching,
  hasError,
  search,
}: AfterDataLoaderProps) => {
  const offCatalogRequestLink = (
    <Link to={pathToHome()} style={{ color: colors.blueElevo }}>
      {__('off-catalog request')}
    </Link>
  );

  const emptyStateDescription = __(
    'The training catalog is currently empty. In the meantime, you can submit an %1 for a specific training.',
    offCatalogRequestLink
  );

  return (
    <InfiniteFetchContainer
      collection={trainingCoursesCollection}
      getNextPage={getNextPage}
      isFetching={isFetching}
      hasError={hasError}
      emptyState={
        search ? (
          <EmptyStateWithIcon
            iconName="search"
            title={__('No trainings match your search.')}
          />
        ) : (
          <EmptyStateWithIcon
            iconName="school"
            title={__('Training catalog')}
            description={emptyStateDescription}
          />
        )
      }
      render={items => (
        <GridCardLayout minCardWidth={260}>
          {items.map(item => (
            <CourseCard
              name={item.name}
              costPerParticipantCents={item.costPerParticipantCents}
              costPerParticipantCurrency={item.costPerParticipantCurrency}
              durationInHours={item.durationInHours}
              organism={item.organism}
              url={pathToTrainingsCatalogCourse(item.id)}
            />
          ))}
        </GridCardLayout>
      )}
    />
  );
};

export default compose<React.ComponentType<Props>>(
  withStatePagination({
    resetPageFor: ({ search }: Props) => [search],
  }),
  newDataLoader({
    fetch: ({ page, countPerPage, search }: AfterPaginateProps) =>
      get(`training/courses/`, {
        page,
        countPerPage,
        search,
        filter: { published: true },
      }),
    hydrate: {
      trainingCoursesCollection: {
        items: {
          organism: {},
        },
      },
    },
    cacheKey: ({ page, countPerPage, search }: AfterPaginateProps) =>
      compositeKey({
        type: 'trainingCourses',
        page,
        countPerPage,
        search,
      }),
  })
)(CoursesList);
