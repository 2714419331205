import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'redux';

import type { NavActionsCount, Organization, User } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { history } from 'config/history';

import can from 'helpers/can';
import classNames from 'helpers/classNames';
import useIsMobile from 'helpers/hooks/useIsMobile';
import { __ } from 'helpers/i18n';
import { isActiveUrl, isPageWithoutNavbar } from 'helpers/navigation';
import { pathToHome, pathToMyTeam, pathToUsers } from 'helpers/paths';
import { withActiveUserAndOrganization } from 'helpers/withSessionProps';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  DesignSystem,
  FeatureFlagged,
  Icon,
  Link,
  Text,
} from 'components';
import Accordion from 'components/Accordion';
import homeLogoSvg from 'components/Logo/homeLogoSvg.svg';

import TrainingRequestModal from 'scenes/components/TrainingRequestModal';

import NavItem from './NavItem';
import NavItemGroup from './NavItemGroup';
import NavProfile from './NavProfile';
import NavSection from './NavSection';
import NavSettings from './NavSettings';
import getNavItemsLinks, { type NavItemLink } from './navItemsLinks';

export type NavItemProps = {
  title: string;
  count?: number;
  path?: string;
  onClick?: () => void;
  enabled?: boolean;
  matchFullPath?: boolean;
};

type Props = {};

type AfterConnectProps = Props & {
  organization: Organization;
  activeUser: User;
  navActionsCount: NavActionsCount;
  fetchCurrentTrainingPeriod: () => Promise<string>;
};

function AppBar({
  organization,
  activeUser,
  navActionsCount,
  fetchCurrentTrainingPeriod,
}: AfterConnectProps) {
  const [settingPageActive, setSettingPageActive] =
    React.useState<boolean>(false);
  const location = useLocation();
  const isMobile = useIsMobile();
  const [navHidden, setNavHidden] = React.useState<boolean>(
    isMobile || isPageWithoutNavbar(location.pathname)
  );
  const [trainingModalOpen, setTrainingModalOpen] =
    React.useState<boolean>(false);
  const [currentTrainingPeriodSlug, setCurrentTrainingPeriodSlug] =
    React.useState<string | undefined>();
  const [selectedGroupName, setSelectedGroupName] = React.useState<
    string | null
  >(null);
  const [selectedGroupFolded, setSelectedGroupFolded] = React.useState(false);

  const { theme, plan } = organization || {};
  const logoSrc = theme.smallLogoUrl;

  const canCreateTrainingRequest = can({
    perform: 'create_training_request_from_home',
    on: organization,
  });

  React.useLayoutEffect(() => {
    window.document.documentElement.style.setProperty(
      '--vertical-nav-width',
      '15.5rem'
    );
  }, []);

  React.useEffect(
    () => window.scrollTo(0, 0),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, location.search]
  );

  React.useEffect(
    () => {
      if (isMobile) setNavHidden(true);
      else setNavHidden(isPageWithoutNavbar(location.pathname));

      const navItemLink = getNavItemLinkMatchingURL();
      if (navItemLink) {
        if (navItemLink.items.length === 1) {
          setSelectedGroupName(null);
        } else if (selectedGroupName !== navItemLink.name) {
          setSelectedGroupFolded(false);
          setSelectedGroupName(navItemLink.name);
        }
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, location.search, isMobile]
  );

  React.useEffect(
    () => {
      if (plan.trainingModuleEnabled && canCreateTrainingRequest) {
        fetchCurrentTrainingPeriod().then(trainingPeriodSlug => {
          setCurrentTrainingPeriodSlug(trainingPeriodSlug);
        });
      }
    },

    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    oneOnOneItems,
    threeSixtyItems,
    objectivesItems,
    trainingItems,
    enpsItems,
    peopleReviewItems,
    skillsAndCareerItems,
    auditsItems,
    surveyItems,
  } = getNavItemsLinks(
    navActionsCount,
    activeUser,
    organization,
    currentTrainingPeriodSlug,
    () => setTrainingModalOpen(true)
  );

  const navItems = [
    oneOnOneItems,
    threeSixtyItems,
    objectivesItems,
    trainingItems,
    enpsItems,
    peopleReviewItems,
    skillsAndCareerItems,
    auditsItems,
    surveyItems,
  ];

  const getNavItemLinkMatchingURL = () => {
    const selectedGroup = navItems.find(({ items }) =>
      items.some(i =>
        isActiveUrl(location.pathname, location.search, i.path, i.matchFullPath)
      )
    );

    return selectedGroup;
  };

  const pushToHistory = (navItemLink: NavItemLink) => {
    if (!isMobile) history.push(navItemLink.items[0].path!);
  };

  const handleNavItemClick = (navItemLink: NavItemLink | string) => {
    if (typeof navItemLink === 'string') {
      setSelectedGroupName(null);
    } else if (navItemLink.items.length === 1) {
      setSelectedGroupName(null);
      pushToHistory(navItemLink);
    } else if (selectedGroupName === navItemLink.name) {
      setSelectedGroupFolded(folded => !folded);
    } else {
      setSelectedGroupName(navItemLink.name);
      setSelectedGroupFolded(false);
      pushToHistory(navItemLink);
    }
  };

  const navContent = navActionsCount && (
    <React.Fragment>
      <div className="flex items-center mb-6 px-4">
        <Link to={pathToHome()} title="Home" className="flex">
          <img
            src={logoSrc || homeLogoSvg}
            alt="logo"
            role="presentation"
            className="w-8 h-8 mr-3 object-contain rounded-md border border-solid border-nav-border-default"
            style={{ background: theme.logoBackgroundColor }}
          />
        </Link>

        <Text
          className="text-nav-names-default flex-1"
          weight="semibold"
          size={6.5}
        >
          {organization.name}
        </Text>
        <FeatureFlagged flag="jimoChangelog">
          <div className="jimo-widget">
            <Icon name="featured_seasonal_and_gifts" />
          </div>
        </FeatureFlagged>

        <Button
          className="border-0 bg-transparent md:hidden"
          onClick={() => setNavHidden(true)}
        >
          <Icon className="text-nav-icon" size="tiny" name="close" />
        </Button>
      </div>

      <Accordion
        type="single"
        collapsible
        className="space-y-1 px-2 overflow-x-auto"
        value={selectedGroupFolded ? '' : selectedGroupName || ''}
      >
        <NavItem
          iconName="home"
          title={__('Home')}
          path={pathToHome()}
          onClick={() => handleNavItemClick(pathToHome())}
        />

        <NavItemGroup
          onClick={() => handleNavItemClick(oneOnOneItems)}
          isActive={selectedGroupName === oneOnOneItems.name}
          title={oneOnOneItems.name}
          iconName="speed"
          items={oneOnOneItems.items}
        />

        {plan.is360Enabled && (
          <NavItemGroup
            onClick={() => handleNavItemClick(threeSixtyItems)}
            isActive={selectedGroupName === threeSixtyItems.name}
            title={threeSixtyItems.name}
            iconName="360"
            items={threeSixtyItems.items}
          />
        )}

        <NavItemGroup
          onClick={() => handleNavItemClick(objectivesItems)}
          isActive={selectedGroupName === objectivesItems.name}
          title={objectivesItems.name}
          iconName="track_changes"
          items={objectivesItems.items}
        />

        {plan.skillsAndCareersEnabled && (
          <NavItemGroup
            onClick={() => handleNavItemClick(skillsAndCareerItems)}
            isActive={selectedGroupName === skillsAndCareerItems.name}
            title={skillsAndCareerItems.name}
            iconName="nest_eco_leaf"
            items={skillsAndCareerItems.items}
          />
        )}

        {plan.baseSurveysEnabled && (
          <NavItemGroup
            onClick={() => handleNavItemClick(surveyItems)}
            isActive={selectedGroupName === surveyItems.name}
            title={surveyItems.name}
            iconName="volunteer_activism"
            items={surveyItems.items}
          />
        )}

        {plan.trainingModuleEnabled && (
          <NavItemGroup
            className="gtm-training-menu"
            onClick={() => handleNavItemClick(trainingItems)}
            isActive={selectedGroupName === trainingItems.name}
            title={trainingItems.name}
            iconName="school"
            items={trainingItems.items}
          />
        )}

        {plan.enpsEnabled && enpsItems.items.length > 0 && (
          <NavItemGroup
            onClick={() => handleNavItemClick(enpsItems)}
            isActive={selectedGroupName === enpsItems.name}
            title={enpsItems.name}
            iconName="volunteer_activism"
            items={enpsItems.items}
          />
        )}

        {can({
          perform: 'show_people_reviews_menu',
          on: organization,
        }) && (
          <NavItemGroup
            onClick={() => handleNavItemClick(peopleReviewItems)}
            isActive={selectedGroupName === peopleReviewItems.name}
            title={peopleReviewItems.name}
            iconName="person_pin"
            items={peopleReviewItems.items}
          />
        )}

        <NavItemGroup
          onClick={() => handleNavItemClick(auditsItems)}
          isActive={selectedGroupName === auditsItems.name}
          title={auditsItems.name}
          iconName="assignment_turned_in"
          items={auditsItems.items}
        />

        {activeUser.isOrganizationAdmin && (
          <NavItem
            iconName="settings"
            title={__('Settings')}
            onClick={() => setSettingPageActive(true)}
          />
        )}

        <NavSection title={__('People')} className="!mt-3">
          {activeUser.isManager && (
            <NavItem
              iconName="group"
              title={__('My Reports')}
              path={pathToMyTeam()}
              onClick={() => handleNavItemClick(pathToMyTeam())}
            />
          )}

          {can({
            perform: 'show_user_directory_menu',
            on: organization,
          }) && (
            <NavItem
              iconName="groups"
              title={__('Directory')}
              path={pathToUsers()}
              onClick={() => handleNavItemClick(pathToUsers())}
            />
          )}
        </NavSection>
      </Accordion>

      <NavProfile className="hidden md:block mt-auto" />
    </React.Fragment>
  );

  return (
    <DesignSystem version={2}>
      <React.Fragment>
        {/* backdrop */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className={classNames(
            'fixed z-[801] bg-black/50 w-screen h-screen inset-0 md:hidden',
            'transition-opacity duration-200',
            { 'opacity-0 -translate-x-full': navHidden }
          )}
          tabIndex={-1}
          onClick={() => setNavHidden(true)}
        />

        <header
          className={classNames(
            'fixed z-50 md:hidden bg-nav-bg flex top-0 w-full h-[63px] justify-between items-center overflow-y-clip',
            'border-0 border-solid border-b border-nav-border-default',
            'navbar-header'
          )}
        >
          <Button
            className="rounded-none bg-transparent h-full w-[52px] border-0 border-r border-nav-border-default"
            onClick={() => {
              setNavHidden(false);
            }}
          >
            <Icon
              className="text-nav-icon"
              iconStyle={{ fontSize: 20 }}
              name="menu"
            />
          </Button>
          <NavProfile />
        </header>

        <nav
          className={classNames(
            'z-[802] fixed inset-0 flex flex-col bg-nav-bg pt-4 w-[var(--vertical-nav-width)] overflow-x-auto',
            'border-0 border-solid border-r border-nav-border-default',
            'transition-transform duration-200',
            { '-translate-x-full': navHidden }
          )}
        >
          {settingPageActive ? (
            <NavSettings
              organization={organization}
              activeUser={activeUser}
              hideSettingsPage={() => setSettingPageActive(false)}
            />
          ) : (
            navContent
          )}
        </nav>

        {trainingModalOpen && currentTrainingPeriodSlug && (
          <TrainingRequestModal
            currentTrainingPeriodSlug={currentTrainingPeriodSlug}
            onClose={() => setTrainingModalOpen(false)}
            isActive={trainingModalOpen}
          />
        )}
      </React.Fragment>
    </DesignSystem>
  );
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    fetchCurrentTrainingPeriod: async () => {
      const { response } = await dispatch(get('training/periods/current'));
      // The endpoint can return no_content if there is no training period
      if (response && response.body) {
        return response.body.data.attributes.slug;
      }
    },
  };
}

export default compose<Props>(
  withActiveUserAndOrganization,
  newDataLoader({
    fetch: () => get('users/me/home_actions'),
    hydrate: { navActionsCount: {} },
    cacheKey: () => window.location.pathname,
  }),
  connect(null, mapDispatchToProps)
)(AppBar) as React.ComponentType<Props>;
