import React, { Fragment, createContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import type { DataLoaderProvidedProps } from 'lib/dataLoader/types';
import type { PeopleReviewCycleShow, PeopleReviewCycleStatus } from 'models';

import can from 'helpers/can';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useAppDispatch, useCurrentOrganization } from 'helpers/hooks';
import { usePollingWithAsyncAction } from 'helpers/hooks/usePollingWithAsyncAction';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToPeopleReviewCycles } from 'helpers/navigation';
import {
  Match,
  pathToPeopleReviewCycleMapping,
  pathToPeopleReviewCycleReviews,
} from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { newDataLoader } from 'lib/dataLoader';
import { get, post, put } from 'redux/actions/api';

import {
  Button,
  ButtonMenu,
  DropdownDivider,
  FetchContainer,
  Icon,
  MenuItem,
  MenuList,
  PageHeader,
  PageTitle,
  Route,
  StrictlySanitizedHtml,
  Switch,
  Text,
  Tooltip,
} from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';

import ExportButton from './ExportButton';
import ImportParticipantsModal from './ImportParticipantsModal';
import Mapping from './Mapping';
import Participants from './Participants';
import AddRoleModal from './components/AddRoleModal';
import ListAdminsModal from './components/ListAdminsModal';

type RouterProps = RouteComponentProps & {
  match: Match<{
    cycleId: string;
  }>;
};

type Props = RouterProps;

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    peopleReviewCycle: PeopleReviewCycleShow;
  };

type DataContextType = {
  withUserMultiFilters: boolean;
};

export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);

function Index({
  peopleReviewCycle,
  isFetching,
  hasError,
  match,
  refetchData,
}: AfterDataLoaderProps) {
  const dispatch = useAppDispatch();
  const { cycleId } = match.params;
  const { featureFlags } = useCurrentOrganization();
  // TODO: multifilters: remove this line when FF is enabled for everyone
  const withUserMultiFilters = featureFlags.includes('userMultifilters');

  const canAddParticipants =
    peopleReviewCycle &&
    can({
      perform: 'add_participants',
      on: peopleReviewCycle,
    });
  const [exportModalIsActive, setExportModalIsActive] = useState(false);
  const [importParticipantsModalIsActive, setImportParticipantsModalIsActive] =
    useState(false);
  const [addRoleModalVisible, setAddRoleModalVisible] = useState(false);
  const [listAdminsModalVisible, setListAdminsModalVisible] = useState(false);

  const { actionIsOngoing, pollerComponent } = usePollingWithAsyncAction(
    peopleReviewCycle?.lastPendingParticipantAdditionAction,
    refetchData
  );

  invariant(
    !!cycleId,
    'cannot display people user reviews without a people review cycle'
  );

  const closePeopleReviewCycle = async () =>
    confirmAsync(
      __('Close the campaign?'),
      <StrictlySanitizedHtml
        html={__(
          'Once the "%1" campaign is closed, <b>the employees\' people reviews will be locked and not editable.</b><br/><br/>However, you will be able to reopen the campaign if needed.',
          peopleReviewCycle?.name
        )}
      />,
      {
        confirmLabel: __('Close the campaign'),
        onConfirm: () => updateCycleStatus('finalized'),
      }
    );

  const reopenPeopleReviewCycle = async () =>
    confirmAsync(
      __('Reopen the campaign?'),
      <StrictlySanitizedHtml
        html={__(
          'By reopening the campaign <b>the people reviews of the employees will be editable again by the reviewers.</b><br/><br/>However, you will have the possibility to close the campaign again.'
        )}
      />,
      {
        confirmLabel: __('Reopen the campaign'),
        onConfirm: () => updateCycleStatus('active'),
      }
    );

  const updateCycleStatus = (status: PeopleReviewCycleStatus) =>
    dispatch(put(`people_review_cycles/${cycleId}`, { status }));

  const exportPeopleReviewCycle = async () => {
    await dispatch(post(`people_review_cycles/${cycleId}/export`));

    setExportModalIsActive(true);
  };

  const handleSendReminder = () => {
    confirmAsync(
      __('Send a reminder'),
      __(
        'Send a reminder email to reviewers who have not completed their people review?'
      ),
      {
        confirmLabel: __('Send a reminder'),
        onConfirm: async () => {
          await dispatch(
            post(`people_review_cycles/${cycleId}/send_reminder`, undefined, {
              successMessage: __('Emails are on their way! 📧'),
            })
          );
        },
      }
    );
  };

  const getHeaderButtons = () => {
    const otherActions = [
      {
        enabled:
          can({ perform: 'send_reminder', on: peopleReviewCycle }) ||
          can({ perform: 'finalize_cycle', on: peopleReviewCycle }),
        node: (
          <ButtonMenu text={__('Other actions')} key="other_actions">
            <MenuList>
              {can({
                perform: 'send_reminder',
                on: peopleReviewCycle,
              }) && (
                <MenuItem key="reminder" onClick={handleSendReminder}>
                  <Text>{__('Send reminder')}</Text>
                </MenuItem>
              )}
              {can({ perform: 'manage_roles', on: peopleReviewCycle }) && (
                <Fragment>
                  <MenuItem
                    key="manage_roles"
                    onClick={() => setAddRoleModalVisible(true)}
                  >
                    <Text>{__('Grant admin permissions on the campaign')}</Text>
                  </MenuItem>
                  <MenuItem
                    key="list_admins"
                    onClick={() => setListAdminsModalVisible(true)}
                  >
                    <Text>
                      {__('See users with admin permissions on the campaign')}
                    </Text>
                  </MenuItem>
                </Fragment>
              )}
              {can({ perform: 'finalize_cycle', on: peopleReviewCycle }) && (
                <Fragment>
                  <DropdownDivider />
                  <MenuItem
                    key="finalize_button"
                    onClick={closePeopleReviewCycle}
                  >
                    <Text>{__('Close People Review Campaign')}</Text>
                  </MenuItem>
                </Fragment>
              )}
            </MenuList>
          </ButtonMenu>
        ),
      },
    ];
    const buttons = [
      {
        enabled: can({
          perform: 'reopen_cycle',
          on: peopleReviewCycle,
        }),
        node: (
          <Button
            key="reopen_button"
            color="secondary"
            onClick={reopenPeopleReviewCycle}
          >
            {__('Reopen')}
          </Button>
        ),
      },
      {
        enabled: true,
        node: (
          <ExportButton
            key="export_button"
            lastPendingExportAction={peopleReviewCycle.lastPendingExportAction}
            exportPeopleReviewCycle={exportPeopleReviewCycle}
            disabled={!can({ perform: 'export', on: peopleReviewCycle })}
          />
        ),
      },
      {
        enabled: canAddParticipants,
        node: (
          <Tooltip
            key="add_participants_tooltip"
            enabled={actionIsOngoing}
            content={__(
              'An addition of participants is in progress, please wait until it is completed.'
            )}
          >
            <Button
              key="add_participants_button"
              color="primary"
              disabled={actionIsOngoing}
              onClick={() => setImportParticipantsModalIsActive(true)}
            >
              <Icon name="add" className="mr-1" />
              {__('Add participants')}
            </Button>
          </Tooltip>
        ),
      },
    ];
    return otherActions
      .concat(buttons)
      .filter(button => button.enabled)
      .map(button => button.node);
  };

  const tabItems = [
    {
      label: __('Participants'),
      to: pathToPeopleReviewCycleReviews(cycleId),
    },
    {
      label: __('Mapping'),
      to: pathToPeopleReviewCycleMapping(cycleId),
    },
  ];

  const pollingDescription = (
    <div>
      <Icon name="sync" isSpinning className="mr-1" />
      {__('Adding participants in progress.')}
    </div>
  );

  return (
    <DataContext.Provider value={{ withUserMultiFilters }}>
      <div className="people-review-cycle">
        {pollerComponent}
        {peopleReviewCycle && <PageTitle title={[peopleReviewCycle.name]} />}

        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          render={() => {
            return (
              <React.Fragment>
                <PageHeader
                  title={peopleReviewCycle.name}
                  description={actionIsOngoing ? pollingDescription : null}
                  withBackButton
                  backButtonProps={{
                    fallbackTarget: pathToPeopleReviewCycles(),
                  }}
                  actions={getHeaderButtons()}
                  tabItems={tabItems}
                />
                <div className="m-4 md:m-6">
                  <Switch>
                    <Route
                      path={`${match.path}/reviews`}
                      render={() => (
                        <Participants peopleReviewCycle={peopleReviewCycle} />
                      )}
                    />

                    <Route
                      path={`${match.path}/mapping`}
                      render={() => <Mapping cycleId={peopleReviewCycle.id} />}
                    />
                  </Switch>
                </div>
              </React.Fragment>
            );
          }}
        />

        {exportModalIsActive && (
          <ExportModal isActive onClose={() => setExportModalIsActive(false)} />
        )}

        {importParticipantsModalIsActive && (
          <ImportParticipantsModal
            cycleId={cycleId}
            onClose={() => setImportParticipantsModalIsActive(false)}
          />
        )}

        {addRoleModalVisible && (
          <AddRoleModal
            cycleId={peopleReviewCycle.id}
            cycleName={peopleReviewCycle.name}
            onClose={() => setAddRoleModalVisible(false)}
          />
        )}
        {listAdminsModalVisible && (
          <ListAdminsModal
            cycleId={peopleReviewCycle.id}
            cycleName={peopleReviewCycle.name}
            onClose={() => setListAdminsModalVisible(false)}
          />
        )}
      </div>
    </DataContext.Provider>
  );
}

export default newDataLoader({
  fetch: ({ match }: Props) =>
    get(`people_review_cycles/${match.params.cycleId}`),
  hydrate: {
    peopleReviewCycle: {
      peopleReviewDimensions: {
        peopleReviewRatingOptions: {},
      },
      abilities: {},
      lastPendingExportAction: {},
      lastPendingParticipantAdditionAction: {},
    },
  },
})(Index);
