import { debounce } from 'lodash';
import React from 'react';
import { components } from 'react-select';
import { DropdownIndicatorProps } from 'react-select';

import { BaseTrainingCourse } from 'models/TrainingCourse';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { hydrateFromResponse } from 'lib/dataLoader';
import { AppDispatch } from 'redux/actions';
import { get } from 'redux/actions/api';
import { ReduxStore } from 'redux/reducers';

import { IOption, Icon, Select } from 'components';

type Props = {
  onChange: (trainingCourse?: BaseTrainingCourse) => void;
  trainingCourse?: BaseTrainingCourse | null;
  isDisabled?: boolean;
};

const SearchDropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon name="search" />
  </components.DropdownIndicator>
);

const TrainingCoursePicker = ({
  onChange,
  trainingCourse,
  isDisabled,
}: Props) => {
  const dispatch = useAppDispatch();

  const trainingCourseToOption = (trainingCourse: BaseTrainingCourse) => ({
    label: trainingCourse.name,
    value: trainingCourse.id,
    trainingCourse,
  });

  const fetchTrainingCourses = async (
    search: string
  ): Promise<BaseTrainingCourse[]> => {
    const fetchUrl = '/training/courses';

    return dispatch(
      async (dispatch: AppDispatch, getState: () => ReduxStore) => {
        const { response } = await dispatch(
          get(fetchUrl, {
            countPerPage: 10,
            search,
            filter: { published: true },
          })
        );

        const { items } = hydrateFromResponse(
          getState().data,
          response.body,
          {
            trainingCourseCollection: {
              items: {
                organism: {},
              },
            },
          },
          response.body.data.id
        );

        return items;
      }
    );
  };

  const loadTrainingCourseOptions = (
    search: string,
    callback: (options: IOption[]) => void
  ) => {
    fetchTrainingCourses(search).then(trainingCourses => {
      callback(trainingCourses.map(trainingCourseToOption));
    });
  };

  const debouncedSearch = debounce(loadTrainingCourseOptions, 300);

  return (
    <Select
      loadOptions={debouncedSearch}
      value={trainingCourse && trainingCourseToOption(trainingCourse)}
      onChange={newValue => {
        onChange(newValue?.trainingCourse);
      }}
      placeholder={__('Search a training')}
      noOptionsMessage={__('No trainings found')}
      isAsync
      inModal={true}
      isClearable
      cacheOptions
      defaultOptions
      loadingMessage={() => __('Loading training courses...')}
      components={{ DropdownIndicator: SearchDropdownIndicator }}
      isDisabled={isDisabled}
    />
  );
};

export default TrainingCoursePicker;
