import React, { Fragment } from 'react';

import { TrainingOrganism } from 'models/TrainingOrganism';

import { useAppDispatch } from 'helpers/hooks';
import { useForm } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';

import { successNotice } from 'redux/actions';
import { put } from 'redux/actions/api';

import UnsavedModificationsGuard from 'components/UnsavedModificationsGuard';

import OrganismDetailsForm, {
  TrainingOrganismData,
} from './OrganismFormDetails';

type Props = {
  organism: TrainingOrganism;
};

const EditOrganismForm = ({ organism }: Props) => {
  const dispatch = useAppDispatch();

  const { values, errors, handleInputChange, handleSubmit, isDirty } =
    useForm<TrainingOrganismData>(
      {
        name: organism.name,
        website: organism.website || '',
        contactName: organism.contactName,
        contactEmail: organism.contactEmail,
      },
      async values => {
        await dispatch(put(`training/organisms/${organism.id}`, values));
        await dispatch(
          successNotice({
            __html: __(
              '<b>The training organization was successfully updated.</b>'
            ),
          })
        );
      }
    );

  return (
    <Fragment>
      <OrganismDetailsForm
        trainingOrganism={values}
        handleInputChange={handleInputChange}
        saveButtonLabel={__('Update')}
        isDirty={isDirty}
        errors={errors}
        onSave={handleSubmit}
      />
      <UnsavedModificationsGuard isDirty={isDirty} onSubmit={handleSubmit} />
    </Fragment>
  );
};

export default EditOrganismForm;
