import React from 'react';

import classNames from 'helpers/classNames';

type Props = {
  children?: React.ReactNode;
  className?: string;
  testClassName?: string;
  isMobile?: boolean;
  style?: React.CSSProperties;
};

export const Level = ({ className, children, style, isMobile }: Props) => (
  <div
    className={classNames(
      'level',
      {
        'is-mobile': !!isMobile,
      },
      className
    )}
    style={style}
  >
    {children}
  </div>
);

export const LevelLeft = (props: Props) => (
  <div
    className={classNames('level-left', props.className)}
    style={props.style}
  >
    {props.children}
  </div>
);

export const LevelRight = ({
  className,
  testClassName,
  style,
  children,
}: Props) => (
  <div
    className={classNames('level-right', className, testClassName)}
    style={style}
  >
    {children}
  </div>
);

export const LevelItem = (props: Props) => (
  <div
    className={classNames('level-item', props.className)}
    style={props.style}
  >
    {props.children}
  </div>
);
