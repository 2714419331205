import { sortBy } from 'lodash';
import React from 'react';

import type { BlockRatingOption, DropdownQuestionBlock } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { del, post, put } from 'redux/actions/api';

import { TextList } from 'components';

type Props = {
  reviewBlock: DropdownQuestionBlock;
  ratingOptions: Array<BlockRatingOption>;
};

const RatingsSection = ({ reviewBlock, ratingOptions }: Props) => {
  const dispatch = useAppDispatch();
  const createRatingOption = (label: string) =>
    dispatch(
      post('rating_options', {
        label,
        role: 'everyone',
        reviewBlockId: reviewBlock.id,
      })
    );
  const updateRatingOption = (
    ratingOptionId: string,
    options: Partial<BlockRatingOption>
  ) => dispatch(put(`rating_options/${ratingOptionId}`, options));
  const deleteRatingOption = (ratingOptionId: string) =>
    dispatch(del(`rating_options/${ratingOptionId}`));

  const canCreateRatingOption = can({
    perform: 'create_rating_option',
    on: reviewBlock,
  });
  const canDeleteRatingOption = can({
    perform: 'delete_rating_option',
    on: reviewBlock,
  });

  return (
    <div className="test-everyone-rating-option-list">
      <TextList
        items={sortBy(ratingOptions, 'position')}
        className="rating-options"
        itemTestClassName="test-rating-option"
        onCreate={canCreateRatingOption ? createRatingOption : undefined}
        onUpdate={updateRatingOption}
        onDelete={
          canDeleteRatingOption
            ? ratingOptionId => deleteRatingOption(ratingOptionId)
            : undefined
        }
        creationPlaceholder={__('Add an answer choice')}
        emptyLabelPlaceholder={__('Option')}
        ordered
      />
    </div>
  );
};

export default RatingsSection;
