import React from 'react';

import type { TrainingSession } from 'models';

import { hasShape, stringType } from 'types/predicates/WithShape';

import ConnectedModal from './ConnectedModal';
import Modal from './Modal';

type Props =
  | {
      sessionId: string;
      onClose: () => void;
      onAfterUpdate?: () => void;
    }
  | {
      session: TrainingSession;
      onClose: () => void;
      onAfterUpdate?: () => void;
    };

const MoveToForecastModal = ({
  onClose,
  onAfterUpdate,
  ...otherProps
}: Props) => {
  if (hasShape(otherProps, { sessionId: stringType })) {
    return (
      <ConnectedModal
        sessionId={otherProps.sessionId}
        onClose={onClose}
        onAfterUpdate={onAfterUpdate}
      />
    );
  }

  return (
    <Modal
      session={otherProps.session}
      onClose={onClose}
      onAfterUpdate={onAfterUpdate}
    />
  );
};

export default MoveToForecastModal;
