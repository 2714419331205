import React, { Fragment } from 'react';

import type { MatrixErrorsType } from '.';
import type { HeaderHandlingProps } from '../../helpers/useParentAndChildrenHandlingWithErrors';
import type { SkillsMatrix } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { pathToMatrixEditor, pathToMatrixEmployeesList } from 'helpers/paths';

import { put } from 'redux/actions/api';

import {
  Button,
  Flex,
  Icon,
  PageHeader,
  SavingStatusLabel,
  Text,
  WithSavingStatusRecorder,
} from 'components';

import { fieldUidForItem } from '../../helpers/useFieldStatusResetWhenLoaded';

type Props = HeaderHandlingProps<SkillsMatrix> & {
  editionMode?: boolean;
};

export default function MatrixHeader({
  parent: matrix,
  onParentUpdate: onMatrixUpdate,
  fieldErrorsFor,
  updateErrors,
  editionMode,
}: Props) {
  const dispatch = useAppDispatch();

  const updateMatrixStatus = (status: string, successMessage: string) => {
    dispatch(
      put(`skills/matrices/${matrix.id}`, { status }, { successMessage })
    );
  };

  const visibilityMessage =
    matrix.status === 'unpublished'
      ? __('Visible only to admins')
      : __('Visible from the career explorer');
  const visibilityIcon =
    matrix.status === 'unpublished' ? 'visibility_off' : 'visibility';

  const canUpdateMatrix = can({ perform: 'update', on: matrix });

  return (
    <PageHeader
      withBackButton
      backButtonProps={{ children: __('Back to skills matrices') }}
      tabItems={
        editionMode
          ? [
              {
                to: pathToMatrixEditor(matrix.id),
                label: __('Levels and expectations'),
                labelPreset: '16bs5.5',
              },
              {
                to: pathToMatrixEmployeesList(matrix.id),
                label: __(
                  'Associated employees (%1)',
                  matrix?.associatedUsersCount || '-'
                ),
                labelPreset: '16bs5.5',
              },
            ]
          : undefined
      }
    >
      <div className="skills-header-editor">
        {editionMode && (
          <div className="float-right mb-3">
            <SavingStatusLabel
              failedText={count =>
                n__(
                  'The skills matrix is invalid, %1 error was detected',
                  'The skills matrix is invalid, %1 errors were detected',
                  count
                )
              }
            />
          </div>
        )}

        <Flex direction="row" className="text-with-save-label">
          <div className="item-title">
            <WithSavingStatusRecorder
              fieldUid={fieldUidForItem('matrix', 0, 'title')}
              onChange={(matrixTitle: string) =>
                onMatrixUpdate({ ...matrix, title: matrixTitle })
              }
              render={onChange => (
                <Text
                  preset="24bs2"
                  isEditable={editionMode}
                  testClassName="test-matrix-title"
                  editableOptions={{
                    type: 'singlelineText',
                    value: matrix.title,
                    placeholder: __('Skills matrix title'),
                    onChange,
                    onFocus: () =>
                      updateErrors((errors: MatrixErrorsType) => ({
                        ...errors,
                        matrix: null,
                      })),
                    displayErrorMessage: false,
                  }}
                />
              )}
            />
            {fieldErrorsFor('matrix')}
          </div>

          {editionMode && (
            <Fragment>
              {matrix.status === 'unpublished' ? (
                <Button
                  color="primary"
                  onClick={() =>
                    updateMatrixStatus(
                      'published',
                      __(
                        '<b>Skills matrix published in career explorer</b><br/>The skills matrix is visible to all employees'
                      )
                    )
                  }
                >
                  <Icon className="mr-1" name="send" />
                  {__('Publish')}
                </Button>
              ) : (
                <Button
                  color="secondary"
                  onClick={() =>
                    updateMatrixStatus(
                      'unpublished',
                      __(
                        '<b>Skills matrix hidden in career explorer</b><br/>The skills matrix is no longer visible to all employees'
                      )
                    )
                  }
                >
                  {__('Hide')}
                </Button>
              )}
            </Fragment>
          )}

          {!editionMode && canUpdateMatrix && (
            <Flex direction="column" className="text-right">
              <div>
                <Button color="secondary" to={pathToMatrixEditor(matrix.id)}>
                  {__('Edit')}
                </Button>
              </div>
              <Text>{__('Only admins can edit a skills matrix.')}</Text>
            </Flex>
          )}
        </Flex>

        {editionMode && (
          <div className="float-right mt-3">
            <Icon className="mr-1" name={visibilityIcon} />
            <Text preset="13s7">{visibilityMessage}</Text>
          </div>
        )}
      </div>
    </PageHeader>
  );
}
