import React from 'react';

import type { UserFilterChangeMethod } from 'lib/dataLoader/pagination/types';

import {
  SurveyCampaign,
  SurveyPollingPeriod,
  SurveyQuestionStats,
  UserFilterSegment,
} from 'models';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { __, date } from 'helpers/i18n';

import {
  Icon,
  Level,
  LevelItem,
  LevelLeft,
  LevelRight,
  PeriodPicker,
  StrictlySanitizedHtml,
  Text,
} from 'components';

import UserFilter from 'scenes/components/UserFilter';
import { surveyIsRecurrent } from 'scenes/surveys/helpers/survey.helpers';

type Props = {
  campaign: SurveyCampaign;
  handleSelectedPeriodChange: (periodId: string) => void;
  selectedPeriod: SurveyPollingPeriod | undefined;
  questionStats?: SurveyQuestionStats;
  userFilter: UserFilterSegment | null;
  onUserFilterChange: UserFilterChangeMethod;
};

const SurveyDetailedResultsHeader = ({
  campaign,
  selectedPeriod,
  handleSelectedPeriodChange,
  questionStats,
  userFilter,
  onUserFilterChange,
}: Props) => {
  const surveyRecurrentWithPeriod =
    surveyIsRecurrent(campaign) && !!selectedPeriod;
  const activePeriod = !!(
    questionStats?.periodIsActive || selectedPeriod?.active
  );
  const formattedEndDate = date(
    questionStats ? questionStats.periodEndDate : selectedPeriod?.endDate,
    'dddd LL'
  );
  const { featureFlags } = useCurrentOrganization();
  const surveyFilteringDisabled = featureFlags.includes(
    'surveyFilteringDisabled'
  );

  return (
    <Level className="mb-6">
      <LevelLeft>
        {surveyRecurrentWithPeriod && (
          <LevelItem>
            <PeriodPicker
              period={{
                id: selectedPeriod.id,
                name: selectedPeriod.displayedName,
              }}
              periods={
                !questionStats?.correlatedPollingPeriods
                  ? []
                  : questionStats?.correlatedPollingPeriods.map(period => {
                      const disabled = !can({
                        perform: 'stats',
                        on: period,
                      });
                      return {
                        id: period.id,
                        name: period.displayedName,
                        disabled,
                        tooltipMessage: disabled
                          ? __(
                              'There are no collaborators from your entity within this period.'
                            )
                          : undefined,
                      };
                    })
              }
              isDisabled={
                !questionStats?.correlatedPollingPeriods ||
                questionStats?.correlatedPollingPeriods.length < 2
              }
              onChange={handleSelectedPeriodChange}
            />
          </LevelItem>
        )}
        {!surveyFilteringDisabled && (
          <LevelItem>
            <UserFilter
              segment={userFilter}
              onChange={onUserFilterChange}
              filteredSource={'survey_users'}
            />
          </LevelItem>
        )}
      </LevelLeft>
      <LevelRight>
        <Text color="info" preset="14s6">
          <Icon name="timer" className="mr-1" />
          <StrictlySanitizedHtml
            html={
              activePeriod
                ? __(
                    'The evaluation of this question is under way and will close on <b>%1</b>',
                    formattedEndDate
                  )
                : __(
                    'The evaluation of this question has been closed since <b>%1</b>',
                    formattedEndDate
                  )
            }
          />
        </Text>
      </LevelRight>
    </Level>
  );
};

export default SurveyDetailedResultsHeader as React.ComponentType<Props>;
