import React, { CSSProperties } from 'react';

import colors from 'styles/colors';

import { DelayedRenderer } from 'components';

export const DEFAULT_SIZE = 25;

const sizes = {
  small: 0.75,
  tiny: 0.5,
};

type Size = keyof typeof sizes;

type CircularProgressProps = {
  size?: Size;
  style?: CSSProperties;
  borderWidth: number;
  color: string;
};

function CircularProgress({
  size,
  style = {},
  color,
  borderWidth,
}: CircularProgressProps) {
  let sizeInPixels = DEFAULT_SIZE;

  if (size) {
    sizeInPixels = sizeInPixels * sizes[size];
  }

  return (
    <div
      className="loader"
      style={{
        display: 'inline-block',
        borderColor: color,
        borderWidth: `${borderWidth}px ${borderWidth}px 0 0`,
        height: `${sizeInPixels}px`,
        width: `${sizeInPixels}px`,
        ...style,
      }}
    />
  );
}

CircularProgress.defaultProps = {
  color: colors.mediumGrey,
  borderWidth: 2,
};

export { CircularProgress };

type LoadingProps = {
  containerStyle?: CSSProperties;
  containerClassName?: string;
  size?: Size;
  style?: CSSProperties;
  color?: string;
  delay: number;
};

function Loading({
  containerStyle,
  containerClassName,
  size,
  style,
  color,
  delay,
}: LoadingProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        ...containerStyle,
      }}
      className={containerClassName}
    >
      <DelayedRenderer delay={delay}>
        <CircularProgress size={size} style={style} color={color} />
      </DelayedRenderer>
    </div>
  );
}

Loading.defaultProps = {
  delay: 500,
};

export default Loading;
