import React from 'react';

import invariant from 'helpers/invariant';

import { Icon } from 'components';
import materialIconMapping from 'components/Icon/materialIconMapping';

type MaterialIconName = keyof typeof materialIconMapping;

type Props = {
  productName:
    | 'one_on_one'
    | '360'
    | 'imported_review'
    | 'people_review'
    | 'training';
};

const productIconSettings = ({
  productName,
}: Props): {
  iconName: MaterialIconName;
  textColor: string;
  bgColor: string;
} => {
  switch (productName) {
    case 'one_on_one':
      return {
        iconName: 'speed',
        textColor: 'text-blue-500',
        bgColor: 'bg-blue-200',
      };
    case '360':
      return {
        iconName: '360',
        textColor: 'text-yellow-500',
        bgColor: 'bg-yellow-200',
      };
    case 'imported_review':
      return {
        iconName: 'download',
        textColor: 'text-blue-500',
        bgColor: 'bg-blue-200',
      };
    case 'people_review':
      return {
        iconName: 'person_pin',
        textColor: 'text-indigo-500',
        bgColor: 'bg-indigo-200',
      };
    case 'training':
      return {
        iconName: 'school',
        textColor: 'text-green-500',
        bgColor: 'bg-green-200',
      };
    default:
      return {
        iconName: 'speed',
        textColor: 'text-blue-500',
        bgColor: 'bg-blue-200',
      };
  }
};

const ProductIcon = ({ productName }: Props) => {
  invariant(
    productName,
    'The name of the product is required to render the product icon.'
  );

  const { iconName, bgColor, textColor } = productIconSettings({ productName });
  return (
    <div
      className={`rounded-full ${bgColor} min-w-8 max-w-8 min-h-8 max-h-8 flex items-center justify-center`}
    >
      <Icon name={iconName} className={textColor} />
    </div>
  );
};

export default ProductIcon;
