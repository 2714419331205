import React, { Fragment } from 'react';

import {
  ForecastFundingSourceStats,
  ValidatedFundingSourceStats,
} from 'models';

import invariant from 'helpers/invariant';

import { hasShape } from 'types/predicates/WithShape';

import { Text } from 'components';

import BudgetVisualizer from '../components/BudgetVisualizer';

type Props = {
  sourceStats: ValidatedFundingSourceStats | ForecastFundingSourceStats;
};

const FundingSourceWithBudgetStats = ({ sourceStats }: Props) => {
  const currency = sourceStats.currency;
  const forecastAmountCents = hasShape(sourceStats, { forecastAmountCents: 0 })
    ? sourceStats.forecastAmountCents
    : undefined;
  const provisionedAmountCents = sourceStats.budgetItem.provisionedAmountCents;
  invariant(provisionedAmountCents, 'provisionedAmountCents is required');

  return (
    <Fragment>
      <Text preset="14bs6">{sourceStats.title}</Text>
      <BudgetVisualizer
        className="mt-1"
        testClassName="test-funding-source-budget-visualizer"
        provisionedAmountCents={provisionedAmountCents}
        validatedAmountCents={sourceStats.validatedAmountCents}
        forecastAmountCents={forecastAmountCents}
        currency={currency}
      />
    </Fragment>
  );
};

export default FundingSourceWithBudgetStats;
