import React, { Fragment } from 'react';

import { __ } from 'helpers/i18n';

import {
  CheckboxSwitch,
  Column,
  Columns,
  Flex,
  PageTitle,
  Text,
  Title,
} from 'components';

const ReviewsSettings = () => (
  <Fragment>
    <PageTitle title={[__('Reviews'), __('Settings')]} />

    <div className="bg-white rounded p-5 max-w-[1000px] mx-auto mt-8 md:mt-0">
      <Columns contentVerticallyAligned>
        <Column>
          <Flex verticalAlign className="mb-2">
            <Title size={5} weight="semibold" className="mr-5">
              {__('Reviews')}
            </Title>
            <Text color="success">{__('Enabled_masculine')}</Text>
          </Flex>
          <Text>{__('The Reviews product is enabled on your platform.')}</Text>
        </Column>
        <Column isNarrow>
          <CheckboxSwitch disabled value onChange={() => {}} size="small" />
        </Column>
      </Columns>
    </div>
  </Fragment>
);

export default ReviewsSettings;
