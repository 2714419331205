import React from 'react';

import type { FinalCommentBlockContent } from 'models';

import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import { Box } from 'components';

import BlockDetails from 'scenes/components/review/BlockDetails';

import FeedbackZone from './components/FeedbackZone';

type Props = {
  content: FinalCommentBlockContent;
  withoutSectionSeparator?: boolean;
};

export default function FinalCommentBlockContentComponent({
  content,
  withoutSectionSeparator,
}: Props) {
  const { id, title, description, feedbackableItems, richTextEnabled } =
    content;
  const feedbackableItem = feedbackableItems[0];

  return (
    <React.Fragment>
      <BlockDetails
        id={id}
        section={__('Resume')}
        title={title}
        description={description}
        withoutSectionSeparator={withoutSectionSeparator}
        withRichText={richTextEnabled}
      />

      <Box
        className="block-content-wrapper"
        testClassName="test-final-comment-block"
        style={{ marginTop: 32 }}
      >
        <FeedbackZone
          feedbackableItem={feedbackableItem}
          persistAction={(
            _evaluationId,
            _feedbackableId,
            _feedbackableType,
            text,
            _rating
          ) =>
            put(`user_reviews/${feedbackableItem.item.id}/final_comment`, {
              userReview: {
                finalComment: text,
              },
            })
          }
        />
      </Box>
    </React.Fragment>
  );
}
