import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';

import type { ObjectivePeriod } from 'models';

import { __ } from 'helpers/i18n';
import { Match } from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  Can,
  EmptyStateWithIcon,
  FetchContainer,
  Testable,
  Toggleable,
} from 'components';

import CreationModal from '../components/CreateOrganizationPeriodModal';

type Props = {
  match: Match<{ id: string }>;
};

type AfterConnectProps = {
  currentObjectivePeriod: ObjectivePeriod;
  createObjectivePeriod: (a: string) => Promise<void>;
  notifySuccess: (message: string) => void;
  notifyError: (message: string) => void;
  isFetching: boolean;
  hasError: boolean;
} & Props;

function OrganizationPeriodCreator({
  currentObjectivePeriod,
  match,
  isFetching,
  hasError,
}: AfterConnectProps) {
  if (currentObjectivePeriod) {
    return (
      <Redirect
        to={{
          pathname: `${match.url}/${currentObjectivePeriod.slug}`,
          state: {
            objectivePeriod: currentObjectivePeriod,
          },
        }}
      />
    );
  }

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => (
        <Can
          perform="manage_team_objectives"
          on="organization"
          alternativeChildren={
            <EmptyStateWithIcon
              title=""
              iconName="track_changes"
              description={__(
                "Team objectives haven't yet been activated by your organization."
              )}
            />
          }
        >
          <Toggleable
            render={(isModalVisible, toggleModalVisibility) => (
              <Fragment>
                <Testable name="test-emptystate-objective-period-creation">
                  <Button color="primary" onClick={toggleModalVisibility}>
                    {__('Create a period')}
                  </Button>
                </Testable>
                <EmptyStateWithIcon
                  title=""
                  iconName="track_changes"
                  className="mt-4"
                  description={__(
                    'Create your first organization period to start using team objectives.'
                  )}
                />

                {isModalVisible && (
                  <CreationModal onClose={toggleModalVisibility} />
                )}
              </Fragment>
            )}
          />
        </Can>
      )}
    />
  );
}

export default newDataLoader({
  fetch: () => get('organization_objective_periods/current'),
  hydrate: {
    currentObjectivePeriod: {},
  },
})(OrganizationPeriodCreator) as React.ComponentType<Props>;
