import React, { createContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useCurrentOrganization } from 'helpers/hooks';

import { Route, Switch } from 'components';

import ReviewCycleIndex from './Index';
import ReviewCycleManage from './Manage';
import Edit from './Setup/Edit';
import New from './Setup/New';

type DataContextType = {
  withUserMultiFilters: boolean;
};

export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);

const Routes = () => {
  const { path } = useRouteMatch();
  const { featureFlags } = useCurrentOrganization();
  const withUserMultiFilters = featureFlags.includes('userMultifilters');

  return (
    <React.Fragment>
      <DataContext.Provider value={{ withUserMultiFilters }}>
        <Switch>
          <Route exact path={`${path}/new`} component={New} />
          <Route path={`${path}/:reviewCycleId/edit`} component={Edit} />
          <Route path={`${path}/:id`} component={ReviewCycleManage} />
          <Route>
            <ReviewCycleIndex />
          </Route>
        </Switch>
      </DataContext.Provider>
    </React.Fragment>
  );
};

export default Routes;
