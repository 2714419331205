import React, {
  CSSProperties,
  ComponentProps,
  ReactElement,
  ReactNode,
  isValidElement,
} from 'react';

import classNames from 'helpers/classNames';
import createBodyPortal from 'helpers/react/createBodyPortal';

import { DesignSystem, Icon } from 'components';

type ModalCardTitleProps = {
  children: ReactNode;
};

export const ModalCardTitle = (props: ModalCardTitleProps) => (
  <div className="modal-card-title">{props.children}</div>
);

type ModalCardHeadProps = {
  children?: ReactNode;
  onClose?: () => void;
};

export const ModalCardHead = (props: ModalCardHeadProps) => (
  <header className="modal-card-head">
    {props.children}
    {props.onClose && (
      <button className="modal-delete-button" onClick={props.onClose}>
        <Icon name="close" />
      </button>
    )}
  </header>
);

type ModalCardProps = {
  refreshContentOnOpening?: boolean;
  isActive?: boolean;
  isLarge?: boolean;
  isBig?: boolean;
  onClose: () => void;
  onOpen?: () => Promise<void> | void;
  children?: React.ReactElement | React.ReactElement[];
  style?: CSSProperties;
  className?: string;
  testClassName?: string;
  nonEscapable?: boolean;
};

export class ModalCard extends React.Component<ModalCardProps> {
  onKeyPress = (event: KeyboardEvent) => {
    const { nonEscapable } = this.props;

    if (event.keyCode === 27 && !nonEscapable) {
      this.closeIfActive();
    }
  };

  componentWillMount() {
    document.addEventListener('keydown', this.onKeyPress, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPress, false);
    this.allowWindowScroll();
  }

  componentWillReceiveProps(nextProps: ModalCardProps) {
    if (!this.props.isActive && nextProps.isActive) {
      this.forbideWindowScroll();
      if (this.props.onOpen) {
        this.props.onOpen();
      }
    } else if (this.props.isActive && !nextProps.isActive) {
      this.allowWindowScroll();
    }
  }

  closeIfActive() {
    if (this.props.isActive) {
      this.props.onClose();
    }
  }

  getChildren() {
    return React.Children.map(this.props.children, child => {
      if (isValidElement(child) && child.type === ModalCardHead) {
        return React.cloneElement(
          child as ReactElement<ComponentProps<typeof ModalCardHead>>,
          {
            onClose: this.props.onClose,
          }
        );
      } else {
        return child;
      }
    });
  }

  allowWindowScroll = () => {
    if (document.documentElement)
      document.documentElement.style.overflow = 'auto';
  };
  forbideWindowScroll = () => {
    if (document.documentElement)
      document.documentElement.style.overflow = 'hidden';
  };

  shouldMountChildren = (): boolean => {
    const { refreshContentOnOpening, isActive } = this.props;

    if (refreshContentOnOpening && !isActive) {
      return false;
    }

    return true;
  };

  render() {
    const { isActive, isLarge, isBig, style, className, testClassName } =
      this.props;

    return createBodyPortal(
      <DesignSystem version={2}>
        <div
          className={classNames('modal', className, {
            'is-active': isActive,
          })}
        >
          <div className="modal-background" />
          <div
            className={classNames('modal-card', testClassName, {
              'is-large': isLarge,
              'is-big': isBig,
            })}
            style={style}
          >
            {this.shouldMountChildren() ? this.getChildren() : null}
          </div>
        </div>
      </DesignSystem>
    );
  }
}

type ModalCardBodyProps = {
  children?: ReactNode;
  overflowVisible?: boolean;
  style?: CSSProperties;
  className?: string;
};

export const ModalCardBody = ({
  children,
  overflowVisible,
  style,
  className,
}: ModalCardBodyProps) => (
  <section
    className={classNames('modal-card-body', className)}
    style={{ overflow: overflowVisible ? 'visible' : 'auto', ...style }}
  >
    {children}
  </section>
);

type ModalCardFooterProps = {
  children?: ReactNode;
};

export const ModalCardFooter = (props: ModalCardFooterProps) => (
  <section className="modal-card-foot">{props.children || null}</section>
);
