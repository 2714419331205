import React from 'react';

import type { SkillsLevel } from 'models';

import { __ } from 'helpers/i18n';
import { concatenatedSkillLevelWithMatrixTitle } from 'helpers/models/skills/skillLevel';
import { pathToMatrix } from 'helpers/paths';

import { Button, Flex, Text, Title } from 'components';

type Props = {
  currentLevel: SkillsLevel;
};

const Header = ({ currentLevel }: Props) => {
  return (
    <Flex direction="column">
      <Flex verticalAlign className="justify-between">
        <Title size={5} className="mr-2">
          {concatenatedSkillLevelWithMatrixTitle(currentLevel)}
        </Title>
        <Button
          style={{ height: '100%' }}
          color="secondary"
          to={pathToMatrix(currentLevel.matrixId)}
        >
          {__('See the matrix')}
        </Button>
      </Flex>

      <Text className="mt-2" color="dark-grey">
        {currentLevel.description}
      </Text>
    </Flex>
  );
};

export default Header;
