import React, { ReactNode } from 'react';

import { Box, Icon, MaterialIconName, Text } from 'components';

export type Props = {
  iconName: MaterialIconName;
  title: string;
  description?: ReactNode;
  inBox?: boolean;
  className?: string;
  testClassName?: string;
};

const EmptyStateWithIcon = ({
  iconName,
  title,
  description,
  className,
  testClassName,
  inBox = true,
}: Props) => {
  const emptyStateFragment = (
    <div className={`flex flex-col items-center ${inBox ? '' : className}`}>
      <div className="w-12 h-12 mb-4 bg-gray-200 rounded-full flex justify-center items-center">
        <Icon name={iconName} />
      </div>

      <Text preset="14bs6">{title}</Text>
      {!!description && (
        <Text preset="13s7" className="my-2 whitespace-pre-wrap">
          {description}
        </Text>
      )}
    </div>
  );
  return inBox ? (
    <Box className={className} testClassName={testClassName}>
      {emptyStateFragment}
    </Box>
  ) : (
    emptyStateFragment
  );
};

export default EmptyStateWithIcon;
