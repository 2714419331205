window.dataLayer = window.dataLayer || [];

export function track(event: string, properties: {}) {
  try {
    window.dataLayer.push({
      ...properties,
      event: event.toLowerCase(),
    });
  } catch (error) {
    window.logException(error);
  }
}

export function identify(info: {}) {
  track('identity set', info);
}

export function reset() {
  track('identity unset', {});
}

export function page({ path }: { path: string }) {
  track('page view', { pagePath: path });
}
