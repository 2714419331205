import React from 'react';

import type { SkillsLevel, SkillsMatrix } from 'models';
import type { EditorHandlingProps } from 'scenes/skills/helpers/useParentAndChildrenHandlingWithErrors';

import { Box, BoxList, BoxListItem, Flex } from 'components';

import MatrixContent from './MatrixContent';
import MatrixHeader from './MatrixHeader';

type Props = EditorHandlingProps<SkillsMatrix, SkillsLevel>;

const Matrix = (props: Props) => {
  return (
    <Box>
      <Flex className="skills-editor-visualizer" style={{ overflow: 'auto' }}>
        <BoxList className="my-4">
          <BoxListItem>
            <MatrixHeader {...props} />
          </BoxListItem>
          <MatrixContent />
        </BoxList>
      </Flex>
    </Box>
  );
};

export default Matrix;
