import React, { useState } from 'react';

import useIsMobile from 'helpers/hooks/useIsMobile';

import { Avatar, Button, Icon } from 'components';

type Props = {
  avatarUrl: string | undefined | null;
  children?: React.ReactNode;
};

const SlidingSidebar = ({ avatarUrl, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const isMobile = useIsMobile();

  if (isMobile) return null;

  return (
    <div className="sliding-sidebar">
      <div
        className={`sliding-sidebar__bar fixed h-full top-0 w-90 bg-white ease-in-out duration-300 z-40 ${
          isOpen ? 'right-0 translate-x-0' : 'right-6 translate-x-full'
        }`}
      >
        {/* this div is a hack to have box shadow only on the laft */}
        <div
          className={`sliding-sidebar__button fixed top-3.5 w-24 h-12 rounded-2xl bg-white ease-in-out duration-300 ${
            isOpen ? 'sliding-sidebar__button--open -left-7' : '-left-20'
          }`}
        />
        <Button
          className={`border-none py-2 px-4 fixed top-3.5 flex rounded-2xl ease-in-out duration-300 ${
            isOpen ? '-left-7 pr-2' : '-left-20'
          }`}
          onClick={() => toggleSidebar()}
        >
          <Icon
            name="menu_open"
            className={`text-2xl p-0 ${isOpen && 'rotate-180'}`}
          />
          {!isOpen && <Avatar url={avatarUrl} className="ml-2" />}
        </Button>
        {isOpen && children}
      </div>
    </div>
  );
};

export default SlidingSidebar;
