import React from 'react';

import { BoxListItem } from 'components';

const BlockSeparator = () => (
  <BoxListItem className="bg-ui-body-bg">
    <div className="h-4" />
  </BoxListItem>
);

export default BlockSeparator;
