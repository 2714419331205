import React from 'react';

import classNames from 'helpers/classNames';

import aiLogo from './svg/aiLogo';

type Props = {
  className?: string;
};

const AILogo = ({ className }: Props) => (
  <span className={classNames('is-size-6', className)}>
    <img
      alt="ai-logo"
      src={`data:image/svg+xml;base64,${window.btoa(aiLogo)}`}
    />
  </span>
);

export default AILogo;
