import * as React from 'react';

import type { User } from 'models';

import { BoxListItem, Checkbox, Column, Columns, PullRight } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

type Props = {
  user: User;
  isSelected: boolean;
  toggleTrainee: (id: string) => void;
};

export default function UserPickerListItem({
  user,
  isSelected,
  toggleTrainee,
}: Props) {
  const { id } = user;
  return (
    <BoxListItem className="user-picker-list-item">
      <Columns contentVerticallyAligned isMobile>
        <Column size={7}>
          <UserAvatar user={user} withJobTitle />
        </Column>
        <Column size={5}>
          <PullRight>
            <Checkbox
              style={{ marginLeft: '10px' }}
              isChecked={isSelected}
              onChange={() => toggleTrainee(id)}
            />
          </PullRight>
        </Column>
      </Columns>
    </BoxListItem>
  );
}
