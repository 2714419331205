import React from 'react';

import { handleFormErrors } from 'helpers/api';
import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import {
  Button,
  DragAndDropContainer,
  DragAndDropItem,
  Flex,
  Icon,
  Testable,
  Text,
  WithSavingStatusRecorder,
} from 'components';

type Item = {
  label: string;
  id: string;
  position: number;
};

type Props = {
  items: Array<Item>;

  disabled?: boolean;
  ordered?: boolean;
  creationPlaceholder?: string;
  emptyLabelPlaceholder?: string;
  onUpdate?: (id: string, options: Partial<Item>) => Promise<any>;
  onCreate?: (label: string) => Promise<any>;
  onDelete?: (id: string) => Promise<any>;
  onReorder?: (id: string, newPosition: number) => Promise<any>;
  className?: string;
  testClassName?: string;
  itemTestClassName?: string;

  handleErrors?: boolean;
};

export default function TextList({
  items,
  creationPlaceholder,
  onCreate,
  onUpdate,
  onDelete,
  disabled,
  emptyLabelPlaceholder,
  ordered,
  className,
  testClassName,
  itemTestClassName,
  handleErrors,
}: Props) {
  const [errors, setErrors] = React.useState({});

  const performAction = async (itemId: string, action: () => Promise<any>) => {
    if (handleErrors) {
      return handleFormErrors(
        action,
        receivedErrors => setErrors({ ...errors, [itemId]: receivedErrors }),
        true
      );
    }

    return action();
  };

  const handleUpdate = async (itemId: string, options: Partial<Item>) =>
    performAction(itemId, () =>
      !!onUpdate ? onUpdate(itemId, options) : Promise.resolve()
    );
  const handleCreate = async (label: string) =>
    performAction('0', () =>
      !!onCreate ? onCreate(label) : Promise.resolve()
    );

  const errorDisplayer = (itemId: string) =>
    handleErrors &&
    !!errors[itemId] && (
      <Text color="danger" weight="semibold" className="help">
        <p>{Object.values(errors[itemId]).join(', ')}</p>
      </Text>
    );

  return (
    <div
      className={classNames('text-list', className, testClassName, {
        ordered,
        editable: !!onUpdate,
      })}
    >
      <DragAndDropContainer
        onChangePosition={(itemId, newIndex) =>
          handleUpdate(itemId, { position: newIndex })
        }
      >
        {items.map(item => (
          <DragAndDropItem
            key={item.id}
            itemId={item.id}
            position={item.position}
            disabled={false}
            className={itemTestClassName || ''}
            style={{ marginLeft: '15px' }}
          >
            <div className="text-list-item">
              <Flex verticalAlign style={{ width: '100%' }}>
                {ordered && (
                  <Text size={7} className="item-position">
                    {item.position + 1}.
                  </Text>
                )}
                <div style={{ width: '100%' }}>
                  <Testable name="test-input-field">
                    <WithSavingStatusRecorder
                      onChange={(label: string) =>
                        handleUpdate(item.id, { label })
                      }
                      render={autoSavingOnchange => (
                        <React.Fragment>
                          <Text
                            size={7}
                            className="label"
                            isEditable={!!onUpdate && !disabled}
                            editableOptions={{
                              type: 'singlelineText',
                              onChange: autoSavingOnchange,
                              value: item.label,
                              placeholder: emptyLabelPlaceholder,
                              withEditIcon: false,
                              displayErrorMessage: !handleErrors,
                            }}
                          />
                          {errorDisplayer(item.id)}
                        </React.Fragment>
                      )}
                    />
                  </Testable>
                </div>
              </Flex>

              {!!onDelete && (
                <WithSavingStatusRecorder
                  onChange={() => onDelete(item.id)}
                  render={autoSavingOnchange => (
                    <Testable name="test-delete-button">
                      <Button
                        className="delete-item"
                        isText
                        size="small"
                        onClick={autoSavingOnchange}
                      >
                        <Icon size="small" name="close" />
                      </Button>
                    </Testable>
                  )}
                />
              )}
            </div>
          </DragAndDropItem>
        ))}
      </DragAndDropContainer>
      {!disabled && onCreate && (
        <div className="add-item text-list-item">
          <WithSavingStatusRecorder
            onChange={handleCreate}
            render={autoSavingOnchange => (
              <Flex direction="column" style={{ marginLeft: '15px' }}>
                <Text
                  size={7}
                  style={{ width: '100%' }}
                  isEditable={!!onCreate}
                  editableOptions={{
                    type: 'singlelineText',
                    value: '',
                    onChange: autoSavingOnchange,
                    placeholder: creationPlaceholder || __('Add a new entry'),
                    displayErrorMessage: !handleErrors,
                  }}
                />
                {errorDisplayer('0')}
              </Flex>
            )}
          />
        </div>
      )}
    </div>
  );
}
