import React from 'react';

import type { SkillsArea } from 'models';

import { BoxListItem, Checkbox, Column, Columns, Text } from 'components';

type Props = {
  area: SkillsArea;
  selected: boolean;
  selectable: boolean;
  unselectableMessage: string;
  onCheckboxClick: (selected: boolean) => void;
};

const SkillAreaListItem = ({
  area,
  selected,
  onCheckboxClick,
  selectable,
  unselectableMessage,
}: Props) => {
  return (
    <BoxListItem>
      <Columns contentVerticallyAligned isMobile>
        <Column size={8}>
          <Text color={selectable ? undefined : 'grey'}>{area.title}</Text>
        </Column>
        <Column size={4} className="pl-0 pr-0" contentIsPulledRight>
          {selectable ? (
            <Checkbox isChecked={selected} onChange={onCheckboxClick} />
          ) : (
            <Text color="grey">{unselectableMessage}</Text>
          )}
        </Column>
      </Columns>
    </BoxListItem>
  );
};

export default SkillAreaListItem;
