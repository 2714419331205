import React from 'react';

import type { Signature } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { AutoSave, Text, Textarea } from 'components';

type Props = {
  signature: Signature;
  style: React.CSSProperties;
  persistFinalComment?: (text: string) => Promise<void>;
};

const MAX_FINAL_COMMENT_LENGTH = 500;

export default function FinalCommentField({
  signature,
  style,
  persistFinalComment,
}: Props) {
  const { finalComment } = signature;
  const [textInput, setTextInput] = React.useState(finalComment || '');

  if (signature.isSigned) {
    return (
      <div style={{ marginTop: 8 }}>
        {!!finalComment ? (
          <Text
            className="final-comment-text"
            testClassName="test-final-comment-text"
          >
            {finalComment}
          </Text>
        ) : (
          <Text
            testClassName="test-final-comment-text"
            transformation="italic"
            weight="light"
          >
            {__('%1 has not left a final comment', signature.author.fullName)}
          </Text>
        )}
      </div>
    );
  }

  invariant(
    persistFinalComment,
    'persistFinalComment must be defined at this point'
  );

  return (
    <div className="test-final-comment-input">
      <AutoSave
        fieldUid={compositeKey({
          itemId: signature.id,
          field: 'finalComment',
        })}
        onChange={setTextInput}
        doPersist={() => persistFinalComment(textInput)}
        render={autoSavingOnChange => (
          <Textarea
            value={textInput}
            placeholder={__('Add a final comment before signing the review...')}
            style={style}
            onChange={autoSavingOnChange}
            maxLength={MAX_FINAL_COMMENT_LENGTH}
            onMaxLengthReached={() =>
              __(
                'Your final comment should not exceed %1 characters',
                MAX_FINAL_COMMENT_LENGTH
              )
            }
          />
        )}
      />
    </div>
  );
}
