import React, { Fragment } from 'react';

import type { Occupation } from 'models';

import {
  dateWithoutTimeFromDateString,
  startToEndDateInMonths,
  timeBetweenDates,
} from 'helpers/date';

import { Button, Icon, Text } from 'components';

import SalaryHistory from 'scenes/components/professionalBackground/SalaryHistory';
import addProgressPercent from 'scenes/components/professionalBackground/helpers/addProgressPercent';
import groupConsecutiveSimilarOccupations from 'scenes/components/professionalBackground/helpers/groupConsecutiveSimilarOccupations';

type Props = {
  occupations: Array<Occupation>;
  showBlurButton: boolean;
  blurSalary: boolean;
  setBlurSalary: (value: boolean) => void;
};

const OccupationHistory = ({
  occupations,
  showBlurButton,
  blurSalary,
  setBlurSalary,
}: Props) => {
  const groupedOccupations = groupConsecutiveSimilarOccupations(
    addProgressPercent(occupations)
  );

  return (
    <ul className="mt-4">
      {groupedOccupations.map((occupationGroup, index) => {
        const mostRecentEndDate = occupationGroup.endDate;
        const oldestStartDate = dateWithoutTimeFromDateString(
          occupationGroup.startDate
        );
        const humanizedPeriod = startToEndDateInMonths(
          oldestStartDate,
          !!mostRecentEndDate
            ? dateWithoutTimeFromDateString(mostRecentEndDate)
            : undefined
        );
        const humanizedTimeSinceStartDate = timeBetweenDates(
          oldestStartDate,
          !!mostRecentEndDate
            ? dateWithoutTimeFromDateString(mostRecentEndDate)
            : new Date()
        );

        const anyOccupationWithSalaryInfo = occupationGroup.occupations.some(
          occupation =>
            !!occupation.fixedRemunerationCents ||
            !!occupation.variableRemunerationCents
        );

        return (
          <Fragment>
            {index > 0 && (
              <div className="h-[1px] w-full mt-4 mb-3 bg-text-lightest bg-opacity-60" />
            )}
            <li
              key={occupationGroup.title + occupationGroup.startDate}
              className="mt-2"
            >
              <div className="flex">
                <div className="flex items-center justify-between w-full h-6">
                  <Text preset="14bs6" className="block mb-0 ">
                    {occupationGroup.title}
                  </Text>
                  {showBlurButton && index === 0 && (
                    <Button
                      onClick={() => setBlurSalary(!blurSalary)}
                      color="secondary"
                      hasIconOnly
                    >
                      <Icon
                        name={blurSalary ? 'visibility_off' : 'visibility'}
                      />
                    </Button>
                  )}
                </div>
              </div>

              <div className="flex">
                <div className="flex-1">
                  <Text color="light">
                    {humanizedPeriod.charAt(0).toUpperCase() +
                      humanizedPeriod.slice(1)}
                    {' • '}
                    {humanizedTimeSinceStartDate}
                  </Text>

                  {anyOccupationWithSalaryInfo && (
                    <SalaryHistory
                      occupations={occupationGroup.occupations}
                      blurSalary={blurSalary}
                      inUserPanel
                    />
                  )}
                </div>
              </div>
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
};

export default OccupationHistory;
