import React from 'react';

import { __ } from 'helpers/i18n';

import { Icon, Text, Tooltip } from 'components';

const ForecastHeader = () => (
  <Text preset="14bs6" color="light" className="min-w-0 text-nowrap">
    {__('Forecast')}
    <Tooltip
      content={__(
        'The forecast sessions impact the forecast budget and can be moved to the validated plan in a click.'
      )}
      isLight
    >
      <Icon name="info" className="ml-2" />
    </Tooltip>
  </Text>
);

export default ForecastHeader;
