import React from 'react';

import { Notification, StrictlySanitizedHtml, Text } from 'components';

interface Props {
  text: string;
  className?: string;
}

const BlueInfoBox = ({ text, className }: Props) => (
  <Notification kind="primary" className={className}>
    <Text className="mr-2">ℹ️</Text>
    <StrictlySanitizedHtml html={text} />
  </Notification>
);

export default BlueInfoBox;
