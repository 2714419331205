import React, { useCallback, useContext } from 'react';
import { compose } from 'redux';

import { AdminCollection } from 'models/User';

import compositeKey from 'helpers/compositeKey';
import { __, date, n__ } from 'helpers/i18n';

import {
  DataLoaderProvidedProps,
  PaginationProps,
  WithPaginationProps,
  newDataLoader,
  withDeprecatedPagination,
} from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  EmptyState,
  FetchContainer,
  SimpleTable,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

import { DataContext } from '..';
import ItemMenu from './ItemMenu';
import RolesOverview from './RolesOverview';

type Props = WithPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = Props &
  PaginationProps &
  DataLoaderProvidedProps & { adminCollection: AdminCollection };

const AdminList = ({
  adminCollection,
  isFetching,
  hasError,
  refetchData,
  ...datatableWrapperProps
}: AfterDataLoaderProps) => {
  const { shouldRefetchAdmins, setShouldRefetchAdmins } =
    useContext(DataContext);

  const refetchAdmins = useCallback(() => {
    refetchData();
    setShouldRefetchAdmins(false);
  }, [refetchData, setShouldRefetchAdmins]);

  React.useEffect(() => {
    if (shouldRefetchAdmins) refetchAdmins();
  }, [refetchAdmins, shouldRefetchAdmins]);

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={adminCollection}
        isFetching={isFetching}
        hasError={hasError}
        {...datatableWrapperProps}
        renderNoResult={() => (
          <EmptyState title={__('No admin matches your search')} inBoxList />
        )}
        totalCountRenderer={totalCount =>
          n__('%1 user', '%1 users', totalCount || 0)
        }
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleTable
              keyFn={user => user.id}
              rows={adminCollection?.users || []}
              columns={[
                {
                  header: __('Users'),
                  cell: user => <UserAvatar user={user} withJobTitle />,
                },
                {
                  header: __('Roles & entities'),
                  cell: user => <RolesOverview user={user} />,
                },
                {
                  header: __('Last update'),
                  cell: user => date(user.lastAdminRoleUpdatedAt, 'LL'),
                },
                {
                  header: '',
                  cell: user => (
                    <ItemMenu user={user} refetchData={refetchData} />
                  ),
                  isNarrow: true,
                },
              ]}
            />
          )}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({ page, countPerPage, search, userFilter }: AfterPaginateProps) =>
      get('users/admins', {
        page,
        countPerPage,
        search,
        userFilter,
      }),
    hydrate: {
      adminCollection: {
        users: {
          roles: { resource: {} },
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      userFilter,
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, userFilter }),
  })
)(AdminList);
