import React, { Fragment, createContext, useEffect, useState } from 'react';

import { SkillsDomain } from 'models/skills';

import { __ } from 'helpers/i18n';
import { navigate } from 'helpers/navigation';
import { pathToArea } from 'helpers/paths';

import { Button, ContentContainer, Icon } from 'components';

import SkillAreaCreationModal from '../../components/SkillAreaCreationModal';
import Header from '../../components/SkillsHeader';
import AreaPaginatedList from './AreaPaginatedList';
import DomainFilteringSelect from './DomainFilteringSelect';
import SkillDomainManagementModal from './SkillDomainManagementModal';

type DataContextType = {
  shouldRefetchSkills: boolean;
  setShouldRefetchSkills: (value: boolean) => void;
};
export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);

export default function AreaList() {
  const [createAreaModalActive, setCreateAreaModalActive] = useState(false);
  const [createDomainModalActive, setCreateDomainModalActive] = useState(false);
  const [shouldRefetchSkills, setShouldRefetchSkills] = useState(false);
  const [domainSelectReloadTriggerKey, setDomainSelectReloadTriggerKey] =
    useState(0);
  const [selectedDomains, setSelectedDomains] = useState<Array<SkillsDomain>>(
    []
  );

  useEffect(() => {
    if (shouldRefetchSkills) {
      setDomainSelectReloadTriggerKey(prevState => prevState + 1);
    }
  }, [shouldRefetchSkills]);

  const createSkillButton = (
    <Button
      className="ml-3"
      onClick={() => setCreateAreaModalActive(true)}
      color="primary"
    >
      <Icon style={{ marginRight: 8 }} name="add" />
      {__('Create a skill')}
    </Button>
  );

  return (
    <Fragment>
      <Header />
      <ContentContainer>
        <div style={{ marginBottom: 32 }}>
          <p>
            {__(
              "Explore and customize your company's skills by regrouping them into domains. Simplify your skills management and understanding by organizing them intuitively for a more efficient evaluation."
            )}
          </p>
        </div>

        <div className="mb-4 mt-4">
          <div className="flex justify-between items-center">
            <DomainFilteringSelect
              key={domainSelectReloadTriggerKey}
              selectedDomains={selectedDomains}
              onChange={(domains: Array<SkillsDomain>) =>
                setSelectedDomains(domains)
              }
            />
            <div>
              <Button
                color="secondary"
                onClick={() => setCreateDomainModalActive(true)}
              >
                {__('Create a domain')}
              </Button>
              {createSkillButton}
            </div>
          </div>
        </div>

        <DataContext.Provider
          value={{
            shouldRefetchSkills,
            setShouldRefetchSkills,
          }}
        >
          <AreaPaginatedList
            paginationType="url"
            domainIds={selectedDomains.map(domain => domain.id)}
          />

          {createDomainModalActive && (
            <SkillDomainManagementModal
              domain={null}
              onClose={() => {
                setCreateDomainModalActive(false);
              }}
            />
          )}
        </DataContext.Provider>

        {createAreaModalActive && (
          <SkillAreaCreationModal
            onClose={() => setCreateAreaModalActive(false)}
            withDomainSelection
            creationEndpoint="/skills/areas"
            afterCreate={(itemId: string) => {
              navigate(pathToArea(itemId));
            }}
          />
        )}
      </ContentContainer>
    </Fragment>
  );
}
