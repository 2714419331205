import React, { Fragment, useState } from 'react';

import {
  SurveyAnswer,
  SurveyAnswerUpdatableParams,
  SurveyMultipleChoiceQuestion,
  SurveyQuestion,
  SurveySingleChoiceQuestion,
} from 'models/surveys';

import store from 'config/store';

import { handleFormErrors } from 'helpers/api';
import { FormErrors } from 'helpers/api';
import resolveRelationships from 'helpers/data/resolveRelationships';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { AutoSave, Textarea } from 'components';

import MultipleChoiceQuestionContent from './MultipleChoiceQuestionContent';
import ScaleQuestionContent from './ScaleQuestionContent';
import SingleChoiceQuestionContent from './SingleChoiceQuestionContent';

type Props = {
  question: SurveyQuestion;
  onChange: (answer: SurveyAnswerUpdatableParams) => Promise<void>;
  answer: SurveyAnswer | null;
  commentEnabled: boolean;
  isDisabled?: boolean;
};

const Question = ({
  question,
  onChange,
  answer,
  commentEnabled,
  isDisabled,
}: Props) => {
  const { id: questionId } = question;
  const [text, setText] = useState<string>(answer?.text || '');
  const [errors, setErrors] = useState<FormErrors>({});

  const handleOnChange = (params: SurveyAnswerUpdatableParams) => {
    return handleFormErrors(
      async () => {
        await onChange(params);
        setErrors({});
      },
      setErrors,
      true
    );
  };

  const renderContent = () => {
    if (question.questionType === 'scale' || question.questionType === 'nps')
      return (
        <ScaleQuestionContent
          minValue={question.minValue}
          maxValue={question.maxValue}
          leftLabel={question.leftLabel}
          rightLabel={question.rightLabel}
          value={answer?.score || null}
          persistChanges={score => handleOnChange({ score })}
          inputName={`survey-question-${questionId}-score`}
          isDisabled={isDisabled}
          errorMessage={errors.score}
        />
      );
    else if (question.questionType === 'single_choice') {
      const hydratedQuestion = resolveRelationships(
        store.getState().data,
        question,
        { choices: {} }
      ) as SurveySingleChoiceQuestion;

      invariant(
        !!hydratedQuestion.choices,
        'Provided question must have choices'
      );

      return (
        <SingleChoiceQuestionContent
          selectedChoiceId={answer?.choices?.[0]?.id || null}
          choices={hydratedQuestion.choices}
          persistChanges={choiceId =>
            handleOnChange({ choiceIds: !!choiceId ? [choiceId] : [] })
          }
          inputName={`survey-question-${questionId}-choices`}
          isDisabled={isDisabled}
        />
      );
    } else if (question.questionType === 'multiple_choice') {
      const hydratedQuestion = resolveRelationships(
        store.getState().data,
        question,
        { choices: {} }
      ) as SurveyMultipleChoiceQuestion;

      invariant(
        !!hydratedQuestion.choices,
        'Provided question must have choices'
      );

      return (
        <MultipleChoiceQuestionContent
          selectedChoiceIds={answer?.choices?.map(choice => choice.id) || []}
          choices={hydratedQuestion.choices}
          persistChanges={choiceIds => handleOnChange({ choiceIds })}
          minChoiceCount={hydratedQuestion.minMultipleChoiceAnswers}
          maxChoiceCount={hydratedQuestion.maxMultipleChoiceAnswers}
          inputName={`survey-question-${questionId}-choices`}
          isDisabled={isDisabled}
          errorMessage={errors.choices}
        />
      );
    }

    return null;
  };

  const textAnswerPlaceholder =
    question.questionType === 'text'
      ? __('Add your answer here')
      : __('Add a comment');

  return (
    <Fragment>
      {renderContent()}

      {commentEnabled && (
        <AutoSave
          fieldUid={`survey-question-${questionId}-text`}
          onChange={(text: string) => setText(text)}
          doPersist={() => handleOnChange({ text })}
          render={autoSavingOnChange => (
            <Textarea
              placeholder={textAnswerPlaceholder}
              value={text}
              className="mt-4"
              onChange={(value: string) => autoSavingOnChange(value)}
              disabled={isDisabled}
              testClassName="test-survey-comment-textarea"
            />
          )}
        />
      )}
    </Fragment>
  );
};

export default Question;
