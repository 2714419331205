// Refrain from using fancy JavaScript here.
// This snippet aims at high-compatibility over readability
export function initializeBrowserUpdate() {
  var fixedTopBarMarginAdjust = function (margin) {
    var topBars = document.getElementsByClassName('fixed-top-bar');
    for (var i = 0, max = topBars.length; i < max; i++) {
      (topBars[i] as HTMLElement).style.marginTop = margin;
    }
  };
  // IE/Edge<=79;Firefox,Opera,Chrome<=12months;Safari<=8
  window.$buoop = {
    vs: { e: 79, i: 42, f: 67, o: -8, s: 8, c: -8 },
    api: 4,
    reminder: 15,
    reminderClosed: 150,
    l: 'en',
    text: "<b>Sorry, we don't fully support your current browser ({brow_name})</b>. Please update your browser for a better experience: <a{up_but}>Update your browser</a> <a{ignore_but}>Ignore</a>",
    onshow: function (infos) {
      fixedTopBarMarginAdjust(infos.div.clientHeight + 'px');
    },
    onclick: function (_infos) {
      fixedTopBarMarginAdjust(null);
    },
    onclose: function (_infos) {
      fixedTopBarMarginAdjust(null);
    },
  };
  var e = document.createElement('script');
  e.src = '//browser-update.org/update.min.js';
  document.body.appendChild(e);
}
