import React, { useContext } from 'react';

import { SkillsAreaMapping } from 'models/skills';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { del } from 'redux/actions/api';

import { Button, Icon, StrictlySanitizedHtml, Text } from 'components';

import { DataContext } from 'scenes/skills/Index/MatrixVisualizer';

type Props = {
  areaMapping: SkillsAreaMapping;
};

const DeleteButton = ({ areaMapping }: Props) => {
  const { refetchData } = useContext(DataContext);
  const dispatch = useAppDispatch();

  const destroyAreaMapping = async () => {
    await dispatch(
      del(`skills/matrix_area_mappings/${areaMapping.id}`, undefined, {
        successMessage: __(
          '<b>The skill %1 has been successfully removed from the matrix.</b><br/>It will no longer be visible in this matrix.',
          areaMapping.title
        ),
      })
    );
    refetchData();
  };

  const confirmDestroyAreaMapping = () => {
    return confirmAsync(
      __('Remove the skill from the skills matrix'),
      <React.Fragment>
        <p>
          <StrictlySanitizedHtml
            html={__(
              'The <b>%1</b> skill will no longer be visible in this matrix and will no longer be used <b>to evaluate the employees associated with the matrix</b>.',
              areaMapping.title
            )}
          />
        </p>
        <br />
        <p>
          {__('Are you sure you want to remove the skill from the matrix?')}
        </p>
        <div className="mt-4 mb-2">
          <Text color="danger">
            <Icon color="danger" name="warning" className="mr-4" />

            {__('This action cannot be undone')}
          </Text>
        </div>
      </React.Fragment>,
      {
        confirmLabel: __('Remove the skill'),
        isDanger: true,
        onConfirm: destroyAreaMapping,
      }
    );
  };

  return (
    <Button
      isText
      hasIconOnly
      className="no-underline"
      onClick={confirmDestroyAreaMapping}
    >
      <Icon name="delete" />
    </Button>
  );
};

export default DeleteButton;
