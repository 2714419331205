import React from 'react';

import classNames from 'helpers/classNames';

import { Radio } from 'components';

type Option<TValue> = {
  value: TValue;
  label: string;
};

type Props<TValue> = {
  selectedOption: Option<TValue> | null;
  options: Option<TValue>[];
  onChange: (option: Option<TValue> | null) => void;
  className?: string;
  testClassName?: string;
  isDisabled?: boolean;
};

const VerticalRadioSelect = <TValue extends string | number>({
  selectedOption,
  options,
  onChange,
  className,
  testClassName,
  isDisabled,
}: Props<TValue>) => {
  return (
    <div
      className={classNames('vertical-radio-select', className, testClassName)}
    >
      {options.map(option => {
        const isSelected = selectedOption?.value === option.value;

        return (
          <Radio
            key={option.value}
            label={option.label}
            inputLabel={option.label}
            isChecked={isSelected}
            onChange={() => {
              if (isSelected) onChange(null);
              else onChange(option);
            }}
            disabled={isDisabled}
            containerClickable
            className={classNames({
              'is-selected': isSelected,
              'is-disabled': isDisabled,
            })}
          />
        );
      })}
    </div>
  );
};
export default VerticalRadioSelect;
