import React, { Fragment } from 'react';

import { TrainingCourse } from 'models/TrainingCourse';

import { FormErrors, FormInputChangeHandler } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';

import { Field, FieldError, Input, Label, Text } from 'components';
import TextArea from 'components/formElements/advancedElements/Textarea';

import TrainingOrganismPicker from 'scenes/components/TrainingOrganismPicker';

import AvailableLanguagesPicker from '../../components/AvailableLanguagesPicker';

export type CourseInputChangeHandler = FormInputChangeHandler<
  Partial<TrainingCourse>
>;

type Props = {
  trainingCourse: Partial<TrainingCourse>;
  handleInputChange: CourseInputChangeHandler;
  errors: FormErrors;
};

const GeneralInfoBlock = ({
  trainingCourse,
  handleInputChange,
  errors,
}: Props) => {
  const MAX_LENGTH = 4000;

  return (
    <Fragment>
      <Text preset="16bs5.5">{__('General information')}</Text>
      <div className="mt-4">
        <Field>
          <Label>{__('Training course name')}</Label>
          <Input
            value={trainingCourse?.name}
            placeholder={__('Enter training course name')}
            onChange={name => {
              handleInputChange({ value: name, name: 'name' });
            }}
          />
          <FieldError>{errors.name}</FieldError>
        </Field>
        <Field>
          <Label>{__('Training organization')}</Label>
          <TrainingOrganismPicker
            trainingOrganism={trainingCourse.organism}
            onChange={trainingOrganism => {
              handleInputChange({
                value: trainingOrganism,
                name: 'organism',
              });
            }}
          />
          <FieldError>{errors.organism}</FieldError>
        </Field>
        <Field>
          <Label>{__('Available Languages')}</Label>
          <AvailableLanguagesPicker
            value={trainingCourse?.availableLanguages || []}
            onChange={availableLanguages => {
              handleInputChange({
                value: availableLanguages,
                name: 'availableLanguages',
              });
            }}
          />
          <FieldError>{errors.availableLanguages}</FieldError>
        </Field>
        <Field>
          <Label>{__('Training objectives')}</Label>
          <TextArea
            value={trainingCourse?.objectives}
            placeholder={__(
              'Add information about the training course objectives'
            )}
            onChange={(objectives: string) => {
              handleInputChange({ value: objectives, name: 'objectives' });
            }}
            maxLength={MAX_LENGTH}
            onMaxLengthReached={() =>
              __('Objectives must be shorter than %1 characters.', MAX_LENGTH)
            }
            richText
            richTextOptions={{
              toolbarAlwaysVisible: true,
            }}
          />
          <FieldError>{errors.objectives}</FieldError>
        </Field>
        <Field>
          <Label>{__('Training content')}</Label>
          <TextArea
            value={trainingCourse?.content}
            placeholder={__(
              'Add information about the training course content'
            )}
            onChange={(content: string) => {
              handleInputChange({ value: content, name: 'content' });
            }}
            maxLength={MAX_LENGTH}
            onMaxLengthReached={() =>
              __('Content must be shorter than %1 characters.', MAX_LENGTH)
            }
            richText
            richTextOptions={{
              toolbarAlwaysVisible: true,
            }}
          />
          <FieldError>{errors.content}</FieldError>
        </Field>
      </div>
    </Fragment>
  );
};

export default GeneralInfoBlock;
