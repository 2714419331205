import React from 'react';
import { OptionProps, components } from 'react-select';

import { Icon } from 'components';

const DimensionOption = (props: OptionProps) => {
  const { isDisabled, label } = props;

  return (
    <components.Option {...props}>
      <div className="flex justify-between" style={{ minWidth: 'fit-content' }}>
        {label}
        {isDisabled && <Icon className="ml-2" name="block" />}
      </div>
    </components.Option>
  );
};

export default DimensionOption;
