import React, { useContext } from 'react';
import LazyLoad from 'react-lazyload';

import type { TextQuestionBlock, UserFilterSegment } from 'models';

import QuestionBlockComponent from 'scenes/components/review/QuestionBlock';

import { UserFiltersContext } from '..';
import AnswersExplorer from '../AnswersExplorer';

type Props = {
  block: TextQuestionBlock;
  reviewCycleId: string;
  userFilter: UserFilterSegment | undefined | null;
};

const TextQuestionBlockResults = ({
  block,
  reviewCycleId,
  userFilter,
}: Props) => {
  const { userFilters } = useContext(UserFiltersContext);
  const { content, revieweeFeedbackOptions, reviewerFeedbackOptions } = block;
  const { title, description, richTextEnabled } = content;

  const revieweeTextEnabled =
    !!revieweeFeedbackOptions && revieweeFeedbackOptions.textEnabled;

  return (
    <QuestionBlockComponent
      title={title}
      description={description}
      testClassName="test-text-question-block"
      withRichText={richTextEnabled}
    >
      {(revieweeTextEnabled || reviewerFeedbackOptions.textEnabled) && (
        <LazyLoad height={650} offset={200}>
          <AnswersExplorer
            block={block}
            reviewCycleId={reviewCycleId}
            userFilter={userFilter}
            userFilters={userFilters}
          />
        </LazyLoad>
      )}
    </QuestionBlockComponent>
  );
};

export default TextQuestionBlockResults;
