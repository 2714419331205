import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from '../redux/reducers';
import { isDevelopment } from './environment';

function configureStore(initialState: {}) {
  const middlewares = [thunk];

  let composeEnhancers = compose;
  // @ts-expect-error: redux devtools extension
  if (isDevelopment() && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    // @ts-expect-error: redux devtools extension
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const store = createStore(
    // @ts-expect-error: seems hard to fix, we use `undefined` as `state` in `appReducer` to clear the store
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return store;
}

export default configureStore({});
