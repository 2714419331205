import * as React from 'react';
import posed from 'react-pose';

import classNames from 'helpers/classNames';

type Props = {
  children: React.ReactNode;
  duration: number;
  visible: boolean;
  className?: string;
};

const Animated = posed.div({
  visible: {
    opacity: 1,
    transition: ({ duration }) => ({ duration }),
  },
  hidden: {
    opacity: 0,
    transition: ({ duration }) => ({ duration }),
  },
});

function WithFadeinFadeout({ children, duration, visible, className }: Props) {
  return (
    <Animated
      pose={visible ? 'visible' : 'hidden'}
      className={classNames(className, {
        'pointer-events-none': !visible,
      })}
      duration={duration}
    >
      {React.Children.only(children)}
    </Animated>
  );
}

WithFadeinFadeout.defaultProps = {
  duration: 200,
};

export default WithFadeinFadeout;
