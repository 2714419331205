import React, { useEffect, useState } from 'react';

import { FormErrors } from 'helpers/api';

import { Column, Columns, FieldError, Input, Select, Text } from 'components';

type UpdatableAttributes = {
  minValue?: number | undefined;
  maxnValue?: number | undefined;
  scaleLeftLabel?: string | undefined;
  scaleRightLabel?: string | undefined;
};

type Props = {
  label: string;
  selectAttribute: { attribute: 'minValue' | 'maxValue'; value: number };
  options: { label: string; value: number }[];
  inputAttribute: {
    attribute: 'scaleLeftLabel' | 'scaleRightLabel';
    value: string | undefined;
  };
  onUpdate: (params: { content: UpdatableAttributes }) => void;
  errors: FormErrors;
  testClassName?: string;
};

const RatingScaleInput = ({
  label,
  selectAttribute,
  options,
  inputAttribute,
  onUpdate,
  errors,
  testClassName,
}: Props) => {
  const [inputValue, setInputValue] = useState(inputAttribute.value);

  useEffect(() => {
    if (errors[inputAttribute.attribute]) {
      setInputValue(inputAttribute.value);
    }
  }, [errors, inputAttribute.attribute, inputAttribute.value]);

  return (
    <Columns
      contentVerticallyAligned
      className="mb-0"
      testClassName={testClassName}
    >
      <Column size={1} className="flex items-center">
        <Text preset="13s7">{label}</Text>
      </Column>
      <Column size={2} testClassName="test-survey-question-rating-value">
        <Select
          onChange={option =>
            !!option &&
            onUpdate({ content: { [selectAttribute.attribute]: option.value } })
          }
          value={{
            label: selectAttribute.value.toLocaleString(),
            value: selectAttribute.value,
          }}
          options={options}
        />
      </Column>
      <Text preset="13s7">:</Text>
      <Column>
        <Input
          style={{ maxWidth: 210 }}
          value={inputValue}
          onChange={value => setInputValue(value)}
          onBlur={() =>
            onUpdate({ content: { [inputAttribute.attribute]: inputValue } })
          }
        />
        <FieldError>{errors[inputAttribute.attribute]}</FieldError>
      </Column>
    </Columns>
  );
};

export default RatingScaleInput;
