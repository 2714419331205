import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { SignatureBlockContent, User } from 'models';
import type { ReduxStore } from 'redux/reducers';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { withActiveUser } from 'helpers/withSessionProps';

import { hydrateFromStore } from 'lib/dataLoader';

import { Box, DisabledLayer, Icon, Notification, Text } from 'components';

import BlockDetails from 'scenes/components/review/BlockDetails';

import Signature from './Signature';

type Props = {
  content: SignatureBlockContent;
  withoutSectionSeparator?: boolean;
};

type AfterConnectProps = Props & {
  activeUser: User;
};

function SignatureBlockContentComponent({
  content,
  withoutSectionSeparator,
  activeUser,
}: AfterConnectProps) {
  const {
    id,
    title,
    description,
    isDisabled,
    feedbackableItems,
    richTextEnabled,
  } = content;
  const signatures = feedbackableItems
    .map(feedbackableItem => feedbackableItem.item)
    .sort((a, b) =>
      a.author.id === activeUser.id ? -1 : a.id > b.id ? 1 : -1
    );
  const finalCommentEnabled = content.finalCommentEnabled;

  let notification = null;
  if (isDisabled && finalCommentEnabled) {
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    notification = __(
      'Signatures and final comments will be enabled when the review is finalized'
    );
  } else if (isDisabled && !finalCommentEnabled) {
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    notification = __(
      'Signatures will be enabled when the review is finalized'
    );
  } else if (!isDisabled && signatures.some(signature => !signature.isSigned)) {
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    notification = __('Once signed the review will be locked');
  }

  return (
    <div className="signature-module test-signature-module-block">
      <BlockDetails
        id={id}
        section={__('Validation')}
        title={title}
        description={description}
        withoutSectionSeparator={withoutSectionSeparator}
        withRichText={richTextEnabled}
        withReadMore
      />

      {!!notification && (
        <Notification kind="primary">
          <Icon color="primary" size="small" name="info" />
          <Text>{notification}</Text>
        </Notification>
      )}

      <Box
        className="block-content-wrapper signature-block"
        style={{ position: 'relative', marginTop: !!notification ? 16 : 32 }}
      >
        <DisabledLayer active={isDisabled} />

        {signatures.map((signature, index) => {
          const feedbackableItem = feedbackableItems.find(
            fi => fi.item.id === signature.id
          );

          invariant(feedbackableItem, 'feedbackableItem must be defined');

          return (
            <div
              key={index}
              className={`test-${signature.authorRole.replace(
                '_',
                '-'
              )}-signature`}
              style={{ marginBottom: index < signatures.length - 1 ? 20 : 0 }}
            >
              <Signature
                feedbackableItem={feedbackableItem}
                withFinalComment={finalCommentEnabled}
                isDisabled={isDisabled}
              />
            </div>
          );
        })}
      </Box>
    </div>
  );
}

function mapStateToProps(state: ReduxStore, { content }: Props) {
  return {
    content: hydrateFromStore(
      state.data,
      { resourceType: 'formContentBlock', id: content.id },
      {
        block: {
          feedbackableItems: {
            item: {
              author: {},
            },
          },
        },
      }
    ),
  };
}

export default compose(
  connect(mapStateToProps),
  // @ts-expect-error: For some reason TS doesn't like when we use connect with compose
  withActiveUser
)(SignatureBlockContentComponent) as React.ComponentType<Props>;
