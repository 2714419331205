import React from 'react';
import { components } from 'react-select';

import type { OptionProps } from 'react-select';

import { TargetedCampaignRole } from 'models';

import { DesignSystem, Text } from 'components';

import { displayableRoleName, roleDescription } from '../../helpers';

export type RoleOptionProps = OptionProps & {
  data: { value: TargetedCampaignRole['name']; label: string };
};

const Option = (props: RoleOptionProps) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <DesignSystem version={2}>
        <div
          className={props.isDisabled ? 'opacity-60 cursor-not-allowed' : ''}
        >
          <Text className="block mb-1" preset="14bs6">
            {displayableRoleName(data.value)}
          </Text>
          <Text
            className="block"
            preset="13s7"
            color={props.isSelected ? 'dark-grey' : 'light'}
          >
            {roleDescription(data.value)}
          </Text>
        </div>
      </DesignSystem>
    </components.Option>
  );
};

export default Option;
