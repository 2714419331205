import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

import classNames from 'helpers/classNames';
import {
  getBrowserAndVersion,
  isNewNavCompatibleWith,
} from 'helpers/legacyBrowsers';
import { isActiveUrl } from 'helpers/navigation';

import AccordionItem from 'components/Accordion/AccordionItem';
import OldBrowserAccordion from 'components/Accordion/OldBrowserAccordion';
import materialIconMapping from 'components/Icon/materialIconMapping';

import NavItem from '../NavItem/NavItem';

type Item = {
  count?: number;
  title: string;
  path?: string;
  onClick?: () => void;
  className?: string;
  matchFullPath?: boolean;
};

type Props = {
  title: string;
  className?: string;
  iconName?: keyof typeof materialIconMapping;
  items: Array<Item>;
  onClick: () => void;
  isActive?: boolean;
};

export default function NavItemGroup({
  title,
  iconName,
  className,
  items,
  onClick,
  isActive,
}: Props) {
  const location = useLocation();
  const hasActiveChild = items.some(i =>
    isActiveUrl(location.pathname, location.search, i.path, i.matchFullPath)
  );
  const hasNotifications = items.some(i => i.count && +i.count > 0);
  const { browser, version } = getBrowserAndVersion();
  if (items.length === 0) return <Fragment>{''}</Fragment>;
  if (items.length === 1) {
    const { path, count, onClick } = items[0];

    return (
      <NavItem
        className={className}
        titleClassName="mt-1"
        iconClassName={classNames({
          '!text-nav-item-active-icon': hasActiveChild,
        })}
        iconName={iconName}
        title={title}
        count={count}
        path={path}
        onClick={onClick}
        withNotification={hasNotifications}
      />
    );
  }

  return isNewNavCompatibleWith(browser, version) ? (
    <AccordionItem
      className={className}
      value={title}
      title={
        <NavItem
          className={className}
          titleClassName={classNames('mt-1', {
            '!text-nav-item-active-text': isActive,
          })}
          iconClassName={classNames({
            '!text-nav-item-active-icon': isActive,
          })}
          iconName={iconName}
          title={title}
          truncateTitle
          onClick={onClick}
          withArrow
          withNotification={hasNotifications}
        />
      }
      content={
        <ul className="space-y-1 mt-1">
          {items.map(item => (
            <li key={item.title}>
              <NavItem
                className={classNames('!pl-12', item.className)}
                title={item.title}
                count={item.count}
                path={item.path}
                onClick={item.onClick}
                matchFullPath={item.matchFullPath}
              />
            </li>
          ))}
        </ul>
      }
    />
  ) : (
    <OldBrowserAccordion title={title} className={className}>
      <ul className="space-y-1 mt-1">
        {items.map(item => (
          <li key={item.title}>
            <NavItem
              className={item.className}
              title={item.title}
              count={item.count}
              path={item.path}
              onClick={item.onClick}
              matchFullPath={item.matchFullPath}
            />
          </li>
        ))}
      </ul>
    </OldBrowserAccordion>
  );
}
