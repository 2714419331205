import { debounce } from 'lodash';
import React, { useState } from 'react';
import { components } from 'react-select';

import type { SkillsDomain } from 'models';
import type { DropdownIndicatorProps } from 'react-select';
import type { AppDispatch } from 'redux/actions';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { hydrateFromResponse } from 'lib/dataLoader';
import { get } from 'redux/actions/api';
import { ReduxStore } from 'redux/reducers';

import { Icon, Select } from 'components';

type Option = {
  label: string;
  value: string;
  domain: SkillsDomain;
};

export type CurrentValue<IsMulti extends Boolean> = IsMulti extends true
  ? Array<SkillsDomain>
  : SkillsDomain | null;

type Props<IsMulti extends boolean> = {
  value: CurrentValue<IsMulti>;
  isMulti?: IsMulti;
  availableForMatrixId?: string;
  onChange: (domain: CurrentValue<IsMulti>) => void;
  className?: string;
};

const DomainPicker = <IsMulti extends boolean = false>({
  value,
  isMulti,
  availableForMatrixId,
  onChange,
  className,
}: Props<IsMulti>) => {
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState(false);

  const domainToOption = (domain: SkillsDomain): Option => ({
    label: domain.title,
    value: domain.id,
    domain,
  });

  const valueToDisplayableOption = (
    value: CurrentValue<IsMulti>
  ): Array<Option> | Option => {
    return isMulti
      ? (value as Array<SkillsDomain>).map(domainToOption)
      : domainToOption(value as SkillsDomain);
  };

  const fetchDomains = async (search: string): Promise<SkillsDomain[]> => {
    const fetchUrl = !!availableForMatrixId
      ? `/skills/matrices/${availableForMatrixId}/available_domains`
      : 'skills/domains';

    return dispatch(
      async (dispatch: AppDispatch, getState: () => ReduxStore) => {
        const { response } = await dispatch(
          get(fetchUrl, {
            countPerPage: 10,
            search,
          })
        );

        const { items } = hydrateFromResponse(
          getState().data,
          response.body,
          {
            domainCollection: { items: {} },
          },
          response.body.data.id
        );

        setLoaded(true);

        return items;
      }
    );
  };

  const loadDomainOptions = (
    search: string,
    callback: (options: Option[]) => void
  ) => {
    fetchDomains(search).then(domains => {
      const domainsAsOptions = domains.map(domain => domainToOption(domain));

      if (isMulti) {
        const selectedIds = (value as Array<SkillsDomain>).map(d => d.id);

        if (selectedIds.length > 0) {
          onChange(
            domains.filter(d =>
              selectedIds.includes(d.id)
            ) as CurrentValue<IsMulti>
          );
        }
      }

      callback(domainsAsOptions);
    });
  };

  const debouncedSearch = debounce(loadDomainOptions, 300);

  return (
    <Select
      loadOptions={debouncedSearch}
      isMulti={isMulti}
      value={
        !!value
          ? (valueToDisplayableOption(value) as IsMulti extends true
              ? Option[]
              : Option)
          : null
      }
      onChange={option => {
        onChange(
          (isMulti
            ? (option as Array<Option>).map(item => item.domain)
            : (option as Option | null)?.domain) as CurrentValue<IsMulti>
        );
      }}
      placeholder={__('Search a domain by its name')}
      noOptionsMessage={__('No domain is available')}
      isClearable
      isAsync
      inModal={isMulti && loaded} // to handle selected items centering. This trick is also used in the TagsOption in the cycle setup
      loadingMessage={() => __('Loading domains...')}
      cacheOptions
      defaultOptions
      components={{
        DropdownIndicator: (props: DropdownIndicatorProps) => (
          <components.DropdownIndicator {...props}>
            <Icon name="search" />
          </components.DropdownIndicator>
        ),
      }}
      className={className}
    />
  );
};

export default DomainPicker;
