import moment from 'moment';
import React, { CSSProperties, Fragment, ReactNode, useContext } from 'react';

import type { KeyResult, Objective } from 'models';
import type { UpdatableObjectiveFields } from 'redux/actions/resources';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { FeatureFlagged, Text } from 'components';
import {
  Box,
  Column,
  Columns,
  DesignSystem,
  Flex,
  Section,
  Testable,
} from 'components';

import { ObjectiveContext } from 'scenes/objectives/objectiveContext';

import {
  Actions,
  CompletionProgress,
  DraftLayer,
  DueDate,
  KeyResultsSection,
  ObjectiveDescription,
  Status,
  Title,
  Weight,
} from './components';

type Props = {
  objective: Objective;

  locked?: boolean;
  lockedReason?: string | null | undefined;
  weighted?: boolean;
  collapsibleKeyResults?: boolean;
  weightSumWarning?: string | null | undefined;

  onUpdate: (attributes: Partial<UpdatableObjectiveFields>) => Promise<void>;
  onDestroy: () => Promise<void>;
  onAddKeyResult: () => Promise<void>;
  onUpdateKeyResult: (
    keyResultId: string,
    attributes: Partial<KeyResult>
  ) => Promise<void>;
  onDestroyKeyResult: (keyResultId: string) => Promise<void>;
  canManageDraftObjectives: boolean;
  getFeedbackCount?: () => Promise<number>;

  children?: ReactNode;
  style?: CSSProperties;
  revieweeFullName?: string;
};

export default function ObjectiveCard(props: Props) {
  const {
    objective,
    locked = false,
    lockedReason = null,
    weighted = false,
    collapsibleKeyResults = false,
    weightSumWarning = null,
    children,
    style,
    onUpdate,
    onDestroy,
    onAddKeyResult,
    onUpdateKeyResult,
    onDestroyKeyResult,
    canManageDraftObjectives,
    revieweeFullName,
    getFeedbackCount,
  } = props;

  const {
    justCreated,
    actualTitle,
    title,
    description,
    dueDate,
    status,
    completion,
    completionMismatchWarning,
    keyResults,
    published,
  } = objective;

  invariant(keyResults, 'Key results should at least be an empty array here.');

  const titleIsMultiline = actualTitle && actualTitle.includes('\n');
  const titleAutoFocus = justCreated && !actualTitle;
  const { setObjective } = useContext(ObjectiveContext);

  const objectiveBox = (
    <Box
      className="objective-card"
      testClassName={
        `test-objective-card-${objective.id}` +
        (objective.published ? '' : ' test-draft-objective-card')
      }
    >
      <Columns className="objective-header" isMobile>
        <Column>
          <Flex
            style={{
              alignItems: titleIsMultiline ? 'flex-start' : 'center',
            }}
          >
            <Status
              status={status}
              onChange={newStatus => onUpdate({ status: newStatus })}
              disabled={locked}
            />
            <div style={{ margin: '0 16px', flexGrow: 2 }}>
              <Title
                actualTitle={actualTitle}
                title={title}
                onChange={newTitle => onUpdate({ title: newTitle })}
                disabled={locked}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={titleAutoFocus}
              />
            </div>
          </Flex>
        </Column>

        <Column isNarrow>
          <Actions
            onDestroy={onDestroy}
            locked={locked}
            lockedReason={lockedReason}
            disabled={locked}
            showPublishObjective={
              canManageDraftObjectives && !objective.published
            }
            showUnpublishObjective={
              canManageDraftObjectives && objective.published
            }
            getFeedbackCount={getFeedbackCount}
            objectiveTitle={objective.title}
            onUpdate={onUpdate}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            revieweeFullName={revieweeFullName}
          />
        </Column>
      </Columns>

      <ObjectiveDescription
        disabled={locked}
        // @ts-ignore TSFIXME: Fix strictNullChecks error
        description={description}
        locked={locked}
        onChange={newDescription => onUpdate({ description: newDescription })}
      />

      <Flex className="objective-section-content" style={{ flexWrap: 'wrap' }}>
        {objective.kind === 'personal' && weighted && (
          <div style={{ marginRight: 16 }}>
            <Weight
              weight={objective.weight}
              weightWarning={objective.weightWarning || weightSumWarning}
              disabled={locked}
              onChange={newWeight => onUpdate({ weight: newWeight })}
              testClassName="test-objective-weight"
            />
          </div>
        )}

        <DueDate
          dueDate={dueDate}
          disabled={locked}
          onChange={newDueDate => onUpdate({ dueDate: newDueDate })}
        />
      </Flex>

      <CompletionProgress
        testClassName="test-objective-completion"
        completion={completion}
        completionMismatchWarning={completionMismatchWarning}
        onChange={newCompletion => onUpdate({ completion: newCompletion })}
        disabled={locked}
      />

      <KeyResultsSection
        keyResults={keyResults}
        disabled={locked}
        weighted={weighted}
        collapsible={collapsibleKeyResults}
        weightSumWarning={
          objective.kind === 'personal'
            ? objective.keyResultWeightSumWarning
            : null
        }
        onAddKeyResult={onAddKeyResult}
        onDestroyKeyResult={onDestroyKeyResult}
        onUpdateKeyResult={onUpdateKeyResult}
      />

      {!!children && (
        <Testable name={`test-status-${locked ? 'locked' : 'editable'}`}>
          <div className="objective-footer">
            <Section>{children}</Section>
          </div>
        </Testable>
      )}

      <FeatureFlagged flag="objectiveHistory">
        {published && setObjective && (
          <Fragment>
            <div className="flex justify-end items-center mt-2">
              <Text color="light" className="mr-1">
                {__(
                  'Last update on %1',
                  moment(objective.updatedAt).format('LLL')
                )}
              </Text>
              <Text
                preset="14bs6"
                color="light"
                onLinkClick={() => {
                  setObjective(objective);
                }}
              >
                {__('(Show history)')}
              </Text>
            </div>
          </Fragment>
        )}
      </FeatureFlagged>
    </Box>
  );

  return (
    <DesignSystem version={2} style={style}>
      {published ? objectiveBox : <DraftLayer>{objectiveBox}</DraftLayer>}
    </DesignSystem>
  );
}
