import { omit } from 'lodash';
import React from 'react';
import { compose } from 'redux';

import type { WithPaginationProps } from 'lib/pagination/types';
import type { PaginatedCollection, User, UserReview } from 'models';

import compositeKey from 'helpers/compositeKey';
import { useActiveUser } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToReviewOrEvaluation } from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import withPagination from 'lib/pagination/withPagination';
import { fetchUserReviewsForUser } from 'redux/actions';

import {
  Box,
  BreadcrumbAnchor,
  DatatableWrapper,
  Date,
  FetchContainer,
  ProductIcon,
  SimpleTable,
  Text,
} from 'components';

import StatusTag from 'scenes/components/StatusTag';
import ReviewEmptyState from 'scenes/components/userReview/ListEmptyState';

type Props = {
  user: User;
};

type AfterPaginateProps = Props & WithPaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  userReviewCollection: PaginatedCollection<UserReview>;
  isFetching: boolean;
  hasError: boolean;
};

function UserReviewList({
  userReviewCollection,
  isFetching,
  hasError,
  page,
  countPerPage,
  queryParams: { search, filter },
  setNextPageParams,
  setPreviousPageParams,
  setQueryParams,
}: AfterConnectProps) {
  const userReviews = userReviewCollection?.items;
  const activeUser = useActiveUser();

  const cycleFrom = (review: UserReview) => {
    invariant(review.reviewCycle, 'A user review must have a review cycle');
    return review.reviewCycle;
  };

  return (
    <Box className="mb-12">
      <DatatableWrapper
        collectionInfo={omit(userReviewCollection, 'items')}
        isFetching={isFetching}
        hasError={hasError}
        page={page}
        countPerPage={countPerPage}
        search={search}
        filter={filter}
        getNextPage={setNextPageParams}
        getPreviousPage={setPreviousPageParams}
        onQueryParamsChange={setQueryParams}
        totalCountRenderer={count => n__('%1 review', '%1 reviews', count || 0)}
        renderHeader={() => null}
        renderNoRecord={() => (
          <ReviewEmptyState title={__('There is no review for this user')} />
        )}
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          render={() => (
            <SimpleTable
              className="mb-2"
              columns={[
                {
                  header: __('Campaign name'),
                  cell: review => {
                    const cycle = cycleFrom(review);

                    return (
                      <div className="flex items-center">
                        <ProductIcon productName={cycle.interactionType} />
                        <Text
                          preset="14bs6"
                          className="ml-2"
                          testClassName="test-link-profile-reviews"
                          linkTo={pathToReviewOrEvaluation(review, activeUser)}
                        >
                          {cycle.name}
                        </Text>
                      </div>
                    );
                  },
                },
                {
                  header: __('Status'),
                  cell: review => (
                    <StatusTag status={cycleFrom(review).status} />
                  ),
                },
                {
                  header: __('Launch date'),
                  cell: review => (
                    <Date
                      value={cycleFrom(review).launchedAt}
                      format="MMMM YYYY"
                    />
                  ),
                },
              ]}
              rows={userReviews}
              keyFn={review => review.id}
            />
          )}
        />
      </DatatableWrapper>
    </Box>
  );
}

const UserReviewListWithBreadcrumb = (props: AfterConnectProps) => (
  <React.Fragment>
    <BreadcrumbAnchor name="user_review_list" />
    <UserReviewList {...props} />
  </React.Fragment>
);

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({ user, page, countPerPage }) =>
      fetchUserReviewsForUser(user, {
        page,
        countPerPage,
        abilities: ['view_results'],
      }),
    hydrate: {
      userReviewCollection: {
        items: {
          user: {},
          manager: {},
          evaluations: {},
          reviewCycle: {},
          abilities: {},
        },
      },
    },
    cacheKey: ({ page, countPerPage }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
      }),
  })
)(UserReviewListWithBreadcrumb);
