import React from 'react';

import classNames from 'helpers/classNames';

import { Button, Icon } from 'components';

type BuilderCreateButtonProps = {
  className: string;
  position: number;
  disabled: boolean;
  disabledExplanation: string;
  onCreate: (position: number) => void;
};
export default function BuilderCreateButton({
  className,
  position,
  disabled,
  onCreate,
  disabledExplanation,
}: BuilderCreateButtonProps) {
  return (
    <Button
      className={classNames('builder-create-button', className)}
      isRounded
      hasIconOnly
      disabled={disabled}
      disabledExplanation={disabledExplanation}
      tooltipProps={{
        triggerClassName: `${className}-tooltip`,
      }}
      onClick={() => onCreate(position)}
    >
      <Icon name="add" />
    </Button>
  );
}
