import * as React from 'react';
import posed from 'react-pose';

import { Hoverable } from 'components';

type Props = {
  children: React.ReactNode;
  duration: number;
  className?: string;
};

function WithHoverableFadeinFadeout({ children, duration, className }: Props) {
  const Animated = posed.div({
    visible: {
      opacity: 1,
      transition: { duration },
    },
    hidden: {
      opacity: 0,
      transition: { duration },
    },
  });

  return (
    <Hoverable
      render={isHovered => (
        <Animated pose={isHovered ? 'visible' : 'hidden'} className={className}>
          {React.Children.only(children)}
        </Animated>
      )}
    />
  );
}

WithHoverableFadeinFadeout.defaultProps = {
  duration: 200,
};

export default WithHoverableFadeinFadeout;
