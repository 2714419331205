import { __ } from 'helpers/i18n';

const sessionStatusLabels = () => ({
  in_preparation: __('In preparation'),
  completed: __('TrainingSessionStatus|Completed'),
  prepared: __('Prepared'),
  canceled: __('Canceled'),
  rescheduled: __('Rescheduled|SingularFeminine'),
});

export default sessionStatusLabels;
