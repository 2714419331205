import React, { useState } from 'react';

import type { ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';

import { PageTitle } from 'components';

import OneOnOneAnalytics from './OneOnOne';
import ThreeSixtyAnalytics from './ThreeSixty';

type Props = {
  reviewCycle: ReviewCycle;
};

export default function ReviewCycleAnalytics({ reviewCycle }: Props) {
  const [filter, setFilter] = useState({ user: { active: true } });
  const [userFilter, setUserFilter] = useState(null);
  const [userFilters, setUserFilters] = useState('');

  return (
    <div style={{ marginTop: 16 }}>
      <PageTitle title={[reviewCycle.name, __('Progress')]} />

      {reviewCycle.interactionType === '360' ? (
        <ThreeSixtyAnalytics
          onFilterChange={setFilter}
          filter={filter}
          onUserFilterChange={setUserFilter}
          userFilter={userFilter}
          reviewCycleId={reviewCycle.id}
          userFilters={userFilters}
          onUserFiltersChange={setUserFilters}
        />
      ) : (
        <OneOnOneAnalytics
          onFilterChange={setFilter}
          filter={filter}
          onUserFilterChange={setUserFilter}
          userFilter={userFilter}
          userFilters={userFilters}
          onUserFiltersChange={setUserFilters}
          reviewCycleId={reviewCycle.id}
        />
      )}
    </div>
  );
}
