import React, { Fragment, ReactNode } from 'react';

import { Text, Tooltip } from 'components';

export type Data = {
  size?: number;
  value: number | null;
  color: string;
  label: string;
  tooltipContent?: ReactNode;
  name?: string;
  testClassName?: string;
};

type LabelItemProps = {
  data: Data;
  valuePositionInLegend?: 'top' | 'bottom' | 'hidden';
  formatValue?: (value: number | null) => ReactNode;
};

const LabelItem = ({
  data,
  valuePositionInLegend,
  formatValue,
}: LabelItemProps) => {
  const { value, color, label, name } = data;

  const renderValue = () => {
    if (valuePositionInLegend === 'hidden') {
      return null;
    }

    if (formatValue) {
      return formatValue(value);
    }

    return (
      <Text preset="24bs2" testClassName={`test-flat-pie-chart-${name}`}>
        {value === null ? '-' : value}%
      </Text>
    );
  };

  const renderLabel = () => (
    <div>
      <span
        className="h-2 w-2 inline-block rounded-sm mr-2"
        style={{ backgroundColor: color }}
      />
      <Text preset="14s6">{label}</Text>
    </div>
  );

  return (
    <Fragment>
      {valuePositionInLegend === 'top' ? renderValue() : renderLabel()}
      {valuePositionInLegend === 'top' ? renderLabel() : renderValue()}
    </Fragment>
  );
};

type Props = {
  data: Data[];
  height?: number;
  legendClassName?: string;
  valuePositionInLegend?: 'top' | 'bottom' | 'hidden';
  formatValue?: (value: number | null) => ReactNode;
};

const FlatPieChart = ({
  data,
  height = 24,
  legendClassName = 'mt-4',
  valuePositionInLegend = 'top',
  formatValue,
}: Props) => {
  return (
    <Fragment>
      <div
        className="flex"
        style={{
          height,
          width: '100%',
          overflow: 'hidden',
          borderRadius: 100,
          backgroundColor: '#eee',
        }}
      >
        {data.map((data, i) => {
          const { size, value, color, tooltipContent } = data;
          const style = { flex: size || value || 0, backgroundColor: color };

          if (tooltipContent) {
            return (
              <Tooltip key={i} triggerStyle={style} content={tooltipContent}>
                <div />
              </Tooltip>
            );
          } else {
            return <div key={i} style={style} />;
          }
        })}
      </div>

      <div className={`flex ${legendClassName}`}>
        {data.map((data, i) => (
          <div
            key={i}
            style={{ flex: 1 }}
            className={
              data.testClassName ? `${data.testClassName}-label-item` : ''
            }
          >
            <LabelItem
              data={data}
              valuePositionInLegend={valuePositionInLegend}
              formatValue={formatValue}
            />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default FlatPieChart;
