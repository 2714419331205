import React from 'react';

import {
  useAppDispatch,
  useCurrentOrganization,
  useOrganizationSettings,
} from 'helpers/hooks';
import { __, date } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import confirmAsync from 'helpers/react/confirmAsync';

import { put } from 'redux/actions/api';

import {
  Box,
  CheckboxSwitch,
  ContentContainer,
  DesignSystem,
  Icon,
  Notification,
  PageHeader,
  StrictlySanitizedHtml,
  Text,
} from 'components';

const NotificationsSettings = () => {
  const settings = useOrganizationSettings();
  invariant(
    typeof settings.emailsEnabled === 'boolean',
    'Emails enabled should be defined.'
  );

  const organization = useCurrentOrganization();
  const dispatch = useAppDispatch();

  const lastUpdateDate = date(settings.emailsEnabledUpdatedAt, 'LL');
  const lastUpdateTime = date(settings.emailsEnabledUpdatedAt, 'LT');

  const updateEmailsEnabled = async (value: boolean) => {
    await dispatch(
      put(
        `organizations/${organization.id}/settings`,
        {
          settings: { emailsEnabled: value },
        },
        {
          successMessage: value
            ? __('Email notifications successfully enabled')
            : __('Email notifications successfully disabled'),
        }
      )
    );
  };

  const onEmailsEnabledChange = (value: boolean) =>
    value
      ? updateEmailsEnabled(true)
      : confirmAsync(
          __('Disable email notifications on your platform'),
          <div>
            <p>
              <StrictlySanitizedHtml
                html={__(
                  'This action will disable <b>all</b> email notifications sent from your platform to your users.'
                )}
              />
            </p>
            <br />
          </div>,
          {
            confirmLabel: __('Disable email notifications'),
            isDanger: true,
            sideEffects: [__('Disable all email notifications on my platform')],
            sideEffectsLabel: __(
              'Are you sure you want to disable all email notifications on your platform?'
            ),
            sideEffectsAreIrreversible: false,
            onConfirm: () => updateEmailsEnabled(false),
          }
        );

  return (
    <DesignSystem version={2}>
      <PageHeader
        title={__('Notifications')}
        description={
          <div className="flex items-center mb-3">
            <Icon className="mr-2" name="timer" />
            <p>
              {__(
                'Last update on %1 at %2 by %3',
                <b>{lastUpdateDate}</b>,
                <b>{lastUpdateTime}</b>,
                settings.emailsEnabledUpdatedByFullName ||
                  __('an Elevo administrator')
              )}
            </p>
          </div>
        }
      />
      <ContentContainer>
        <Box>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col">
              <Text preset="24bs2" className="mr-5">
                {__('Email notifications')}
              </Text>
              <Text className="mt-2">
                {__(
                  'The email notifications are %1 on your platform.',
                  <b>
                    {settings.emailsEnabled
                      ? __('enabled|FemininePlural')
                      : __('disabled|FemininePlural')}
                  </b>
                )}
              </Text>
            </div>
            <CheckboxSwitch
              value={settings.emailsEnabled}
              onChange={onEmailsEnabledChange}
            />
          </div>
          {!settings.emailsEnabled && (
            <Notification icon="warning" className="mt-3" kind="warning">
              <StrictlySanitizedHtml
                html={__(
                  '<b>No emails</b> have been sent to your users since %1 at %2.',
                  lastUpdateDate,
                  lastUpdateTime
                )}
              />
            </Notification>
          )}
        </Box>
      </ContentContainer>
    </DesignSystem>
  );
};

export default NotificationsSettings;
