import React, { Fragment } from 'react';

import { ObjectiveVersion } from 'models';

import { Avatar } from 'components';

import VersionDate from './VersionDate';

type Props = {
  version: ObjectiveVersion;
  text: React.ReactNode;
};

const AvatarDescription = ({ version, text }: Props) => {
  return (
    <div className="flex">
      {version.user && (
        <Fragment>
          <Avatar url={version.user.avatarUrl} className="mr-3" />
          <div className="flex flex-col justify-center">
            {text}
            <VersionDate date={version.createdAt} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default AvatarDescription;
