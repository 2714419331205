import * as React from 'react';

import type { OneOnOneUserReview } from 'models';

import can from 'helpers/can';
import { __ } from 'helpers/i18n';
import { assert } from 'helpers/invariant';

import { Column, Columns, Date, Flex, PullRight, Text } from 'components';

import { AvatarWithStatus } from 'scenes/components/AvatarWithStatus';
import CalendarButton from 'scenes/components/userReview/CalendarButton';

import MainAction from './MainAction';

type Props = {
  userReview: OneOnOneUserReview;
};

export default function OneOnOneReviewCycleComponent({ userReview }: Props) {
  return (
    <React.Fragment>
      <Columns isGapLess className="mb-4 items-end">
        <Column size={4}>
          <Text color="soften" className="mb-4 block">
            {__('Self-assessment deadline:')}{' '}
            <Date value={userReview.revieweeDeadline} />
          </Text>
          <AvatarWithStatus
            className="mb-4"
            user={userReview.user}
            status={userReview.revieweeStatus}
            statusFallback={__('No self evaluation')}
          />
        </Column>
        <Column size={4}>
          <Text color="soften" className="mb-4 block">
            {__('Reviewer assessment deadline:')}{' '}
            <Date value={userReview.reviewerDeadline} />
          </Text>
          <AvatarWithStatus
            className="mb-4"
            user={userReview.reviewer}
            status={userReview.reviewerStatus}
          />
        </Column>
        {!!userReview.additionalReviewer && (
          <Column size={4}>
            <AvatarWithStatus
              className="mb-4"
              user={userReview.additionalReviewer}
              status={userReview.additionalReviewerStatus}
            />
          </Column>
        )}
      </Columns>

      <PullRight>
        <Flex>
          <MainAction userReview={userReview} />

          <div style={{ marginLeft: 10 }}>
            <CalendarButton
              userReviewId={userReview.id}
              meeting={assert(
                userReview.meeting,
                'Meeting should be available on userReview on this page'
              )}
              disabled={!can({ perform: 'schedule_meeting', on: userReview })}
            />
          </div>
        </Flex>
      </PullRight>
    </React.Fragment>
  );
}
