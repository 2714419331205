import React from 'react';

import { UserReview } from 'models';

import { Avatar, Text } from 'components';

import usePanelTabs from './hooks/usePanelTabs';
import { getEnabledTabs } from './tabs';

type Props = {
  userReview: UserReview;
};

const UserPanel = ({ userReview }: Props) => {
  const { user } = userReview;

  const [tabs, tabContent] = usePanelTabs(getEnabledTabs(userReview));

  if (!tabContent) return null;

  return (
    // pb-16 offset because of overlaping flags & helpdesk button
    <div className="flex flex-col pt-5 h-[calc(100vh-4rem)] md:h-full">
      <div className="pl-6 pr-3">
        <div className="flex items-center">
          <Avatar url={user.avatarUrl} size="small-lg" />
          <div className="flex flex-col ml-2">
            <Text preset="16bs5.5" className="text-blue-elevo">
              {user.fullName}
            </Text>
            <Text preset="13s7">{user.jobTitle}</Text>
          </div>
        </div>
        <Text preset="13s7" className="ml-12 text-text-light">
          {user.workStartDate}
        </Text>
        {tabs}
      </div>
      <div className="pl-6 pr-3 pb-16 overflow-y-auto">{tabContent}</div>
    </div>
  );
};

export const shouldShowUserPanel = (userReview: UserReview) => {
  return getEnabledTabs(userReview).length > 0;
};

export default UserPanel;
