import * as React from 'react';

import type { UserFilterSegment } from 'models';

import { __, n__ } from 'helpers/i18n';
import { assertDefined } from 'helpers/invariant';

import { UserFilterChangeMethod } from 'lib/dataLoader';

import {
  Button,
  Icon,
  Level,
  LevelLeft,
  LevelRight,
  SearchBar,
  Title6,
} from 'components';

import UserFilter from 'scenes/components/UserFilter';

type Props = {
  participantCount: number;
  search: string;
  onSearchChange: (search: string) => void;
  totalRecordCount: number;
  userFilter: UserFilterSegment | null;
  onUserFilterChange?: UserFilterChangeMethod;
  onRemoveEveryoneClick?: () => void;
  onAddEveryoneClick: (
    search: string,
    userFilter: UserFilterSegment | null
  ) => Promise<void>;
};

export default function Header({
  participantCount,
  search,
  onSearchChange,
  totalRecordCount,
  userFilter,
  onUserFilterChange,
  onRemoveEveryoneClick,
  onAddEveryoneClick,
}: Props) {
  return (
    <React.Fragment>
      <Level>
        <LevelLeft>
          <Title6>
            {n__(
              '%1 employee selected',
              '%1 employees selected',
              participantCount
            )}
          </Title6>
        </LevelLeft>
      </Level>
      <SearchBar
        value={search}
        placeholder={__('Search by name or email')}
        onChange={onSearchChange}
        style={{ marginTop: 12, marginBottom: 12 }}
      />
      <Level style={{ marginBottom: 12 }}>
        <LevelLeft>
          {!!onUserFilterChange && (
            <UserFilter segment={userFilter} onChange={onUserFilterChange} />
          )}
        </LevelLeft>

        <LevelRight className="tr-modal-header-buttons">
          <Button
            testClassName="test-add-everyone-button"
            color="secondary"
            onClick={() => onAddEveryoneClick(search, userFilter)}
            style={{ marginRight: 16 }}
          >
            <Icon
              style={{ marginBottom: 1, marginRight: 7 }}
              name="add_circle"
            />

            {totalRecordCount
              ? n__(
                  'Add %1 result',
                  'Add %1 results',
                  assertDefined(
                    totalRecordCount,
                    'totalRecordCount must be defined'
                  )
                )
              : __('Add everyone')}
          </Button>
          <Button
            testClassName="test-remove-everyone-button"
            color="secondary"
            onClick={onRemoveEveryoneClick}
          >
            <Icon
              style={{ marginBottom: 1, marginRight: 7 }}
              name="do_not_disturb_on"
            />
            {__('Remove everyone')}
          </Button>
        </LevelRight>
      </Level>
    </React.Fragment>
  );
}
