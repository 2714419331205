import { type TrainingType } from 'models';

import { __ } from 'helpers/i18n';

export const formatsTranslation = () => ({
  face_to_face: __('Face-to-face'),
  remote: __('Remote'),
  remote_and_face_to_face: __('Remote and face-to-face'),
});

export const typesTranslation = (): Record<TrainingType, string> => {
  return {
    external_intra_company: __('External Intra-company'),
    external_inter_company: __('External Inter-company'),
    internal_intra_company: __('Internal Intra-company'),
    internal_inter_company: __('Internal Inter-company'),
  };
};
