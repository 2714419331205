import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type {
  FeedbackableItem,
  FeedbackableRatingCriterion,
  MultipleScaleQuestionBlock,
  MultipleScaleQuestionBlockContent,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';
import type { ReduxStore } from 'redux/reducers';

import invariant from 'helpers/invariant';
import transformProps from 'helpers/transformProps';

import { hydrateFromStore } from 'lib/dataLoader';
import { post } from 'redux/actions/api';

import QuestionBlock from 'scenes/components/review/QuestionBlock';

import ReviewMessages from '../../ReviewMessages';
import ClosedAnswersSection from './ClosedAnswersSection';
import OpenAnswersSection from './OpenAnswersSection';

/* TODO: Discussion:
  Should we serialize everything instead of find/filter in the frontend side? e.g:
  feedbackableItems
  feedbackableBlockWithClosedAnswers
  feedbackableBlockWithOpenAnswers
  feedbackableCriteriaWithClosedAnswers
  feedbackableCriteriaWithOpenAnswers
  */

function findFeedbackableItemsByType<T extends FeedbackableItem['item']>(
  feedbackableItems: Array<FeedbackableItem<any>>,
  itemType: string
): Array<FeedbackableItem<T>> {
  return feedbackableItems.filter(fi => fi.item.type === itemType);
}

type Props = {
  content: MultipleScaleQuestionBlockContent;
  refreshShareability: () => Promise<void>;
  isPreview?: boolean;
};

type AfterConnectProps = Props & {
  persistAction: (
    evaluationId: string | undefined | null,
    feedbackableId: string | undefined | null,
    feedbackableType: string | undefined | null,
    text: string,
    rating: string | undefined | null
  ) => (dispatch: AppDispatch) => Promise<void>;
};

function MultipleScaleQuestionBlockContentComponent({
  content,
  persistAction,
}: AfterConnectProps) {
  const {
    title,
    description,
    messages,
    feedbackableItems,
    radarChart,
    richTextEnabled,
  } = content;

  const feedbackableCriteria =
    findFeedbackableItemsByType<FeedbackableRatingCriterion>(
      feedbackableItems,
      'ratingCriterion'
    );
  const feedbackableBlock =
    findFeedbackableItemsByType<MultipleScaleQuestionBlock>(
      feedbackableItems,
      'multipleScaleQuestion'
    )[0];

  return (
    <QuestionBlock
      title={title}
      description={description}
      testClassName="test-multiple-scale-question-block"
      className="multiple-scale-question-block"
      withRichText={richTextEnabled}
    >
      <div>
        <ReviewMessages messages={messages} />
      </div>

      <ClosedAnswersSection
        feedbackableBlock={feedbackableBlock}
        radarChart={radarChart}
      />

      <OpenAnswersSection
        feedbackableCriteria={feedbackableCriteria}
        feedbackableBlock={feedbackableBlock}
        persistAction={persistAction}
      />
    </QuestionBlock>
  );
}

const propsTransformer = ({ refreshShareability, isPreview }: Props) => {
  return {
    persistAction: (
      evaluationId,
      feedbackableId,
      feedbackableType,
      text,
      rating
    ) => {
      invariant(evaluationId, 'evaluationId must be defined');

      return async (dispatch: AppDispatch) => {
        if (isPreview) {
          return null;
        }

        await dispatch(
          post('feedbacks', {
            feedback: {
              evaluationId,
              feedbackableId,
              feedbackableType,
              text,
              rating,
            },
          })
        );

        await refreshShareability();
      };
    },
  };
};

function mapStateToProps(state: ReduxStore, { content }: Props) {
  return {
    content: hydrateFromStore(
      state.data,
      { resourceType: 'formContentBlock', id: content.id },
      {
        block: {
          messages: {},
          feedbackableItems: {
            item: {},
            answers: {
              author: {},
            },
          },
          radarChart: {},
        },
      }
    ),
  };
}

export default compose(
  connect(mapStateToProps),
  transformProps(propsTransformer)
)(MultipleScaleQuestionBlockContentComponent);
