import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';

import invariant from 'helpers/invariant';

import { Flex, Icon, Slider } from 'components';

type Props = {
  src: string; // Base64 or url
  onChange: (croppedImage: string) => any;
  cropShape?: 'round' | 'rect';
  aspect?: number;
  fileType?: string;
  minZoom?: number;
  maxZoom?: number;
  backgroundColor?: string;
};

export default function ImageResizer({
  src,
  onChange,
  cropShape = 'round',
  aspect = 1,
  fileType,
  minZoom = 1,
  maxZoom = 3,
  backgroundColor,
}: Props) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const generateCroppedImage = useCallback(
    async croppedAreaPixels => {
      try {
        const croppedImage = await getCroppedImg(
          src,
          croppedAreaPixels,
          fileType
        );
        onChange(croppedImage);
      } catch (e) {
        console.error(e);
      }
    },
    [src, onChange, fileType]
  );

  return (
    <div className="image-resizer">
      <div className="crop-section">
        <Cropper
          image={src}
          crop={crop}
          zoom={zoom}
          minZoom={minZoom}
          maxZoom={maxZoom}
          showGrid={false}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={(_, croppedAreaPixels) =>
            generateCroppedImage(croppedAreaPixels)
          }
          onZoomChange={setZoom}
          cropShape={cropShape}
          restrictPosition={false}
          style={
            backgroundColor
              ? { containerStyle: { backgroundColor } }
              : undefined
          }
        />
      </div>
      <Flex className="slider-section" verticalAlign>
        <Icon name="image" />
        <Slider
          minValue={minZoom * 100}
          maxValue={maxZoom * 100}
          value={zoom * 100}
          onChange={newValue => setZoom(newValue / 100)}
          style={{ flexGrow: 2 }}
        />
        <Icon name="image" />
      </Flex>
    </div>
  );
}

// Code taken from https://codesandbox.io/s/v69ly910ql, from https://github.com/ricardo-ch/react-easy-crop examples
const createImage = url =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', reject);
    image.src = url;
  });

async function getCroppedImg(imageSrc, pixelCrop, fileType?) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  invariant(ctx, '2d context is not supported');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  return canvas.toDataURL(fileType || 'image/jpeg');
}
