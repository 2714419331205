import { capitalize, every } from 'lodash';
import React from 'react';

import type { KeyResult } from 'models';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { Field, Flex, Label, Text } from 'components';

type Props = {
  disabled: boolean;
  startValue: string;
  currentValue: string;
  targetValue: string;
  autoFocus: boolean;
  onBlur: () => any;
  onUpdate: (attributes: Partial<KeyResult>) => Promise<void>;
};

type State = {
  editingStartValue: boolean;
  editingCurrentValue: boolean;
  editingTargetValue: boolean;
};

type FieldName = 'startValue' | 'currentValue' | 'targetValue';
type EditingFieldName =
  | 'editingStartValue'
  | 'editingCurrentValue'
  | 'editingTargetValue';

const BLUR_TIMEOUT = 500;

const craftEditingFieldName = (fieldName: FieldName) =>
  `editing${
    capitalize(fieldName)[0] + fieldName.substring(1, fieldName.length)
  }` as EditingFieldName;

export default class KeyResultMeasure extends React.Component<Props, State> {
  state = {
    editingStartValue: this.props.autoFocus,
    editingCurrentValue: false,
    editingTargetValue: false,
  };

  onEditField = (fieldName: FieldName, edit: boolean) => {
    const editingFieldName = craftEditingFieldName(fieldName);
    this.setState({ [editingFieldName]: edit } as {
      // eslint-disable-next-line no-unused-vars
      [editingFieldName in EditingFieldName]: boolean;
    });
  };

  craftFieldClassName = (fieldName: FieldName) => {
    const value = this.props[fieldName];
    const editingFieldName = craftEditingFieldName(fieldName);
    const editingFieldValue = this.state[editingFieldName];

    return classNames({
      'with-average-value-length': !!value && value.length > 5,
      'with-long-value': !!value && value.length > 30,
      'has-input': editingFieldValue,
    });
  };

  onBlurField = (fieldName: FieldName) => {
    this.onEditField(fieldName, false);

    setTimeout(() => {
      const { startValue, currentValue, targetValue } = this.props;
      const hasMeasure = !!startValue || !!currentValue || !!targetValue;

      if (every(this.state, isEditing => !isEditing) && !hasMeasure) {
        this.props.onBlur();
        return;
      }
    }, BLUR_TIMEOUT);
  };

  render() {
    const {
      disabled,
      startValue,
      currentValue,
      targetValue,
      autoFocus,
      onUpdate,
    } = this.props;

    const { editingStartValue, editingCurrentValue, editingTargetValue } =
      this.state;

    return (
      <Flex className="key-result-measure">
        <Field className={this.craftFieldClassName('startValue')}>
          <Label textSize={7} color="info" weight="semibold">
            {__('Start:')}
          </Label>

          <div className="measure-container">
            <Text
              size={7}
              isEditable={!disabled}
              style={{ width: '100%' }}
              editableOptions={{
                type: 'singlelineText',
                value: startValue,
                disabledValue: startValue || '-',
                onChange: newStartValue =>
                  onUpdate({ startValue: newStartValue }),
                autoFocus: autoFocus || editingStartValue,
                withEditIcon: false,
                onBlur: () => this.onBlurField('startValue'),
                onFocus: () => this.onEditField('startValue', true),
              }}
              testClassName="test-key-result-start-value-field"
            />
          </div>
        </Field>

        <Field className={this.craftFieldClassName('targetValue')}>
          <Label textSize={7} color="info" weight="semibold">
            {__('Target:')}
          </Label>

          <div className="measure-container">
            <Text
              size={7}
              isEditable={!disabled}
              style={{ width: '100%' }}
              editableOptions={{
                type: 'singlelineText',
                value: targetValue,
                disabledValue: targetValue || '-',
                onChange: newTargetValue =>
                  onUpdate({ targetValue: newTargetValue }),
                autoFocus: editingTargetValue,
                withEditIcon: false,
                onBlur: () => this.onBlurField('targetValue'),
                onFocus: () => this.onEditField('targetValue', true),
              }}
              testClassName="test-key-result-target-value-field"
            />
          </div>
        </Field>

        <Field className={this.craftFieldClassName('currentValue')}>
          <Label textSize={7} color="info" weight="semibold">
            {__('Result:')}
          </Label>

          <div className="measure-container">
            <Text
              size={7}
              isEditable={!disabled}
              style={{ width: '100%' }}
              editableOptions={{
                type: 'singlelineText',
                value: currentValue,
                disabledValue: currentValue || '-',
                onChange: newCurrentValue =>
                  onUpdate({ currentValue: newCurrentValue }),
                autoFocus: editingCurrentValue,
                withEditIcon: false,
                onBlur: () => this.onBlurField('currentValue'),
                onFocus: () => this.onEditField('currentValue', true),
              }}
              testClassName="test-key-result-current-value-field"
            />
          </div>
        </Field>
      </Flex>
    );
  }
}
