import React, { useState } from 'react';

import type { TrainingParticipant } from 'models';

import { handleFormErrors } from 'helpers/api';
import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import {
  BoxListItem,
  Button,
  Column,
  Columns,
  HamburgerMenu,
  Icon,
  MenuItem,
  MenuList,
  Text,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

import AddBudgetItemForParticipantModal from './AddBudgetItemForParticipantModal';
import RemoveFromSessionModal from './ParticipantsTable/RemoveFromSessionModal';

type Props = {
  participant: TrainingParticipant;
  refreshSession: () => Promise<void>;
  sessionName: string | undefined | null;
  saveParticipant: (participant: TrainingParticipant) => Promise<void>;
  periodSlug: string;
  defaultCurrency: string;
};

const ParticipantItem = ({
  participant,
  periodSlug,
  refreshSession,
  sessionName,
  saveParticipant,
  defaultCurrency,
}: Props) => {
  const [budgetModalShown, setBudgetModalShown] = React.useState(false);
  const [isDeletionModalActive, setIsDeletionModalActive] = useState(false);
  const [errors, setErrors] = React.useState({});

  const handleUpdateParticipant = async (data: TrainingParticipant) => {
    await handleFormErrors(() => saveParticipant(data), setErrors, true);
    closeBudgetModal();
  };

  const closeBudgetModal = () => {
    setErrors({});
    setBudgetModalShown(false);
  };

  return (
    <React.Fragment>
      <BoxListItem
        key={participant.id}
        testClassName="test-participant-list-item"
      >
        <Columns isMobile className="flex flex-row items-center">
          <Column size={5}>
            <UserAvatar user={participant.user} withJobTitle />
          </Column>
          <Column>
            {participant.fundingItems.length === 0 ? (
              <Button
                color="secondary"
                className="border-none bg-inherit px-0"
                onClick={() => setBudgetModalShown(true)}
                style={{}}
              >
                {__('Add a budget')}
              </Button>
            ) : (
              <React.Fragment>
                {participant.fundingItems.map(item => (
                  <div key={item.id}>
                    <span>{item.fundingSource.title}</span>:{' '}
                    <span>
                      {!!item.amountCents &&
                        formatMoney(item.amountCents, item.amountCurrency)}
                    </span>
                  </div>
                ))}
              </React.Fragment>
            )}
          </Column>
          <Column size={2} ellipsisOnLineOverflow>
            {participant.meeting && !participant.meeting.canceledAt ? (
              <span className="flex items-center">
                <Icon
                  style={{ marginRight: 8, marginBottom: 2 }}
                  name="event"
                />
                {__('Sent')}
              </span>
            ) : (
              <Text weight="light" transformation="italic" color="light">
                {__('Not invited')}
              </Text>
            )}
          </Column>
          <Column size={1} contentIsPulledRight>
            <HamburgerMenu>
              <MenuList>
                <MenuItem onClick={() => setBudgetModalShown(true)}>
                  {participant.fundingItems.length > 0
                    ? __('Edit funding')
                    : __('Add funding')}
                </MenuItem>
                <MenuItem
                  isDanger
                  onClick={() => setIsDeletionModalActive(true)}
                >
                  {__('Remove from session')}
                </MenuItem>
              </MenuList>
            </HamburgerMenu>
          </Column>
        </Columns>
      </BoxListItem>
      {budgetModalShown && (
        <AddBudgetItemForParticipantModal
          periodSlug={periodSlug}
          defaultCurrency={defaultCurrency}
          participant={participant}
          onClose={closeBudgetModal}
          onSubmit={handleUpdateParticipant}
          errors={errors}
        />
      )}
      {isDeletionModalActive && (
        <RemoveFromSessionModal
          participant={participant}
          setIsRemoveModalActive={setIsDeletionModalActive}
          sessionName={sessionName}
          onAfterRemove={() => refreshSession()}
        />
      )}
    </React.Fragment>
  );
};

export default ParticipantItem;
