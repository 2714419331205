import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import type {
  FeedbackableItem,
  Objective,
  ObjectivesModuleBlockContent,
  PersonalObjective,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';
import type { ReduxStore } from 'redux/reducers';

import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { hydrateFromStore } from 'lib/dataLoader';
import { post, put } from 'redux/actions/api';

import { Button, Can, Icon, PullRight } from 'components';
import RightSidebar from 'components/RightSidebar';

import ImportObjectivesModal from 'scenes/components/objectives/ImportObjectivesModal';
import PersonalObjectiveSections from 'scenes/components/objectives/PersonalObjectiveSections';
import BlockDetails from 'scenes/components/review/BlockDetails';
import ObjectiveVersions from 'scenes/objectives/ObjectiveVersions';
import { ObjectiveContext } from 'scenes/objectives/objectiveContext';

import CollectionInfo from './CollectionInfo';
import FeedbackableObjectiveList from './FeedbackableObjectiveList';

type Props = {
  content: ObjectivesModuleBlockContent;
  refreshBlock: (reviewBlockId: string) => Promise<void>;
  refreshShareability: () => Promise<void>;
  withoutSectionSeparator?: boolean;
  revieweeFullName: string;
  isPreview?: boolean;
};

type AfterConnectProps = Props & {
  createObjective: (collectionId: string) => Promise<void>;
  publishObjectives: (collectionPath: string) => Promise<void>;
  onPositionUpdate: (
    collectionPath: string,
    objectiveId: string,
    position: number
  ) => Promise<void>;
};

type AfterMergeProps = AfterConnectProps & {
  createObjective: () => Promise<void>;
  onPositionUpdate: (objectiveId: string, position: number) => Promise<void>;
  refreshBlock: () => Promise<void>;
};

function ObjectivesModuleBlockContentComponent({
  content,
  createObjective,
  onPositionUpdate,
  refreshBlock,
  refreshShareability,
  withoutSectionSeparator,
  isPreview,
  publishObjectives,
  revieweeFullName,
}: AfterMergeProps) {
  const [importModalIsActive, setImportModalIsActive] = React.useState(false);
  const [objective, setObjective] = React.useState<Objective | null>(null);

  const {
    id,
    title,
    description,
    feedbackableItems,
    userObjectivePeriod,
    objectiveCollection,
    definesObjectives,
    richTextEnabled,
  } = content;

  const draftObjectives = feedbackableItems.filter(
    item => !item.item.published
  );

  const addObjectiveButtons = (
    <Fragment>
      <Can perform="import_objectives" on={objectiveCollection}>
        <Button
          color="secondary"
          style={{ marginRight: 8 }}
          onClick={() => setImportModalIsActive(true)}
          testClassName="test-import-objective-button"
          disabled={isPreview}
        >
          <Icon style={{ marginRight: 8 }} name="content_copy" />
          {__('Import')}
        </Button>
      </Can>
      <Can perform="create_objective" on={objectiveCollection}>
        <Button
          testClassName="test-create-objective-button"
          color="primary"
          onClick={createObjective}
          disabled={isPreview}
        >
          <Icon style={{ marginRight: 8 }} name="add" />
          {__('Add')}
        </Button>
      </Can>
    </Fragment>
  );

  return (
    <ObjectiveContext.Provider value={{ objective, setObjective }}>
      <div className="objectives-module test-objectives-module-block">
        <BlockDetails
          id={id}
          section={__('Objectives')}
          title={title}
          description={description}
          withoutSectionSeparator={withoutSectionSeparator}
          withRichText={richTextEnabled}
          withReadMore
        />

        <div className="block-content-wrapper objectives-module-block">
          <PersonalObjectiveSections<FeedbackableItem<PersonalObjective>>
            publishedItems={feedbackableItems.filter(
              item => item.item.published
            )}
            draftItems={draftObjectives}
            objectiveCollection={objectiveCollection}
            revieweeFullName={revieweeFullName}
            onPublishClick={() =>
              publishObjectives(objectiveCollection.collectionPath)
            }
            definesObjectives={definesObjectives}
            fromReview
            renderItems={feedbackableItems => (
              <FeedbackableObjectiveList
                feedbackableObjectives={feedbackableItems}
                objectiveCollection={objectiveCollection}
                onCreate={createObjective}
                onPositionUpdate={onPositionUpdate}
                afterUpdate={refreshShareability}
                afterDestroy={() => {
                  return Promise.all([refreshBlock(), refreshShareability()]);
                }}
                isDisabled={isPreview}
                definesObjectives={definesObjectives}
                revieweeFullName={revieweeFullName}
              />
            )}
          />
          <PullRight>{addObjectiveButtons}</PullRight>
        </div>

        <CollectionInfo objectiveCollection={objectiveCollection} />

        <Can perform="import_objectives" on={objectiveCollection}>
          <ImportObjectivesModal
            isActive={importModalIsActive}
            toTargetUserObjectivePeriod={userObjectivePeriod}
            onClose={() => setImportModalIsActive(false)}
            afterImport={refreshBlock}
          />
        </Can>
      </div>
      <RightSidebar
        title={objective?.title || ''}
        isOpen={!!objective}
        onClose={() => setObjective(null)}
      >
        {!!objective && <ObjectiveVersions objective={objective} />}
      </RightSidebar>
    </ObjectiveContext.Provider>
  );
}

function mapStateToProps(state: ReduxStore, { content }: Props) {
  return {
    content: hydrateFromStore(
      state.data,
      { resourceType: 'formContentBlock', id: content.id },
      {
        block: {
          feedbackableItems: {
            item: {
              abilities: {},
              keyResults: {},
            },
            answers: {
              author: {},
            },
          },
          userObjectivePeriod: {
            user: {},
            abilities: {},
          },
          objectiveCollection: {
            abilities: {},
          },
        },
      }
    ),
  };
}

function mapDispatchToProps(dispatch: AppDispatch, props: Props) {
  return {
    // mapDispatchToProps is called before mapStateToProps witch means that
    //  we dont have access to collectionPath at this point, so we mergeProps
    createObjective: async (objectiveCollectionPath: string) => {
      const { content, refreshBlock } = props;
      const { reviewBlockId } = content;

      await dispatch(post(objectiveCollectionPath));

      !!reviewBlockId && (await refreshBlock(reviewBlockId));
    },
    onPositionUpdate: (objectiveCollectionPath, objectiveId, position) =>
      dispatch(put(`${objectiveCollectionPath}/${objectiveId}`, { position })),
    publishObjectives: objectiveCollectionPath =>
      dispatch(put(`${objectiveCollectionPath}/bulk_publish_objectives`)),
  };
}

function mergeProps(
  stateProps: AfterConnectProps,
  dispatchProps: AfterConnectProps,
  ownProps: Props
) {
  const { refreshBlock } = ownProps;
  const { createObjective, onPositionUpdate } = dispatchProps;
  const { reviewBlockId, objectiveCollection } = stateProps.content;
  const { collectionPath } = objectiveCollection;

  invariant(reviewBlockId, 'ObjectivesModule must have a reviewBlockId!');

  return {
    ...ownProps,
    ...dispatchProps,
    ...stateProps,
    createObjective: () => createObjective(collectionPath),
    onPositionUpdate: (objectiveId, position) =>
      onPositionUpdate(collectionPath, objectiveId, position),
    refreshBlock: () => refreshBlock(reviewBlockId),
  };
}

export default connect(
  // @ts-ignore TSFIXME
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ObjectivesModuleBlockContentComponent) as React.ComponentType<Props>;
