import moment from 'moment';
import React from 'react';

import { DateValue, date, dateRelative } from '../../helpers/i18n';

type DateProps = {
  value: DateValue;
  format?: string;
  className?: string;
};

export const Date = ({ value, format, className }: DateProps) => (
  <span className={className}>{date(value, format)}</span>
);

type DateRelativeProps = {
  value: DateValue;
};

export const DateRelative = (props: DateRelativeProps) => {
  return <span>{dateRelative(props.value)}</span>;
};

export const DateRelativeAuto = (props: DateRelativeProps) => {
  const { value } = props;
  if (!value) return <span>-</span>;
  const dateIsOld = moment(value) < moment().subtract(1, 'days');
  if (dateIsOld) {
    return <Date value={value} />;
  } else {
    return <DateRelative value={value} />;
  }
};
