import React, { useContext } from 'react';

import { SkillsDomain } from 'models/skills';

import { BoxListItem, Column, Columns, Icon, Text } from 'components';

import { DataContext } from 'scenes/skills/Index/MatrixVisualizer';

import DeleteButton from './DeleteButton';

const ICON_SIZE = 20;

type Props = {
  domain: SkillsDomain;
  active: boolean;
  onClick: () => void;
};

const DomainHeader = ({ domain, active, onClick }: Props) => {
  const { matrix, editionMode } = useContext(DataContext);

  return (
    <BoxListItem
      className="bg-ui-body-bg"
      testClassName="test-skills-matrix-domain"
    >
      <Columns>
        <Column className="domain-header">
          <div className="flex justify-between">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              onClick={onClick}
              className="flex items-center hover:cursor-pointer"
            >
              <Icon
                name={active ? 'arrow_drop_down' : 'arrow_right'}
                className="mr-3"
                style={{ fontSize: ICON_SIZE }}
              />
              <Text
                preset="16bs5.5"
                style={{ whiteSpace: 'nowrap' }}
                testClassName="test-domain-title"
              >
                {domain.title}
              </Text>
            </div>
            {editionMode && <DeleteButton domain={domain} matrix={matrix} />}
          </div>
        </Column>
      </Columns>
    </BoxListItem>
  );
};

export default DomainHeader;
