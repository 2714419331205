import React from 'react';

import type { TrainingSession } from 'models';

import classNames from 'helpers/classNames';
import { startToEndDateLabel } from 'helpers/date';
import { __ } from 'helpers/i18n';
import { formatsTranslation } from 'helpers/models/trainingCourse';

import { Text } from 'components';

type Props = {
  session: TrainingSession;
  className?: string;
  sessionNameDisplayed?: boolean;
};

const SessionSummary = ({
  session,
  className,
  sessionNameDisplayed = true,
}: Props) => {
  const notSpecifiedText = (
    <Text transformation="italic" color="light">
      {__('Not specified')}
    </Text>
  );
  const translatedFormats = formatsTranslation();

  return (
    <div className={classNames('flex flex-col', className)}>
      {sessionNameDisplayed && (
        <span>
          {__('Session name:')} {session.name || notSpecifiedText}
        </span>
      )}
      <span>
        {__('Training organization:')}{' '}
        {session.trainingOrganization || notSpecifiedText}
      </span>
      <span>
        {__('Location:')} {session.location || notSpecifiedText}
      </span>
      <span>
        {__('Date:')}{' '}
        {session.startDate || session.endDate
          ? startToEndDateLabel(session.startDate, session.endDate, {
              fromToLabelsAlwaysVisible: true,
            })
          : notSpecifiedText}
      </span>
      <span>
        {__('Number of hours:')} {session.durationInHours || notSpecifiedText}
      </span>
      <span>
        {__('Format:')}{' '}
        {session.trainingFormat
          ? translatedFormats[session.trainingFormat]
          : notSpecifiedText}
      </span>
    </div>
  );
};

export default SessionSummary;
