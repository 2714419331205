import React from 'react';

import type { User } from 'models';

import { __ } from 'helpers/i18n';

import { Flex, Notification, Text } from 'components';

type Props = {
  user: User;
  sessionName: string | undefined | null;
};

const DeleteParticipantWarning = ({ user, sessionName }: Props) => (
  <Notification kind="danger" className="mb-0" icon="warning">
    <Flex direction="column" className="gap-0">
      <Text preset="13bs7">
        {__(
          'The employee will be removed from the session "%1"',
          sessionName || __('Untitled session')
        )}
      </Text>
      <Text preset="13s7">
        {__(
          'Refusing this request will automatically remove %1 from the training session. %1 will be informed by e-mail that the request has been refused.',
          user.fullName
        )}
      </Text>
    </Flex>
  </Notification>
);

export default DeleteParticipantWarning;
