import React from 'react';

import type { BlockRatingOption, InteractionType } from 'models';

import { __ } from 'helpers/i18n';

import { Column, Columns, Title } from 'components';

import RatingOptions from './RatingOptions';
import RatingOptionsPlaceholder from './RatingOptionsPlaceholder';

type Props = {
  reviewBlockId: string;
  canDeleteRevieweeRatingOption: boolean;
  canDeleteReviewerRatingOption: boolean;
  canUpdateWithEmptyLabel: boolean;
  revieweeRatingOptionsEnabled: boolean;
  revieweeRatingOptions: Array<BlockRatingOption>;
  reviewerRatingOptionsEnabled: boolean;
  reviewerRatingOptions: Array<BlockRatingOption>;
  hasSelfEvaluationEnabled: boolean;
  interactionType: InteractionType;
};

const LegacyRatingsSection = ({
  reviewBlockId,
  canDeleteRevieweeRatingOption,
  canDeleteReviewerRatingOption,
  canUpdateWithEmptyLabel,
  revieweeRatingOptionsEnabled,
  revieweeRatingOptions,
  reviewerRatingOptionsEnabled,
  reviewerRatingOptions,
  hasSelfEvaluationEnabled,
  interactionType,
}: Props) => {
  return (
    <Columns className="ratings-section">
      {interactionType === 'one_on_one' && (
        <Column>
          <Title
            size={7}
            transformation="uppercase"
            style={{ marginBottom: 8 }}
          >
            {__('Answers for the reviewee')}
          </Title>
          {hasSelfEvaluationEnabled ? (
            <RatingOptions
              reviewBlockId={reviewBlockId}
              ratingOptionRole="reviewee"
              ratingOptions={revieweeRatingOptions}
              isDisabled={!revieweeRatingOptionsEnabled}
              canDeleteRatingOption={canDeleteRevieweeRatingOption}
              canUpdateWithEmptyLabel={canUpdateWithEmptyLabel}
            />
          ) : (
            <RatingOptionsPlaceholder title={__('Self evaluation disabled')} />
          )}
        </Column>
      )}
      <Column>
        {interactionType === 'one_on_one' && (
          <Title
            size={7}
            transformation="uppercase"
            style={{ marginBottom: 8 }}
          >
            {__('Answers for the reviewer')}
          </Title>
        )}
        <RatingOptions
          reviewBlockId={reviewBlockId}
          ratingOptionRole="reviewer"
          ratingOptions={reviewerRatingOptions}
          isDisabled={!reviewerRatingOptionsEnabled}
          canDeleteRatingOption={canDeleteReviewerRatingOption}
          canUpdateWithEmptyLabel={canUpdateWithEmptyLabel}
        />
      </Column>
    </Columns>
  );
};

export default LegacyRatingsSection;
