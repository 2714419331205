import React, { Fragment } from 'react';

import type { FundingItem as FundingItemType, FundingSource } from 'models';

import NestedResourceWrapper from 'helpers/NestedResourceWrapper';
import { FormErrors } from 'helpers/api';
import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Button, FetchContainer, FieldError, Text } from 'components';

import FundingItem from './FundingItem';

type NewFundingItem = {
  amountCents: number | undefined | null;
  amountCurrency: string;
  fundingSource: FundingSource | undefined | null;
};

type Props = {
  periodSlug: string;
  fundingItems: Array<FundingItemType>;
  errors: FormErrors;
  onChange: (params: Array<FundingItemType>) => void;
  defaultCurrency: string;
  disabled?: boolean;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    fundingSources: Array<FundingSource>;
  };

const FundingSection = ({
  fundingItems,
  errors,
  onChange,
  isFetching,
  hasError,
  fundingSources,
  defaultCurrency,
  disabled,
}: AfterDataLoaderProps) => {
  const [fundingItemCreationIsActive, setFundingItemCreationIsActive] =
    React.useState(false);

  const itemsList = new NestedResourceWrapper<FundingItemType>({
    items: fundingItems,
    afterChange: items => {
      setFundingItemCreationIsActive(
        items.filter(item => !item.data._destroy).length === 0
      );
      onChange(items.map(item => ({ ...item.data })));
    },
  });

  const emptyFundingItem = {
    amountCents: null,
    amountCurrency: defaultCurrency,
    fundingSource: null,
    position: fundingItems.length,
  } as NewFundingItem;

  const disabledFundingSources = itemsList
    .notDeleted()
    .map(item => item.fundingSource);

  return (
    <Fragment>
      <div className="flex items-center justify-between gap-7 mb-2">
        <Text className="flex-1" preset="14bs6">
          {__('Funding source')}
        </Text>
        <Text className="flex-1" preset="14bs6">
          {__('Amount')}
        </Text>
      </div>
      <FetchContainer
        loadingStyle="alone"
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <div
            style={{ marginBottom: '16px' }}
            className="test-training-request-funding-section"
          >
            {itemsList.map((fundingItem, position) => (
              <FundingItem
                key={position}
                fundingItem={fundingItem}
                fundingSources={fundingSources}
                disabledFundingSources={disabledFundingSources}
                onUpdate={newFundingItem =>
                  itemsList.onUpdate(fundingItem, newFundingItem)
                }
                onDelete={() => itemsList.onDelete(fundingItem)}
                disabled={disabled}
              />
            ))}
            {(fundingItemCreationIsActive || fundingItems.length === 0) && (
              <FundingItem
                key={0}
                fundingItem={emptyFundingItem}
                fundingSources={fundingSources}
                disabledFundingSources={disabledFundingSources}
                onUpdate={newFundingItem => itemsList.onCreate(newFundingItem)}
                onDelete={() => setFundingItemCreationIsActive(false)}
                disabled={disabled}
                deletable={itemsList.notDeleted().length > 0}
              />
            )}
            <Button
              testClassName="test-add-funding-item-button"
              color="secondary"
              onClick={() => setFundingItemCreationIsActive(true)}
              disabled={disabled || itemsList.notDeleted().length === 0}
            >
              <span>{__('Add a funding source')}</span>
            </Button>
            <FieldError>{errors.fundingItemsAmount}</FieldError>
          </div>
        )}
      />
    </Fragment>
  );
};

export default newDataLoader({
  fetch: ({ periodSlug }: AfterDataLoaderProps) =>
    get(`training/periods/${periodSlug}/funding_sources`, {
      active: true,
    }),
  hydrate: {
    fundingSources: {},
  },
})(FundingSection) as React.ComponentType<Props>;
