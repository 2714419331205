import React from 'react';

import type { OneOnOneUserReview } from 'models';
import type { OneOnOneUserReviewFile } from 'models/UserReview/OneOnOneUserReview';

import { __ } from 'helpers/i18n';

import {
  Button,
  Link,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
  TooltipOnEllipsis,
} from 'components';

type Props = {
  isActive: boolean;
  onClose: () => void;
  userReview: OneOnOneUserReview;
};

const AttachedFilesModal = ({ isActive, onClose, userReview }: Props) => {
  return (
    <ModalCard onClose={onClose} isActive={isActive} isLarge>
      <ModalCardHead>
        <ModalCardTitle>{__('Attached Documents')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        {userReview.files.map((file: OneOnOneUserReviewFile) => (
          <div className="flex items-center justify-between rounded p-2 my-2 bg-ui-feedback-bg">
            <Text preset="14bs6" color="primary" className="overflow-hidden">
              <Link
                to={file.url}
                hasInheritedColor
                openInNewTab
                additionalClassName="whitespace-nowrap flex gap-1"
              >
                <TooltipOnEllipsis>{file.name}</TooltipOnEllipsis>
              </Link>
            </Text>
            <div className="shrink-0">
              <Text preset="14s6" color="light" className="mr-2 ml-3">
                ({file.size})
              </Text>
            </div>
          </div>
        ))}
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default AttachedFilesModal;
