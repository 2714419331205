import React from 'react';

import compositeKey from 'helpers/compositeKey';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import Modal from './Modal';

type Props = {
  sessionId: string;
  onClose: () => void;
  onAfterDelete: () => void;
};

export default newDataLoader({
  fetch: ({ sessionId }: Props) => get(`training/sessions/${sessionId}`),
  hydrate: { session: {} },
  cacheKey: ({ sessionId }) => compositeKey({ sessionId }),
})(Modal) as React.ComponentType<Props>;
