import React from 'react';

import { TrainingSession, TrainingSessionStatus } from 'models/TrainingSession';

import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  Tooltip,
  WithSavingStatusRecorder,
} from 'components';

import { trainingSessionStatusIcons } from 'scenes/components/StatusTag';

import sessionStatusLabels from './sessionStatusLabels';

type Props = { session: TrainingSession };

const StatusActions = ({ session }: Props) => {
  const dispatch = useAppDispatch();

  const statusLabels = sessionStatusLabels();
  const sessionStatusLabel =
    (session.status && statusLabels[session.status]) || session.status;

  const onSessionStatusUpdate = async (status: TrainingSessionStatus) => {
    await dispatch(
      put(`training/sessions/${session.id}`, {
        trainingSession: { status },
      })
    );
  };

  return (
    <WithSavingStatusRecorder
      fieldUid={compositeKey({
        trainingSessionId: session.id,
        type: 'training_session',
      })}
      onChange={onSessionStatusUpdate}
      render={autoSavingOnChange => (
        <Dropdown>
          <DropdownTrigger
            render={() => (
              <Button color="primary">
                <Icon
                  className="mr-2"
                  name={
                    trainingSessionStatusIcons[
                      session.status as TrainingSessionStatus
                    ]
                  }
                />
                <span>{sessionStatusLabel}</span>
                <Icon className="ml-2" name="keyboard_arrow_down" />
              </Button>
            )}
          />
          <DropdownMenu>
            {Object.keys(statusLabels)
              .filter(value => value !== session.status)
              .map(value => {
                const label = statusLabels[value];
                const disabled = value === 'completed' && !session.validated;
                return (
                  <Tooltip
                    key={value}
                    content={__(
                      'A session cannot be completed if it is not in the validated plan.'
                    )}
                    enabled={disabled}
                  >
                    <DropdownMenuItem
                      disabled={disabled}
                      key={value}
                      onClick={() =>
                        autoSavingOnChange(value as TrainingSessionStatus)
                      }
                    >
                      <span>{label}</span>
                    </DropdownMenuItem>
                  </Tooltip>
                );
              })}
          </DropdownMenu>
        </Dropdown>
      )}
    />
  );
};

export default StatusActions;
