import React, { Fragment } from 'react';

import type { BudgetStats } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { DataLoaderProvidedProps } from 'lib/dataLoader';
import { requestStarted, requestSucceeded } from 'lib/dataLoader/actions';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { FetchContainer, Label, Text } from 'components';

type Props = {
  periodSlug: string;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    budgetStats: BudgetStats;
  };

export function refetchBudgetStats(periodSlug: string) {
  const requestId = compositeKey({ periodSlug, viewId: 'budgetStats' });

  return async function (dispatch: AppDispatch) {
    await dispatch(requestStarted(requestId));
    const response = await dispatch(
      get(`training/periods/${periodSlug}/budget_items/stats`)
    );
    await dispatch(requestSucceeded(requestId, response.response.body));
  };
}

const BudgetSummary = ({
  budgetStats,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => (
        <Fragment>
          <Label>{__('Overall budget')}</Label>
          <Text style={{ display: 'block' }} color="info" weight="normal">
            {__('The overall budget is calculated based on funding sources')}
          </Text>
          <Text
            style={{ display: 'block' }}
            testClassName="test-training-global-budget-amount"
          >
            {budgetStats.totalAmountCents > 0
              ? formatMoney(
                  budgetStats.totalAmountCents,
                  budgetStats.totalAmountCurrency
                )
              : `- ${budgetStats.totalAmountCurrency}`}
          </Text>
        </Fragment>
      )}
    />
  );
};

export default newDataLoader({
  fetch: ({ periodSlug }: Props) =>
    get(`training/periods/${periodSlug}/budget_items/stats`),
  hydrate: {
    budgetStats: {},
  },
})(BudgetSummary) as React.ComponentType<Props>;
