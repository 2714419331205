import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';

import {
  SurveyCampaignUpdatableAttributes,
  SurveyForm,
  SurveyFormStep,
} from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToSurveyCampaignPreview } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { del, get, post, put } from 'redux/actions/api';

import {
  Button,
  DragAndDropContainer,
  DragAndDropItem,
  EmptyStateWithIcon,
  FetchContainer,
  Icon,
  Text,
} from 'components';

import StepContainer from 'scenes/components/Stepper/StepContainer';
import { StepContentStyleKey } from 'scenes/surveys/Campaign/helpers/steps';

import FormStep from './FormStep';
import AddStepButton from './components/AddStepButton';

type Props = {
  campaignId: string;
  formId: string;
  onUpdate: (params: SurveyCampaignUpdatableAttributes) => Promise<void>;
  goToNextStep: () => void;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    form: SurveyForm;
  };

const StepQuestions = ({
  campaignId,
  onUpdate,
  goToNextStep,
  form,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();

  const createFormStep = (contentType: StepContentStyleKey) =>
    dispatch(
      post(`survey/forms/${form.id}/form_steps`, {
        content: { contentType },
      })
    );

  const deleteFormStep = (formStep: SurveyFormStep) => {
    dispatch(del(`survey/forms/${form.id}/form_steps/${formStep.id}`));
  };

  const onChangeFormStepPosition = async (
    stepId: string,
    stepPosition: number
  ) =>
    await dispatch(
      put(`survey/forms/${form.id}/form_steps/${stepId}/position`, {
        position: stepPosition,
      })
    );
  const canPassStep = form?.steps.some(step => !!step.question);

  return (
    <StepContainer
      stepName="questions"
      onContinueClick={async () => {
        await onUpdate({ setupStepIndex: 2 });
        goToNextStep();
      }}
      canPassStep={canPassStep}
      disabledExplanation={
        canPassStep
          ? undefined
          : __(
              'The form must include at least one question (excluding titles).'
            )
      }
    >
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="alone"
        render={() => (
          <Fragment>
            <div className="flex justify-between">
              <Text preset="18bs5">{__('Questions')}</Text>
              <Button
                to={pathToSurveyCampaignPreview(campaignId)}
                openInNewTab
                color="secondary"
                testClassName="test-survey-edition-preview-button"
              >
                {__('Preview')}
                <Icon
                  size="tiny"
                  className="align-text-bottom ml-1"
                  name="open_in_new"
                />
              </Button>
            </div>
            {isEmpty(form.steps) ? (
              <EmptyStateWithIcon
                className="mb-0"
                iconName={'description'}
                title={__('Start creating your form')}
                description={__(
                  'Add a first question by clicking on the button below'
                )}
              />
            ) : (
              <DragAndDropContainer onChangePosition={onChangeFormStepPosition}>
                {form.steps.map(step => (
                  <DragAndDropItem
                    key={step.id}
                    itemId={step.id}
                    position={step.position}
                    handlePosition="top"
                  >
                    <FormStep
                      formStep={step}
                      formId={form.id}
                      onDelete={() => deleteFormStep(step)}
                    />
                  </DragAndDropItem>
                ))}
              </DragAndDropContainer>
            )}
            <AddStepButton onClick={createFormStep} />
          </Fragment>
        )}
      />
    </StepContainer>
  );
};

export default newDataLoader({
  fetch: ({ formId }: Props) => get(`survey/forms/${formId}`),
  hydrate: {
    form: {
      steps: {
        question: {},
        instruction: {},
      },
    },
  },
})(StepQuestions) as React.ComponentType<Props>;
