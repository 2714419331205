import moment from 'moment';
import React from 'react';

import type { ReviewCycle } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __, dateCalendar } from 'helpers/i18n';
import { pathToReviewCycles } from 'helpers/paths';

import { put } from 'redux/actions/api';

import {
  Column,
  Columns,
  Control,
  Field,
  Label,
  PageHeader,
  Testable,
  Text,
} from 'components';
import { NavigationItem } from 'components/navigation/Tabs/NavigationList';

import StatusTag from 'scenes/components/StatusTag';

import { UpdatableReviewCycleFields } from '../../Setup/Edit';
import HeaderActions from './Actions';
import AddParticipantsButton from './AddParticipantsButton';
import OneOnOneDeadlines from './OneOnOneReviewCycle/Deadlines';
import TagsBlock from './TagsBlock';
import ThreeSixtyDeadlines from './ThreeSixtyReviewCycle/Deadlines';

type Props = {
  reviewCycle: ReviewCycle;
  tabItems: NavigationItem[];
};

function Header({ reviewCycle, tabItems }: Props) {
  const readOnly = !can({ perform: 'update', on: reviewCycle });
  const tomorrow = moment().add(1, 'days');
  const dispatch = useAppDispatch();
  const onFieldChange = (attributes: Partial<UpdatableReviewCycleFields>) =>
    dispatch(
      put(
        `review_cycles/${reviewCycle.id}`,
        { reviewCycle: attributes },
        {
          errorMessage: __('This attribute must be defined.'),
        }
      )
    );

  return (
    <Testable name="test-header">
      <PageHeader
        children={
          <div className="flex items-center">
            <Text
              preset="24bs2"
              className="cycle-name"
              testClassName="test-review-cycle-title"
              isEditable={!readOnly}
              editableOptions={{
                type: 'singlelineText',
                onChange: name => {
                  return onFieldChange({
                    name,
                  });
                },
                value: reviewCycle.actualName,
                placeholder: __('Add a title to this campaign'),
              }}
            >
              {reviewCycle.name}
            </Text>
            <StatusTag status={reviewCycle.status} className="ml-2" />
          </div>
        }
        actions={<HeaderActions reviewCycle={reviewCycle} />}
        withBackButton
        backButtonProps={{
          target: pathToReviewCycles(reviewCycle.interactionType),
          children: __('Back'),
        }}
        description={
          <Columns
            isMobile
            className="header-fields-container mb-1 w-full max-w-4xl"
          >
            <Column mobileSize={6} style={{ flex: 'auto' }}>
              <Field className="header-field">
                <Label>{__('Launch date')}</Label>

                <Control>
                  <Text>
                    {dateCalendar(reviewCycle.launchedAt).toLowerCase()}
                  </Text>
                </Control>
              </Field>

              <Field className="header-field">
                <Label>{__('Number of participants')}</Label>

                <Control>
                  <AddParticipantsButton reviewCycle={reviewCycle} />
                </Control>
              </Field>
            </Column>

            <Column mobileSize={6} style={{ flex: 'auto' }}>
              {reviewCycle.interactionType === '360' ? (
                <ThreeSixtyDeadlines
                  reviewCycle={reviewCycle}
                  tomorrow={tomorrow}
                  onFieldChange={onFieldChange}
                  readOnly={readOnly}
                />
              ) : (
                <OneOnOneDeadlines
                  reviewCycle={reviewCycle}
                  tomorrow={tomorrow}
                  onFieldChange={onFieldChange}
                  readOnly={readOnly}
                />
              )}
            </Column>
            <Column mobileSize={6} style={{ flex: 'auto' }}>
              <Field className="header-field">
                <Label>{__('Related tags')}</Label>
                <TagsBlock
                  reviewCycle={reviewCycle}
                  onFieldChange={onFieldChange}
                  readOnly={readOnly}
                />
              </Field>
            </Column>
          </Columns>
        }
        tabItems={tabItems}
      />
    </Testable>
  );
}

export default Header;
