import React, { useState } from 'react';

import type {
  FeedbackableItem,
  FeedbackableSkillsDomain,
  SkillsCareerLevelExpectationBlock,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { useIsLargeScreen } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { Box, Icon, Link, Text } from 'components';

import RequiredQuestionWarning from '../../components/FeedbackZone/RequiredQuestionWarning';
import RatingSliderHeader from '../components/RatingSliderHeader';
import ExpectationFeedbackZone from './ExpectationFeedbackZone';
import ExpectationModal from './ExpectationModal';
import OverallDomainFeedbackZone from './OverallDomainFeedbackZone';

type Props = {
  feedbackableDomain: FeedbackableItem<FeedbackableSkillsDomain>;
  feedbackableItems: FeedbackableItem<SkillsCareerLevelExpectationBlock>[];
  persistAction: (
    evaluationId: string | null,
    feedbackableId: string,
    feedbackableType: string,
    text: string | undefined,
    rating: string | null | undefined
  ) => (dispatch: AppDispatch) => Promise<void>;
};

const DomainBlockContent = ({
  feedbackableDomain,
  feedbackableItems,
  persistAction,
}: Props) => {
  const ratingOptions = feedbackableItems[0].answers[0]?.ratings || [];
  const firstOpenedAnswer = feedbackableItems[0].answers.find(
    answer => answer.status === 'opened'
  );
  const ratingEnabled = feedbackableItems[0].answers.some(
    answer => answer.ratingEnabled
  );

  const ratingMandatory =
    firstOpenedAnswer && firstOpenedAnswer.ratingMandatory;
  const domainTitle = feedbackableItems[0].item.domain?.title;
  invariant(domainTitle, 'DomainTitle must be defined');
  const [expectationModalProps, setExpectationModalProps] = useState<{
    levelTitle: string;
    skillTitle: string;
    expectationContent: string;
  } | null>(null);
  const [ratingScaleDisplayed, setRatingScaleDisplayed] = useState(true);
  const isExpecationModalVisible = expectationModalProps !== null;
  const isLargeScreen = useIsLargeScreen();

  const closeExpectationModal = () => setExpectationModalProps(null);

  const toggleRatingDisplayLink = (
    <div className="flex justify-end flex-row mr-2 mb-4">
      <Link
        onClick={() => setRatingScaleDisplayed(!ratingScaleDisplayed)}
        additionalClassName="text-blue-elevo"
      >
        <Text preset="13s7">
          {ratingScaleDisplayed
            ? __('Hide rating scale')
            : __('Display rating scale')}
        </Text>
        <Icon
          name={ratingScaleDisplayed ? 'arrow_drop_up' : 'arrow_drop_down'}
          className="ml-2"
          style={{ fontSize: 20 }}
        />
      </Link>
    </div>
  );

  // On large screen, we have a display grid with:
  // - A line with the skill name (size 10) + a empty block on the right to handle the border (size 2)
  // - A line with the rating slider (size 10) + a checkbox (size 2)
  // On mobile, we have a simple flex-col with Selects instead of Slider and no checkboxes

  return (
    <Box
      className="block-content-wrapper"
      testClassName="test-skills-domain-block"
    >
      <div className="flex justify-between">
        <Text preset="18bs5" className="block mb-2">
          {domainTitle}
        </Text>
        {ratingMandatory && <RequiredQuestionWarning />}
      </div>

      {isLargeScreen && ratingEnabled && toggleRatingDisplayLink}
      <div className="flex flex-col lg:grid lg:grid-cols-12">
        {isLargeScreen && ratingEnabled && ratingScaleDisplayed && (
          <RatingSliderHeader ratingOptions={ratingOptions} className="mb-5" />
        )}

        {feedbackableItems.map(feedbackableItem => (
          <ExpectationFeedbackZone
            key={feedbackableItem.id}
            feedbackableItem={feedbackableItem}
            persistAction={persistAction}
            setExpectationModalProps={setExpectationModalProps}
          />
        ))}
      </div>

      <OverallDomainFeedbackZone
        feedbackableDomain={feedbackableDomain}
        persistAction={persistAction}
      />
      {isExpecationModalVisible && (
        <ExpectationModal
          domainTitle={domainTitle}
          onClose={closeExpectationModal}
          {...expectationModalProps}
        />
      )}
    </Box>
  );
};
export default DomainBlockContent;
