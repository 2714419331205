import React from 'react';

import { Button, ClickAwayListener, Icon, Text } from 'components';

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const RightSidebar = ({ title, isOpen, onClose, children }: Props) => (
  <ClickAwayListener onClickAway={onClose}>
    <div
      className={`sliding-sidebar__bar fixed h-full overflow-scroll top-0 right-0 min-w-96 w-1/3 bg-white ease-in-out duration-300 z-50 ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <div className="page-header flex justify-between sticky top-0 items-center min-h-14 px-5">
        <Text preset="18bs5">{title}</Text>

        <Button
          hasIconOnly
          onClick={onClose}
          className="border-none bg-transparent"
        >
          <Icon name="close" />
        </Button>
      </div>
      {isOpen && <div className="py-4 px-6">{children}</div>}
    </div>
  </ClickAwayListener>
);

export default RightSidebar;
