import React from 'react';

import classNames from 'helpers/classNames';
import invariant from 'helpers/invariant';

type Props = {
  progress?: number | null | undefined;
  className?: string;
};

const AnimatedProgressBar = ({ progress, className }: Props) => {
  const indeterminate = progress === null || progress === undefined;

  invariant(
    indeterminate || (progress >= 0 && progress <= 100),
    `Progress should be between 0 and 100, got ${progress}`
  );

  const progressionWidth = indeterminate ? undefined : progress + '%';

  return (
    <div className={classNames('upload-progress-bar-container', className)}>
      <div
        className={classNames('upload-progress-bar', {
          indeterminate,
        })}
        style={{
          width: progressionWidth,
        }}
      ></div>
    </div>
  );
};

export default AnimatedProgressBar;
