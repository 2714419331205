import React from 'react';

import { Box, InfoTooltip, Number, Text, Title } from 'components';

type SingleStatElement = {
  label: string;
  value: number | undefined | null;
  denominator?: number;
  percentage?: boolean;
  info?: React.ReactNode;
};

export type SimpleStats = Array<SingleStatElement>;

type Props = {
  title: string;
  description?: string;
  stats: SimpleStats;
};

export default function SimpleStatsBlock({ title, description, stats }: Props) {
  return (
    <Box className="stats-block">
      <Title size={5} className="stats-block-title">
        {title}
      </Title>

      <div className="flex flex-wrap stats-block-content my-3">
        {stats.map(stat => {
          return (
            <div key={stat.label} className="px-1">
              <Text
                size={7}
                weight="semibold"
                transformation="uppercase"
                color="grey"
              >
                {stat.label}
                {stat.info && (
                  <React.Fragment>
                    {' '}
                    <InfoTooltip>{stat.info}</InfoTooltip>
                  </React.Fragment>
                )}
              </Text>
              <Title size={3} weight="semibold" color="grey">
                <Number value={stat.value} isPercentage={stat.percentage} />
                {/* We assert on type in order not to cast 0 to false */}
                {stat.value !== null &&
                  typeof stat.denominator !== 'undefined' && (
                    <Text size={5}>{'/' + stat.denominator}</Text>
                  )}
              </Title>
            </div>
          );
        })}
      </div>

      {description && (
        <p className="stats-block-description">
          <Text size={7} color="dark-grey">
            {description}
          </Text>
        </p>
      )}
    </Box>
  );
}
