import React from 'react';

import { Box } from 'components';

import SimpleTable, { type Props } from './SimpleTable';

const SimpleBoxTable = <TRow,>({ className, ...props }: Props<TRow>) => {
  return (
    <Box className={className}>
      <SimpleTable className="-mx-4 -my-4" {...props} />
    </Box>
  );
};

export default SimpleBoxTable;
