import React from 'react';

import type { SuccessorBlock as SuccessorBlockModel } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import { Button } from 'components';
import { EmptyStateWithIcon } from 'components/emptyStates';

import QuestionBlock from 'scenes/components/review/QuestionBlock';

import FeedbackZone from '../FeedbackZone';
import SuccessorList from './SuccessorList';
import SuccessorModal from './SuccessorModal';

type Props = {
  reviewId: string;
  revieweeFullName: string;
  successorBlock: SuccessorBlockModel;
  canFillContent: boolean;
  isClosed: boolean;
};

const SuccessorBlock = ({
  successorBlock,
  canFillContent,
  isClosed,
  revieweeFullName,
  reviewId,
}: Props) => {
  const dispatch = useAppDispatch();
  const updateSuccessorComment = (
    successorComment: string | null | undefined
  ) => dispatch(put(`people_user_reviews/${reviewId}`, { successorComment }));

  const [isSuccessorModalActive, setIsSuccessorModalActive] =
    React.useState(false);

  const successorDescription = __(
    'Add the identified successors for %1 in order of preference',
    revieweeFullName
  );

  const hasSuccessors = successorBlock.peopleReviewSuccessors.length > 0;

  const successors = successorBlock.peopleReviewSuccessors;

  return (
    <QuestionBlock
      testClassName="test-successor-block"
      title={__('Identify one or more potential successors')}
      description={hasSuccessors ? successorDescription : ''}
    >
      {hasSuccessors ? (
        <SuccessorList
          successors={successors}
          reviewId={reviewId}
          isClosed={isClosed}
        />
      ) : (
        <EmptyStateWithIcon
          iconName="format_list_bulleted"
          title={successorDescription}
          description={''}
          inBox={false}
        />
      )}

      <div className="flex justify-end">
        <Button
          color="secondary"
          className="my-4"
          onClick={() => setIsSuccessorModalActive(true)}
          disabled={!canFillContent || isClosed}
          disabledExplanation={
            isClosed
              ? __('You cannot add a successor to a closed review')
              : __('You are not allowed to add a successor')
          }
        >
          {__('Add a successor')}
        </Button>
      </div>

      <FeedbackZone
        comment={successorBlock.comment}
        readOnly={isClosed || !canFillContent}
        persistAction={(_, comment) => updateSuccessorComment(comment)}
      />
      {isSuccessorModalActive && (
        <SuccessorModal
          onClose={() => setIsSuccessorModalActive(false)}
          reviewId={reviewId}
          revieweeFullName={revieweeFullName}
          successorIds={successors.map(s => s.user.id)}
        />
      )}
    </QuestionBlock>
  );
};

export default SuccessorBlock;
