import { Style, StyleRoot } from 'radium';
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { generalStyle } from 'styles';

import {
  DesignSystem,
  FlagsContainer,
  GlobalErrorBoundary,
  LoadingOverlay,
  Notice,
  PageTitle,
} from 'components';

import { Routes } from 'scenes/Routes';

import SessionInitializer from './SessionInitializer';
import { history } from './history';
import store from './store';

export default function Root() {
  return (
    <Provider store={store}>
      <GlobalErrorBoundary>
        <StyleRoot>
          <DesignSystem version={2}>
            <Style rules={generalStyle} />
            <PageTitle title="Elevo" />
            <SessionInitializer
              render={(isInitialized, session) => {
                if (!isInitialized) {
                  return <LoadingOverlay />;
                }
                return (
                  <React.Fragment>
                    <FlagsContainer session={session || null} />
                    <Notice />
                    <Router history={history}>
                      <Routes />
                    </Router>
                  </React.Fragment>
                );
              }}
            />
          </DesignSystem>
        </StyleRoot>
      </GlobalErrorBoundary>
    </Provider>
  );
}
