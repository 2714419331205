import React from 'react';

import classNames from 'helpers/classNames';

import ErrorBoundary from 'components/errors/ErrorBoundary';

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

/**
 * @deprecated as we're now using the ContentContainer component from the design system v2
 */

const DeprecatedContainerElevo = ({ children, style, className }: Props) => (
  <div className={classNames('container-elevo', className)} style={style}>
    <ErrorBoundary>{children}</ErrorBoundary>
  </div>
);

export default DeprecatedContainerElevo;
