import * as React from 'react';

import classNames from 'helpers/classNames';

import { ReadMore, SubTitle, Text, Title } from 'components';

import RichTextContent from './RichTextContent';

type Props = {
  id: string;
  section?: string | null | undefined;
  title?: string | null | undefined;
  description: string | null | undefined;
  withoutSectionSeparator?: boolean;
  testClassName?: string;
  withRichText?: boolean;
  withReadMore?: boolean;
};

export default function BlockDetails({
  id,
  section,
  title,
  description,
  withoutSectionSeparator,
  testClassName,
  withRichText,
  withReadMore = false,
}: Props) {
  return (
    <React.Fragment>
      {!withoutSectionSeparator && <div className="section-separator" />}

      <div
        className={classNames(
          'block-content-wrapper block-details',
          testClassName || 'test-block-details',
          { 'overflow-visible': withReadMore }
        )}
        id={id}
      >
        {section && (
          <Text transformation="uppercase" size={7} color="light" weight="bold">
            {section}
          </Text>
        )}
        {title && (
          <Title
            size={3}
            className="is-spaced block-title"
            testClassName="test-block-title"
            weight="bold"
            preserveLinebreaks
          >
            <RichTextContent content={title} />
          </Title>
        )}
        {!!description && (
          <SubTitle
            size={6}
            preserveLinebreaks
            className="block-description"
            testClassName="test-block-description"
          >
            {withReadMore ? (
              <ReadMore>
                <RichTextContent
                  content={description}
                  withRichText={withRichText}
                />
              </ReadMore>
            ) : (
              <RichTextContent
                content={description}
                withRichText={withRichText}
              />
            )}
          </SubTitle>
        )}
      </div>
    </React.Fragment>
  );
}
