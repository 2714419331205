import React from 'react';

import { AdminRoleName, Entity } from 'models';

import { Button, Icon } from 'components';

import { RolesWithEntitiesType } from '.';
import EntitySelect from './EntitySelect';
import RoleNameSelect from './RoleNameSelect';
import fakeAllUserEntity from './fakeAllUserEntity';

type Props = {
  selectedRoleName: AdminRoleName | null;
  selectedRolesWithEntities: RolesWithEntitiesType;
  roleOptions: Array<{ value: AdminRoleName; label: string }>;
  multiLevelAdminEnabled: boolean;
  showEmptySelect: boolean;
  setSelectedRolesWithEntities: (value: RolesWithEntitiesType) => void;
  setShowEmptySelect: (value: boolean) => void;
};

const RoleNameAndEntitySelects = ({
  selectedRoleName,
  roleOptions,
  multiLevelAdminEnabled,
  selectedRolesWithEntities,
  setSelectedRolesWithEntities,
  showEmptySelect,
  setShowEmptySelect,
}: Props) => {
  const removeRoleButtonEnabled =
    Object.keys(selectedRolesWithEntities).length > 1 ||
    (Object.keys(selectedRolesWithEntities).length === 1 && showEmptySelect);

  return (
    <div className="mt-2 flex items-center gap-2" key={selectedRoleName}>
      <RoleNameSelect
        value={selectedRoleName}
        options={roleOptions.map(option => ({
          ...option,
          isDisabled:
            Object.keys(selectedRolesWithEntities).includes(option.value) &&
            option.value !== selectedRoleName,
        }))}
        onChange={role => {
          if (role === selectedRoleName) return;

          const newGroupedRoles = {
            ...selectedRolesWithEntities,
            [role as AdminRoleName]: multiLevelAdminEnabled
              ? []
              : [fakeAllUserEntity()],
          };
          if (!!selectedRoleName) {
            delete newGroupedRoles[selectedRoleName];
          }
          setSelectedRolesWithEntities(newGroupedRoles);
          setShowEmptySelect(false);
        }}
      />
      {multiLevelAdminEnabled && (
        <EntitySelect
          disabled={!selectedRoleName}
          onChange={(entities: Entity[]) => {
            setSelectedRolesWithEntities({
              ...selectedRolesWithEntities,
              [selectedRoleName as AdminRoleName]: entities,
            });
          }}
          selectedEntities={
            selectedRoleName ? selectedRolesWithEntities[selectedRoleName] : []
          }
        />
      )}
      {removeRoleButtonEnabled && (
        <Button
          hasIconOnly
          className="border-none bg-transparent p-0"
          onClick={() => {
            if (selectedRoleName === null) {
              setShowEmptySelect(false);
              return;
            }

            const newGroupedRoles = {
              ...selectedRolesWithEntities,
            };
            if (!!selectedRoleName) {
              delete newGroupedRoles[selectedRoleName];
            }
            setSelectedRolesWithEntities(newGroupedRoles);
            if (Object.keys(newGroupedRoles).length > 0) {
              setShowEmptySelect(false);
            }
          }}
        >
          <Icon name={'delete'} />
        </Button>
      )}
    </div>
  );
};

export default RoleNameAndEntitySelects;
