import React, { useCallback, useEffect, useState } from 'react';

import type { AppDispatch } from 'redux/actions';

import currencies from 'helpers/currencies';
import { useAppDispatch, useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate, pathToTrainingPeriodRequests } from 'helpers/navigation';

import { withGenericErrorHandling } from 'lib/api/errorHandler';
import { genericFetch } from 'lib/api/genericFetch';
import { post } from 'redux/actions/api';

import {
  Button,
  Field,
  Icon,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Select,
  StrictlySanitizedHtml,
  Text,
  stringToOption,
} from 'components';

import { refetchTrainingPeriods } from './TrainingPeriodPicker';

type Props = {
  onClose: () => void;
};

const TrainingPeriodCreationModal = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();
  const organization = useCurrentOrganization();

  const createPeriod = async (name: string, mainCurrency: string) => {
    await dispatch(post('training/periods', { name, mainCurrency }));
    await dispatch(refetchTrainingPeriods);
    navigate(pathToTrainingPeriodRequests(name));
  };

  const getNextPeriodsName = useCallback(() => {
    const getData = () => (dispatch: AppDispatch) =>
      withGenericErrorHandling(
        genericFetch({ method: 'GET', url: 'training/periods/next' })
      )(dispatch).then(response => ({ response }));
    return dispatch(getData());
  }, [dispatch]);

  const [nextPeriodNames, setNextPeriodNames] = useState<Array<string>>([]);
  const [selectedPeriodName, setSelectedPeriodName] = useState<string | null>(
    null
  );
  const [selectedCurrency, setSelectedCurrency] = useState<string>('EUR');

  const trainingCurrenciesEnabled =
    organization.featureFlags.includes('trainingCurrencies');

  useEffect(() => {
    const setInitialState = async () => {
      const { response } = await getNextPeriodsName();
      setNextPeriodNames(response.body);
      setSelectedPeriodName(response.body[0]);
    };

    setInitialState();
  }, [getNextPeriodsName]);

  const onCreate = () => {
    invariant(selectedPeriodName, 'Period name must exist at this point');
    createPeriod(selectedPeriodName, selectedCurrency);

    onClose();
  };

  const isButtonDisabled = trainingCurrenciesEnabled
    ? !selectedPeriodName || !selectedCurrency
    : !selectedPeriodName;

  return (
    <ModalCard
      refreshContentOnOpening
      isActive
      onClose={onClose}
      isLarge={trainingCurrenciesEnabled}
    >
      <div className="training-period-creation-modal">
        <ModalCardHead onClose={onClose}>
          <ModalCardTitle>{__('Create a new period')}</ModalCardTitle>
        </ModalCardHead>
        <ModalCardBody overflowVisible>
          <Field>
            {trainingCurrenciesEnabled ? (
              <Text color="light">
                <StrictlySanitizedHtml
                  html={__(
                    'This new period and its currency will be common to <b>all administrators.</b>'
                  )}
                />
              </Text>
            ) : (
              <Text>
                {__(
                  'This new period will be shared with all administrators. Once created, managers and administrators will be able to create training requests by selecting this period.'
                )}
              </Text>
            )}
          </Field>
          <Field>
            <Label>{__('Choose a period')}</Label>

            <Select
              value={stringToOption(selectedPeriodName || '')}
              options={nextPeriodNames.map(stringToOption)}
              onChange={option => {
                invariant(
                  option && !Array.isArray(option),
                  'Option must exist and cannot be an array'
                );
                setSelectedPeriodName(option.value);
              }}
              isMulti={false}
              isClearable={false}
              inModal
            />
          </Field>
          {trainingCurrenciesEnabled && (
            <Field>
              <Label>{__("Your budget's main currency")}</Label>

              <Field>
                <Text color="light">
                  {__(
                    'Your budget will be defined in this currency. You can define other currencies and exchange rates to be used when defining your budget.'
                  )}
                </Text>
              </Field>

              <Select
                value={stringToOption(selectedCurrency || '')}
                options={currencies.map(stringToOption)}
                onChange={option => {
                  invariant(
                    option && !Array.isArray(option),
                    'Option must exist and cannot be an array'
                  );
                  setSelectedCurrency(option.value);
                }}
                isMulti={false}
                isClearable={false}
                inModal
              />
            </Field>
          )}
        </ModalCardBody>
        <ModalCardFooter>
          <div style={{ marginTop: 16 }}>
            <Button onClick={onClose} color="secondary">
              {__('Cancel')}
            </Button>
            <Button
              onClick={onCreate}
              color="primary"
              disabled={isButtonDisabled}
            >
              <Icon style={{ marginRight: 8 }} name="add" />
              {__('Create period')}
            </Button>
          </div>
        </ModalCardFooter>
      </div>
    </ModalCard>
  );
};

export default TrainingPeriodCreationModal;
