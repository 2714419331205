import React from 'react';

import type { User, UserCareer } from 'models';

import { assert } from 'helpers/invariant';

import { Box } from 'components';

import MatrixUnpublishedEmptyState from 'scenes/components/skills/MatrixUnpublishedEmptyState';

import EmptyState from './EmptyState';
import ExpectationsSummary from './ExpectationsSummary';
import Header from './Header';

type Props = {
  user: User;
  userCareer: UserCareer;
};

const CurrentMatrixVisualizerBox = ({ user, userCareer }: Props) => {
  const skillsLevel = userCareer.currentCareerLevel?.skillsLevel;

  if (!userCareer.showCurrentExpectations)
    return <MatrixUnpublishedEmptyState />;

  return (
    <Box className="p-4 pb-5 pt-5 current-matrix-visualizer-box">
      {!skillsLevel ? (
        <EmptyState user={user} />
      ) : (
        <React.Fragment>
          <Header currentLevel={skillsLevel} />

          <ExpectationsSummary
            currentLevelTitle={skillsLevel.title}
            expectationMappings={assert(
              userCareer.currentExpectationMappings,
              'mappings must be defined'
            )}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

export default CurrentMatrixVisualizerBox;
