import React, { Fragment } from 'react';

import type { PeopleGroup } from 'models';

import classNames from 'helpers/classNames';
import { useAppDispatch } from 'helpers/hooks';
import { usePollingWithAsyncAction } from 'helpers/hooks/usePollingWithAsyncAction';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { del, get } from 'redux/actions/api';

import {
  Box,
  Flex,
  HamburgerMenu,
  Icon,
  MenuItem,
  MenuList,
  Placeholder,
  RawHtml,
  Text,
  Title,
  Tooltip,
} from 'components';

import UpdatePeopleGroupModal from './PeopleGroupModal/UpdatePeopleGroupModal';

type Props = {
  peopleGroup: PeopleGroup;
  isSelected: boolean;
  canEditPeopleGroups: boolean;
  togglePeopleGroupSelection: () => void;
  refetchData: () => Promise<any>;
  setShouldRefetchReviews: (shouldRefetch: boolean) => void;
};

function PeopleGroupListItem({
  peopleGroup,
  isSelected,
  canEditPeopleGroups,
  togglePeopleGroupSelection,
  refetchData,
  setShouldRefetchReviews,
}: Props) {
  const dispatch = useAppDispatch();
  const refreshPeopleGroup = () =>
    dispatch(get(`people_groups/${peopleGroup.id}`));
  const deletePeopleGroup = () =>
    dispatch(del(`people_groups/${peopleGroup.id}`));
  const { actionIsOngoing, pollerComponent } = usePollingWithAsyncAction(
    peopleGroup.lastPendingComputationAction,
    async () => {
      setShouldRefetchReviews(true);
      refreshPeopleGroup();
    }
  );

  const [showUpdatePeopleGroupModal, setShowUpdatePeopleGroupModal] =
    React.useState(false);

  const confirmDeletePeopleGroup = () =>
    confirmAsync(
      __('Delete the group?'),
      __('This action will delete the group %1.', peopleGroup.title),
      {
        confirmLabel: __('Delete the group'),
        isDanger: true,
        onConfirm: async () => {
          await deletePeopleGroup();
          if (isSelected) togglePeopleGroupSelection();
          setShouldRefetchReviews(true);
          refetchData();
        },
      }
    );

  return (
    <Fragment>
      <Box
        size="small"
        className={classNames('people-group', {
          'is-selected': isSelected,
        })}
        testClassName="test-people-group-item"
        onClick={togglePeopleGroupSelection}
      >
        {pollerComponent}
        {actionIsOngoing ? (
          <Placeholder width={25} />
        ) : (
          <div className="flex justify-between items-center">
            <Title size={3} weight="semibold" className="mr-4">
              {peopleGroup.peopleUserReviewCount}
            </Title>
            {canEditPeopleGroups && (
              <HamburgerMenu
                onClose={e => {
                  if (e) e.stopPropagation();
                }}
              >
                <MenuList>
                  <MenuItem
                    key="update"
                    onClick={() => {
                      setShowUpdatePeopleGroupModal(true);
                    }}
                  >
                    <Text>{__('Update')}</Text>
                  </MenuItem>
                  <MenuItem
                    key="delete"
                    onClick={() => {
                      confirmDeletePeopleGroup();
                    }}
                  >
                    <Text color="danger">{__('Delete')}</Text>
                  </MenuItem>
                </MenuList>
              </HamburgerMenu>
            )}
          </div>
        )}

        <Flex style={{ marginTop: 4 }}>
          <div className="mr-2 max-w-[164px] is-text-overflow-ellipsis">
            {peopleGroup.title}
          </div>
          <Tooltip
            content={<RawHtml html={peopleGroup.description} />}
            placement="bottom"
            style={{ maxWidth: '400px' }}
          >
            <Icon name="info" />
          </Tooltip>
        </Flex>
      </Box>
      {showUpdatePeopleGroupModal && (
        <UpdatePeopleGroupModal
          peopleGroup={peopleGroup}
          onClose={() => setShowUpdatePeopleGroupModal(false)}
        />
      )}
    </Fragment>
  );
}

export default PeopleGroupListItem;
