import React from 'react';

import type { ValidatedFundingSourceStats } from 'models';

import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { CompletionProgressBar, Flex, Text } from 'components';

type Props = {
  stats: ValidatedFundingSourceStats;
};

const FundingSourceDetails = ({ stats }: Props) => {
  const { budgetItem, validatedAmountCents, title, currency } = stats;

  const { provisionedAmountCents } = budgetItem;
  const formattedValidatedAmount = formatMoney(validatedAmountCents, currency);

  const completion =
    provisionedAmountCents &&
    validatedAmountCents &&
    (validatedAmountCents * 100) / provisionedAmountCents;

  const renderBudget = () => {
    if (provisionedAmountCents) {
      return (
        <Flex verticalAlign className="no-shrink">
          <Text preset="14bs6" testClassName="test-budget-item-spent-amount">
            {formattedValidatedAmount}
          </Text>

          <Text
            preset="13s7"
            color="light"
            testClassName="test-budget-item-amount"
          >
            &nbsp;
            {__('of')}
            &nbsp;
            {formatMoney(provisionedAmountCents, currency)}
          </Text>
        </Flex>
      );
    }

    return (
      <Flex verticalAlign className="no-shrink">
        <Text color="light" className="no-shrink">
          {__('Budget spent:')}
          &nbsp;
        </Text>
        <Text preset="13bs7" testClassName="test-budget-item-spent-amount">
          {formattedValidatedAmount}
        </Text>
      </Flex>
    );
  };

  return (
    <div className="budget-details-modal-funding-source test-budget-item">
      <Flex verticalAlign className="funding-source-details">
        <Text preset="13bs7" className="funding-source-title">
          {title}
        </Text>

        {renderBudget()}
      </Flex>

      {provisionedAmountCents && (
        <CompletionProgressBar completion={completion} />
      )}
    </div>
  );
};

export default FundingSourceDetails;
