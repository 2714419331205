import React from 'react';

import type { ENPSPeriod } from 'models';

import { __ } from 'helpers/i18n';

import {
  Box,
  DeprecatedPageHeader,
  DeprecatedPageHeaderTitle,
  Icon,
  PageTitle,
  StrictlySanitizedHtml,
  Text,
} from 'components';

type Props = {
  enpsPeriod: ENPSPeriod;
};

const InactivePeriod = ({ enpsPeriod }: Props) => {
  return (
    <div className="m-4 md:m-8">
      <div className="max-w-[960px] mx-auto">
        <PageTitle title={__('eNPS')} />
        <DeprecatedPageHeader style={{ margin: 0, marginBottom: 24 }}>
          <DeprecatedPageHeaderTitle style={{ padding: 0 }}>
            {__('eNPS')}
          </DeprecatedPageHeaderTitle>
        </DeprecatedPageHeader>

        <Box className="flex flex-col items-center">
          <div className="w-12 h-12 mb-4 bg-gray-200 rounded-full flex justify-center items-center">
            <Icon name="volunteer_activism" />
          </div>

          <Text preset="14bs6">{__('No eNPS in progress')}</Text>
          <Text preset="13s7" className="my-2">
            {__(
              'The %1 period is closed, thank you for answering',
              enpsPeriod.name
            )}
          </Text>
          <Text preset="13s7">
            <StrictlySanitizedHtml
              html={__(
                'The next period, %1, will be launched on <b>%2</b>',
                enpsPeriod.nextPeriodExpectedName,
                enpsPeriod.nextPeriodExpectedStartDate
              )}
            />
          </Text>
        </Box>
      </div>
    </div>
  );
};

export default InactivePeriod;
