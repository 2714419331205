import React, { Fragment } from 'react';

import { TrainingCourse } from 'models/TrainingCourse';

import { useAppDispatch } from 'helpers/hooks';
import { useForm } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';
import {
  navigate,
  pathToTrainingCourse,
  pathToTrainingCourses,
} from 'helpers/navigation';

import { successNotice } from 'redux/actions';
import { post } from 'redux/actions/api';

import {
  ContentContainer,
  PageHeader,
  UnsavedModificationsGuard,
} from 'components';

import CourseFormDetails from './Form/CourseFormDetails';

const CreateCourseForm = () => {
  const dispatch = useAppDispatch();

  const { values, errors, handleInputChange, handleSubmit, isDirty } = useForm<
    Partial<TrainingCourse>
  >(
    {
      name: '',
      organism: undefined,
      availableLanguages: [],
      content: '<div></div>',
    },
    async values => {
      const { organism, ...rest } = values;
      const params = {
        ...rest,
        organism_id: organism?.id || null,
      };
      const response = await dispatch(post('training/courses', params));
      await dispatch(
        successNotice({
          __html: __('<b>The training course was successfully created.</b>'),
        })
      );
      navigate(pathToTrainingCourse(response.response.body.data.id));
    }
  );

  return (
    <Fragment>
      <PageHeader
        title={__('New training course')}
        withBackButton
        backButtonProps={{
          target: pathToTrainingCourses(),
          children: __('Back to training courses'),
        }}
      />
      <ContentContainer>
        <CourseFormDetails
          trainingCourse={values}
          handleInputChange={handleInputChange}
          saveButtonLabel={__('Create')}
          errors={errors}
          isDirty={isDirty}
          onSave={handleSubmit}
        />
      </ContentContainer>
      <UnsavedModificationsGuard isDirty={isDirty} onSubmit={handleSubmit} />
    </Fragment>
  );
};

export default CreateCourseForm;
