import { flatten } from 'lodash';
import React, { useState } from 'react';

import { handleFormErrors } from 'helpers/api';
import { useAppDispatch } from 'helpers/hooks';

import { post, put } from 'redux/actions/api';

import { FieldError } from 'components';

import { useFieldStatusResetWhenLoaded } from './useFieldStatusResetWhenLoaded';

type Props<Parent> = {
  refetchData: () => void;
  parent: Parent;
  parentProps: {
    name: string;
    endpoint: string;
    errorField: string;
  };
  childProps: {
    name: string;
    endpoint: string;
    errorField: string;
  };
};

type ErrorsType = {
  [key: string]: any;
};

type CommonProps<Parent> = {
  parent: Parent;
  fieldErrorsFor: (
    item: string,
    id?: string,
    field?: string
  ) => React.ReactNode;
  updateErrors: (formatter: (errors: ErrorsType) => ErrorsType) => void;
  onParentUpdate: (
    parent: Parent,
    afterParentUpdate?: () => void
  ) => Promise<void>;
};

export type HeaderHandlingProps<Parent> = CommonProps<Parent>;

export type EditorHandlingProps<Parent, Child> = CommonProps<Parent> & {
  newCreatedChildId: string | null;
  onChildUpdate: (child: Child) => Promise<void>;
  onChildCreate: (position: number) => void;
};

type ReturnProps<Parent, Child> = {
  headerProps: HeaderHandlingProps<Parent>;
  editorProps: EditorHandlingProps<Parent, Child>;
};

interface ContainingID {
  id: string;
}

export default function useParentAndChildrenHandlingWithErrors<
  Parent extends ContainingID,
  Child extends ContainingID
>({
  refetchData,
  parent,
  parentProps,
  childProps,
}: Props<Parent>): ReturnProps<Parent, Child> {
  type HandleUpdateProps = {
    endpoint: string;
    params: Object;
    afterUpdate: () => void;
    setErrors: (errors: ErrorsType) => void;
  };

  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<ErrorsType>({});
  const [newCreatedChildId, setNewCreatedChildId] = useState(null);

  const handleUpdate = ({
    endpoint,
    params,
    afterUpdate,
    setErrors,
  }: HandleUpdateProps) =>
    handleFormErrors(
      async () => {
        await dispatch(put(endpoint, params));
        afterUpdate();
      },
      setErrors,
      true
    );

  const onChildCreate = async (position: number) => {
    const { response } = await dispatch(
      post(childProps.endpoint, {
        position,
        [parentProps.name + '_id']: parent.id,
      })
    );
    setNewCreatedChildId(response.body.data.id);
    setErrors({});

    refetchData();
  };

  const updateErrorsForParent = (newErrors: ErrorsType) =>
    setErrors({ ...errors, [parentProps.errorField]: newErrors });
  const updateErrorsForChild = (id: string, newErrors: ErrorsType) =>
    setErrors({
      ...errors,
      [childProps.errorField]: {
        ...errors?.[childProps.errorField],
        [id]: newErrors,
      },
    });

  const onParentUpdate = (parent: Parent, afterParentUpdate?: () => void) =>
    handleUpdate({
      endpoint: `${parentProps.endpoint}/${parent.id}`,
      params: { [parentProps.name]: { ...parent } },
      afterUpdate: () => {
        afterParentUpdate && afterParentUpdate();
        updateErrorsForParent({});
      },
      setErrors: newErrors => updateErrorsForParent(newErrors),
    });

  const onChildUpdate = (child: Child) =>
    handleUpdate({
      endpoint: `${childProps.endpoint}/${child.id}`,
      params: { [childProps.name]: child },
      afterUpdate: () => updateErrorsForChild(child.id, {}),
      setErrors: newErrors => updateErrorsForChild(child.id, newErrors),
    });

  const fieldErrorsFor = (item: string, id?: string, field?: string) => {
    const itemErrors = !!id ? errors?.[item]?.[id] : errors?.[item];
    const errorsToDisplay = !!field ? itemErrors?.[field] : itemErrors;

    if (!errorsToDisplay) return null;

    return (
      <div className="errors">
        {flatten(Object.values(errorsToDisplay)).map((error, idx) => (
          <FieldError key={`error-${item}-${id}-${idx}`} className="is-bold">
            {error}
          </FieldError>
        ))}
      </div>
    );
  };

  useFieldStatusResetWhenLoaded({
    parentName: parentProps.name,
    childName: childProps.name,
  });

  const updateErrors = (formatter: (errors: ErrorsType) => ErrorsType) =>
    setErrors(formatter(errors));

  const baseProps: CommonProps<Parent> = {
    parent,
    updateErrors,
    onParentUpdate,
    fieldErrorsFor,
  };

  return {
    headerProps: baseProps,
    editorProps: {
      ...baseProps,
      newCreatedChildId,
      onChildUpdate,
      onChildCreate,
    },
  };
}
