import React from 'react';

import type { KeyResult } from 'models';

import { __ } from 'helpers/i18n';

import { Column, Columns, Title } from 'components';

import { Completion, Weight } from '../../';
import { Actions, IsCompletedCheckbox, Measures } from './components';

type Props = {
  keyResult: KeyResult;
  disabled: boolean;
  weighted: boolean;
  weightSumWarning: string | null | undefined;

  onChange: (
    keyResultId: string,
    attributes: Partial<KeyResult>
  ) => Promise<void>;
  onDestroy: (keyResultId: string) => Promise<unknown>;
};

export default function KeyResultItem({
  keyResult,
  disabled,
  weighted,
  weightSumWarning,
  onChange,
  onDestroy,
}: Props) {
  const [addingMeasures, setAddingMeasures] = React.useState<boolean>(false);

  const {
    id,
    actualTitle,
    title,
    startValue,
    currentValue,
    targetValue,
    completion,
    completionMismatchWarning,
    weight,
    isCompleted,
    weightWarning,
    justCreated,
  } = keyResult;

  const hasMeasure = !!(startValue || currentValue || targetValue);

  return (
    <div className="test-key-result">
      <Columns isMobile contentVerticallyAligned style={{ marginBottom: 8 }}>
        <Column isNarrow className="completed-checkbox">
          <IsCompletedCheckbox
            disabled={disabled}
            onChange={newIsCompleted =>
              onChange(id, { isCompleted: newIsCompleted })
            }
            isCompleted={isCompleted}
            completion={completion}
          />
        </Column>
        <Column>
          <Title
            size={6}
            isEditable={!disabled}
            weight="semibold"
            editableOptions={{
              type: 'multilineText',
              value: actualTitle,
              disabledValue: title,
              onChange: newTitle => onChange(id, { title: newTitle }),
              withEditIcon: false,
              autoFocus: justCreated && !actualTitle,
              placeholder: __('Add a success criteria'),
              fieldProps: {
                lineBreakHelperStyle: 'float',
              },
            }}
            className="key-result-title"
            testClassName="test-key-result-title-field"
          />
        </Column>
        <Column isNarrow className="key-result-completion-container">
          <Completion
            disabled={disabled}
            completion={completion}
            completionMismatchWarning={completionMismatchWarning}
            onChange={newCompletion =>
              onChange(id, { completion: newCompletion })
            }
          />
        </Column>
        <Column isNarrow>
          <Actions
            disabled={disabled}
            hasMeasure={hasMeasure}
            onRemoveMeasure={() =>
              onChange(id, {
                startValue: '',
                currentValue: '',
                targetValue: '',
              })
            }
            onAddMeasures={() => setAddingMeasures(true)}
            onDestroy={() => onDestroy(id)}
          />
        </Column>
      </Columns>
      <Columns className="key-result-weight-measures-container">
        {weighted && (
          <Column isVerticallyCentered>
            <Weight
              disabled={disabled}
              weight={weight}
              weightWarning={weightWarning || weightSumWarning}
              onChange={newWeight => onChange(id, { weight: newWeight })}
            />
          </Column>
        )}
        {(hasMeasure || addingMeasures) && (
          <Column isNarrow>
            <Measures
              disabled={disabled}
              startValue={startValue}
              currentValue={currentValue}
              targetValue={targetValue}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={addingMeasures}
              onBlur={() => setAddingMeasures(false)}
              onUpdate={attributes => onChange(id, attributes)}
            />
          </Column>
        )}
      </Columns>
    </div>
  );
}
