import React, { CSSProperties, MouseEventHandler } from 'react';

import classNames from 'helpers/classNames';

import materialIconMapping from './materialIconMapping';

export type MaterialIconName = keyof typeof materialIconMapping;
export type Props = {
  name: MaterialIconName;
  style?: CSSProperties;
  className?: string;
  testClassName?: string;
  filled?: boolean;
  color?: string;
  isSpinning?: boolean;
  onClick?: MouseEventHandler<HTMLSpanElement>;

  /** @deprecated Deprecated prop from Design System v1 */
  size?: any;
  /** @deprecated Deprecated prop from Design System v1 */
  iconStyle?: any;
  /** @deprecated Deprecated prop from Design System v1 */
  isInfo?: any;
};

export default function Icon(props: Props) {
  const { style, className, testClassName, name, color, filled, isSpinning } =
    props;

  const colorClassName = classNames({
    'has-text-primary': color && color === 'primary',
    'has-text-info': color && color === 'info',
    'has-text-danger': color && color === 'danger',
    'has-text-warning': color && color === 'warning',
    'has-text-success': color && color === 'success',
    'animate-[spin_2s_linear_reverse_infinite]': isSpinning,
  });

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <i
      style={{
        ...style,
        fontVariationSettings: `'FILL' ${filled ? 1 : 0}`,
      }}
      className={classNames(
        'material-icons',
        colorClassName,
        className,
        testClassName
      )}
      onClick={props.onClick}
    >
      {name}
    </i>
  );
}
