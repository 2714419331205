import React from 'react';

import { BaseTrainingOrganism } from 'models/TrainingOrganism';

import { Text } from 'components';

type Props = {
  organism: BaseTrainingOrganism;
};

const OrganismWithAvatar = ({ organism }: Props) => {
  return (
    <div className="flex gap-2 items-center">
      <img
        src={organism.logoUrl}
        alt={organism.name}
        className="rounded-full w-8 h-8"
      />
      <Text preset="14s6">{organism.name}</Text>
    </div>
  );
};

export default OrganismWithAvatar;
