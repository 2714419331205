import React, { useContext, useState } from 'react';
import LazyLoad from 'react-lazyload';

import type { DropdownQuestionBlock, UserFilterSegment } from 'models';

import QuestionBlockComponent from 'scenes/components/review/QuestionBlock';

import { UserFiltersContext } from '..';
import AnswersExplorer from '../AnswersExplorer';
import FeedbackPerOptionModal from '../FeedbackPerOptionModal';
import QuestionPieChart from '../QuestionPieChart';

type Props = {
  block: DropdownQuestionBlock;
  reviewCycleId: string;
  userFilter: UserFilterSegment | undefined | null;
};

const DropdownQuestionBlockResults = ({
  block,
  reviewCycleId,
  userFilter,
}: Props) => {
  const { userFilters } = useContext(UserFiltersContext);
  const [lastLegendSelected, setLastLegendSelected] = useState<string | null>(
    null
  );
  const [isModalActive, setIsModalActive] = useState(false);
  const [modalDefaultFilter, setModalDefaultFilter] = useState<{
    [key: string]: boolean;
  }>({
    all: true,
  });

  const toggleModal = () => setIsModalActive(!isModalActive);

  const onAnswerDetails = (
    filter: {
      [filter: string]: boolean;
    },
    label: string | null
  ) => {
    setModalDefaultFilter(filter);
    setLastLegendSelected(label);
    toggleModal();
  };

  const { id, content, revieweeFeedbackOptions, reviewerFeedbackOptions } =
    block;
  const { title, description, richTextEnabled } = content;

  const revieweeRatingEnabled = revieweeFeedbackOptions?.ratingEnabled;
  const revieweeTextEnabled = revieweeFeedbackOptions?.textEnabled;

  return (
    <QuestionBlockComponent
      title={title}
      description={description}
      testClassName="test-dropdown-question-block"
      withRichText={richTextEnabled}
    >
      {(revieweeRatingEnabled || reviewerFeedbackOptions.ratingEnabled) && (
        <LazyLoad height={400} offset={200}>
          <QuestionPieChart
            blockId={id}
            reviewCycleId={reviewCycleId}
            userFilter={userFilter}
            userFilters={userFilters}
            onAnswerDetails={onAnswerDetails}
          />
        </LazyLoad>
      )}

      {(revieweeTextEnabled || reviewerFeedbackOptions.textEnabled) && (
        <LazyLoad height={650} offset={200}>
          <AnswersExplorer
            block={block}
            reviewCycleId={reviewCycleId}
            userFilter={userFilter}
            userFilters={userFilters}
          />
        </LazyLoad>
      )}
      {isModalActive && (
        <FeedbackPerOptionModal
          reviewCycleId={reviewCycleId}
          block={block}
          userFilter={userFilter}
          defaultFilter={modalDefaultFilter}
          option={lastLegendSelected}
          onClose={toggleModal}
        />
      )}
    </QuestionBlockComponent>
  );
};

export default DropdownQuestionBlockResults;
