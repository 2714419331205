import React, { UIEvent } from 'react';

import type { StyleProps as ButtonStyleProps } from 'components/buttons/Button';

import colors from 'styles/colors';

import { Button, Dropdown, DropdownTrigger, Icon, Tooltip } from 'components';

import { MenuList } from './index';

type Props = ButtonStyleProps & {
  disabled?: boolean;
  disabledMessage?: string;
  align?: 'right' | 'left';
  className?: string;
  testClassName?: string;
  children?: React.ReactElement<React.ComponentProps<typeof MenuList>>;
  onClose?: (e: KeyboardEvent | UIEvent | undefined) => void;
};

export default function HamburgerMenu({
  children,
  disabled,
  disabledMessage,
  align,
  className,
  testClassName,
  onClose,
}: Props) {
  const child = !!children ? React.Children.only(children) : children;

  return (
    <Dropdown onClose={onClose} align={align || 'right'} disabled={disabled}>
      <DropdownTrigger>
        <Tooltip content={disabled && disabledMessage}>
          <HamburgerMenuButton
            disabled={disabled}
            className={className}
            testClassName={testClassName}
          />
        </Tooltip>
      </DropdownTrigger>
      {child}
    </Dropdown>
  );
}

type HamburgerMenuButtonProps = ButtonStyleProps & {
  disabled?: boolean;
  dropdownIsOpened?: boolean;
  className?: string;
  testClassName?: string;
};

const HamburgerMenuButton = ({
  disabled,
  className,
  testClassName,
}: HamburgerMenuButtonProps) => {
  return (
    <Button
      color="secondary"
      hasIconOnly
      disabled={disabled}
      className={className}
      testClassName={testClassName}
    >
      <Icon color={colors.primaryColor} name="more_vert" />
    </Button>
  );
};
