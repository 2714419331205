import React, { Fragment } from 'react';

import { PeopleUserReviewPlannedAction, PlannedActionStatus } from 'models';

import can from 'helpers/can';
import {
  dateFromDefaultDateString,
  dateToDefaultDateTimeStringFormat,
} from 'helpers/date';
import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  Control,
  DatePicker,
  Field,
  FieldError,
  Flex,
  Helper,
  Input,
  Label,
  Textarea,
} from 'components';

import TagPicker from 'scenes/components/TagPicker';
import StatusPicker from 'scenes/components/plannedActions/StatusPicker';

type Props = {
  plannedActionObject: Partial<PeopleUserReviewPlannedAction>;
  setPlannedActionObject: (
    plannedAction: Partial<PeopleUserReviewPlannedAction>
  ) => void;
  errors: Partial<PeopleUserReviewPlannedAction>;
};

const PeopleReviewActionForm = ({
  plannedActionObject,
  setPlannedActionObject,
  errors,
}: Props) => {
  const organization = useCurrentOrganization();

  return (
    <Fragment>
      <Field>
        <Label>{__('Action title')}</Label>
        <Input
          value={plannedActionObject.title}
          placeholder={__(
            'Give a title to the action (e.g. Promotion following the people review...)'
          )}
          onChange={title =>
            setPlannedActionObject({
              ...plannedActionObject,
              title,
            })
          }
        />
        <FieldError>{errors.title}</FieldError>
      </Field>
      <Field>
        <Label>{__('Type')}</Label>
        <TagPicker
          context="planned_action"
          selectedTags={plannedActionObject.tags || []}
          onTagsChange={tags => {
            setPlannedActionObject({
              ...plannedActionObject,
              tags,
            });
          }}
          preventCreation={
            !can({
              perform: 'create_people_review_action_type_tags',
              on: organization,
            })
          }
        />
      </Field>

      <Field>
        <Label>{__('Description')}</Label>
        <Textarea
          value={plannedActionObject.description}
          placeholder={__(
            "What is the purpose of this action? Why is it important for the employee's development?"
          )}
          rows={5}
          maxRows={10}
          onChange={description =>
            setPlannedActionObject({
              ...plannedActionObject,
              description,
            })
          }
        />
        <FieldError>{errors.description}</FieldError>
      </Field>
      <Field className="date-picker">
        <Label>{__('Due date')}</Label>
        <Flex>
          <Control
            isClearable={!!plannedActionObject.dueDate}
            onClear={() =>
              setPlannedActionObject({
                ...plannedActionObject,
                dueDate: null,
              })
            }
          >
            <DatePicker
              value={
                plannedActionObject.dueDate
                  ? dateFromDefaultDateString(plannedActionObject.dueDate)
                  : null
              }
              onChange={dueDate =>
                setPlannedActionObject({
                  ...plannedActionObject,
                  dueDate: dueDate
                    ? dateToDefaultDateTimeStringFormat(dueDate)
                    : null,
                })
              }
              placeholder={__('Select a date')}
              popperPlacement="right-end"
            />
          </Control>
        </Flex>
        <FieldError>{errors.dueDate}</FieldError>
      </Field>
      <Field>
        <Label>{__('Status')}</Label>
        <StatusPicker
          value={plannedActionObject.status as PlannedActionStatus}
          onChange={status =>
            setPlannedActionObject({
              ...plannedActionObject,
              status,
            })
          }
        />
        <FieldError>{errors.status}</FieldError>
      </Field>
      {!!plannedActionObject.dueDate && (
        <Helper>
          {__(
            'A reminder will be sent to you by email in the week preceding the due date.'
          )}
        </Helper>
      )}
    </Fragment>
  );
};

export default PeopleReviewActionForm;
