import React, { useContext } from 'react';

import { Column, Columns } from 'components';

import { DataContext } from '../..';
import UserSyncState from './UserSyncState';

const LastSyncStateCard = () => {
  const { user } = useContext(DataContext);

  return (
    <Columns className="field-section">
      <Column size={6}>
        <hr />
        <UserSyncState userSync={user.lastUserSync} />
      </Column>
    </Columns>
  );
};

export default LastSyncStateCard;
