import React from 'react';

import type {
  InstructionsBlock,
  ReviewBlockUpdateParams,
} from 'models/ReviewStructure';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import {
  Box,
  BoxSeparator,
  Flex,
  Helper,
  Text,
  Title6,
  WithSavingStatusRecorder,
} from 'components';

import reviewTemplateVariables from 'scenes/admin/reviewTemplates/Template/Edit/Structure/reviewTemplateVariables';

import BlockActions from './components/BlockActions';

type Props = {
  block: InstructionsBlock;
  onChange: (params: ReviewBlockUpdateParams) => Promise<void>;
  justEnteredInList: boolean;
  onDelete?: () => Promise<void>;
  onMoveUp: () => Promise<void>;
  onMoveDown: () => Promise<void>;
  onDuplicate: () => Promise<void>;
};

const MAX_DESCRIPTION_LENGTH = 2500;

const InstructionsBlockComponent = ({
  block,
  onChange,
  justEnteredInList,
  ...actionProps
}: Props) => {
  const description = block.content.description || '';

  const [editingContent, setEditingContent] = React.useState(justEnteredInList);

  const mergeTags = [{ trigger: '[', tags: reviewTemplateVariables() }];

  return (
    <Box testClassName="test-instructions-block">
      <Flex style={{ justifyContent: 'space-between' }} verticalAlign>
        <Title6 transformation="uppercase">{__('Instructions')}</Title6>
        <BlockActions
          canMoveUp={block.canMoveUp}
          canMoveDown={block.canMoveDown}
          canDuplicate={block.canDuplicate}
          {...actionProps}
        />
      </Flex>

      <BoxSeparator style={{ margin: '16px 0' }} />

      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          blockId: block.id,
          field: 'instructions_block_content',
        })}
        onChange={onChange}
        render={autoSavingOnChange => (
          <Text
            size={6}
            className="is-spaced"
            isEditable
            editableOptions={{
              type: 'multilineText',
              value: description,
              onChange: text => autoSavingOnChange({ description: text }),
              placeholder: __('Add a description'),
              autoFocus: justEnteredInList,
              onFocus: () => setEditingContent(true),
              onBlur: () => setEditingContent(false),
              fieldProps: {
                maxLength: MAX_DESCRIPTION_LENGTH,
                onMaxLengthReached: () =>
                  __(
                    'The instructions block content does not allow more than %1 characters.',
                    MAX_DESCRIPTION_LENGTH
                  ),
                lineBreakHelperStyle: 'none',
                mergeTags: mergeTags,
                richText: block.richTextEnabled,
                hideSaveButton: true,
              },
            }}
          />
        )}
      />
      {description.length === 0 && !editingContent && (
        <Helper style={{ marginTop: 8 }}>
          {__(
            'Please note that nothing will be shown in the review template if the instructions block is empty.'
          )}
        </Helper>
      )}
    </Box>
  );
};

export default InstructionsBlockComponent;
