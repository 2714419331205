import React, { useState } from 'react';

import type { SkillsUserCareerLevel } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { htmlSuccessNotice } from 'redux/actions';
import { post, put } from 'redux/actions/api';

import { Button, Helper } from 'components';

import CareerLevelManagementModal from 'scenes/components/skills/CareerLevelManagementModal';

import useUserReviewFromParams from './hooks/useUserReview';

type Props = {
  careerLevel: SkillsUserCareerLevel | null;
  revieweeId: string;
  revieweeFullName: string;
  refreshBlock: () => Promise<unknown>;
  refreshShareability: () => Promise<void>;
};

const CurrentCareerLevelManagementBox = ({
  revieweeId,
  revieweeFullName,
  careerLevel,
  refreshBlock,
  refreshShareability,
}: Props) => {
  const [modalIsActive, setModalIsActive] = useState(false);
  const dispatch = useAppDispatch();

  const userReview = useUserReviewFromParams();
  const hasRevieweeShared =
    userReview?.revieweeStatus === 'shared' ||
    userReview?.revieweeStatus === 'signed';

  return (
    <React.Fragment>
      <Button color="secondary" onClick={() => setModalIsActive(true)}>
        {__('Update the level')}
      </Button>
      <CareerLevelManagementModal
        isActive={modalIsActive}
        onClose={() => setModalIsActive(false)}
        title={__('Update the current level of %1', revieweeFullName)}
        description={
          <React.Fragment>
            <p>
              {__(
                'You are about to update the current level of %1 on the skills matrix.',
                revieweeFullName
              )}
            </p>
            <p>
              {__(
                'Make sure to make the necessary adjustments before sharing your feedback.'
              )}
            </p>
          </React.Fragment>
        }
        levelSelectionLabel={__('Select the current level')}
        submitLabel={__('Update the current level')}
        onLevelSelect={async (levelId: string | null) => {
          const params = {
            userId: revieweeId,
            levelType: 'current',
            levelId,
          };

          await dispatch(
            !!careerLevel
              ? put(`skills/user_career_levels/${careerLevel.id}`, params)
              : post('skills/user_career_levels', params)
          );

          setModalIsActive(false);

          dispatch(
            htmlSuccessNotice(
              __(
                'The level of %1 has been successfully updated.',
                revieweeFullName
              )
            )
          );

          Promise.all([refreshBlock(), refreshShareability()]);
        }}
        currentSkillsLevel={careerLevel?.skillsLevel}
        disabled={hasRevieweeShared}
        helperBox={
          hasRevieweeShared ? (
            <Helper className="mt-6">
              {__(
                '%1 has shared their review. Reopen their self-evaluation to change the level.',
                revieweeFullName
              )}
              <br />
              <b>
                {__(
                  'Ratings and comments will be lost once the level is updated.'
                )}
              </b>{' '}
              {__(
                'Export the review to PDF before reopening their self-assessment to keep them.'
              )}
            </Helper>
          ) : undefined
        }
      />
    </React.Fragment>
  );
};

export default CurrentCareerLevelManagementBox;
