import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import type {
  ReviewCycle,
  User,
  UserFilterSegment,
  UserFilterType,
} from 'models';

import {
  formatFilterToParamsHash,
  formatParamsHashToFilterName,
} from 'helpers/filter';
import { __ } from 'helpers/i18n';
import useUrlPersistedState from 'helpers/useUrlPersistedState';
import { withActiveUser } from 'helpers/withSessionProps';

import {
  ContentContainer,
  FilterBar,
  Level,
  LevelItem,
  LevelLeft,
  LevelRight,
  PageTitle,
} from 'components';

import ParticipantList from 'scenes/admin/reviewCycles/Manage/Participants';
import OldParticipantList from 'scenes/admin/reviewCycles/Manage/Participants/OldParticipantsIndex';
import UserFilter from 'scenes/components/UserFilter';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

import { DataContext } from '..';
import ManagerReviewCycleAnalytics from './components/ManagerReviewCycleAnalytics';

type Props = {
  reviewCycle: ReviewCycle;
};

type AfterConnectProps = Props & {
  activeUser: User;
};

function ManagerCycleAnalytics({ reviewCycle, activeUser }: AfterConnectProps) {
  const { withUserMultiFilters, userFilters, setUserFilters } =
    useContext(DataContext);

  const defaultUserFilter = {
    filterLabel: __('Direct reports of'),
    filterType: 'direct_reports' as UserFilterType,
    label: activeUser.fullName,
    value: activeUser.id,
  };
  const filters = [
    { param: 'active', label: __('Active') },
    { param: 'suspended', label: __('Suspended') },
    { param: 'all', label: __('All') },
  ];

  const [filter, setFilter] = useUrlPersistedState<{
    [key: string]: { [key: string]: boolean } | boolean;
  }>('filter', { all: true, user: { active: true } }, true);

  const [userFilter, setUserFilter] =
    useUrlPersistedState<UserFilterSegment | null>(
      'userFilter',
      defaultUserFilter
    );

  const activeFilter = formatParamsHashToFilterName(
    filter.user as { [key: string]: boolean }
  );

  return (
    <ContentContainer>
      <PageTitle title={[reviewCycle.name, __('Progress')]} />

      <Level style={{ marginBottom: 16 }}>
        <LevelLeft>
          <LevelItem>
            {withUserMultiFilters ? (
              <UserMultiFilters
                userFilters={userFilters}
                onChange={({ userFilters }) => setUserFilters(userFilters)}
                maxFiltersCount={5}
              />
            ) : (
              <UserFilter segment={userFilter} onChange={setUserFilter} />
            )}
          </LevelItem>
        </LevelLeft>
        <LevelRight style={{ textAlign: 'end' }}>
          <FilterBar
            display="link"
            leftText={__('Show')}
            filters={filters}
            activeFilter={activeFilter}
            onFilterChange={(param: string) => {
              setFilter({ ...filter, user: formatFilterToParamsHash(param) });
            }}
          />
        </LevelRight>
      </Level>

      <ManagerReviewCycleAnalytics
        reviewCycleId={reviewCycle.id}
        reviewCycleType={reviewCycle.interactionType}
        filter={{ user: filter.user }}
        userFilter={userFilter}
        userFilters={userFilters}
      />

      {withUserMultiFilters ? (
        <ParticipantList
          reviewCycle={reviewCycle}
          hideUserFilters
          defaultFilter={{ all: true }}
          additionalFilter={{ user: filter.user }}
          additionalQueryParams={{ userFilters }}
        />
      ) : (
        <OldParticipantList
          reviewCycle={reviewCycle}
          hideUserFilters
          filter={filter}
          userFilter={userFilter}
          paginationType="url"
          defaultPaginationParams={{ userFilter, filter }}
        />
      )}
    </ContentContainer>
  );
}

export default compose<React.ComponentType<Props>>(
  withRouter,
  withActiveUser
)(ManagerCycleAnalytics);
