import classNames from 'classnames';
import React from 'react';

import { Helper, RawHtml } from 'components';

type Props = {
  message: string;
  cta?: React.ReactNode;
  style?: React.CSSProperties;
};

export default function Message({ message, cta, style }: Props) {
  return (
    <Helper
      style={style}
      testClassName="test-review-message"
      className={classNames('review-message', { 'with-cta': !!cta })}
    >
      <div className="review-message-content">
        <p>
          <RawHtml // FIXME: we should not use RawHtml, in this case message content are handle backend side and should be safe
            html={message}
          />
        </p>
        {cta && <div className="review-message-cta">{cta}</div>}
      </div>
    </Helper>
  );
}
