import { capitalize } from 'lodash';
import React from 'react';

import type { PlannedActionStatus } from 'models';

import {
  plannedActionStatusColor,
  plannedActionStatusLabel,
} from 'helpers/models/plannedAction';

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
} from 'components';

type Option = {
  value: PlannedActionStatus;
  label: string;
};

type Props = {
  value: PlannedActionStatus;
  onChange: (plannedActionStatus: PlannedActionStatus) => void;
};

export default function StatusPicker({ value, onChange }: Props) {
  const statuses: Array<PlannedActionStatus> = ['to_do', 'in_progress', 'done'];
  const options: Array<Option> = statuses.map(
    (status: PlannedActionStatus) => ({
      value: status,
      label: capitalize(plannedActionStatusLabel(status)),
    })
  );

  return (
    <Dropdown align="left">
      <DropdownTrigger
        render={() => (
          <Button color={plannedActionStatusColor(value)}>
            <Icon name="check_circle" className="mr-2" />
            <span>{capitalize(plannedActionStatusLabel(value))}</span>
            <Icon name="keyboard_arrow_down" className="ml-2" />
          </Button>
        )}
      />
      <DropdownMenu>
        {options.map((option, index) => (
          <DropdownMenuItem key={index} onClick={() => onChange(option.value)}>
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
