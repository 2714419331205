import React, { Fragment } from 'react';

import { __ } from 'helpers/i18n';
import { pathToNewTrainingOrganism } from 'helpers/paths';

import { Button, ContentContainer, Icon } from 'components';

import PageHeader from '../components/PageHeader';
import OrganismListWithPagination from './OrganismListWithPagination';

const Organisms = () => {
  return (
    <Fragment>
      <PageHeader />

      <ContentContainer>
        <div className="flex justify-end mb-4">
          <Button color="primary" to={pathToNewTrainingOrganism()}>
            <Icon name="add" className={'mr-2'} />
            {__('Add a training organization')}
          </Button>
        </div>
        <OrganismListWithPagination />
      </ContentContainer>
    </Fragment>
  );
};

export default Organisms;
