import React from 'react';

import {
  ChoiceScore,
  SurveyAnswerSentimentCategory,
  SurveyQuestion,
  SurveyQuestionScore,
} from 'models';

import { __ } from 'helpers/i18n';

import { Text, Tooltip } from 'components';

import ChoiceScoreComponent from './ChoiceScore';
import Sentiment from './Sentiment';

type Props = {
  question: SurveyQuestion;
  score: SurveyQuestionScore;
  sentimentCategory?: SurveyAnswerSentimentCategory;
  className?: string;
};

const QuestionScore = ({
  question,
  score,
  sentimentCategory,
  className,
}: Props) => {
  const { questionType } = question;

  const FormattedScore = () => {
    switch (questionType) {
      case 'text':
        return (
          <Sentiment
            sentimentCategory={
              sentimentCategory as SurveyAnswerSentimentCategory
            }
          />
        );
      case 'scale': {
        const scaleScore = `${score}/${question.maxValue}`;

        return (
          <Text preset="24bs2" testClassName="test-survey-question-score">
            {scaleScore}
          </Text>
        );
      }
      case 'multiple_choice':
      case 'single_choice':
        return <ChoiceScoreComponent score={score as ChoiceScore} />;
      default:
        return (
          <Text preset="24bs2" testClassName="test-survey-question-score">
            {score}
          </Text>
        );
    }
  };

  return (
    <div className={className}>
      {score === null ? (
        <Tooltip content={__('There are no answers yet')}>
          <Text preset="24bs2">-</Text>
        </Tooltip>
      ) : (
        <FormattedScore />
      )}
    </div>
  );
};

export default QuestionScore;
