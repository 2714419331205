import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  ReviewCycle,
  SkillsStatsDevelopmentNeedsAreaData,
  SkillsStatsDevelopmentNeedsData,
  UserFilterSegment,
} from 'models';

import store from 'config/store';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { hydrateFromResponse } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  BoxList,
  BoxListItem,
  Column,
  Columns,
  Divider,
  Flex,
  Text,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import AttachedCollabsModal from './AttachedCollabsModal';
import Item from './Item';

type Props = {
  reviewCycle: ReviewCycle;
  selectedMatrixId: null | string;
  selectedLevelId: null | string;
  userFilter: UserFilterSegment | null;
  displayMockedData: boolean;
  totalCount: number;
  setTotalCount: (number) => void;
  userFilters: ActiveFilters;
};

type SkillRating = {
  rating: number;
  ratingLabel: string;
  skillAreaTitle: string;
  skillAreaId: string;
};

const List = ({
  reviewCycle,
  selectedMatrixId,
  selectedLevelId,
  userFilter,
  displayMockedData,
  totalCount,
  setTotalCount,
  userFilters,
}: Props) => {
  const dispatch = useAppDispatch();
  const [skillsStatsDevelopmentNeedsData, setSkillsStatsDevelopmentNeedsData] =
    useState<SkillsStatsDevelopmentNeedsData>({
      // @ts-ignore TSFIXME: Fix strictNullChecks error
      matricesData: { areas: {}, totalCount: null, ratingOptions: [] },
    });
  const fetchSkillsStatsDevelopmentNeedsData = async () => {
    const { response } = await dispatch(
      get(`skills/stats/development_needs`, {
        reviewCycleIds: reviewCycle.id,
        matrixIds: selectedMatrixId,
        levelIds: selectedLevelId,
        userFilter,
        userFilters,
      })
    );
    const data = hydrateFromResponse(
      store.getState().data,
      response.body,
      {
        matricesData: {},
      },
      response.body.data.id
    );

    setSkillsStatsDevelopmentNeedsData(data);
    setTotalCount(data.matricesData.totalCount);
  };
  const initializeSkillsStatsDevelopmentNeedsData = () => {
    if (displayMockedData) {
      setSkillsStatsDevelopmentNeedsData({
        matricesData: {
          totalCount: 101,
          ratingOptions: [
            { label: __('Below the expected level'), position: 0, id: 0 },
            { label: __('Currently learning'), position: 1, id: 1 },
            { label: __('Skill mastered'), position: 2, id: 2 },
            { label: __('Above expectations'), position: 3, id: 3 },
          ],
          areas: {
            1: {
              areaId: '1',
              areaTitle: __('Discovery'),
              position: 2,
              countsByRating: {
                none: 3,
                0: 42,
                1: 28,
                2: 14,
                3: 14,
              },
            },
            2: {
              areaId: '2',
              areaTitle: __('Delivery'),
              position: 3,
              countsByRating: {
                none: 2,
                0: 0,
                1: 0,
                2: 16,
                3: 42,
              },
            },
            3: {
              areaId: '3',
              areaTitle: __('Product deployment'),
              position: 4,
              countsByRating: {
                none: 4,
                0: 37,
                1: 18,
                2: 9,
                3: 0,
              },
            },
            4: {
              areaId: '4',
              areaTitle: __('Product knowledge'),
              position: 5,
              countsByRating: {
                none: 7,
                0: 15,
                1: 15,
                2: 15,
                3: 8,
              },
            },
            5: {
              areaId: '5',
              areaTitle: __('Company values'),
              position: 6,
              countsByRating: {
                none: 1,
                0: 0,
                1: 0,
                2: 10,
                3: 59,
              },
            },
            6: {
              areaId: '6',
              areaTitle: __('Team spirit'),
              position: 0,
              countsByRating: {
                none: 0,
                0: 0,
                1: 0,
                2: 11,
                3: 61,
              },
            },
          },
        },
      });
      setTotalCount(101);
    } else {
      fetchSkillsStatsDevelopmentNeedsData();
    }
  };

  useEffect(() => {
    initializeSkillsStatsDevelopmentNeedsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatrixId, selectedLevelId, userFilter, userFilters]);
  const [selectedRating, setSelectedRating] = useState<SkillRating | null>(
    null
  );

  const areaData = sortBy(
    skillsStatsDevelopmentNeedsData.matricesData.areas,
    'position'
  ) as Array<SkillsStatsDevelopmentNeedsAreaData>;

  if (!totalCount || totalCount === 0) {
    return <React.Fragment />;
  }

  const ratingOptions =
    skillsStatsDevelopmentNeedsData.matricesData.ratingOptions;

  return (
    <React.Fragment>
      <Flex direction="column" className="review-cycle-skills">
        <div className="mt-10">
          <BoxList>
            <BoxListItem>
              <Columns contentVerticallyAligned>
                <Column key="none"></Column>
                {ratingOptions.map(ratingOption => (
                  <Column
                    key={ratingOption.label}
                    style={{ textAlign: 'center' }}
                  >
                    {ratingOption.label}
                  </Column>
                ))}
              </Columns>
            </BoxListItem>
            {areaData.map(row => (
              <Item
                key={row.areaId}
                id={row.areaId}
                row={row}
                ratingOptions={ratingOptions}
                setSelectedRating={setSelectedRating}
              />
            ))}
          </BoxList>
          <Divider />
          <Text transformation="italic">
            {__('Results based on the rating of the main reviewer')}
          </Text>
        </div>
      </Flex>
      {!!selectedRating && (
        <AttachedCollabsModal
          paginationType="state"
          rating={selectedRating.rating}
          selectedRating={selectedRating.ratingLabel}
          selectedSkillAreaTitle={selectedRating.skillAreaTitle}
          selectedSkillAreaId={selectedRating.skillAreaId}
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          selectedMatrixId={selectedMatrixId}
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          selectedLevelId={selectedLevelId}
          userFilter={userFilter}
          reviewCycleId={reviewCycle.id}
          displayMockedData={displayMockedData}
          onClose={() => setSelectedRating(null)}
        />
      )}
    </React.Fragment>
  );
};

export default List;
