import React from 'react';

import type { Props as CompletionProps } from './Completion';

import { CompletionProgressBar, Flex } from 'components';

import { Completion } from './';

export default function CompletionProgress(props: CompletionProps) {
  return (
    <Flex className="completion-progress-container">
      <CompletionProgressBar completion={props.completion} />
      <Completion {...props} />
    </Flex>
  );
}
