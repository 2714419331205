import React from 'react';

import { __ } from 'helpers/i18n';

import {
  Button,
  Flex,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';
import { SavingStatusLabel } from 'components/withSavingStatus';

import BudgetList from './BudgetList';
import BudgetSummary from './BudgetSummary';

type Props = {
  periodSlug: string;
  onClose: () => void;
};

const BudgetManagementModal = ({ onClose, periodSlug }: Props) => {
  return (
    <ModalCard
      isActive
      refreshContentOnOpening
      onClose={onClose}
      style={{ width: 640, maxWidth: 640 }}
    >
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>{__('Configure the budget')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody style={{ paddingBottom: 16 }}>
        <BudgetSummary periodSlug={periodSlug} />
        <hr />
        <Flex>
          <Label>{__('Define your funding sources')}</Label>
          <div style={{ marginLeft: 'auto' }}>
            <SavingStatusLabel
              failedText={() => __('The budget could not be saved')}
            />
          </div>
        </Flex>
        <Text
          color="info"
          weight="normal"
          style={{ display: 'block', paddingBottom: 16 }}
        >
          {__(
            'It will allow you to add different funding sources for a training session.'
          )}
        </Text>

        <BudgetList periodSlug={periodSlug} />
      </ModalCardBody>
      <ModalCardFooter>
        <Button onClick={onClose} color="secondary">
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default BudgetManagementModal;
