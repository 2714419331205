import { clamp } from 'lodash';
import React, { useState } from 'react';

import { AutoAddRule } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { del, put } from 'redux/actions/api';

import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  NumberInput,
  Select,
  Text,
} from 'components';

import UserSegmentsPicker from './UserSegmentsPicker';

type Props = {
  index: number;
  autoAddRule: AutoAddRule;
  filterTypeLabel: string;
  reviewCycleId: string;
  isDestroyEnabled: boolean;
  triggerRefreshCount: number;
};

export default function RuleCard({
  index,
  autoAddRule,
  filterTypeLabel,
  reviewCycleId,
  isDestroyEnabled,
  triggerRefreshCount,
}: Props) {
  const operatorOptions: {
    label: string;
    value: 'is' | 'is_not';
  }[] = [
    { label: __('Is'), value: 'is' },
    { label: __('Is not'), value: 'is_not' },
  ];
  const getOperatorOptionFromRule = (rule: AutoAddRule) =>
    operatorOptions.filter(option => option.value === rule.operator)[0];

  const dispatch = useAppDispatch();
  const updateRule = (newRule: AutoAddRule) => {
    if (autoAddRule.operator !== newRule.operator) newRule.userSegments = [];
    return dispatch(
      put(`review_cycles/${reviewCycleId}/auto_add_rules/${autoAddRule.id}`, {
        autoAddRule: newRule,
      })
    );
  };
  const destroyRule = () =>
    dispatch(
      del(`review_cycles/${reviewCycleId}/auto_add_rules/${autoAddRule.id}`)
    );

  const [daysThreshold, setDaysThreshold] = useState<number>(
    autoAddRule.daysThreshold
  );

  return (
    <Box className="auto-add-rule-card mb-0" size="small">
      <div className="inline-flex w-full items-center h-8">
        <Text preset="13buppers7">{__('Rule %1', index + 1)}</Text>
        {isDestroyEnabled && (
          <Button
            hasIconOnly
            isText
            size="small"
            className="ml-auto no-underline"
            onClick={destroyRule}
          >
            <Icon name="delete" />
          </Button>
        )}
      </div>
      <Divider className="mb-3 mt-0" />
      <div>
        {__('Employees whose ')}
        <b>{filterTypeLabel}</b>
        <Flex className="my-2 gap-2">
          <Select
            className="operator-select"
            value={getOperatorOptionFromRule(autoAddRule)}
            options={operatorOptions}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            onChange={({ value }) => {
              if (value !== autoAddRule.operator)
                updateRule({ ...autoAddRule, operator: value });
            }}
          />
          <UserSegmentsPicker
            onChange={segments =>
              updateRule({ ...autoAddRule, userSegments: segments })
            }
            autoAddRuleId={autoAddRule.id}
            selectedSegments={autoAddRule.userSegments}
            reviewCycleId={reviewCycleId}
            triggerRefreshCount={triggerRefreshCount}
            isMulti={autoAddRule.operator === 'is'}
          />
        </Flex>
        <div className="inline">
          {__('will be added to the campaign')}
          <NumberInput
            value={daysThreshold}
            min={1}
            max={365}
            className="align-baseline text-center w-12 mr-2 ml-2 bg-white"
            testClassName="test-auto-add-rule-card-days"
            style={{ MozAppearance: 'textfield' }}
            onChange={value => {
              setDaysThreshold(value || 0);
            }}
            onBlur={() => {
              const clampedValue = clamp(daysThreshold, 1, 365);
              setDaysThreshold(clampedValue);
              updateRule({
                ...autoAddRule,
                daysThreshold: clampedValue,
              });
            }}
          />
          {__('calendar days after their start date')}
        </div>
      </div>
    </Box>
  );
}
