import React, { Fragment, useState } from 'react';

import type { OneOnOneUserReview, OneOnOneUserReviewFile } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { del, put } from 'redux/actions/api';

import {
  Button,
  Centered,
  Helper,
  Icon,
  StrictlySanitizedHtml,
  Title,
} from 'components';

import AttachFilesModal from './AttachFilesModal';
import FileCell from './FileCell';

export const MAX_FILES_COUNT = 10;

type Props = {
  userReview: OneOnOneUserReview;
};

const AttachFilesSection = ({ userReview }: Props) => {
  const dispatch = useAppDispatch();
  const [modalIsActive, setModalActive] = useState<boolean>(false);
  const canManageAttachedFiles: boolean = can({
    perform: 'manage_attached_files',
    on: userReview,
  });

  const canDownloadAttachedFiles: boolean = can({
    perform: 'download_attached_files',
    on: userReview,
  });

  const showRemoveFileButton: boolean = can({
    perform: 'show_remove_file_button',
    on: userReview,
  });

  const isMaxFilesCountReached = userReview.files.length >= MAX_FILES_COUNT;

  const handleAttachments = (fileIds: string[]) => {
    dispatch(
      put(`user_reviews/${userReview.id}/attach_files`, {
        user_review: { fileIds },
      })
    ).then(setModalActive(false));
  };

  const deleteFile = (fileId: string) =>
    dispatch(del(`user_reviews/${userReview.id}/attached_files/${fileId}`));

  const confirmAsyncDeleteFile = (file: OneOnOneUserReviewFile) => {
    return confirmAsync(
      __('Remove document from the review'),
      <React.Fragment>
        <StrictlySanitizedHtml
          html={__('This action will <b>remove the following document:</b>')}
        />
        <br />
        {file.name}
        <br />
        <br />
      </React.Fragment>,
      {
        sideEffects: [__('Remove the document from the review')],
        confirmLabel: __('Remove document'),
        onConfirm: () => deleteFile(file.id),
      }
    );
  };

  return (
    <Fragment>
      <Title className="side-bar-title" size={5} weight="bold">
        {__('Attached Documents')}
      </Title>
      {canManageAttachedFiles && (
        <Helper className="mb-4">
          <p>
            {__(
              'Every document added here is visible to anyone accessing the review, even if you did not share your feedback.'
            )}
          </p>
        </Helper>
      )}
      {canDownloadAttachedFiles &&
        userReview.files.map((file: OneOnOneUserReviewFile) => (
          <FileCell
            key={file.id}
            file={file}
            onDelete={() => confirmAsyncDeleteFile(file)}
            showDeleteButton={showRemoveFileButton}
            canDelete={canManageAttachedFiles}
          />
        ))}
      {canManageAttachedFiles && (
        <Fragment>
          <Centered className="mt-4">
            <Button
              color="secondary"
              disabled={isMaxFilesCountReached}
              disabledExplanation={__(
                'Maximum %1 files can be attached',
                MAX_FILES_COUNT
              )}
              onClick={() => setModalActive(true)}
            >
              <Icon style={{ marginRight: 5 }} name="add" />
              {__('Add a document')}
            </Button>
          </Centered>

          {modalIsActive && (
            <AttachFilesModal
              isActive={modalIsActive}
              maxUploadsCount={MAX_FILES_COUNT - userReview.files.length}
              onClose={() => setModalActive(false)}
              handleAttachments={handleAttachments}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default AttachFilesSection;
