import React, { type ReactNode } from 'react';

import classNames from 'helpers/classNames';

import { Icon } from 'components';

type Props = {
  title: string;
  children: ReactNode;
  className?: string;
};

export default function OldBrowserAccordion({
  title,
  children,
  className,
}: Props) {
  return (
    <details
      className={classNames(
        'old-browser-accordions w-full px-3 py-2.5 rounded-md',
        className
      )}
    >
      <summary className="nav-item-title text-sm flex-1 !font-semibold text-left pr-2 text-nav-text">
        {title}
        <Icon
          className="ml-2 rounded-full text-nav-icon hover:bg-nav-nav_group_caret-hover-bg"
          iconStyle={{ fontSize: 20 }}
          name="arrow_right"
        />
      </summary>
      {children}
    </details>
  );
}
