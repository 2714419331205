import React from 'react';

import { PeopleGroupRule } from 'models/PeopleGroup';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

type Props = {
  peopleGroupRules: PeopleGroupRule[];
};

const AndOperator = ({ peopleGroupRules }: Props) => {
  return (
    <div>
      {peopleGroupRules.length > 1 &&
        peopleGroupRules.map((_rule, idx) => {
          return (
            idx !== peopleGroupRules.length - 1 && (
              <Text
                preset="13bs7"
                className="p-2 rounded-lg mr-2 mt-2 h-9 flex items-center justify-center bg-ui-highlight-gray"
              >
                {__('and').toUpperCase()}
              </Text>
            )
          );
        })}
    </div>
  );
};

export default AndOperator;
