import React, { useCallback, useContext } from 'react';
import { compose } from 'redux';

import type {
  PaginationProps,
  WithPaginationProps,
} from 'lib/dataLoader/pagination/types';
import type { PaginatedCollection, SkillsArea } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import {
  type DataLoaderProvidedProps,
  newDataLoader,
  withDeprecatedPagination,
} from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxList,
  BoxListItem,
  Column,
  Columns,
  DatatableWrapper,
  Divider,
  EmptyStateWithIcon,
  FetchContainer,
  Text,
} from 'components';

import { DataContext } from '.';
import AreaListItem from './AreaListItem';

type Props = WithPaginationProps & {
  domainIds: null | Array<string>;
};

type AfterConnectProps = Props & PaginationProps;

type AfterDataLoaderProps = AfterConnectProps &
  DataLoaderProvidedProps & {
    areaCollection: PaginatedCollection<SkillsArea>;
  };

function AreaPaginatedList({
  areaCollection,
  ...otherProps
}: AfterDataLoaderProps) {
  const { isFetching, hasError, refetchData } = otherProps;

  const { shouldRefetchSkills, setShouldRefetchSkills } =
    useContext(DataContext);

  const refetchSkills = useCallback(() => {
    refetchData();
    setShouldRefetchSkills(false);
  }, [refetchData, setShouldRefetchSkills]);

  React.useEffect(() => {
    if (shouldRefetchSkills) refetchSkills();
  }, [refetchSkills, shouldRefetchSkills]);

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={areaCollection}
        {...otherProps}
        filter={undefined}
        onUserFilterChange={undefined}
        totalCountRenderer={count => n__('%1 skill', '%1 skills', count || 0)}
        renderNoRecord={
          otherProps.domainIds?.length === 0
            ? () => (
                <EmptyStateWithIcon
                  iconName="hotel_class"
                  title={__('No skill has been created yet.')}
                  description={__(
                    'Create your first skill by clicking on the "%1" button',
                    __('Create a skill')
                  )}
                />
              )
            : null
        }
      >
        <BoxList>
          <BoxListItem>
            <Columns>
              <Column size={3}>
                <Text preset="14s6" color="light">
                  {__('Skill title')}
                </Text>
              </Column>

              <Column size={3}>
                <Text preset="14s6" color="light">
                  {__('Domain')}
                </Text>
              </Column>

              <Column size={2}>
                <Text preset="14s6" color="light">
                  {__('Associated matrices')}
                </Text>
              </Column>

              <Column size={3}>
                <Text preset="14s6" color="light">
                  {__('Last modification')}
                </Text>
              </Column>
            </Columns>
          </BoxListItem>
          <Divider className="mt-0 mb-0" />
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            loadingStyle="overlay"
            render={() => (
              <div>
                {!!areaCollection &&
                  areaCollection.items.map((area: SkillsArea) => (
                    <AreaListItem
                      key={area.id}
                      area={area}
                      refetchData={refetchData}
                    />
                  ))}
              </div>
            )}
          />
        </BoxList>
      </DatatableWrapper>
    </Box>
  );
}

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({ search, page, countPerPage, domainIds }: AfterConnectProps) =>
      get('/skills/areas', {
        search,
        page,
        countPerPage,
        'domainIds[]': domainIds,
      }),
    hydrate: { areaCollection: { items: { domain: {} } } },
    cacheKey: ({ search, page, countPerPage, domainIds }) =>
      compositeKey({ search, page, countPerPage, domainIds }),
  })
)(AreaPaginatedList);
