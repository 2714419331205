import React, { ComponentType } from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { Integration } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __, date } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get, put } from 'redux/actions/api';

import {
  Box,
  BoxList,
  CheckboxSwitch,
  FetchContainer,
  SimpleTable,
} from 'components';

import IntegrationEmptyState from 'scenes/components/integrations/ListEmptyState/index';

import Actions from './Actions';
import IntegrationSyncStatusText from './IntegrationSyncStatusText';

type Props = {};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    integrations: Array<Integration>;
  };

const IntegrationList = ({
  integrations,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();
  const updateIntegration = (integration: Partial<Integration>) =>
    dispatch(put(`integrations/${integration.id}`, { integration }));

  return (
    <Box>
      <BoxList>
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          render={() => {
            return (
              <SimpleTable
                rows={integrations}
                emptyState={
                  <IntegrationEmptyState title={__('No integrations found')} />
                }
                keyFn={integration => integration.id}
                columns={[
                  {
                    header: __('Type'),
                    cell: integration => integration.integrationType,
                  },
                  {
                    header: __('Last status'),
                    cell: integration => (
                      <IntegrationSyncStatusText sync={integration.lastSync} />
                    ),
                  },
                  {
                    header: __('Last update'),
                    cell: integration =>
                      date(
                        integration.lastSync && integration.lastSync.endedAt
                      ),
                  },
                  {
                    header: __('Activation'),
                    cell: integration => (
                      <div className="inline-flex">
                        <CheckboxSwitch
                          value={integration.active}
                          onChange={() =>
                            updateIntegration({
                              id: integration.id,
                              active: !integration.active,
                            })
                          }
                          size="small"
                          id="integration-activated-switch"
                        />
                      </div>
                    ),
                  },
                  {
                    header: '',
                    cell: integration => (
                      <Actions
                        integration={integration}
                        refetchIntegrations={refetchData}
                      />
                    ),
                    isNarrow: true,
                  },
                ]}
              />
            );
          }}
        />
      </BoxList>
    </Box>
  );
};

export default newDataLoader({
  fetch: () => get('integrations'),
  hydrate: {
    integrations: {
      lastSync: {},
      abilities: {},
    },
  },
})(IntegrationList) as ComponentType<Props>;
