import React from 'react';
import { compose } from 'redux';

import { PeopleUserReviewPlannedAction, UserFilterSegment } from 'models';
import { PaginatedCollection } from 'models/Collection';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import {
  DataLoaderProvidedProps,
  PaginationProps,
  WithPaginationProps,
  newDataLoader,
  withDeprecatedPagination,
} from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, DatatableWrapper, EmptyStateWithIcon } from 'components';

import PeopleReviewActionsWithPagination from '../components/PeopleReviewActionsWithPagination';

type Props = WithPaginationProps & {
  segment?: UserFilterSegment | null;
  tagLabels?: Array<string> | undefined;
  parentRefetchData?: () => Promise<any>;
};
type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    peopleReviewActionsCollection: PaginatedCollection<PeopleUserReviewPlannedAction>;
  };

const PaginatedList = ({
  peopleReviewActionsCollection,
  refetchData,
  parentRefetchData,
  ...otherProps
}: AfterDataLoaderProps) => {
  const { isFetching, hasError } = otherProps;

  return (
    <Box className="actions-plan">
      <DatatableWrapper
        collectionInfo={peopleReviewActionsCollection}
        {...otherProps}
        onUserFilterChange={undefined}
        totalCountRenderer={totalCount =>
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          n__('%1 action', '%1 actions', totalCount)
        }
        filters={[
          { param: 'soon_overdue', label: __('Due soon') },
          { param: 'overdue', label: __('Overdue') },
          { param: 'to_do', label: __('To do') },
          { param: 'in_progress', label: __('In progress') },
          { param: 'done', label: __('Done') },
          { param: 'all', label: __('All') },
        ]}
        renderNoRecord={() => (
          <EmptyStateWithIcon
            iconName="rule"
            title={__('No action has been created at the moment.')}
            inBox
          />
        )}
      >
        <PeopleReviewActionsWithPagination
          peopleReviewActionsCollection={peopleReviewActionsCollection}
          isFetching={isFetching}
          hasError={hasError}
          refetchData={() => {
            parentRefetchData && parentRefetchData();
            return refetchData();
          }}
        />
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      filter,
      segment,
      tagLabels,
    }: AfterPaginateProps) =>
      get('planned/people_review_actions', {
        page,
        countPerPage,
        search,
        filter,
        userFilter: segment,
        'tagLabels[]': tagLabels,
      }),
    hydrate: {
      peopleReviewActionsCollection: {
        items: {
          abilities: {},
          user: {},
          resource: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      filter,
      segment,
      tagLabels,
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter, segment, tagLabels }),
  })
)(PaginatedList);
