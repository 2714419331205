import React from 'react';

import type { SkillsLevelExpectationMapping } from 'models';

import { assert } from 'helpers/invariant';

import { BoxListItem, Text } from 'components';

type Props = {
  expectationMapping: SkillsLevelExpectationMapping;
};

const ExpectationWithoutDomain = ({ expectationMapping }: Props) => (
  <BoxListItem className={`p-0 mb-5 pt-5`}>
    <div className="ml-2 mr-2">
      <Text preset="16bs5.5">{expectationMapping.areaTitle}</Text>
      <p className="mt-2 whitespace-pre-wrap">
        {
          assert(expectationMapping.expectation, 'expectation must be defined')
            .content
        }
      </p>
    </div>
  </BoxListItem>
);

export default ExpectationWithoutDomain;
