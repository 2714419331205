import React from 'react';

import { NPSScore, NPSSpecificStats, SurveyNPSQuestion } from 'models/surveys';

import tailwindTheme from 'config/tailwindTheme';

import { __ } from 'helpers/i18n';

import {
  Box,
  BoxVerticalSeparator,
  FlatPieChart,
  Flex,
  Text,
} from 'components';

import ComingSoon from '../../components/ComingSoon';
import QuestionScore from '../../components/QuestionScore';

type Props = {
  stats: NPSSpecificStats;
  score: NPSScore;
  question: SurveyNPSQuestion;
  testClassName?: string;
};

const NPSStats = ({ stats, score, question, testClassName }: Props) => {
  const categoriesData = [
    {
      label: __('Promoters'),
      value: stats.promotersPercent,
      color: tailwindTheme.colors['green-success'],
      name: 'promoters',
    },
    {
      label: __('Passives'),
      value: stats.passivesPercent,
      color: tailwindTheme.colors['orange-warning'],
      name: 'passives',
    },
    {
      label: __('Detractors'),
      value: stats.detractorsPercent,
      color: tailwindTheme.colors['red-fuckup'],
      name: 'detractors',
    },
  ];

  return (
    <Box className="mt-4" testClassName={testClassName}>
      <Flex>
        <div className="w-2/3">
          <Text preset="18bs5">{__('NPS Score')}</Text>
          <Flex className="mt-4">
            <QuestionScore score={score} question={question} />
            <div className="ml-16 grow">
              <FlatPieChart data={categoriesData} />
            </div>
          </Flex>
        </div>

        <BoxVerticalSeparator />

        <div>
          <Text preset="18bs5">{__('Completion')}</Text>
          <ComingSoon className="mt-4" />
        </div>
      </Flex>
    </Box>
  );
};

export default NPSStats;
