import React, { CSSProperties } from 'react';

import type {
  InputColor,
  InputSize,
  InputState,
  InputType,
  InputValue,
} from 'components/types/input';

import { bulmaColorClassName, bulmaSizeClassName } from 'helpers/bulma';
import classNames from 'helpers/classNames';

export type InputRef = { current: null | HTMLInputElement };

// Though passed through default props, we have to make them maybe type
export type StylingProps = {
  color?: InputColor;
  size?: InputSize;
  state?: InputState;

  className?: string;
  style?: CSSProperties;
};

type HTMLInputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  // Not part of "otherProps", handled by the component
  'onChange' | 'size' | 'ref'
>;

type DefaultProps = {
  type: InputType;
  disabled: boolean;
  size: InputSize;
  state: InputState;
};

export type Props<T extends InputValue> = HTMLInputProps &
  Partial<DefaultProps> & {
    value: T | null | undefined;
    onChange?: (value: string) => any;
    placeholder?: string;
    autoFocus?: boolean;
    name?: string;
    innerRef?: any;
    testClassName?: string;

    onBlur?: () => any;
    onFocus?: () => any;
    onEnterKeyPress?: () => any;
    onEscapeKeyPress?: () => any;
  } & StylingProps;

export const inputClassName = ({
  color,
  size,
  state,
  className,
  testClassName,
}: {
  color?: InputColor;
  size?: InputSize;
  state?: InputState;
  className?: string;
  testClassName?: string;
}) => {
  const stateClassName = classNames({
    'is-hovered': state === 'hovered',
    'is-focused': state === 'focused',
  });

  return classNames(
    'input',
    className,
    testClassName,
    bulmaColorClassName(color),
    bulmaSizeClassName(size),
    stateClassName
  );
};

class Input<T extends InputValue> extends React.Component<Props<T>> {
  static defaultProps: DefaultProps = {
    disabled: false,
    type: 'text',
    size: 'normal',
    state: 'normal',
  };

  render() {
    const {
      color,
      size,
      state,
      className,
      testClassName,
      onEnterKeyPress,
      onEscapeKeyPress,
      onChange,
      innerRef,
      ...otherProps
    } = this.props;

    const mergedClassName = inputClassName({
      state,
      color,
      size,
      className,
      testClassName,
    });

    return (
      <input
        {...otherProps}
        className={mergedClassName}
        onKeyDown={e => {
          onEnterKeyPress && e.keyCode === 13 && onEnterKeyPress();
          onEscapeKeyPress && e.keyCode === 27 && onEscapeKeyPress();
        }}
        ref={innerRef}
        onChange={e => onChange && onChange(e.target.value)}
      />
    );
  }
}

export default React.forwardRef(
  <T extends InputValue>(
    props: Props<T>,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    // @ts-ignore
    return <Input innerRef={ref} {...props} />;
  }
);
