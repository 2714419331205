import React from 'react';

import type { SkillsUserCareerLevel } from 'models';

import { __ } from 'helpers/i18n';
import { date } from 'helpers/i18n';
import { concatenatedSkillLevelWithMatrixTitle } from 'helpers/models/skills/skillLevel';
import { pathToMatrix } from 'helpers/paths';

import { Box, Button, Flex, Helper, Icon, Link, Text } from 'components';

type Props = {
  careerLevel: SkillsUserCareerLevel | null;
  title: string;
  canUpdate: boolean;
  emptyCareerLevelMessage: string;
  onLevelTitleClick: () => void;
};

const CareerLevelManagementBoxContent = ({
  careerLevel,
  title,
  canUpdate,
  emptyCareerLevelMessage,
  onLevelTitleClick,
}: Props) => {
  return (
    <Box>
      <Flex direction="column">
        <Flex className="items-center mb-4">
          <div>
            <Text className="mb-3" preset="16bs5.5">
              {title}
            </Text>
          </div>
          {canUpdate && (
            <div className="ml-5">
              <Button
                hasIconOnly
                isText
                className="edit-career-level no-underline hover:bg-ui-bg"
                onClick={onLevelTitleClick}
              >
                <Icon name="edit" />
              </Button>
            </div>
          )}
        </Flex>

        <Flex className="justify-between" verticalAlign>
          {!!careerLevel?.skillsLevel ? (
            <Link to={pathToMatrix(careerLevel.skillsLevel.matrixId)}>
              <Flex verticalAlign className="justify-between">
                <Text>
                  {concatenatedSkillLevelWithMatrixTitle(
                    careerLevel.skillsLevel
                  )}
                </Text>
                <Icon name="open_in_new" />
              </Flex>
            </Link>
          ) : (
            <Helper>{emptyCareerLevelMessage}</Helper>
          )}
        </Flex>

        {!!careerLevel && (
          <React.Fragment>
            <Text className="mt-4 mb-4" preset="16bs5.5">
              {__('Last update')}
            </Text>
            {date(careerLevel.updatedAt, 'LL')}
          </React.Fragment>
        )}
      </Flex>
    </Box>
  );
};

export default CareerLevelManagementBoxContent;
