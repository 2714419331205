import React, { Fragment, useContext, useState } from 'react';

import type { SkillsAreaMapping } from 'models';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { pathToArea } from 'helpers/paths';

import {
  BoxListItem,
  Column,
  Columns,
  Flex,
  Helper,
  Link,
  Text,
} from 'components';

import { DataContext } from 'scenes/skills/Index/MatrixVisualizer';
import SkillExpectationPicker from 'scenes/skills/components/SkillExpectationPicker';

import DeleteButton from './DeleteButton';
import ExpectationBox from './ExpectationBox';

type Props = {
  areaMapping: SkillsAreaMapping;
};

const AreaMappingBoxListItem = ({ areaMapping }: Props) => {
  const { matrix, editionMode } = useContext(DataContext);

  const [showFullText, setShowFullText] = useState(!editionMode);

  const toggleShowFullText = () => setShowFullText(!showFullText);

  invariant(!!areaMapping.mappedExpectations, 'Expectations should be mapped');

  const showUnassignedSkillHelper = areaMapping.mappedExpectations.every(
    expectation => expectation.title === null
  );

  return (
    <Fragment key={`skills-matrix-area-${areaMapping.id}`}>
      <BoxListItem testClassName="test-skills-matrix-area">
        <Columns>
          <Column
            className={classNames({
              'bg-ui-body-bg': !!areaMapping.domain,
            })}
          >
            {editionMode ? (
              <Fragment>
                <Flex
                  style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                    gap: '8px',
                  }}
                >
                  <Flex
                    style={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Text preset="14bs6" testClassName="test-area-title">
                      <Link to={pathToArea(areaMapping.areaId)}>
                        {areaMapping.title}
                      </Link>
                    </Text>
                    {!areaMapping.domain && (
                      <DeleteButton areaMapping={areaMapping} />
                    )}
                  </Flex>
                  {showUnassignedSkillHelper && (
                    <Helper style={{ marginBottom: 0 }}>
                      {__('Unassigned skill, it will not be evaluated')}
                    </Helper>
                  )}
                </Flex>
              </Fragment>
            ) : (
              <Flex
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Text preset="14bs6" testClassName="test-area-title">
                  {areaMapping.title}
                </Text>
              </Flex>
            )}
          </Column>
          {areaMapping.mappedExpectations.map((expectation, index) => (
            <Column
              key={`skills-matrix-expectation-${areaMapping.id}--${index}`}
              className="flex flex-col"
            >
              {!!editionMode && (
                <SkillExpectationPicker
                  expectation={expectation.id !== '0' ? expectation : null}
                  areaMapping={areaMapping}
                  levelId={matrix.levels[index].id}
                />
              )}
              {expectation.id !== '0' && (
                <ExpectationBox
                  areaId={areaMapping.areaId}
                  expectation={expectation}
                  editionMode={editionMode}
                  showFullText={showFullText}
                  toggleShowFullText={toggleShowFullText}
                />
              )}
            </Column>
          ))}
        </Columns>
      </BoxListItem>
    </Fragment>
  );
};

export default AreaMappingBoxListItem;
