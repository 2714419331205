import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { FeedbackableItem, Signature, User } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import transformProps from 'helpers/transformProps';
import { withActiveUser } from 'helpers/withSessionProps';

import { post, put } from 'redux/actions/api';

import { Divider, Flex } from 'components';

import SignatureStatus from './SignatureStatus';
import UserAvatarWithSignature from './UserAvatarWithSignature';

type Props = {
  feedbackableItem: FeedbackableItem<Signature>;
  withFinalComment: boolean;
  isDisabled: boolean;
};

type AfterConnectProps = Props & {
  activeUser: User;
  onSignClick: () => Promise<void>;
  onFinalCommentChange: () => Promise<void>;
};

type AfterTransformProps = AfterConnectProps & {
  signature: Signature;
  isSigned: boolean;
  isNotSignedYet: boolean;
};

function SignatureComponent({
  signature,
  withFinalComment,
  isSigned,
  isNotSignedYet,
  isDisabled,
  onSignClick,
  onFinalCommentChange,
}: AfterTransformProps) {
  const { author } = signature;

  return (
    <Flex className="signature" direction="column">
      <UserAvatarWithSignature
        signature={signature}
        withFinalComment={withFinalComment}
        isDisabled={isDisabled}
        onFinalCommentChange={onFinalCommentChange}
        onSignClick={onSignClick}
      />

      <div style={{ marginLeft: 64, marginTop: 8 }}>
        <SignatureStatus
          authorFullName={author.fullName}
          isSigned={isSigned}
          isNotSignedYet={isNotSignedYet}
          isDisabled={isDisabled}
          withFinalComment={withFinalComment}
        />
      </div>

      {!isDisabled && (
        <Divider
          style={{
            marginTop: 32,
            marginBottom: 12,
            backgroundColor: 'lightgray',
          }}
        />
      )}
    </Flex>
  );
}

function mapDispatchToProps(
  dispatch: AppDispatch,
  { feedbackableItem }: Props
) {
  const signature = feedbackableItem.item;

  return {
    onSignClick: () =>
      dispatch(post(`user_reviews/${signature.userReviewId}/sign`)),
    onFinalCommentChange: value => {
      return dispatch(
        put(`signatures/${signature.id}`, {
          finalComment: value,
        })
      );
    },
  };
}

function propsTransformer({
  feedbackableItem,
  activeUser,
  isDisabled,
}: AfterConnectProps) {
  const signature = feedbackableItem.item;
  const { author, isSigned } = signature;

  // FIXME: We should probably add an ability
  const canSign = activeUser.id === author.id;

  return {
    signature: signature,
    isSigned: isSigned,
    isNotSignedYet: !isDisabled && !isSigned,
    isDisabled: isDisabled || isSigned || !canSign,
  };
}

export default compose(
  connect(null, mapDispatchToProps),
  // @ts-expect-error: For some reason TS doesn't like when we use connect with compose
  withActiveUser,
  transformProps(propsTransformer)
)(SignatureComponent) as React.ComponentType<Props>;
