import React, { useContext, useState } from 'react';
import Lazyload from 'react-lazyload';

import type { MultipleScaleQuestionBlock, UserFilterSegment } from 'models';

import QuestionBlockComponent from 'scenes/components/review/QuestionBlock';

import { UserFiltersContext } from '..';
import AnswersExplorer from '../AnswersExplorer';
import FeedbackPerOptionModal from '../FeedbackPerOptionModal';
import MultipleScaleRadarChart from '../MultipleScaleRadarChart';

type Props = {
  block: MultipleScaleQuestionBlock;
  reviewCycleId: string;
  userFilter: UserFilterSegment | undefined | null;
};

const MultipleScaleQuestionBlockResults = ({
  block,
  reviewCycleId,
  userFilter,
}: Props) => {
  const { userFilters } = useContext(UserFiltersContext);
  const [lastCriterionSelected, setLastCriterionSelected] = useState<
    string | null
  >(null);
  const [isModalActive, setIsModalActive] = useState(false);

  const toggleModal = () => setIsModalActive(!isModalActive);

  const onCriterionClick = (criterion: string) => {
    setLastCriterionSelected(criterion);
    toggleModal();
  };

  const { id, content, reviewerFeedbackOptions, revieweeFeedbackOptions } =
    block;
  const { title, description, richTextEnabled } = content;

  return (
    <QuestionBlockComponent
      title={title}
      description={description}
      testClassName="test-multiple-scale-question-block"
      withRichText={richTextEnabled}
    >
      <Lazyload height={330} offset={200} once style={{ margin: '16px 0' }}>
        <MultipleScaleRadarChart
          blockId={id}
          reviewCycleId={reviewCycleId}
          userFilter={userFilter}
          userFilters={userFilters}
          onCriterionClick={onCriterionClick}
        />
      </Lazyload>

      {(revieweeFeedbackOptions?.textEnabled ||
        reviewerFeedbackOptions.textEnabled) && (
        <Lazyload height={400} offset={200} once>
          <AnswersExplorer
            block={block}
            reviewCycleId={reviewCycleId}
            userFilter={userFilter}
            userFilters={userFilters}
          />
        </Lazyload>
      )}
      {isModalActive && !!lastCriterionSelected && (
        <FeedbackPerOptionModal
          reviewCycleId={reviewCycleId}
          block={block}
          userFilter={userFilter}
          criterion={lastCriterionSelected}
          option={block.ratingOptions[0].label}
          onClose={toggleModal}
        />
      )}
    </QuestionBlockComponent>
  );
};

export default MultipleScaleQuestionBlockResults;
