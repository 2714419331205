import React from 'react';

import { Confettis } from 'components';

type Props = {
  period: 'new_year' | undefined;
};

const SeasonalComponent = ({ period }: Props) => {
  switch (period) {
    case 'new_year':
      return (
        <Confettis
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
          }}
        />
      );
    default:
      return null;
  }
};

export default SeasonalComponent;
