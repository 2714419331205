import React from 'react';

import type { SelectedDimensionIds } from './index';
import type { ReviewedDimension } from 'models';

import { __ } from 'helpers/i18n';

import { Select, Text } from 'components';

import DimensionOption from './components/DimensionOption';

type Props = {
  reviewedDimensions: Array<ReviewedDimension>;
  selectedDimensionIds: SelectedDimensionIds;
  setSelectedDimensionIds: React.Dispatch<
    React.SetStateAction<SelectedDimensionIds>
  >;
};

type Direction = 'x' | 'y';

type Options = Array<{ value: string; label: string; isDisabled: boolean }>;

const DimensionPicker = ({
  reviewedDimensions,
  selectedDimensionIds,
  setSelectedDimensionIds,
}: Props) => {
  const getOptions = (direction: Direction): Options => {
    return reviewedDimensions.map(dimension => {
      const otherDirection = direction === 'x' ? 'y' : 'x';
      const isDisabled = selectedDimensionIds[otherDirection] === dimension.id;

      return {
        value: dimension.id,
        label: dimension.label,
        isDisabled,
      };
    });
  };

  const getCurrentDimension = (direction: Direction) =>
    reviewedDimensions.find(
      dimension => dimension.id === selectedDimensionIds[direction]
    );

  const getCurrentOption = (direction: Direction) => {
    const currentDimension = getCurrentDimension(direction);
    if (!currentDimension) return;
    return {
      value: currentDimension.id,
      label: currentDimension.label,
      isDisabled: false,
    };
  };

  const DimensionSelect = ({ dimensionDirection }) => {
    return (
      <Select
        value={getCurrentOption(dimensionDirection)}
        options={getOptions(dimensionDirection)}
        placeholder={__('Choose a dimension...')}
        onChange={option => {
          const value = option ? option.value : undefined;
          if (value !== selectedDimensionIds[dimensionDirection])
            setSelectedDimensionIds(prevState => ({
              ...prevState,
              [dimensionDirection]: value,
            }));
        }}
        components={{ Option: DimensionOption }}
        className="people-review-cycle__dimension-select"
      />
    );
  };

  return (
    <div className="flex items-center flex-wrap">
      <DimensionSelect dimensionDirection="y" />
      <Text className="px-4" preset="13s7">
        {__(' and ')}
      </Text>
      <DimensionSelect dimensionDirection="x" />
    </div>
  );
};

export default DimensionPicker;
