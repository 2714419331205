import React from 'react';

import type { MaterialIconName } from 'components';

import colors from 'styles/colors';

import { Control, Field, Icon, Text } from 'components';

type Props = {
  resetStrategy: () => void;
  email: string;
  leftIcon: MaterialIconName;
};

export default function EditEmail({ resetStrategy, email, leftIcon }: Props) {
  return (
    <Field>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        onFocus={resetStrategy}
        onClick={resetStrategy}
      >
        <Control leftIcon={leftIcon}>
          <div className="editable-text input pr-10">
            <Text className="is-text-overflow-ellipsis">{email}</Text>
          </div>
          <Icon
            color={colors.mediumGrey}
            className={'login-edit-icon'}
            name="edit"
          />
        </Control>
      </div>
    </Field>
  );
}
