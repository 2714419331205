import React from 'react';

import classNames from 'helpers/classNames';

type Props = {
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  className?: string;
};

const Content = ({ size, className, children }: Props) => (
  <div
    className={classNames('content', className, {
      [`is-${size || ''}`]: !!size,
    })}
  >
    {children}
  </div>
);

export default Content;
