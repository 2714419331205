import React, { Fragment } from 'react';

import type { Answer, User } from 'models';

import { Checkbox, DiscreteSlider } from 'components';

import groupUsersByAnswerRating from '../../helpers/groupUsersByAnswerRating';

type Props = {
  ratingOptions: string[];
  rating: string | null;
  setRating: (rating: string | null) => void;
  closedAnswers: Answer[];
  marginTopSlider?: number;
  marginBottomSlider?: number;
  renderTooltipContent?: (user: User) => string;
  hasWhiteRail?: boolean;
  testClassName?: string;
};

const RatingSliderWithCheckBox = ({
  ratingOptions,
  rating,
  setRating,
  closedAnswers,
  marginTopSlider = 20,
  marginBottomSlider = 20,
  renderTooltipContent,
  hasWhiteRail = false,
  testClassName,
}: Props) => {
  const usersBySelectedLabel = groupUsersByAnswerRating(closedAnswers);
  const sliderRailHeight = 8;
  const checkboxHeight = 24;

  return (
    <Fragment>
      <DiscreteSlider
        selectedLabel={rating}
        labels={ratingOptions}
        onChange={value => setRating(value.label)}
        usersBySelectedLabel={usersBySelectedLabel}
        renderTooltipContent={renderTooltipContent}
        className="col-span-10 pb-0"
        testClassName={testClassName}
        style={{
          marginTop: marginTopSlider,
          marginBottom: marginBottomSlider + sliderRailHeight,
        }}
        hasWhiteRail={hasWhiteRail}
        hideLabels
      />
      <div
        className="col-span-2 pl-2 border-0 border-solid border-l border-nav-border-default flex justify-center items-center"
        style={{
          paddingTop:
            marginTopSlider + sliderRailHeight / 2 - checkboxHeight / 2,
          paddingBottom:
            marginBottomSlider + sliderRailHeight / 2 - checkboxHeight / 2,
        }}
      >
        <Checkbox
          size="small"
          isChecked={rating === 'none'}
          onChange={value => setRating(value ? 'none' : null)}
        />
      </div>
    </Fragment>
  );
};

export default RatingSliderWithCheckBox;
