import React, { ReactNode } from 'react';

import type { UserFilterChangeMethod } from 'lib/dataLoader/pagination/types';
import type { UserFilterSegment } from 'models';

import { __, n__ } from 'helpers/i18n';
import { assertDefined } from 'helpers/invariant';

import {
  Button,
  DesignSystem,
  FilterBar,
  Helper,
  Icon,
  Level,
  LevelLeft,
  LevelRight,
  SearchBar,
  Testable,
  Text,
  Title6,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import UserFilter from 'scenes/components/UserFilter';

import UserMultiFilters from '../UserMultiFilters';

type Props = {
  search?: string;
  onSearchChange?: (search: string) => void;

  filters?: Array<{ param: string; label: string }>;
  activeFilter?: string | null;
  onFilterChange?: (filter: string) => void;

  userFilter?: UserFilterSegment | null | undefined;
  userFilters?: ActiveFilters;
  withUserMultiFilters?: boolean;
  onQueryParamsChange?: (newQueryParams: {
    [key: string]: ActiveFilters | string;
  }) => void;
  onUserFilterChange?: UserFilterChangeMethod;

  participantCount: number;
  participantEligibilityCount: number;
  onAddEveryoneClick?: () => Promise<any>;
  onRemoveEveryoneClick?: () => Promise<any>;

  renderImportButton?: (
    setReport: (report: ReactNode) => void,
    setError: (report: ReactNode) => void
  ) => ReactNode;
};

export default function Header({
  search,
  onSearchChange,

  filters,
  activeFilter,
  onFilterChange,

  userFilter,
  onUserFilterChange,

  userFilters,
  onQueryParamsChange,
  withUserMultiFilters,

  onAddEveryoneClick,
  onRemoveEveryoneClick,
  participantCount,
  participantEligibilityCount,

  renderImportButton,
}: Props) {
  const [report, setReport] = React.useState<ReactNode>(null);
  const [error, setError] = React.useState<ReactNode>(null);

  return (
    <React.Fragment>
      {report && (
        <Helper style={{ marginTop: 8, marginBottom: 16 }}>{report}</Helper>
      )}
      {error && (
        <Text color="danger">
          <Icon style={{ marginBottom: 4 }} name="warning" /> {error}
        </Text>
      )}
      <Level>
        <LevelLeft>
          <Title6>
            <Testable name="test-selected-participants-number">
              {n__(
                '%1 participant selected',
                '%1 participants selected',
                participantCount
              )}
            </Testable>
          </Title6>
        </LevelLeft>
        <LevelRight>
          {filters && onFilterChange && (
            <FilterBar
              display="link"
              filters={filters}
              activeFilter={activeFilter}
              onFilterChange={onFilterChange}
            />
          )}
        </LevelRight>
      </Level>
      <SearchBar
        value={search}
        placeholder={__('Search by name or email')}
        onChange={
          // TODO: multifilters: remove this if condition onSearchChange when FF is enabled for everyone
          onSearchChange
            ? onSearchChange
            : search => onQueryParamsChange && onQueryParamsChange({ search })
        }
        style={{ marginTop: 12, marginBottom: 12 }}
      />
      <DesignSystem version={2}>
        <Level style={{ marginBottom: 12 }}>
          <LevelLeft>
            {!!onUserFilterChange && (
              <UserFilter segment={userFilter} onChange={onUserFilterChange} />
            )}
            {withUserMultiFilters && (
              <UserMultiFilters
                userFilters={userFilters}
                onChange={onQueryParamsChange}
              />
            )}
          </LevelLeft>
          <LevelRight>
            {renderImportButton &&
              renderImportButton(
                report => setReport(report),
                error => setError(error)
              )}
            {onAddEveryoneClick && (
              <Button
                testClassName="test-add-everyone-button"
                color="secondary"
                onClick={onAddEveryoneClick}
                style={{ marginRight: 16 }}
              >
                <Icon
                  style={{ marginBottom: 1, marginRight: 7 }}
                  name="add_circle"
                />

                {userFilter || userFilters
                  ? n__(
                      'Add %1 result',
                      'Add %1 results',
                      assertDefined(
                        participantEligibilityCount,
                        'participantEligibilityCount must be defined'
                      )
                    )
                  : __('Add everyone')}
              </Button>
            )}
            {onRemoveEveryoneClick && (
              <Button
                testClassName="test-remove-everyone-button"
                color="secondary"
                onClick={onRemoveEveryoneClick}
              >
                <Icon
                  style={{ marginBottom: 1, marginRight: 7 }}
                  name="do_not_disturb_on"
                />

                {userFilter || userFilters
                  ? n__(
                      'Remove %1 result',
                      'Remove %1 results',
                      assertDefined(
                        participantEligibilityCount,
                        'participantEligibilityCount must be defined'
                      )
                    )
                  : __('Remove everyone')}
              </Button>
            )}
          </LevelRight>
        </Level>
      </DesignSystem>
    </React.Fragment>
  );
}
