import React from 'react';

import type { ShareBlockContent } from 'models';
import type { ReleaseActionInfo } from 'scenes/review/Review/ReleaseAction';

import breadcrumb from 'helpers/breadcrumb';
import { __ } from 'helpers/i18n';

import { Box, Button, Icon, Notification } from 'components';

import BlockDetails from 'scenes/components/review/BlockDetails';

type Props = {
  content: ShareBlockContent;
  shareAction: ReleaseActionInfo;
  withoutSectionSeparator?: boolean;
  isPreview?: boolean;
};

export default function ShareBlockContentComponent({
  content,
  shareAction,
  withoutSectionSeparator,
  isPreview,
}: Props) {
  if (!shareAction.isShown) return null;

  const { id, title, description, richTextEnabled } = content;

  return (
    <React.Fragment>
      <BlockDetails
        id={id}
        section={__('Finalize')}
        title={title}
        description={description}
        withoutSectionSeparator={withoutSectionSeparator}
        testClassName="test-block-title"
        withRichText={richTextEnabled}
      />

      <Box
        testClassName="test-share-block"
        className="block-content-wrapper share-block"
      >
        <React.Fragment>
          {shareAction &&
            shareAction.disabledReasons &&
            shareAction.disabledReasons.map(reason => {
              return (
                <Notification
                  key={reason}
                  kind="warning"
                  icon="warning"
                  style={{ display: 'block' }}
                >
                  {reason}
                </Notification>
              );
            })}
          <div className="block-actions">
            <Button
              color="secondary"
              to={isPreview ? '' : breadcrumb.getAnchor('user_review_list')}
              disabled={isPreview}
            >
              <Icon style={{ marginBottom: 2, marginRight: 4 }} name="alarm" />{' '}
              {__('Close and share later')}
            </Button>
            <Button
              color="primary"
              testClassName="test-submit-button"
              disabled={!shareAction.isEnabled || isPreview}
              onClick={shareAction.trigger}
            >
              <Icon style={{ margin: '-1px 4px 0 0' }} name="send" />
              {__('Share')}
            </Button>
          </div>
        </React.Fragment>
      </Box>
    </React.Fragment>
  );
}
