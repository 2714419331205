import React, { Fragment, useState } from 'react';

import type { TrainingSession } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import {
  Button,
  Checkbox,
  FetchContainer,
  Icon,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  StrictlySanitizedHtml,
} from 'components';

type Props = {
  session: TrainingSession;
  onClose: () => void;
  onAfterUpdate?: () => void;
  isFetching?: boolean;
  hasError?: boolean;
};

const Modal = ({
  session,
  onClose,
  onAfterUpdate,
  isFetching,
  hasError,
}: Props) => {
  const dispatch = useAppDispatch();
  const moveToForecastPlan = async () => {
    await dispatch(
      post(`training/sessions/${session.id}/unvalidate`, {
        cancelInvitations: shouldCancelInvitations,
      })
    );
    !!onAfterUpdate && onAfterUpdate();
    onClose();
  };
  const [shouldCancelInvitations, setShouldCancelInvitations] = useState(false);
  const someInvitationsCanceable =
    !!session?.cancelableInvitationCount &&
    session.cancelableInvitationCount > 0;

  return (
    <ModalCard isActive refreshContentOnOpening onClose={onClose}>
      <ModalCardHead>
        <ModalCardTitle>
          {__('Move the session to the forecast plan?')}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody className="pb-4">
        <FetchContainer
          isFetching={!!isFetching}
          hasError={!!hasError}
          render={() => {
            return (
              <Fragment>
                <p>
                  <StrictlySanitizedHtml
                    html={__(
                      'This action will <b>remove the session "%1" from the validated plan.</b>',
                      session.name || __('Untitled session')
                    )}
                  />
                </p>
                <ul className="list-disc pl-6">
                  <li>
                    <b>{__('The budget will be impacted')}</b>
                  </li>
                  <li>
                    <StrictlySanitizedHtml
                      html={__(
                        "The session <b>won't be deleted</b> and be <b>listed in the forecast plan</b>"
                      )}
                    />
                  </li>
                  <li>
                    {__(
                      'The training session informations and funding will be kept'
                    )}
                  </li>
                  <li>
                    {someInvitationsCanceable
                      ? __(
                          'The participants and their managers won’t be notified but you can cancel the calendar invitation'
                        )
                      : __(
                          'The participants and their managers won’t be notified'
                        )}
                  </li>
                </ul>
                {someInvitationsCanceable && (
                  <Fragment>
                    <br />
                    <StrictlySanitizedHtml
                      html={n__(
                        'Do you want to cancel the calendar invitation? <b> The participant will be notified</b> about the training session cancellation.',
                        'Do you want to cancel the calendar invitation? <b> The participants will be notified</b> about the training session cancellation.',
                        session.cancelableInvitationCount || 0
                      )}
                    />
                    <br />
                    <span className="flex mt-1">
                      <Checkbox
                        isChecked={shouldCancelInvitations}
                        className="mr-2"
                        onChange={() =>
                          setShouldCancelInvitations(!shouldCancelInvitations)
                        }
                      />
                      {__('Cancel the calendar invitation')}
                    </span>
                  </Fragment>
                )}
              </Fragment>
            );
          }}
        />
      </ModalCardBody>
      <ModalCardFooter>
        <Button
          onClick={moveToForecastPlan}
          color="danger"
          disabled={isFetching || hasError}
        >
          <Icon className="mr-2" name="cancel" />
          {__('Move to forecast plan')}
        </Button>
        <Button onClick={onClose} color="secondary">
          {__('Cancel')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default Modal;
