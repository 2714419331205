import { sortBy } from 'lodash';
import React, { Fragment, useContext } from 'react';

import type { MatrixErrorsType } from '.';
import type { SkillsLevel, SkillsMatrix } from 'models';
import type { EditorHandlingProps } from 'scenes/skills/helpers/useParentAndChildrenHandlingWithErrors';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { htmlSuccessNotice } from 'redux/actions';
import { del } from 'redux/actions/api';

import { Button, Column, Columns, Flex, Icon, Text, Tooltip } from 'components';
import { WithSavingStatusRecorder } from 'components/withSavingStatus';

import BuilderCreateButton from 'scenes/skills/components/BuilderCreateButton';
import { fieldUidForItem } from 'scenes/skills/helpers/useFieldStatusResetWhenLoaded';

import { DataContext } from '.';

const MatrixHeader = ({
  fieldErrorsFor,
  updateErrors,
  onChildUpdate: onLevelUpdate,
  onChildCreate: onLevelCreate,
  newCreatedChildId: levelIdToAutofocus,
}: EditorHandlingProps<SkillsMatrix, SkillsLevel>) => {
  const dispatch = useAppDispatch();

  const { matrix, refetchData, editionMode } = useContext(DataContext);

  const resetLevelErrors = (errors: MatrixErrorsType, level: SkillsLevel) => ({
    ...errors,
    levels: {
      ...errors?.levels,
      [level.id]: { ...errors?.levels?.[level.id], title: null },
    },
  });

  const levelDeletedSuccessMessage = (levelTitle: string) =>
    __(
      'The level <b>%1</b> has been successfully removed from the skills matrix <b>%2</b>',
      levelTitle,
      matrix.title
    );

  const destroyLevel = async (level: SkillsLevel) => {
    await dispatch(del(`skills/levels/${level.id}`));
    refetchData();
    dispatch(htmlSuccessNotice(levelDeletedSuccessMessage(level.title)));
  };

  const confirmDestroyLevel = (level: SkillsLevel) => {
    const usersCount = level.usersCount || 0;

    const sideEffects = [
      usersCount > 0
        ? __('Detach expectations and associated employees')
        : __('Detach expectations'),
    ];

    return confirmAsync(
      __('Delete the level?'),
      <Fragment>
        <p>
          {n__(
            '%1 employee is attached to the %2 level',
            '%1 employees are attached to the %2 level',
            usersCount,
            <b>{level.title}</b>
          )}
        </p>
        <br />
        {usersCount > 0 && (
          <Fragment>
            <p>
              {__(
                'By deleting the %1 level, the employees will be detached from this level.',
                <b>{level.title}</b>
              )}
            </p>
            <br />
          </Fragment>
        )}
        <p>
          {__(
            'The expectations of each skill will no longer be associated with this level.'
          )}
        </p>
        <br />
      </Fragment>,
      {
        confirmLabel: __('Delete the level'),
        isDanger: true,
        sideEffectsLabel: __(
          'Are you sure you want to delete the %1 level from the %2 skills matrix?',
          <b>{level.title}</b>,
          matrix.title
        ),
        sideEffects: sideEffects,
        onConfirm: () => destroyLevel(level),
      }
    );
  };

  return (
    <Columns>
      <Column>
        {editionMode && (
          <BuilderCreateButton
            className="create-button"
            disabled={matrix.levels.length === 10}
            position={0}
            disabledExplanation={__(
              'A skills matrix cannot have more than 10 levels'
            )}
            onCreate={onLevelCreate}
          />
        )}
      </Column>
      {sortBy(matrix.levels, 'position').map(level => (
        <Column key={`skills-matrix-level-${level.id}`}>
          {editionMode && (
            <BuilderCreateButton
              className={'create-button'}
              disabled={matrix.levels.length === 10}
              onCreate={onLevelCreate}
              disabledExplanation={__(
                'A skills matrix cannot have more than 10 levels'
              )}
              position={level.position + 1}
            />
          )}
          <Flex
            className="level-editor-container"
            testClassName="test-skills-matrix-level"
            verticalAlign
            style={{ justifyContent: 'space-between' }}
          >
            <div>
              <WithSavingStatusRecorder
                fieldUid={fieldUidForItem('level', level.position, 'title')}
                onChange={(title: string) => onLevelUpdate({ ...level, title })}
                render={onChange => (
                  <Text
                    testClassName="test-level-title"
                    preset="14s6"
                    weight="bold"
                    isEditable={editionMode}
                    editableOptions={{
                      type: 'singlelineText',
                      value: level.title,
                      placeholder: __('Level name'),
                      autoFocus: level.id === levelIdToAutofocus,
                      onChange,
                      onFocus: () =>
                        updateErrors(errors => resetLevelErrors(errors, level)),
                      displayErrorMessage: false,
                    }}
                  />
                )}
              />

              <WithSavingStatusRecorder
                fieldUid={fieldUidForItem(
                  'level',
                  level.position,
                  'description'
                )}
                onChange={(description: string) =>
                  onLevelUpdate({ ...level, description })
                }
                render={onChange => (
                  <div className="level-description">
                    <Text
                      preset="13s7"
                      isEditable={editionMode}
                      editableOptions={{
                        type: 'multilineText',
                        value: level.description,
                        placeholder: editionMode
                          ? __('Add a description')
                          : undefined,
                        onChange,
                        onFocus: () =>
                          updateErrors(errors =>
                            resetLevelErrors(errors, level)
                          ),
                        displayErrorMessage: false,
                      }}
                    />
                  </div>
                )}
              />
            </div>
            {fieldErrorsFor('levels', level.id)}
            {matrix.levels.length !== 1 && editionMode && (
              <Tooltip content={__('Delete Level')}>
                <Button
                  hasIconOnly
                  className="border-none bg-transparent pr-0"
                  onClick={() => confirmDestroyLevel(level)}
                >
                  <Icon color="info" size="large" name="delete" />
                </Button>
              </Tooltip>
            )}
          </Flex>
        </Column>
      ))}
    </Columns>
  );
};

export default MatrixHeader;
