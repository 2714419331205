import React, { useState } from 'react';

import { User } from 'models';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { DataLoaderProvidedProps } from 'lib/dataLoader';

import {
  Box,
  BoxFooter,
  Button,
  Column,
  Columns,
  DiscreteSliderResults,
  FetchContainer,
  Flex,
  Icon,
  Text,
  Tooltip,
} from 'components';

import RadarChartSummaryModal from './RadarChartSummaryModal';
import { SummaryData } from './summary.types';

type Props = {
  summaryData: SummaryData;
};

type AfterDataloaderProps = DataLoaderProvidedProps & Props;

const SkillsSummaryBoardContainer = ({
  summaryData,
  isFetching,
  hasError,
}: AfterDataloaderProps) => (
  <Box className="hidden md:block">
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => <SkillsSummaryBoardComponent summaryData={summaryData} />}
    />
  </Box>
);

const SkillsSummaryBoardComponent = ({ summaryData }: Props) => {
  const [radarChartSummaryModalIsActive, setRadarChartSummaryModalIsActive] =
    useState(false);

  const MAX_AREAS_DISPLAYED = 9;
  const { areasWithRatings, ratingOptions, reviewers } = summaryData;
  const [displayAllSkillAreas, setDisplayAllSkillAreas] = useState(
    areasWithRatings.length <= MAX_AREAS_DISPLAYED
  );
  const shouldDisplayBlockFooter =
    areasWithRatings.length > MAX_AREAS_DISPLAYED;

  return (
    <Flex direction="column">
      <Flex
        style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}
      >
        <Text preset="16bs5.5" className="mb-4">
          {__('Skills Summary')}
        </Text>
        <Button
          hasIconOnly
          className="p-2 border-none bg-secondary"
          onClick={() => setRadarChartSummaryModalIsActive(true)}
          color="secondary"
        >
          <Icon name="track_changes" />
        </Button>
      </Flex>
      <Text className="mb-6">
        {__(
          'Find the summary of feedbacks from the employee and the main reviewer for each skill.'
        )}
      </Text>
      {areasWithRatings.map((area, index) => {
        if (index > MAX_AREAS_DISPLAYED && !displayAllSkillAreas) {
          return null;
        }

        const isFirst = index === 0;

        const usersBySelectedLabel = area.ratings.reduce<{
          [key: string]: User[];
        }>((acc, rating) => {
          const user = reviewers.find(
            reviewer => reviewer.id === rating.reviewerId.toString()
          );

          if (!(rating.label in acc)) {
            acc[rating.label] = [];
          }

          // @ts-ignore TSFIXME: Fix strictNullChecks error
          acc[rating.label].push(user);

          return acc;
        }, {});

        return (
          <Columns
            key={index}
            className={classNames('pb-2', {
              'items-end': isFirst,
            })}
          >
            <Column size={2} className="py-0 pr-0 h-8">
              <Text preset="13bs7" className="line-clamp-2 text-ellipsis">
                <Tooltip content={area.title} placement="top">
                  {area.title}
                </Tooltip>
              </Text>
            </Column>
            <Column className="px-0 pt-1 pb-4">
              <DiscreteSliderResults
                hideLabels={!isFirst}
                labels={ratingOptions.map(ratingOption => ratingOption.label)}
                usersBySelectedLabel={usersBySelectedLabel}
              />
            </Column>
          </Columns>
        );
      })}
      {shouldDisplayBlockFooter && (
        <BoxFooter
          onClick={() => setDisplayAllSkillAreas(!displayAllSkillAreas)}
        >
          <Icon
            size="tiny"
            style={{ verticalAlign: 'text-bottom' }}
            name={
              displayAllSkillAreas ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
            }
          />
          {displayAllSkillAreas ? __('Show less') : __('Show more')}
        </BoxFooter>
      )}
      <RadarChartSummaryModal
        ratingOptions={ratingOptions}
        ratings={areasWithRatings}
        isActive={radarChartSummaryModalIsActive}
        onClose={() => setRadarChartSummaryModalIsActive(false)}
      />
    </Flex>
  );
};

export default SkillsSummaryBoardContainer;
