import React from 'react';

import type { User } from 'models';

import { n__ } from 'helpers/i18n';

import { Flex, Notification, Text } from 'components';

type Props = {
  users: Array<User>;
};

const AlreadyInTheSessionWarning = ({ users }: Props) => (
  <Notification kind="warning" className="mb-0" icon="warning">
    <Flex direction="column" className="gap-0">
      <Text preset="13bs7">
        {n__(
          'One employee is already in the session',
          '%1 employees are already in the session',
          users.length
        )}
      </Text>
      <Text preset="13s7">
        {n__(
          'The following employee is already in the training session:',
          'The following employees are already in the training session:',
          users.length
        )}
        <ul>
          {users.map(user => (
            <li key={user.id} className="mb-0">
              {user.fullName}
            </li>
          ))}
        </ul>
      </Text>
    </Flex>
  </Notification>
);
export default AlreadyInTheSessionWarning;
