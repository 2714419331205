import { sortBy } from 'lodash';
import React from 'react';

import type { KeyResult } from 'models';

import { __ } from 'helpers/i18n';

import {
  Button,
  DragAndDropContainer,
  DragAndDropItem,
  Icon,
  Placeholder,
  Title,
} from 'components';

import KeyResultItem from './KeyResultItem';

type Props = {
  keyResults: Array<KeyResult>;
  disabled: boolean;
  weighted: boolean;
  collapsible: boolean;
  weightSumWarning: string | null | undefined;

  onAddKeyResult: () => Promise<void>;
  onUpdateKeyResult: (
    keyResultId: string,
    attributes: Partial<KeyResult>
  ) => Promise<void>;
  onDestroyKeyResult: (keyResultId: string) => Promise<void>;
};

export default function KeyResultsSection({
  keyResults,
  disabled,
  weighted,
  collapsible,
  weightSumWarning,

  onAddKeyResult,
  onUpdateKeyResult,
  onDestroyKeyResult,
}: Props) {
  const [showKeyResults, setShowKeyResults] = React.useState(true);

  if (disabled && keyResults.length === 0) return null;

  const toggleKeyResultsVisibility = () => {
    if (collapsible) {
      setShowKeyResults(!showKeyResults);
    }
  };

  return (
    <div className="key-result-section">
      <Title size={7} className="is-uppercase" style={{ marginBottom: 8 }}>
        {collapsible && (
          <Icon
            size="small"
            style={{
              marginRight: 8,
              verticalAlign: 'text-bottom',
              cursor: 'pointer',
            }}
            onClick={toggleKeyResultsVisibility}
            name={
              showKeyResults ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
            }
          />
        )}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <span
          onClick={toggleKeyResultsVisibility}
          style={{
            cursor: collapsible ? 'pointer' : 'default',
          }}
        >
          {__('Key results')}
        </span>
      </Title>

      {showKeyResults && (
        <div className="objective-section-content" style={{ marginTop: 0 }}>
          {keyResults?.length > 0 && (
            <DragAndDropContainer
              onChangePosition={(itemId, newIndex) =>
                onUpdateKeyResult(itemId, { position: newIndex })
              }
            >
              {sortBy(keyResults, 'position').map(keyResult => (
                <DragAndDropItem
                  key={keyResult.id}
                  itemId={keyResult.id}
                  position={keyResult.position}
                  className="key-result-item"
                  disabled={disabled}
                  alwaysDisplayHandle
                >
                  <KeyResultItem
                    keyResult={keyResult}
                    disabled={disabled}
                    weighted={weighted}
                    weightSumWarning={weightSumWarning}
                    onChange={onUpdateKeyResult}
                    onDestroy={onDestroyKeyResult}
                  />
                </DragAndDropItem>
              ))}
            </DragAndDropContainer>
          )}

          {!disabled && (
            <Button
              size="small"
              className="add-key-result-button"
              testClassName="test-new-key-result"
              color="secondary"
              onClick={onAddKeyResult}
            >
              {__('Add a key result')}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export function KeyResultsSectionPlaceholder() {
  return (
    <div className="key-result-section">
      <Title size={6} className="is-uppercase">
        <Icon
          size="small"
          style={{ marginRight: 8, verticalAlign: 'text-bottom' }}
          name="keyboard_arrow_down"
        />

        {__('Key results')}
      </Title>

      <div className="objective-section-content" style={{ marginTop: 0 }}>
        <div>
          <Placeholder width="60%" />
        </div>
        <Button size="small" className="add-key-result-button" disabled>
          {__('Add a key result')}
        </Button>
      </div>
    </div>
  );
}
