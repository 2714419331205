import React, { ReactNode } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { CollectionInfo, ParticipantEligibility } from 'models';

import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { WithPaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { del, get, post } from 'redux/actions/api';

import { DatatableWrapper, FetchContainer, Testable } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import Header from './Header';
import ParticipantList from './ParticipantList';

type Props = {
  fetchUrl: string;
  updateUrl: string;
  additionalUpdateParams?: { [key: string]: number };
  participantsCount: number;
  participantListStyle?: {};
  showBulkActions: boolean;
  countPerPage?: number;
  defaultSort?: string;
  defaultFilter?: { [key: string]: boolean };
  withUrlChange?: boolean;
  renderAfter?: (refetch: () => Promise<any>) => ReactNode;
  renderImportButton?: (
    refetchData: () => Promise<void>,
    setReport: (report: ReactNode) => void,
    setError: (report: ReactNode) => void
  ) => ReactNode;
};

type AfterPaginateProps = Props & WithPaginationProps;

type ParticipantEligilibityCollection = {
  items: Array<ParticipantEligibility>;
} & CollectionInfo;

type AfterDataloaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    participantEligibilityCollection: ParticipantEligilibityCollection;
  };

const ParticipantsChooser: React.FC<AfterDataloaderProps> = ({
  participantEligibilityCollection,
  isFetching,
  hasError,
  refetchData,
  showBulkActions = false,
  participantsCount,
  renderAfter,
  queryParams: { search, filter, userFilters },
  countPerPage,
  page,
  setNextPageParams,
  setPreviousPageParams,
  setQueryParams,
  renderImportButton,
  updateUrl,
  additionalUpdateParams,
}) => {
  const dispatch = useAppDispatch();
  const filterParams = userFilters
    ? { userFilters }
    : { filterType: 'everyone' };

  const addParticipant = async (participantId: string) => {
    await dispatch(
      post(updateUrl, {
        participantId,
        ...additionalUpdateParams,
      })
    );
    await refetchData();
  };

  const removeParticipant = async (participantId: string) => {
    await dispatch(
      del(updateUrl, {
        participantId,
        ...additionalUpdateParams,
      })
    );
    await refetchData();
  };

  const addEveryone = async () => {
    await dispatch(
      post(updateUrl, { ...filterParams, ...additionalUpdateParams })
    );
    await refetchData();
  };

  const removeEveryone = async () => {
    await dispatch(
      del(updateUrl, { ...filterParams, ...additionalUpdateParams })
    );
    await refetchData();
  };

  if ((isFetching && !participantEligibilityCollection) || hasError) {
    return <FetchContainer isFetching={isFetching} hasError={hasError} />;
  }

  const { items, ...collectionInfo } = participantEligibilityCollection;

  return (
    <Testable name="test-participants-chooser">
      <div className="participants-chooser">
        <DatatableWrapper
          collectionInfo={collectionInfo}
          isFetching={isFetching}
          hasError={hasError}
          search={search}
          countPerPage={countPerPage}
          onQueryParamsChange={setQueryParams}
          filters={[
            { param: 'all', label: __('Everyone') },
            { param: 'selected', label: __('Selected') },
            { param: 'not_selected', label: __('Not selected') },
          ]}
          filter={filter}
          userFilters={userFilters}
          withUserMultiFilters
          page={page}
          getNextPage={setNextPageParams}
          getPreviousPage={setPreviousPageParams}
          showTotalRecordCount={false}
          renderHeader={({ search, filters, activeFilter, userFilters }) => (
            <Header
              search={search}
              filters={filters}
              activeFilter={activeFilter}
              withUserMultiFilters
              onQueryParamsChange={setQueryParams}
              userFilters={userFilters as ActiveFilters}
              participantEligibilityCount={collectionInfo.totalRecordCount}
              onAddEveryoneClick={showBulkActions ? addEveryone : undefined}
              onRemoveEveryoneClick={
                showBulkActions ? removeEveryone : undefined
              }
              participantCount={participantsCount}
              renderImportButton={(setReport, setError) =>
                renderImportButton?.(refetchData, setReport, setError)
              }
            />
          )}
        >
          <ParticipantList
            eligibilities={items}
            onRemoveParticipant={removeParticipant}
            onAddParticipant={addParticipant}
            isFetching={isFetching}
            hasError={hasError}
          />
        </DatatableWrapper>
        {renderAfter && renderAfter(refetchData)}
      </div>
    </Testable>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
      fetchUrl,
    }: AfterPaginateProps) =>
      get(fetchUrl, {
        page,
        countPerPage,
        search,
        filter,
        userFilters,
      }),
    hydrate: {
      participantEligibilityCollection: {
        userFilter: {},
        items: {
          reviewee: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, filter, userFilters },
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter, userFilters }),
  })
)(ParticipantsChooser);
