import React from 'react';

import { StrictlySanitizedHtml } from 'components';

import Notification from '../Notification';
import { AppContentContainer } from '../appLayout';
import DefaultErrorMessage from './DefaultErrorMessage';

type Props = {
  message?: string;
};

export default function GlobalErrorNotice({ message }: Props) {
  return (
    <AppContentContainer>
      <Notification kind="danger" className="global-error-notice">
        {message ? (
          <StrictlySanitizedHtml html={message} />
        ) : (
          <DefaultErrorMessage />
        )}
      </Notification>
    </AppContentContainer>
  );
}
