import React, { Fragment, useState } from 'react';

import type { Integration } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { del, post, put } from 'redux/actions/api';

import { HamburgerMenu, Icon, MenuItem, MenuList } from 'components';

import IntegrationModal from '../IntegrationModal';
import ConfirmationModal from './ConfirmationModal';

type Props = {
  integration: Integration;
  refetchIntegrations: () => Promise<void>;
};

const Actions = ({ integration, refetchIntegrations }: Props) => {
  const [isEditionModalActive, setIsEditionModalActive] = useState(false);
  const [isLaunchSyncModalActive, setIsLaunchSyncModalActive] = useState(false);

  const dispatch = useAppDispatch();
  const updateIntegration = (integration: Partial<Integration>) =>
    dispatch(put(`integrations/${integration.id}`, { integration }));
  const launchSyncTest = async () => {
    await dispatch(
      post(`integrations/${integration.id}/prepare_organization_sync`)
    );
    // We don't use refetchIntegrations here because the action is async and the refetch is too fast
    window.location.reload();
  };
  const launchManualSync = async () => {
    await dispatch(
      post(`integrations/${integration.id}/launch_organization_sync`)
    );
    // We don't use refetchIntegrations here because the action is async and the refetch is too fast
    window.location.reload();
  };

  const destroyIntegration = () =>
    confirmAsync(
      __('Delete this %1 integration', integration.integrationType),
      __(
        'This action is irreversible. Are you sure you want to delete this integration?'
      ),
      {
        inlineButtonIcon: <Icon name="delete" className="mr-2" />,
        confirmLabel: __('Delete'),
        isDanger: true,
        onConfirm: async () => {
          await dispatch(del(`integrations/${integration.id}`));
          refetchIntegrations();
        },
      }
    );

  return (
    <Fragment>
      <HamburgerMenu align="right">
        <MenuList>
          <MenuItem onClick={() => setIsEditionModalActive(true)} key="edit">
            {__('Edit')}
          </MenuItem>
          {can({ perform: 'prepare_organization_sync', on: integration }) && (
            <MenuItem onClick={launchSyncTest} key="launchSyncTest">
              {__('Launch a synchronization test')}
            </MenuItem>
          )}
          {can({
            perform: 'launch_organization_sync',
            on: integration,
          }) && (
            <MenuItem
              onClick={() => setIsLaunchSyncModalActive(true)}
              key="launchManualSync"
            >
              {__('Launch a manual synchronization')}
            </MenuItem>
          )}
          {can({ perform: 'destroy', on: integration }) && (
            <MenuItem
              onClick={destroyIntegration}
              key="destroyManualSync"
              isDanger
            >
              {__('Delete this integration')}
            </MenuItem>
          )}
        </MenuList>
      </HamburgerMenu>
      {!!isEditionModalActive && (
        <IntegrationModal
          onConfirm={updateIntegration}
          integration={integration}
          onClose={() => setIsEditionModalActive(false)}
        />
      )}
      {!!isLaunchSyncModalActive && (
        <ConfirmationModal
          integration={integration}
          onCancel={() => setIsLaunchSyncModalActive(false)}
          onConfirm={launchManualSync}
        />
      )}
    </Fragment>
  );
};

export default Actions;
