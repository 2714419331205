import React from 'react';

import { __ } from 'helpers/i18n';
import { pathToTrainingCourses, pathToTrainingOrganisms } from 'helpers/paths';

import { PageHeader as DefaultPageHeader } from 'components';

const PageHeader = () => {
  const tabItems = [
    {
      label: __('Training courses'),
      to: pathToTrainingCourses(),
    },
    {
      label: __('Training organizations'),
      to: pathToTrainingOrganisms(),
    },
  ];
  return (
    <DefaultPageHeader title={__('Catalog management')} tabItems={tabItems} />
  );
};

export default PageHeader;
