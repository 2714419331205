import React, { Fragment, ReactNode, useState } from 'react';

import {
  SurveyFormStep,
  isMultipleChoiceQuestionFormStep,
  isQuestionFormStep,
  isSingleChoiceQuestionFormStep,
} from 'models';

import { FormErrors, handleFormErrors } from 'helpers/api';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useAppDispatch, useOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post, put } from 'redux/actions/api';

import {
  Box,
  BoxSeparator,
  Button,
  Checkbox,
  FieldError,
  Icon,
  Text,
} from 'components';

import { getContentStyle } from 'scenes/surveys/Campaign/helpers/steps';

import QuestionChoices from './components/QuestionChoices';
import ScaleQuestionDetails from './components/ScaleQuestionDetails';
import TextSentiment from './components/TextSentiment';

type Props = {
  formStep: SurveyFormStep;
  formId: string;
  onDelete: () => void;
};

const FormStep = ({ formStep, formId, onDelete }: Props) => {
  const dispatch = useAppDispatch();
  const content = isQuestionFormStep(formStep)
    ? formStep.question
    : formStep.instruction;
  const organization = useOrganization();

  const [errors, setErrors] = useState<FormErrors>({});
  const [title, setTitle] = useState<string | null | undefined>(content.title);
  const [description, setDescription] = useState<string | null | undefined>(
    content.description
  );

  const { label, iconName } = getContentStyle(formStep);

  const testType = isQuestionFormStep(formStep)
    ? formStep.question.questionType
    : 'instruction';

  const onUpdate = params => {
    return handleFormErrors(async () => {
      await dispatch(
        put(`survey/forms/${formId}/form_steps/${formStep.id}`, params, {
          withDefaultErrorHandling: false,
        })
      );
      setErrors({});
    }, setErrors);
  };

  const onDuplicate = () => {
    dispatch(
      post(`survey/forms/${formId}/form_steps/${formStep.id}/duplicate`)
    );
  };

  const additionalSettings: ReactNode[] = [];
  if (
    isQuestionFormStep(formStep) &&
    formStep.question.questionType !== 'text'
  ) {
    additionalSettings.push(
      <Fragment key="comment">
        <div className="flex items-center gap-2 test-survey-edition-allow-answers">
          <Checkbox
            isChecked={formStep.commentEnabled}
            size="small"
            onChange={value => onUpdate({ commentEnabled: value })}
          />
          <Text>{__('The participant can leave a comment')}</Text>
        </div>
      </Fragment>
    );
  }

  return (
    <Box
      className="p-4 mt-4 border border-solid border-text-lightest/60"
      testClassName={`test-survey-edition-form-step test-survey-edition-form-step-${testType}`}
    >
      <div className="flex justify-between">
        <div className="mb-4 flex items-center gap-2">
          <Icon name={iconName} />
          <Text preset="13buppers7">{label}</Text>
        </div>
        <div className="mb-4 flex items-center">
          <Button
            hasIconOnly
            isText
            className="no-underline"
            onClick={onDuplicate}
          >
            <Icon name="content_copy" />
          </Button>
          <Button
            hasIconOnly
            isText
            className="no-underline"
            onClick={onDelete}
          >
            <Icon name="delete" />
          </Button>
        </div>
      </div>
      <div className="mb-4">
        <Text
          preset="16s5.5"
          weight="semibold"
          className="mb-4"
          isEditable
          editableOptions={{
            type: 'multilineText',
            onChange: newTitle => setTitle(newTitle),
            value: title,
            placeholder: __('Add a title'),
            onBlur: () => onUpdate({ content: { title } }),
          }}
          testClassName="test-survey-edition-form-step-title"
        >
          {title}
        </Text>
        <FieldError>{errors.title}</FieldError>
      </div>
      <div className="mb-4">
        <Text
          preset="16s5.5"
          className="mb-4"
          isEditable
          editableOptions={{
            type: 'multilineText',
            onChange: newDescription => setDescription(newDescription),
            value: description,
            placeholder: __('Add a description'),
            onBlur: () => onUpdate({ content: { description } }),
          }}
        >
          {description}
        </Text>
      </div>
      {isQuestionFormStep(formStep) && (
        <Fragment>
          {formStep.question.questionType === 'scale' && (
            <ScaleQuestionDetails
              minValue={formStep.question.minValue}
              maxValue={formStep.question.maxValue}
              leftLabel={formStep.question.leftLabel}
              rightLabel={formStep.question.rightLabel}
              onUpdate={onUpdate}
              errors={errors}
            />
          )}
          {formStep.question.questionType === 'text' &&
            organization.aiFeaturesEnabled && (
              <TextSentiment
                enabled={formStep.question.textSentimentEnabled}
                onUpdate={onUpdate}
              />
            )}
          {(isMultipleChoiceQuestionFormStep(formStep) ||
            isSingleChoiceQuestionFormStep(formStep)) && (
            <QuestionChoices formStep={formStep} />
          )}
          {additionalSettings.length > 0 && (
            <BoxSeparator style={{ margin: '16px 0' }} />
          )}
          {additionalSettings}
        </Fragment>
      )}
    </Box>
  );
};

export default FormStep;
