import React from 'react';

import { TrainingCourse } from 'models/TrainingCourse';

import { FormErrors } from 'helpers/hooks/useForm';

import { Box, Button, Divider, PullRight } from 'components';

import CertificationBlock from './CertificationBlock';
import FormatCostBlock from './FormatCostBlock';
import GeneralInfoBlock, { CourseInputChangeHandler } from './GeneralInfoBlock';

type Props = {
  trainingCourse: Partial<TrainingCourse>;
  handleInputChange: CourseInputChangeHandler;
  errors: FormErrors;
  saveButtonLabel: string;
  isDirty: boolean;
  onSave: () => void;
};

const CourseFormDetails = ({
  trainingCourse,
  handleInputChange,
  saveButtonLabel,
  isDirty,
  errors,
  onSave,
}: Props) => {
  return (
    <Box>
      <GeneralInfoBlock
        trainingCourse={trainingCourse}
        handleInputChange={handleInputChange}
        errors={errors}
      />
      <Divider />
      <FormatCostBlock
        trainingCourse={trainingCourse}
        handleInputChange={handleInputChange}
        errors={errors}
      />
      <Divider />
      <CertificationBlock
        trainingCourse={trainingCourse}
        handleInputChange={handleInputChange}
        errors={errors}
      />

      {isDirty && (
        <PullRight>
          <div className="mt-8">
            <Button color="primary" onClick={onSave}>
              {saveButtonLabel}
            </Button>
          </div>
        </PullRight>
      )}
    </Box>
  );
};

export default CourseFormDetails;
