import React, { Fragment, useState } from 'react';
import { useContext } from 'react';

import useSearch from 'helpers/hooks/useSearch';
import { __ } from 'helpers/i18n';

import { FetchContainer, Icon, Link, Radio, SearchBar, Text } from 'components';

import { DataContext } from './DataContext';
import { FilterSegment, FilterableField } from './types';

type Props = {
  activeSegment: FilterSegment | null;
  onSegmentSelect: (segment: FilterSegment | null) => void;
  onFilterChange: (filter: FilterableField | null) => void;
  selectedFilter: FilterableField | null;
};

type ComponentProps = {
  activeSegment: FilterSegment | null;
  filterSegments: Array<FilterSegment>;
  onSegmentSelect: (segment: FilterSegment | null) => void;
  selectedFilter: FilterableField | null;
};

const SegmentsComponent = ({
  activeSegment,
  filterSegments,
  onSegmentSelect,
  selectedFilter,
}: ComponentProps) => {
  const [shownFilterSegments, searchFunction, segmentSearch] = useSearch(
    filterSegments,
    element => element.label
  );

  const [selectedSegment, setSelectedSegment] = useState<FilterSegment | null>(
    activeSegment
  );

  if (filterSegments.length === 0) {
    return (
      <div className="mt-2">
        <Text>{__('No value is defined on this field')}</Text>
      </div>
    );
  }

  return (
    <Fragment>
      <SearchBar
        value={segmentSearch}
        placeholder={__('Search for a %1', selectedFilter?.label)}
        onChange={searchFunction}
        className="my-2"
      />

      <div className="flex flex-col">
        {shownFilterSegments.map(segment => {
          const isChecked =
            segment.label === selectedSegment?.label &&
            segment.value === selectedSegment?.value;
          return (
            <Radio
              key={segment.value}
              label={segment.label}
              isChecked={isChecked}
              onChange={() => {
                const value = isChecked ? null : segment;

                setSelectedSegment(value);
                onSegmentSelect(value);
              }}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

const Segments = ({
  activeSegment,
  onSegmentSelect,
  onFilterChange,
  selectedFilter,
}: Props) => {
  const { isFetchingSegments, hasSegmentsError, filterSegments } =
    useContext(DataContext);

  return (
    <Fragment>
      <Link onClick={() => onFilterChange(null)}>
        <Icon name="arrow_back" className="mr-2" />
        <span>{__('Back')}</span>
      </Link>

      <FetchContainer
        isFetching={isFetchingSegments}
        hasError={hasSegmentsError}
        render={() =>
          filterSegments && (
            <SegmentsComponent
              activeSegment={activeSegment}
              filterSegments={filterSegments}
              onSegmentSelect={onSegmentSelect}
              selectedFilter={selectedFilter}
            />
          )
        }
      />
    </Fragment>
  );
};

export default Segments;
