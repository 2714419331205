import classNames from 'classnames';
import React, { ReactNode } from 'react';

import {
  AnimatedProgressBar,
  Button,
  Icon,
  Text,
  TooltipOnEllipsis,
} from 'components';

type Props = {
  filename: string;
  fileInfo?: ReactNode;
  progressInfo?: ReactNode;
  progress?: number | 'indeterminate';
  hasError?: boolean;
  onDelete?: () => void;
};

const FileInfo = ({
  filename,
  fileInfo,
  progressInfo,
  progress,
  hasError,
  onDelete,
}: Props) => {
  return (
    <div
      className={classNames(
        hasError ? 'bg-red-fuckup/20' : 'bg-ui-highlight-gray',
        'px-4 py-2 rounded flex flex-col gap-2'
      )}
    >
      <div className="flex items-center gap-4">
        {hasError ? (
          <Icon color="danger" name="warning" />
        ) : (
          <Icon name="description" />
        )}
        <div className="flex flex-col grow overflow-hidden">
          <div className="flex items-center justify-between">
            <Text preset="13bs7" className="grow overflow-hidden">
              <TooltipOnEllipsis>{filename}</TooltipOnEllipsis>
            </Text>
            {onDelete && (
              <Button
                hasIconOnly
                className="border-none p-1 bg-transparent"
                onClick={onDelete}
              >
                <Icon name="close" />
              </Button>
            )}
          </div>
          <div className="flex items-center justify-between">
            <Text color={hasError ? 'danger' : 'soften'}>{fileInfo}</Text>
            <Text color="soften">{progressInfo}</Text>
          </div>
        </div>
      </div>
      {progress !== undefined && (
        <AnimatedProgressBar
          progress={progress === 'indeterminate' ? null : progress}
        ></AnimatedProgressBar>
      )}
    </div>
  );
};

export default FileInfo;
