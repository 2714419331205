import React, { useState } from 'react';
import { batch } from 'react-redux';

import type { ENPSAnswer } from 'models';

import { __ } from 'helpers/i18n';

import { Box, Icon, RadioScale, Text, Textarea } from 'components';

type Props = {
  organizationName: string;
  enpsAnswer: ENPSAnswer;
  onCreateAnswer: (answer: Partial<ENPSAnswer>) => Promise<null>;
  onUpdateAnswer: (answer: Partial<ENPSAnswer>) => Promise<null>;
  onAfterSubmit?: () => void;
};

const Form = ({
  organizationName,
  enpsAnswer,
  onCreateAnswer,
  onUpdateAnswer,
  onAfterSubmit,
}: Props) => {
  const [score, setScore] = useState<number | null>(
    !!enpsAnswer ? enpsAnswer.score : null
  );
  const [comment, setComment] = useState<string | undefined | null>(
    enpsAnswer?.comment
  );
  const [isUpdate, setIsUpdate] = useState<boolean>(!!enpsAnswer);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    isUpdate
      ? // @ts-ignore TSFIXME: Fix strictNullChecks error
        await onUpdateAnswer({ score, comment })
      : // @ts-ignore TSFIXME: Fix strictNullChecks error
        await onCreateAnswer({ score, comment });

    batch(() => {
      if (!isUpdate) setIsUpdate(true);
      setHasChanged(false);
    });

    if (onAfterSubmit) onAfterSubmit();
  };

  const isDisabled = score === null || !hasChanged;

  return (
    <Box className="max-md:p-2">
      <form onSubmit={onSubmit} className="flex flex-col gap-2 md:gap-8">
        <Text preset="18bs5">
          {__(
            'On a scale from 0 to 10, how likely are you to recommend %1 as a workplace?',
            organizationName
          )}
        </Text>
        <RadioScale
          name="enps-radio"
          values={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
          selectedValue={score}
          legends={[__('Not at all'), __('Absolutely')]}
          onChange={value => {
            batch(() => {
              setScore(value);
              setHasChanged(true);
            });
          }}
        />
        <Textarea
          placeholder={__('Add your comment here')}
          value={comment}
          maxLength={1000}
          onChange={(value: string) => {
            batch(() => {
              setComment(value);
              setHasChanged(true);
            });
          }}
        />
        <div className="flex justify-center gap-2">
          <button className="button is-primary" disabled={isDisabled}>
            <Icon style={{ marginRight: 8 }} name="send" />
            {isUpdate ? __('Resend') : __('Send')}
          </button>
        </div>
      </form>
    </Box>
  );
};

export default Form;
