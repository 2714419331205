import React, { Fragment, useState } from 'react';

import { formatData } from 'helpers/horizontalBarChart';
import { __ } from 'helpers/i18n';

import { Icon, Link, Text, Tooltip } from 'components';

type ItemFromProps = {
  percent: number;
  label: string;
  color?: string;
};

type Props = {
  data: ItemFromProps[];
};

type Item = {
  percent: number;
  label: string;
  color: string;
  width: string;
};

const HorizontalBarChart = ({ data: dataFromProps }: Props) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const data: Item[] = formatData(dataFromProps);

  return (
    <Fragment>
      <div className="horizontal-bar-chart">
        {data.slice(0, showMore ? data.length : 4).map((item, index) => (
          <div key={index} className="flex justify-start items-center py-1">
            <Tooltip content={item.label} placement="top">
              <span className="w-44 mr-2 flex-shrink-0 line-clamp-2 leading-4">
                {item.label}
              </span>
            </Tooltip>
            <div className="w-full pr-8">
              <div
                className="rounded-full h-6 relative"
                style={{
                  width: item.width,
                  backgroundColor: item.color,
                }}
              >
                <Text
                  preset="18bs5"
                  className={`ml-2 absolute ${item.percent === 0 && 'ml-0'}`}
                  style={{ left: 'calc(100% + 4px)' }}
                >
                  {item.percent}%
                </Text>
              </div>
            </div>
          </div>
        ))}
        {data.length > 4 && (
          <Link
            onClick={() => setShowMore(showMore => !showMore)}
            className="text-text-light mt-2 underline"
          >
            <Text preset="14s6">
              {showMore ? __('Show less') : __('Show more')}
            </Text>
            <Icon
              className="pl-2"
              size="tiny"
              name={showMore ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            />
          </Link>
        )}
      </div>
    </Fragment>
  );
};

export default HorizontalBarChart;
