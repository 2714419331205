import React, { useContext } from 'react';

import type { ReviewCycle, UserFilterType } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { ContentContainer } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import Skills from 'scenes/components/reviewCycle/analytics/Skills';

import { DataContext } from '..';

type Props = {
  reviewCycle: ReviewCycle;
};

const ManagerCycleSkills = ({ reviewCycle }: Props) => {
  const activeUser = useActiveUser();
  const { userFilters, withUserMultiFilters, setUserFilters } =
    useContext(DataContext);
  const defaultUserFilter = {
    filterLabel: __('Direct reports of'),
    filterType: 'direct_reports' as UserFilterType,
    label: activeUser.fullName,
    value: activeUser.id,
  };
  return (
    <ContentContainer>
      <Skills
        reviewCycle={reviewCycle}
        defaultUserFilter={defaultUserFilter}
        userFilters={userFilters as ActiveFilters}
        setUserFilters={setUserFilters}
        withUserMultiFilters={withUserMultiFilters}
      />
    </ContentContainer>
  );
};

export default ManagerCycleSkills;
