import React, { Fragment } from 'react';

import type { User } from 'models';

import { __ } from 'helpers/i18n';

import { FieldError, Flex, Label, Radio } from 'components';

import UserPicker from 'scenes/components/UserPicker';

import TraineesChooser from './TraineesChooser';
import { CreateTrainingRequestFormObject } from './types';

type Props = {
  trainingRequest: CreateTrainingRequestFormObject;
  concernedUser: User | null;
  errors: Object;
  collaboratorsChoice: string;
  setTrainingRequest: (
    trainingRequest: CreateTrainingRequestFormObject
  ) => void;
  setSendRequestLabel: (sendRequestLabel: string) => void;
  setCollaboratorsChoice: (collaboratorsChoice: 'single' | 'multiple') => void;
};

const singleCollaboratorPicker = (
  trainingRequest,
  concernedUser,
  setTrainingRequest
) => {
  return (
    <UserPicker
      value={trainingRequest.trainee}
      disabled={!!concernedUser}
      fetchParams={{ permission: 'create_training_request?' }}
      onChange={trainee =>
        setTrainingRequest({ ...trainingRequest, trainee: trainee })
      }
    />
  );
};

const concernedCollaborator = (
  trainingRequest,
  concernedUser,
  errors,
  setTrainingRequest
) => {
  return (
    <React.Fragment>
      <Label>{__('Concerned employee')}</Label>
      {singleCollaboratorPicker(
        trainingRequest,
        concernedUser,
        setTrainingRequest
      )}
      <FieldError>{errors.trainee}</FieldError>
    </React.Fragment>
  );
};

export function ChooseCollaborators({
  trainingRequest,
  concernedUser,
  setTrainingRequest,
  setSendRequestLabel,
  collaboratorsChoice,
  setCollaboratorsChoice,
}: Props) {
  return (
    <Fragment>
      <Label>{__('Who is concerned?')}</Label>
      <Flex
        style={{ margin: '0 0 16px -10px', flexWrap: 'wrap', gap: '16px 40px' }}
      >
        <Radio
          label={__('Only one employee')}
          isChecked={collaboratorsChoice === 'single'}
          onChange={() => {
            setCollaboratorsChoice('single');
            setSendRequestLabel(__('Send request'));
          }}
          style={{ flex: 'inherit' }}
        />
        <Radio
          label={__('Several employees')}
          isChecked={collaboratorsChoice === 'multiple'}
          onChange={() => {
            setCollaboratorsChoice('multiple');
            setSendRequestLabel(__('Send requests'));
          }}
          style={{ flex: 'auto' }}
        />
      </Flex>
      {collaboratorsChoice === 'multiple' ? (
        <TraineesChooser
          trainingRequest={trainingRequest}
          setTrainingRequest={setTrainingRequest}
        />
      ) : (
        singleCollaboratorPicker(
          trainingRequest,
          concernedUser,
          setTrainingRequest
        )
      )}
    </Fragment>
  );
}

export default function CollaboratorsChoiceBlock({
  trainingRequest,
  setTrainingRequest,
  concernedUser,
  errors,
  setSendRequestLabel,
  collaboratorsChoice,
  setCollaboratorsChoice,
}: Props) {
  return !!concernedUser ? (
    concernedCollaborator(
      trainingRequest,
      concernedUser,
      errors,
      setTrainingRequest
    )
  ) : (
    <ChooseCollaborators
      trainingRequest={trainingRequest}
      concernedUser={concernedUser}
      errors={errors}
      setTrainingRequest={setTrainingRequest}
      setSendRequestLabel={setSendRequestLabel}
      collaboratorsChoice={collaboratorsChoice}
      setCollaboratorsChoice={setCollaboratorsChoice}
    />
  );
}
