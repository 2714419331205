import React from 'react';

import type { HeaderHandlingProps } from '../../helpers/useParentAndChildrenHandlingWithErrors';
import type { AreaErrorsType } from './index';
import type { SkillsArea, SkillsDomain } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';
import { pathToArea, pathToAreaMatrixList } from 'helpers/paths';
import confirmAsync from 'helpers/react/confirmAsync';

import { htmlSuccessNotice } from 'redux/actions';

import {
  Flex,
  PageHeader,
  SavingStatusLabel,
  StrictlySanitizedHtml,
  Text,
  WithSavingStatusRecorder,
} from 'components';

import DomainPicker from 'scenes/skills/components/DomainPicker';

import { fieldUidForItem } from '../../helpers/useFieldStatusResetWhenLoaded';

export default function AreaHeader({
  parent: area,
  onParentUpdate: onAreaUpdate,
  fieldErrorsFor,
  updateErrors,
}: HeaderHandlingProps<SkillsArea>) {
  const dispatch = useAppDispatch();

  const confirmRemoveDomain = (area: SkillsArea) => {
    return confirmAsync(
      __('Remove the skill from domain'),
      <React.Fragment>
        <StrictlySanitizedHtml
          componentType="span"
          html={__(
            'Removing the skill from the domain <b>%1</b> will also result in <b>removing the skill from the matrices where the domain has been added.</b>',
            area.domain?.title
          )}
        />
        <br />
      </React.Fragment>,
      {
        confirmLabel: __('Remove skill'),
        isDanger: true,
        onConfirm: () => onAreaUpdate({ ...area, domain: null }),
      }
    );
  };

  return (
    <PageHeader
      withBackButton
      backButtonProps={{ children: __('Back to skills') }}
      tabItems={[
        {
          to: pathToArea(area.id),
          label: __('Expectations'),
          labelPreset: '16bs5.5',
        },
        {
          to: pathToAreaMatrixList(area.id),
          label: __(
            'Associated matrices (%1)',
            area?.associatedMatrixCount || '-'
          ),
          labelPreset: '16bs5.5',
        },
      ]}
    >
      <div className="skills-header-editor">
        <Flex direction="row" className="text-with-save-label">
          <div>
            <div className="item-title">
              <WithSavingStatusRecorder
                fieldUid={fieldUidForItem('area', 0, 'title')}
                onChange={(areaTitle: string) =>
                  onAreaUpdate({ ...area, title: areaTitle })
                }
                render={onChange => (
                  <Text
                    preset="24bs2"
                    isEditable
                    editableOptions={{
                      type: 'singlelineText',
                      value: area.title,
                      placeholder: __('Skill title'),
                      onChange,
                      onFocus: () =>
                        updateErrors((errors: AreaErrorsType) => ({
                          ...errors,
                          area: null,
                        })),
                      displayErrorMessage: false,
                    }}
                  />
                )}
              />
            </div>

            <div>
              <WithSavingStatusRecorder
                fieldUid={fieldUidForItem('area', 0, 'title')}
                onChange={(areaDomain: SkillsDomain | null) => {
                  if (areaDomain === null) {
                    return confirmRemoveDomain(area);
                  } else {
                    return onAreaUpdate({ ...area, domain: areaDomain }, () => {
                      if (areaDomain?.matricesCount) {
                        dispatch(
                          htmlSuccessNotice(
                            __(
                              '<b>The skill has been successfully associated with the domain.</b> <br> It has been added to the skills matrices in which the domain is already present.'
                            )
                          )
                        );
                      }
                    });
                  }
                }}
                render={onChange => (
                  <div className="flex items-center gap-4 mt-4">
                    <div className="font-bold">
                      {__('Associated domain (optional)')}
                    </div>

                    <DomainPicker
                      value={area.domain}
                      onChange={onChange}
                      className="w-64"
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="save-label">
            <SavingStatusLabel
              failedText={(count: number) =>
                n__(
                  'The skill is invalid, %1 error was detected',
                  'The skill is invalid, %1 errors were detected',
                  count
                )
              }
            />
          </div>
        </Flex>
        {fieldErrorsFor('area')}
      </div>
    </PageHeader>
  );
}
