import React from 'react';

import type { Objective, ObjectiveCollection } from 'models';

import { Box, BoxList } from 'components';

import ObjectiveRowItem from './ObjectiveRowItem';

type Props<T> = {
  objectives: Array<T>;
  objectiveCollection?: ObjectiveCollection;
  target?: string;
};

const ObjectivesList = <T extends Objective>({
  objectives,
  objectiveCollection,
  target,
}: Props<T>) => {
  return (
    <Box>
      <BoxList className="objective-row-item-list">
        {objectives.map(objective => {
          return (
            <ObjectiveRowItem
              objective={objective}
              key={objective.id}
              target={target}
              locked={
                !!objectiveCollection ? objectiveCollection.isLocked : false
              }
              lockedReason={
                !!objectiveCollection ? objectiveCollection.lockedReason : null
              }
            />
          );
        })}
      </BoxList>
    </Box>
  );
};

export default ObjectivesList;
