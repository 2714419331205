import React from 'react';

import { __ } from 'helpers/i18n';

import { Text, Title } from 'components';

type Props = {
  ratingLabel: string | undefined | null;
  comment: string | undefined | null;
};

export default function ClosedAnswer({ ratingLabel, comment }: Props) {
  const isAnswered = ratingLabel || comment;

  return (
    <div className="feedback-zone test-people-user-review-closed-answer">
      <div className="answer is-closed">
        <div className="answer-content">
          {isAnswered ? (
            <React.Fragment>
              <Title weight="bold" size={6}>
                {ratingLabel}
              </Title>
              <Text weight="normal">{comment}</Text>
            </React.Fragment>
          ) : (
            <Text transformation="italic" className="placeholder">
              {__('No answer was given to this question')}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
}
