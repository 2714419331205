import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type {
  AllValidatedFundingSourceStats,
  FundingSourceStats,
  TrainingPlanBudgetStats,
  TrainingPlanValidatedBudgetStats,
  User,
} from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { withActiveUser } from 'helpers/withSessionProps';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Button,
  FetchContainer,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import DeprecatedStats from './DeprecatedStats';
import Stats from './Stats';

type Props = {
  periodSlug: string;
  isValidatedPlan: boolean;
  canManageBudget: boolean;
  stats: TrainingPlanBudgetStats;
  onClose: () => void;
  openBudgetManagementModal: () => void;
};

type AfterWithActiveUserProps = Props & {
  activeUser: User;
};

type AfterDataLoaderProps = AfterWithActiveUserProps &
  DataLoaderProvidedProps & {
    fundingSourcesStats: FundingSourceStats;
  };

const BudgetDetailsModal = ({
  canManageBudget,
  stats,
  onClose,
  openBudgetManagementModal,
  fundingSourcesStats,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  const { featureFlags } = useCurrentOrganization();
  const trainingPlansEnabled = featureFlags.includes('trainingPlans');

  return (
    <ModalCard
      isActive
      isLarge
      refreshContentOnOpening
      onClose={onClose}
      className="budget-details-modal"
    >
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>{__('My budget')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="alone"
          render={() => {
            if (!fundingSourcesStats) return null;

            if (!trainingPlansEnabled) {
              return (
                <DeprecatedStats
                  stats={stats as TrainingPlanValidatedBudgetStats}
                  fundingSourcesStats={
                    fundingSourcesStats as AllValidatedFundingSourceStats
                  }
                />
              );
            }

            return (
              <Stats stats={stats} fundingSourcesStats={fundingSourcesStats} />
            );
          }}
        />
      </ModalCardBody>

      <ModalCardFooter>
        {canManageBudget && (
          <Button
            onClick={() => {
              onClose();
              openBudgetManagementModal();
            }}
            color="secondary"
          >
            {__('Edit')}
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default compose<React.ComponentType<Props>>(
  withActiveUser,
  newDataLoader({
    fetch: ({ periodSlug, isValidatedPlan }: Props) =>
      get(
        `training/periods/${periodSlug}/plans/${
          isValidatedPlan ? 'validated' : 'forecast'
        }/funding_sources_stats`
      ),
    hydrate: {
      fundingSourcesStats: {
        budgetItem: {
          abilities: {},
        },
      },
    },
  })
)(BudgetDetailsModal);
