import * as React from 'react';

import type { Props } from './index';

import classNames from 'helpers/classNames';

import { Level, LevelLeft, LevelRight, Tabs } from 'components';

export default function FullWidthSubmenuBar({
  tabItems,
  backButton,
  statusInfo,
  children,
  actions,
  style,
  hasCenteredContent,
}: Props) {
  return (
    <div className="full-width-submenu-bar" style={style}>
      {(!!backButton || !!statusInfo) && (
        <Level className={classNames(hasCenteredContent && 'centered-content')}>
          <LevelLeft>
            <div className="full-width-submenu-bar-back-button-container">
              {backButton}
            </div>
          </LevelLeft>
          <LevelRight>
            <div className="full-width-submenu-bar-status-info">
              {statusInfo}
            </div>
          </LevelRight>
        </Level>
      )}

      {(!!children || !!actions) && (
        <Level className={classNames(hasCenteredContent && 'centered-content')}>
          <LevelLeft>
            <div className="full-width-submenu-bar-content">{children}</div>
          </LevelLeft>
          <LevelRight>
            <div className="full-width-submenu-bar-actions">{actions}</div>
          </LevelRight>
        </Level>
      )}

      {tabItems && (
        <Tabs tabsPosition="center" tabsWidth="fit-content" items={tabItems} />
      )}
    </div>
  );
}
