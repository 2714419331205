// Comes from https://wikiki.github.io/form/checkradio/
import React from 'react';

import type {
  CheckboxColor,
  CheckboxDirection,
  CheckboxSize,
  CheckboxType,
} from 'components/types/checkbox';

import { bulmaColorClassName, bulmaSizeClassName } from 'helpers/bulma';
import classNames from 'helpers/classNames';

export type Props = {
  isChecked: boolean;
  disabled?: boolean;
  id?: string;

  color?: CheckboxColor;
  size?: CheckboxSize;
  type?: CheckboxType;
  direction?: CheckboxDirection;

  className?: string;
};

function Checkbox({
  isChecked,
  disabled,
  id,
  size,
  color,
  className,
  direction,
}: Props) {
  const mergedClassName = classNames(
    'is-checkradio',
    bulmaColorClassName(color),
    bulmaSizeClassName(size),
    className,
    {
      'is-rtl': direction === 'right-to-left',
    }
  );

  return (
    <input
      type="checkbox"
      className={mergedClassName}
      checked={isChecked}
      id={id}
      disabled={disabled}
      onChange={() => {}}
    />
  );
}

Checkbox.defaultProps = {
  type: 'checkbox',
  size: 'normal',
  direction: 'left-to-right',
};

export default Checkbox;
