import React from 'react';

import { Flex } from 'components';

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

export default function Centered({ children, style, className }: Props) {
  return (
    <Flex
      direction="column"
      verticalAlign
      horizontalAlign
      style={style}
      className={className}
    >
      {children}
    </Flex>
  );
}
