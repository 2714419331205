import * as React from 'react';

import type { MaterialIconName } from 'components/Icon';
import type { ApprovalStatus, Priority } from 'models';

import { __ } from 'helpers/i18n';

import { Icon, Text, Tooltip } from 'components';

export function priorityLabel(priority: Priority) {
  switch (priority) {
    case 'low':
      return __('Low');
    case 'medium':
      return __('Medium');
    case 'high':
      return __('High');
    default:
      return priority;
  }
}

export function approvalStatusLabel(approvalStatus: ApprovalStatus) {
  switch (approvalStatus) {
    case 'waiting_for_approval':
      return __('Waiting for approval');
    case 'approved':
      return __('Approved');
    case 'refused':
      return __('Refused');
    default:
      return approvalStatus;
  }
}

function approvalStatusIcon(approvalStatus: ApprovalStatus): {
  icon: MaterialIconName;
  color?: string;
} {
  const icons = {
    waiting_for_approval: { icon: 'alarm' },
    approved: { icon: 'thumb_up', color: 'success' },
    refused: { icon: 'thumb_down', color: 'danger' },
  } as const;

  return icons[approvalStatus];
}

export function approvalStatusLabelWithIcon(approvalStatus: ApprovalStatus) {
  const text = approvalStatusLabel(approvalStatus);
  const approvalIcon = approvalStatusIcon(approvalStatus);

  return (
    <Text>
      <Icon
        size="small"
        style={{ marginRight: 8 }}
        color={approvalIcon.color}
        name={approvalIcon.icon}
      />
      {text}
    </Text>
  );
}

export function approvalStatusIconWithTooltip(approvalStatus: ApprovalStatus) {
  const text = approvalStatusLabel(approvalStatus);
  const approvalIcon = approvalStatusIcon(approvalStatus);

  return (
    <Tooltip
      content={
        <Text size={7} className="info-tooltip-content">
          {text}
        </Text>
      }
    >
      <Icon size="small" color={approvalIcon.color} name={approvalIcon.icon} />
    </Tooltip>
  );
}
