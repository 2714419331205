import React, { CSSProperties } from 'react';

import { __ } from 'helpers/i18n';

import { Input, Text } from 'components';

type Props = {
  value: string;
  style?: CSSProperties;
  isSigned: boolean;
  isDisabled?: boolean;
  onChange: (value: string) => void;
};

export default function SignatureField({
  value,
  style,
  isSigned,
  isDisabled,
  onChange,
}: Props) {
  if (isSigned) {
    return (
      <div style={{ marginTop: 8 }}>
        <Text className="signature-field">{value}</Text>
      </div>
    );
  }

  return (
    <Input
      className="signature-field"
      testClassName="test-signature-field"
      value={value}
      placeholder={__('Sign here...')}
      onChange={value => onChange(value || '')}
      disabled={isDisabled}
      style={style}
    />
  );
}
