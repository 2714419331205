import React from 'react';

import type { SurveyQuestionChoice } from 'models/surveys';

import classNames from 'helpers/classNames';
import { useMultiSelect } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { AutoSave, Checkbox, FieldError, Text } from 'components';

export type Props = {
  selectedChoiceIds: string[];
  choices: SurveyQuestionChoice[];
  persistChanges: (choiceIds: string[]) => Promise<void>;
  inputName: string;
  minChoiceCount: number | null;
  maxChoiceCount: number | null;
  isDisabled?: boolean;
  errorMessage: string;
};

const ScaleQuestionContent = ({
  selectedChoiceIds: selectedChoiceIdsFromProps,
  choices,
  persistChanges,
  minChoiceCount,
  maxChoiceCount,
  inputName,
  isDisabled,
  errorMessage,
}: Props) => {
  const [selectedChoiceIds, toggleSelected] = useMultiSelect(
    selectedChoiceIdsFromProps
  );

  const options = choices.map(choice => ({
    value: choice.id,
    label: choice.label,
  }));

  const instructionMessage = (() => {
    if (!!minChoiceCount && !!maxChoiceCount)
      return n__(
        'Select between %2 and %1 answer',
        'Select between %2 and %1 answers',
        maxChoiceCount,
        minChoiceCount
      );
    else if (!!maxChoiceCount)
      return n__(
        'Select %1 answer maximum',
        'Select %1 answers maximum',
        maxChoiceCount
      );
    else if (!!minChoiceCount)
      return n__(
        'Select at least %1 answer',
        'Select at least %1 answers',
        minChoiceCount
      );
    return __('Multiple answers possible');
  })();

  return (
    <AutoSave<string>
      fieldUid={inputName}
      onChange={choiceId => {
        toggleSelected(choiceId);
      }}
      doPersist={() => persistChanges(selectedChoiceIds)}
      withoutRetryLabel
      unprocessableEntityHandled
      render={autoSavingOnChange => (
        <div className="mt-2 md:mt-8">
          {!!instructionMessage && (
            <Text preset="14bs6" className="block mb-2">
              {instructionMessage}
            </Text>
          )}
          <div className="multiple-choice-select test-survey-multiple-choice-select">
            {options.map(option => {
              const isSelected = selectedChoiceIds.includes(option.value);

              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  key={option.value}
                  className={classNames(
                    'flex',
                    'test-survey-multiple-choice-option',
                    {
                      'is-selected': isSelected,
                      'is-disabled': isDisabled,
                    }
                  )}
                  onClick={() => {
                    !isDisabled && autoSavingOnChange(option.value);
                  }}
                >
                  <Checkbox
                    isChecked={isSelected}
                    onChange={() => {
                      autoSavingOnChange(option.value);
                    }}
                    disabled={isDisabled}
                    className="mx-2"
                    size="tiny"
                  />
                  <Text>{option.label}</Text>
                </div>
              );
            })}
          </div>
          <FieldError>{errorMessage}</FieldError>
        </div>
      )}
    />
  );
};

export default ScaleQuestionContent;
