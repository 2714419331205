import React from 'react';

import classNames from 'helpers/classNames';
import useIsMobile from 'helpers/hooks/useIsMobile';
import { __ } from 'helpers/i18n';

import { Button, Icon, Text } from 'components';

type Props = {
  toggleSidebar: () => void;
  showMobileSlidingSidebar: boolean;
  className?: string;
};

const MobileShowPanelButton = ({
  toggleSidebar,
  showMobileSlidingSidebar,
  className,
}: Props) => {
  const isMobile = useIsMobile();

  if (!isMobile) return null;

  return (
    <div
      className={classNames(
        'mobile-sliding-sidebar__button flex h-8 rounded-3xl p-2 bg-white w-fit',
        className
      )}
    >
      <Button
        onClick={() => toggleSidebar()}
        className={'border-none p-0 mx-2'}
        hasIconOnly
      >
        <Icon
          name="menu_open"
          className={`text-xl p-0 mr-1 ${
            showMobileSlidingSidebar && 'rotate-180'
          }`}
        />
        <Text preset="13s7" className="font-normal">
          {showMobileSlidingSidebar
            ? __('Close the panel')
            : __('Open the panel')}
        </Text>
      </Button>
    </div>
  );
};

export default MobileShowPanelButton;
