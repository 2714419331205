import React from 'react';

export type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  color?: string;
};

const Overlay = ({ children, style, className }: Props) => (
  <div
    className={className}
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      ...style,
    }}
  >
    {children}
  </div>
);

export default Overlay;
