import * as React from 'react';
import { confirmable, createConfirmation } from 'react-confirm';

import { ConfirmationModal } from '../../components/modals';
import ConfirmationModalWithSideEffects from '../../components/modals/ConfirmationModalWithSideEffects';

type Options = {
  confirmLabel?: string;
  cancelLabel?: string;
  isDanger?: boolean;
  isLarge?: boolean;
  onConfirm?: () => Promise<any>;
  onCancel?: () => Promise<any>;
  sideEffects?: Array<React.ReactNode>;
  sideEffectsLabel?: React.ReactNode;
  inlineButtonIcon?: React.ReactNode;
  irreversibleWarningLabel?: React.ReactNode;
  sideEffectsAreIrreversible?: boolean;
  hideCancel?: boolean;
  nonEscapable?: boolean;
};

type Props = {
  show: boolean;
  proceed: (arg1: boolean) => any;
  cancel: () => any;
  dismiss: () => any;
  confirmation: React.ReactNode;
  options: Options & { title: string };
};

function ConfirmableModal({
  proceed,
  cancel,
  show,
  confirmation,
  options,
}: Props) {
  const onConfirm = async () => {
    if (!!options.onConfirm) {
      await options.onConfirm();
    }

    proceed(true);
  };

  const onCancel = async () => {
    if (!!options.onCancel) {
      await options.onCancel();
    }

    cancel();
  };

  if (!options.sideEffects || options.sideEffects.length === 0) {
    return (
      <ConfirmationModal
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmLabel={options.confirmLabel}
        cancelLabel={options.cancelLabel}
        isDanger={options.isDanger}
        isActive={show}
        isLarge={options.isLarge}
        refreshContentOnOpening={false}
        title={options.title}
        hideCancel={options.hideCancel}
        inlineButtonIcon={options.inlineButtonIcon}
        nonEscapable={options.nonEscapable}
      >
        {confirmation}
      </ConfirmationModal>
    );
  }

  return (
    <ConfirmationModalWithSideEffects
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmLabel={options.confirmLabel}
      sideEffectsLabel={options.sideEffectsLabel}
      cancelLabel={options.cancelLabel}
      isActive={show}
      sideEffects={options.sideEffects}
      message={confirmation}
      title={options.title}
      irreversibleWarningLabel={options.irreversibleWarningLabel}
      sideEffectsAreIrreversible={options.sideEffectsAreIrreversible}
    />
  );
}

const confirm = createConfirmation(confirmable(ConfirmableModal));

export default async function confirmAsync(
  title: string,
  message: React.ReactNode,
  options: Options = {}
) {
  try {
    return await confirm({
      confirmation: message,
      options: { ...options, title },
    });
  } catch (e: any) {
    return false;
  }
}
