import { camelCase } from 'lodash';
import React from 'react';

import { RadarOption, ReviewerRole } from 'models';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';

import {
  Button,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  RadarChart,
} from 'components';

import { SummaryArea } from './summary.types';

type RatingOption = {
  label: string;
  position: number;
};

type Props = {
  ratingOptions: Array<RatingOption>;
  ratings: Array<SummaryArea>;
  isActive: boolean;
  onClose: () => void;
};

function constructRadarOptions(
  reviewerRoles: Array<ReviewerRole>
): Array<RadarOption> {
  const nameMap = {
    reviewee: __('Reviewee'),
    reviewer: __('Reviewer'),
    additionalReviewer: __('Additional reviewer'),
  };

  return reviewerRoles.map(role => ({
    dataKey: role,
    name: nameMap[camelCase(role)],
    color: colors.radarChart[camelCase(role)],
  }));
}

function findRating(
  ratingOptions: Array<RatingOption>,
  rating: SummaryArea,
  reviewerRole: ReviewerRole
): number {
  const reviewerRating = rating['ratings'].find(
    rating => rating['reviewerRole'] === reviewerRole
  );

  const optionFound = ratingOptions.find(
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    ratingOption => ratingOption['label'] === reviewerRating['label']
  );

  return optionFound ? optionFound['position'] + 1 : 0;
}

const RadarChartSummaryModal = ({
  ratingOptions,
  ratings,
  onClose,
  isActive,
}: Props) => {
  const reviewerRoles = ratings[0]['ratings'].map(
    rating => rating['reviewerRole']
  );
  const axisLabels = ratingOptions.map(ratingOption => ratingOption['label']);
  const radarOptions = constructRadarOptions(reviewerRoles);

  const data = [];

  ratings.forEach(rating => {
    const dataPoints = { label: rating['title'] };

    reviewerRoles.forEach(
      reviewerRole =>
        (dataPoints[reviewerRole] = findRating(
          ratingOptions,
          rating,
          reviewerRole
        ))
    );

    // @ts-ignore TSFIXME: Fix strictNullChecks error
    data.push(dataPoints);
  });

  return (
    <ModalCard onClose={onClose} isActive={isActive} isBig>
      <ModalCardHead>
        <ModalCardTitle>{__('Skills Summary')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody style={{ paddingBottom: 16 }}>
        <RadarChart
          className={'summary-board'}
          axisLabels={axisLabels}
          data={data}
          axisDataKey={'label'}
          radarOptions={radarOptions}
          height={580}
          outerRadius={230}
          adjustTextPositionCoefficient={10}
        />
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};
export default RadarChartSummaryModal;
