import React from 'react';
import { compose } from 'redux';

import { BaseTrainingOrganism, PaginatedCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';
import { pathToTrainingOrganism } from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { WithPaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import {
  Box,
  DatatableWrapper,
  EmptyStateWithIcon,
  FetchContainer,
  Link,
  SimpleTable,
  Text,
} from 'components';

import NoOrganismState from './NoOrganismState';
import OrganismWithAvatar from './OrganismWithAvatar';

type Props = {};

type AfterPaginateProps = Props & WithPaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  trainingOrganismCollection: PaginatedCollection<BaseTrainingOrganism>;
  isFetching: boolean;
  hasError: boolean;
};
function OrganismListWithPagination({
  page,
  countPerPage,
  queryParams,
  setPreviousPageParams,
  setQueryParams,
  setNextPageParams,
  trainingOrganismCollection,
  isFetching,
  hasError,
}: AfterConnectProps) {
  const { search } = queryParams || {};
  return (
    <Box>
      <DatatableWrapper
        collectionInfo={
          trainingOrganismCollection ? trainingOrganismCollection : null
        }
        search={search}
        page={page}
        countPerPage={countPerPage}
        getPreviousPage={setPreviousPageParams}
        onQueryParamsChange={setQueryParams}
        getNextPage={setNextPageParams}
        isFetching={isFetching}
        withSearch
        hasError={hasError}
        renderNoResult={() => (
          <EmptyStateWithIcon
            title={__('No training organizations match your search')}
            iconName={'school'}
            inBox={false}
          />
        )}
        renderNoRecord={() => <NoOrganismState />}
        totalCountRenderer={totalCount =>
          n__(
            '%1 training organization',
            '%1 training organizations',
            totalCount || 0
          )
        }
      >
        <FetchContainer
          isFetching={isFetching}
          hasError={hasError}
          loadingStyle="overlay"
          render={() => (
            <SimpleTable
              columns={[
                {
                  header: __('Training organization name'),
                  cell: (organism: BaseTrainingOrganism) => (
                    <Link to={pathToTrainingOrganism(organism.id)}>
                      <OrganismWithAvatar organism={organism} />
                    </Link>
                  ),
                },
                {
                  header: __('Associated trainings'),
                  cell: (organism: BaseTrainingOrganism) =>
                    organism.trainingCoursesCount === 0 ? (
                      <Text preset="14s6">{__('No trainings')}</Text>
                    ) : (
                      <Text preset="14s6">{organism.trainingCoursesCount}</Text>
                    ),
                },
              ]}
              rows={trainingOrganismCollection?.items || []}
              keyFn={organism => organism.id}
              emptyState={<div />}
              className="mb-4"
            />
          )}
        />
      </DatatableWrapper>
    </Box>
  );
}

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({ queryParams: { search }, page, countPerPage }) =>
      get('/training/organisms', {
        search,
        page,
        countPerPage,
      }),
    hydrate: { trainingOrganismCollection: { items: {} } },
    cacheKey: ({ queryParams, page, countPerPage }: AfterConnectProps) =>
      compositeKey({ queryParams, page, countPerPage }),
  })
)(OrganismListWithPagination);
