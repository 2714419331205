import React, { ReactNode } from 'react';

import OldParticipantsChooser from './OldParticipantsChooser';
import ParticipantsChooser from './ParticipantsChooser';

interface Props {
  fetchUrl: string;
  updateUrl: string;
  participantsCount: number;
  showBulkActions: boolean;
  withUserMultiFilters?: boolean;
  participantListStyle?: {};
  additionalUpdateParams?: { [key: string]: number };
  renderAfter?: (refetch: () => Promise<any>) => ReactNode;
  renderImportButton?: (
    refetchData: any,
    setReport: any,
    setError: any
  ) => React.JSX.Element;
}

const ParticipantsChooserWrapper: React.FC<Props> = ({
  withUserMultiFilters = false,
  ...props
}) => {
  return withUserMultiFilters ? (
    <ParticipantsChooser
      {...props}
      countPerPage={6}
      defaultSort={'desc'}
      withUrlChange={false}
    />
  ) : (
    // TODO: multifilters: remove this condition when FF is enabled for everyone
    <OldParticipantsChooser {...props} />
  );
};

export default ParticipantsChooserWrapper;
