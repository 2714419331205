import React, { Fragment, useState } from 'react';

import type { BaseTrainingCourse, TrainingSession } from 'models';

import { FormErrors } from 'helpers/api';
import can from 'helpers/can';
import { useOrganizationSettings } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  formatsTranslation,
  typesTranslation,
} from 'helpers/models/trainingCourse';

import {
  Checkbox,
  Column,
  Columns,
  FeatureFlagged,
  Field,
  FieldError,
  IOption,
  Icon,
  Input,
  Label,
  NumberInput,
  Select,
  Text,
  Tooltip,
} from 'components';
import FloatInput from 'components/formElements/advancedElements/FloatInput';
import MoneyInput from 'components/formElements/advancedElements/MoneyInput';

import SimpleSelect from 'scenes/admin/Trainings/components/SimpleSelect';
import TrainingCoursePicker from 'scenes/components/TrainingCoursePicker';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';

import EndDatePicker from './EndDatePicker';
import StartDatePicker from './StartDatePicker';

type Props = {
  session: TrainingSession;
  setSession: (session: TrainingSession) => void;
  onChange: (session?: TrainingSession) => void;
  errors: FormErrors;
};

const EditableFields = ({ session, setSession, onChange, errors }: Props) => {
  const formDisabled = !can({ perform: 'update', on: session });
  const canUpdateLocation = can({
    perform: 'update_dates_and_location',
    on: session,
  });

  const locationDisabledContent = canUpdateLocation
    ? null
    : __(
        'Dates and location of the training session cannot be edited once the calendar invitation has been sent'
      );

  const settings = useOrganizationSettings();

  const trainingCatalogEnabled = settings.trainingCatalogEnabled;

  const options: IOption<string>[] = [
    {
      value: 'catalog',
      label: __('From the catalog'),
      icon: <Icon name="menu_book" />,
    },
    {
      value: 'offCatalog',
      label: __('Off-catalog'),
      icon: <Icon name="edit" />,
    },
  ];
  const [trainingOrigin, setTrainingOrigin] = useState<IOption<string>>(
    session.trainingCourse === null || !trainingCatalogEnabled
      ? options[1]
      : options[0]
  );

  const handleCourseChange = (trainingCourse?: BaseTrainingCourse) => {
    const newSession = {
      ...session,
      name: trainingCourse?.name || '',
      trainingCourse: trainingCourse ? { ...trainingCourse } : null,
      trainingOrganization: trainingCourse?.organism.name || null,
    };
    onChange(newSession);
  };

  const offCatalogInput = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
        <Field>
          <Label>{__('Training name')}</Label>
          <Input
            value={session.name || ''}
            placeholder={__('Add training name')}
            onBlur={() => onChange()}
            onChange={name => setSession({ ...session, name })}
            disabled={formDisabled}
            testClassName="test-name-field"
          />
          <FieldError>{errors.name}</FieldError>
        </Field>
        <Field>
          <Label>{__('Training organization')}</Label>
          <Input
            value={session.trainingOrganization || ''}
            onBlur={() => onChange()}
            onChange={trainingOrganization =>
              setSession({
                ...session,
                trainingOrganization,
              })
            }
            disabled={formDisabled}
          />
          <FieldError>{errors.trainingOrganization}</FieldError>
        </Field>
      </div>
    );
  };

  const fromCatalogSearch = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8 mt-4">
        <Field>
          <Label>{__('Training name')}</Label>{' '}
          <TrainingCoursePicker
            onChange={trainingCourse => {
              handleCourseChange(trainingCourse);
            }}
            trainingCourse={session.trainingCourse}
            isDisabled={formDisabled}
          />
        </Field>
        <Field>
          <Label>{__('Training organization')}</Label>
          <Input value={session.trainingOrganization || ''} disabled={true} />
          <FieldError>{errors.trainingOrganization}</FieldError>
        </Field>
      </div>
    );
  };

  return (
    <Fragment>
      {trainingCatalogEnabled ? (
        <Fragment>
          <Field className="mb-4">
            <Label>{__('Origin of training')}</Label>
            <Select
              value={trainingOrigin}
              options={options}
              placeholder={__('Select training origin')}
              onChange={option => {
                setTrainingOrigin(option!);
                onChange({
                  ...session,
                  trainingCourse: null,
                });
              }}
              isDisabled={formDisabled}
            />
            <FieldError>{errors.trainingType}</FieldError>
          </Field>
          {trainingOrigin.value === 'catalog' && fromCatalogSearch()}
          {trainingOrigin.value === 'offCatalog' && offCatalogInput()}
        </Fragment>
      ) : (
        offCatalogInput()
      )}

      <div className="mt-4">
        <Field>
          <Checkbox
            className="mr-4"
            isChecked={!!session.certifying}
            onChange={() =>
              onChange({ ...session, certifying: !session.certifying })
            }
            label={__('This training leads to certification')}
          />
        </Field>
      </div>

      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
        <div className="grid grid-cols-2 gap-x-8">
          <StartDatePicker
            session={session}
            onChange={onChange}
            errors={errors}
            disabled={formDisabled}
          />
          <EndDatePicker
            session={session}
            onChange={onChange}
            errors={errors}
            disabled={formDisabled}
          />
        </div>
        <Field>
          <Label>{__('Number of hours')}</Label>
          <FloatInput
            syncWithParent={true}
            value={
              session.durationInHours ? Number(session.durationInHours) : null
            }
            placeholder={__('Enter the number of hours')}
            onInputChange={(durationInHours: number | null) => {
              onChange({
                ...session,
                durationInHours: `${durationInHours || ''}`,
              });
            }}
            isDisabled={formDisabled}
            saveOnlyOnBlur
          />
          <FieldError>{errors.durationInHours}</FieldError>
        </Field>
      </div>
      <Columns className="mt-0 mb-0">
        <Column size={3}>
          <Field>
            <Label>{__('Type')}</Label>
            <SimpleSelect
              value={session.trainingType}
              values={typesTranslation()}
              placeholder={__('Select a type')}
              onChange={trainingType => {
                onChange({
                  ...session,
                  trainingType,
                });
              }}
              isDisabled={formDisabled}
            />
            <FieldError>{errors.trainingType}</FieldError>
          </Field>
        </Column>
        <Column size={5}>
          <Field>
            <Label>{__('Format')}</Label>
            <SimpleSelect
              value={session.trainingFormat}
              values={formatsTranslation()}
              placeholder={__('Select a format')}
              onChange={trainingFormat => {
                onChange({
                  ...session,
                  trainingFormat,
                });
              }}
              isDisabled={formDisabled}
            />
            <FieldError>{errors.trainingFormat}</FieldError>
          </Field>
        </Column>
        <Column size={4}>
          <Field>
            <Label>{__('Number of available seats')}</Label>
            <div className="grid grid-cols-2 gap-x-8">
              <div className="flex items-center">
                <Text className="mr-2"> {__('Minimum')}</Text>
                <NumberInput
                  style={{ MozAppearance: 'textfield' }}
                  value={
                    session.minAvailableSeats === null
                      ? undefined
                      : session.minAvailableSeats
                  }
                  onChange={value =>
                    setSession({
                      ...session,
                      minAvailableSeats: value || null,
                    })
                  }
                  onBlur={() => onChange()}
                />
              </div>
              <div className="flex items-center">
                <Text className="mr-2"> {__('Maximum')}</Text>
                <NumberInput
                  style={{ MozAppearance: 'textfield' }}
                  value={
                    session.maxAvailableSeats === null
                      ? undefined
                      : session.maxAvailableSeats
                  }
                  onChange={value =>
                    setSession({
                      ...session,
                      maxAvailableSeats: value || null,
                    })
                  }
                  onBlur={() => onChange()}
                />
              </div>
            </div>
            <FieldError>{errors.minAvailableSeats}</FieldError>
            <FieldError>{errors.maxAvailableSeats}</FieldError>
          </Field>
        </Column>
      </Columns>

      <Field>
        <Label>{__('Location of the training')}</Label>
        <Tooltip content={locationDisabledContent}>
          <Input
            value={session.location || ''}
            onBlur={() => onChange()}
            onChange={location => {
              setSession({
                ...session,
                location,
              });
            }}
            disabled={formDisabled || !canUpdateLocation}
          />
        </Tooltip>
        <FieldError>{errors.location}</FieldError>
      </Field>
      <div className="grid mt-4 grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-8">
        <Field>
          <Label>{__('Period')}</Label>
          <TrainingPeriodPicker
            renderingStyle="select"
            currentPeriodSlug={session.period.slug}
            disabled={formDisabled}
            onChange={trainingPeriod =>
              onChange({ ...session, period: trainingPeriod })
            }
          />
        </Field>
        <FeatureFlagged flag="newTrainingCostManagement" inverseDefaultBehavior>
          <Field>
            <Label>{__('Total budget')}</Label>
            <MoneyInput
              amount={session.totalBudgetCents}
              placeholder={__('Enter the total budget')}
              onAmountChange={totalBudgetCents => {
                onChange({
                  ...session,
                  totalBudgetCents,
                });
              }}
              currency={session.totalBudgetCurrency}
              isDisabled={formDisabled}
              saveOnlyOnBlur
            />
            <FieldError>{errors.totalBudget}</FieldError>
          </Field>
        </FeatureFlagged>
      </div>
    </Fragment>
  );
};

export default EditableFields;
