import { capitalize } from 'lodash';
import React from 'react';
import { DropdownIndicatorProps, components } from 'react-select';

import { __, getCurrentLocale } from 'helpers/i18n';
import { supportedLanguageCodes } from 'helpers/i18n/languageCodesISO6391';

import { IOption, Icon, Select } from 'components';

type Props = {
  value?: Array<string>;
  onChange: (values: Array<string>) => void;
};

const translateLocales = () => {
  const languageNames = new Intl.DisplayNames([getCurrentLocale()], {
    type: 'language',
  });

  const options = supportedLanguageCodes().map(code => ({
    label: capitalize(languageNames.of(code)),
    value: code,
  }));

  return options.sort((a, b) => a.label.localeCompare(b.label));
};

const SearchDropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon name="search" />
  </components.DropdownIndicator>
);

const AvailableLanguagesPicker = ({ value, onChange }: Props) => {
  const options = translateLocales();

  const handleChange = (selectedOptions: any) => {
    const newValues = selectedOptions
      ? selectedOptions.map((option: IOption) => option.value)
      : [];
    onChange(newValues);
  };

  return (
    <Select
      options={options}
      value={value
        ?.map(code => {
          return options.find(option => option.value === code);
        })
        .filter(option => option !== undefined)}
      onChange={handleChange}
      placeholder={__('Select languages')}
      isSearchable
      isClearable
      isMulti
      components={{ DropdownIndicator: SearchDropdownIndicator }}
    />
  );
};

export default AvailableLanguagesPicker;
