import React, { ReactElement } from 'react';

import type { UpdateReviewCycle } from '../../..';
import type { Organization, ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';
import { withOrganization } from 'helpers/withSessionProps';

import OptionWrapper from '../OptionWrapper';
import OneOnOneDeadlinesOptions from './OneOnOneDeadlinesOptions';
import ThreeSixtyDeadlinesOptions from './ThreeSixtyDeadlinesOptions';

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
};

type AfterConnectProps = Props & {
  organization: Organization;
};

function DeadlinesOptions({
  reviewCycle,
  updateReviewCycle,
  organization,
}: AfterConnectProps) {
  const { interactionType } = reviewCycle;
  const autoAddParticipantsEnabled =
    organization.plan.autoAddParticipantsEnabled &&
    reviewCycle.autoAddParticipantsEnabled;

  let content: ReactElement;
  let deadlineTitle: string;

  if (interactionType === 'one_on_one') {
    content = (
      <OneOnOneDeadlinesOptions
        reviewCycle={reviewCycle}
        updateReviewCycle={updateReviewCycle}
        autoAddParticipantsEnabled={autoAddParticipantsEnabled}
      />
    );
    deadlineTitle = __('Deadlines');
  } else if (interactionType === '360') {
    content = (
      <ThreeSixtyDeadlinesOptions
        reviewCycle={reviewCycle}
        updateReviewCycle={updateReviewCycle}
      />
    );
    deadlineTitle = __('Deadline');
  } else {
    throw new Error(
      `Review cycle interactionType should be one_on_one or 360, got ${interactionType}`
    );
  }

  return (
    <OptionWrapper
      title={
        autoAddParticipantsEnabled
          ? __('Deadline to complete the review')
          : deadlineTitle
      }
      subtitle={
        autoAddParticipantsEnabled
          ? __(
              'After this date, review will be still accessible by participants'
            )
          : __('Define the timeline for this campaign')
      }
      className="deadlines-option"
    >
      {content}
    </OptionWrapper>
  );
}

export default withOrganization(DeadlinesOptions) as React.ComponentType<Props>;
