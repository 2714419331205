import React from 'react';

import { SkillsDomain } from 'models/skills';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

import DomainPicker from 'scenes/skills/components/DomainPicker';

type Props = {
  selectedDomains: Array<SkillsDomain>;
  onChange: (selectedDomains: Array<SkillsDomain>) => void;
};

const DomainFilteringSelect = ({ selectedDomains, onChange }: Props) => {
  return (
    <div className="flex items-center gap-4">
      <Text preset="14bs6">{__('Filter by domain')}</Text>
      <DomainPicker
        value={selectedDomains}
        isMulti
        onChange={onChange}
        className="w-72"
      />
    </div>
  );
};

export default DomainFilteringSelect;
