import moment from 'moment';
import React, { Fragment } from 'react';

import type { ReviewCycle } from 'models';
import type { Moment } from 'moment';

import { dateToDefaultDateStringFormat } from 'helpers/date';
import { __ } from 'helpers/i18n';

import {
  Column,
  Columns,
  Control,
  Field,
  Label,
  Testable,
  Text,
} from 'components';

import AutoAddDeadlineInput from './AutoAddDeadlineInput';

type Props = {
  reviewCycle: ReviewCycle;
  tomorrow: Moment;
  readOnly: boolean;
  onFieldChange: (attributes: {
    dateReviewReviewerEnd?: string | null | undefined;
    dateReviewRevieweeEnd?: string | null | undefined;
  }) => Promise<void>;
};

const OneOnOneDeadlines = ({
  reviewCycle,
  tomorrow,
  readOnly,
  onFieldChange,
}: Props) => {
  const revieweeDeadlineDisplayed =
    reviewCycle.defaultTemplate?.hasSelfEvaluationEnabled !== false;

  if (reviewCycle.autoAddParticipantsEnabled) {
    return (
      <Fragment>
        <Columns>
          {reviewCycle.autoAddMode === 'static' && (
            <Column>
              <Field className="header-field auto-add-deadline-header">
                <Label>{__('Automatic addition of participants')}</Label>
                <AutoAddDeadlineInput
                  text={__('days after start date')}
                  name={'addParticipantsBasedOnWorkStartDateAfterDays'}
                  value={
                    reviewCycle.addParticipantsBasedOnWorkStartDateAfterDays
                  }
                  min={1}
                  max={365}
                  onFieldChange={onFieldChange}
                />
              </Field>
            </Column>
          )}

          <Column>
            <Field className="header-field auto-add-deadline-header">
              <Label>{__('Deadline after addition')}</Label>
              {revieweeDeadlineDisplayed && (
                <AutoAddDeadlineInput
                  text={__('days after for reviewee')}
                  name={'revieweeDeadlineAfterDays'}
                  value={reviewCycle.revieweeDeadlineAfterDays}
                  min={1}
                  max={reviewCycle.reviewerDeadlineAfterDays || 365}
                  onFieldChange={onFieldChange}
                />
              )}
              <AutoAddDeadlineInput
                text={__('days after for reviewer')}
                name={'reviewerDeadlineAfterDays'}
                value={reviewCycle.reviewerDeadlineAfterDays}
                onFieldChange={onFieldChange}
                min={reviewCycle.revieweeDeadlineAfterDays || 1}
                max={365}
              />
            </Field>
          </Column>
        </Columns>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {revieweeDeadlineDisplayed && (
        <Field className="header-field inline-editable-field">
          <Label>{__('Reviewee deadline')}</Label>

          <Control className="date-control">
            <Testable name="test-review-cycle-reviewee-date">
              <Text
                isEditable={!readOnly}
                editableOptions={{
                  type: 'date',
                  value: !!reviewCycle.dateReviewRevieweeEnd
                    ? new Date(reviewCycle.dateReviewRevieweeEnd)
                    : null,
                  onChange: dateReviewRevieweeEnd =>
                    onFieldChange({
                      dateReviewRevieweeEnd: dateToDefaultDateStringFormat(
                        // @ts-ignore TSFIXME: Fix strictNullChecks error
                        dateReviewRevieweeEnd
                      ),
                    }),
                  fieldProps: {
                    minDate: tomorrow.toDate(),
                    maxDate: reviewCycle.dateReviewReviewerEnd
                      ? new Date(reviewCycle.dateReviewReviewerEnd)
                      : undefined,
                  },
                }}
              />
            </Testable>
          </Control>
        </Field>
      )}

      <Field className="header-field inline-editable-field">
        <Label>{__('Reviewer deadline')}</Label>

        <Control className="date-control">
          <Testable name="test-review-cycle-reviewer-date">
            <Text
              isEditable={!readOnly}
              editableOptions={{
                type: 'date',
                value: !!reviewCycle.dateReviewReviewerEnd
                  ? new Date(reviewCycle.dateReviewReviewerEnd)
                  : null,
                onChange: dateReviewReviewerEnd =>
                  onFieldChange({
                    dateReviewReviewerEnd: dateToDefaultDateStringFormat(
                      dateReviewReviewerEnd
                    ),
                  }),
                fieldProps: {
                  minDate: (!!reviewCycle.dateReviewRevieweeEnd
                    ? moment.max(
                        tomorrow,
                        moment(reviewCycle.dateReviewRevieweeEnd)
                      )
                    : tomorrow
                  ).toDate(),
                },
              }}
            />
          </Testable>
        </Control>
      </Field>
    </Fragment>
  );
};

export default OneOnOneDeadlines;
