import React from 'react';

import type { AvatarSize } from 'components/Avatar';
import type { User } from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';

import { del, get } from 'redux/actions/api';

import { DirectUploader } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';

type Props = {
  user: User;
  size?: AvatarSize;
  withJobTitle?: boolean;
};

const UserAvatarWithImagePicker = ({ user, size, withJobTitle }: Props) => {
  const dispatch = useAppDispatch();
  const onUploadSuccess = () => dispatch(get(`users/${user.id}`));
  const onDelete = () => dispatch(del(`users/${user.id}/avatar`));

  return (
    <DirectUploader
      resourcePath={`/users/${user.id}`}
      method="PUT"
      attribute="avatar"
      model="User"
      onSuccess={onUploadSuccess}
      render={({ uploadFromDataURL }) => (
        <UserAvatar
          user={user}
          size={size}
          withJobTitle={withJobTitle}
          canUpload={can({ perform: 'update_profile_picture', on: user })}
          onUpload={uploadFromDataURL}
          onDelete={onDelete}
        />
      )}
    />
  );
};

export default UserAvatarWithImagePicker;
