import React, { useContext } from 'react';

import type { ReviewCycle } from 'models';

import { useActiveUser } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { ContentContainer } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import Results from 'scenes/admin/reviewCycles/Manage/Results';

import { DataContext } from '..';

type Props = {
  reviewCycle: ReviewCycle;
};

const ManagerCycleResults = ({ reviewCycle }: Props) => {
  const { fullName, id } = useActiveUser();
  const { userFilters, withUserMultiFilters } = useContext(DataContext);

  return (
    <ContentContainer>
      <Results
        reviewCycle={reviewCycle}
        defaultUserFilter={{
          filterLabel: __('Direct reports of'),
          filterType: 'direct_reports',
          label: fullName,
          value: id,
        }}
        userFilters={userFilters as ActiveFilters}
        withUserMultiFilters={withUserMultiFilters}
      />
    </ContentContainer>
  );
};

export default ManagerCycleResults;
