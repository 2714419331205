import React, { Fragment } from 'react';

import { __ } from 'helpers/i18n';
import { pathToNewTrainingCourse } from 'helpers/navigation';

import { Button, ContentContainer, Icon } from 'components';

import PageHeader from '../components/PageHeader';
import CourseListWithPagination from './CourseListWithPagination';

const Courses = () => {
  return (
    <Fragment>
      <PageHeader />

      <ContentContainer>
        <div className="flex justify-end mb-4">
          <Button color="primary" to={pathToNewTrainingCourse()}>
            <Icon name="add" className={'mr-2'} />
            {__('Add a training course')}
          </Button>
        </div>
        <CourseListWithPagination />
      </ContentContainer>
    </Fragment>
  );
};
export default Courses;
