import React from 'react';

import { PeopleUserReviewPlannedAction } from 'models';
import { PaginatedCollection } from 'models/Collection';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import {
  BoxList,
  BoxListItem,
  Column,
  Columns,
  Divider,
  FetchContainer,
  Text,
} from 'components';

import PeopleReviewActionListItem from './PeopleReviewActionListItem';

type Props = {
  peopleReviewActionsCollection: PaginatedCollection<PeopleUserReviewPlannedAction>;
  compactDisplay?: boolean;
  isFetching?: boolean;
  hasError?: boolean;
  refetchData: () => Promise<any>;
};

const PeopleReviewActionsWithPagination = ({
  peopleReviewActionsCollection,
  compactDisplay,
  isFetching,
  hasError,
  refetchData,
}: Props) => (
  <BoxList>
    <BoxListItem>
      <Columns className={classNames({ 'pt-0': compactDisplay })}>
        <Column>
          <Text preset="14bs6" color="light">
            {__('Action')}
          </Text>
        </Column>
        {!compactDisplay && (
          <React.Fragment>
            <Column size={2}>
              <Text preset="14bs6" color="light">
                {__('Type')}
              </Text>
            </Column>

            <Column size={2}>
              <Text preset="14bs6" color="light">
                {__('Employee')}
              </Text>
            </Column>
          </React.Fragment>
        )}

        <Column size={2}>
          <Text preset="14bs6" color="light">
            {__('Due date')}
          </Text>
        </Column>
        <Column size={compactDisplay ? 3 : 2}>
          <Text preset="14bs6" color="light">
            {__('Status')}
          </Text>
        </Column>
        <Column size={1}></Column>
      </Columns>
    </BoxListItem>
    <Divider className="my-0" />
    {/** @ts-ignore TSFIXME: Fix strictNullChecks error **/}
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() =>
        peopleReviewActionsCollection.items.map(peopleReviewAction => (
          <PeopleReviewActionListItem
            key={peopleReviewAction.id}
            peopleReviewAction={peopleReviewAction}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            compactDisplay={compactDisplay}
            refetchData={refetchData}
          />
        ))
      }
    />
  </BoxList>
);

PeopleReviewActionsWithPagination.defaultProps = {
  compactDisplay: false,
  isFetching: false,
  hasError: false,
};

export default PeopleReviewActionsWithPagination;
