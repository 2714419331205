import React from 'react';

import type { ManagerReviewCycleStats, UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { Column, Columns } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import ReviewerEvaluationStatusesChart from 'scenes/admin/reviewCycles/Manage/Analytics/OneOnOne/ReviewerEvaluationStatusesChart';
import SelfEvaluationStatusesChart from 'scenes/admin/reviewCycles/Manage/Analytics/OneOnOne/SelfEvaluationStatusesChart';
import GlobalStatusChart from 'scenes/admin/reviewCycles/Manage/Analytics/ThreeSixty/GlobalStatusChart';
import PeerFeedbackStatusChart from 'scenes/admin/reviewCycles/Manage/Analytics/ThreeSixty/PeerFeedbackStatusChart';

import StatsContainer from './StatsContainer';

type Props = {
  reviewCycleId: string;
  reviewCycleType: string;
  filter: { user: { [key: string]: boolean } };
  userFilter: UserFilterSegment | null;
  userFilters: ActiveFilters;
};

type AfterConnectProps = Props & {
  stats: ManagerReviewCycleStats;
  isFetching: boolean;
  hasError: boolean;
};

function ManagerReviewCycleAnalytics({
  reviewCycleType,
  stats,
  isFetching,
  hasError,
  filter,
  userFilter,
  userFilters,
}: AfterConnectProps) {
  return (
    <StatsContainer
      isFetching={isFetching}
      hasError={hasError}
      filter={filter.user}
      userFilter={userFilter}
      userFilters={userFilters}
      hasStats={!!stats}
      render={() => {
        if (reviewCycleType === '360') {
          return (
            <Columns>
              <Column size={6} testClassName="test-global-status-chart">
                <GlobalStatusChart globalStatuses={stats.globalStatuses} />
              </Column>

              <Column size={6} testClassName="test-peer-feedback-status-chart">
                <PeerFeedbackStatusChart
                  statuses={stats.peerFeedbackStatuses}
                />
              </Column>
            </Columns>
          );
        }

        return (
          <Columns>
            {stats.selfEvaluationStatuses && (
              <Column
                size={6}
                testClassName="test-self-evaluation-statuses-chart"
              >
                <SelfEvaluationStatusesChart
                  statuses={stats.selfEvaluationStatuses}
                />
              </Column>
            )}

            <Column
              size={6}
              testClassName="test-reviewer-evaluation-statuses-chart"
            >
              <ReviewerEvaluationStatusesChart
                statuses={stats.reviewerEvaluationStatuses}
              />
            </Column>
          </Columns>
        );
      }}
    />
  );
}

export default newDataLoader({
  fetch: ({ reviewCycleId, userFilter, filter, userFilters }) =>
    get(`review_cycles/${reviewCycleId}/stats`, {
      isManager: true,
      userFilter,
      userFilters,
      filter,
    }),
  hydrate: {
    stats: {
      selfEvaluationStatuses: {},
      reviewerEvaluationStatuses: {},
      globalStatuses: {},
      peerFeedbackStatuses: {},
    },
  },
  cacheKey: ({ reviewCycleId, userFilter, filter, userFilters }) =>
    compositeKey({
      reviewCycleId,
      viewId: 'managerReviewCycleAnalytics',
      userFilter,
      userFilters,
      filter,
    }),
})(ManagerReviewCycleAnalytics);
