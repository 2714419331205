import React from 'react';

import { TrainingSession } from 'models';

import { FeatureFlagged } from 'components';

import GeneralActions from './GeneralActions';
import StatusActions from './StatusActions';

type Props = { session: TrainingSession };

const Actions = ({ session }: Props) => {
  return (
    <div className="flex gap-3">
      <GeneralActions session={session} />
      <FeatureFlagged flag="trainingPlans">
        <StatusActions session={session} />
      </FeatureFlagged>
    </div>
  );
};

export default Actions;
