import React from 'react';

import type { UserReviewSummary } from 'models';

import { Box, Icon, Text, Tooltip } from 'components';

import AILogo from 'scenes/components/AILogo';

type Props = {
  title: string;
  tooltipContent: string;
  summary: UserReviewSummary | null;
};
const ReviewSummary = ({ title, tooltipContent, summary }: Props) => {
  if (!summary?.content) {
    return null;
  }

  return (
    <Box className="form-content swag-border">
      <div className="flex justify-between items-center mb-3">
        <div className="flex items-center">
          <Text preset="16bs5.5">{title}</Text>
          <div>
            <Tooltip content={tooltipContent}>
              <Icon className="flex ml-2" name="info" />
            </Tooltip>
          </div>
        </div>
        <div className="flex">
          <AILogo />
        </div>
      </div>
      <Text className="whitespace-pre-wrap">{summary.content}</Text>
    </Box>
  );
};

export default ReviewSummary;
