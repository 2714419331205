import React, { CSSProperties } from 'react';

import type { UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  Column,
  Columns,
  FetchContainer,
  Number,
  Placeholder,
  Text,
} from 'components';
import { FilterSegment } from 'components/Filters/types';

const boxStyle: CSSProperties = {
  textAlign: 'center',
  padding: '20px',
};

type UserObjectivePeriodsStats = {
  averageCompletion: number | null;
  usersWithoutObjectivesCount: number;
};

type Props = {
  userFilter: UserFilterSegment;
  userFilters: Array<FilterSegment>;
};

type AfterDataLoaderProps = Props & {
  userObjectivePeriodsStats: UserObjectivePeriodsStats;
  isFetching: boolean;
  hasError: boolean;
};

const UserObjectivesStats = ({
  userObjectivePeriodsStats,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => {
  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      renderFetching={() => (
        <Columns className="mb-0 mt-0">
          <Column size={4}>
            <Box style={boxStyle}>
              <Placeholder width={50} />
              <br />
              <Placeholder />
            </Box>
          </Column>
          <Column size={4}>
            <Box style={boxStyle}>
              <Placeholder width={50} />
              <br />
              <Placeholder />
            </Box>
          </Column>
        </Columns>
      )}
      render={() => (
        <Columns className="mb-0 mt-0">
          <Column size={4}>
            <Box style={boxStyle}>
              <Text preset="18bs5">
                {userObjectivePeriodsStats.averageCompletion !== null &&
                userObjectivePeriodsStats.averageCompletion !== undefined ? (
                  <Number
                    value={userObjectivePeriodsStats.averageCompletion}
                    isPercentage
                  />
                ) : (
                  __('N/A')
                )}
              </Text>
              <br />
              <Text>{__('average objectives completion')}</Text>
            </Box>
          </Column>
          <Column size={4}>
            <Box style={boxStyle}>
              <Text preset="18bs5">
                <Number
                  value={userObjectivePeriodsStats.usersWithoutObjectivesCount}
                />
              </Text>
              <br />
              <Text>
                {n__(
                  'employee without objectives',
                  'employees without objectives',
                  userObjectivePeriodsStats.usersWithoutObjectivesCount
                )}
              </Text>
            </Box>
          </Column>
        </Columns>
      )}
    />
  );
};

export default newDataLoader({
  // TODO: multifilters: remove userFilter when FF is enabled for everyone
  fetch: ({ userFilter, userFilters }: Props) =>
    get('/user_objective_periods/stats', { userFilter, userFilters }),
  hydrate: {
    userObjectivePeriodsStats: {},
  },
  cacheKey: ({ userFilter, userFilters }: Props) =>
    compositeKey({ userFilter, userFilters }),
})(UserObjectivesStats);
