import React, { useEffect, useState } from 'react';

import type { UserFilterChangeMethod } from 'lib/dataLoader/pagination/types';

import {
  ReviewCycle,
  SkillsLevel,
  SkillsMatrix,
  UserFilterSegment,
} from 'models';

import store from 'config/store';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { hydrateFromResponse } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { ActiveFilters } from 'components/Filters/types';

import FiltersRenderer from './FiltersRenderer';

type Props = {
  reviewCycle: ReviewCycle;
  displayMockedData: boolean;
  totalCount: number | null;
  selectedMatrixId: null | string;
  setSelectedMatrixId: (string) => void;
  selectedLevelId: null | string;
  setSelectedLevelId: (string) => void;
  userFilter: UserFilterSegment | null;
  setUserFilter: UserFilterChangeMethod;
  withUserMultiFilters?: boolean;
  userFilters?: ActiveFilters;
  setUserFilters?: (ActiveFilters) => void;
};

const Filters = ({
  reviewCycle,
  selectedMatrixId,
  displayMockedData,
  ...otherProps
}: Props) => {
  const dispatch = useAppDispatch();
  const [
    skillsStatsDevelopmentNeedsAvailableFilters,
    setSkillsStatsDevelopmentNeedsAvailableFilters,
  ] = useState<{
    matrices: Array<Partial<SkillsMatrix>>;
    levels: Array<Partial<SkillsLevel>>;
  }>({ matrices: [], levels: [] });
  const fetchSkillsStatsDevelopmentNeedsAvailableFilters = async () => {
    const { response } = await dispatch(
      get(`skills/stats/development_needs/available_filters`, {
        reviewCycleIds: reviewCycle.id,
      })
    );
    const availableFilters = hydrateFromResponse(
      store.getState().data,
      response.body,
      {
        matrices: {},
        levels: {},
      },
      response.body.data.id
    );

    setSkillsStatsDevelopmentNeedsAvailableFilters(availableFilters);
  };
  const initializeSkillsStatsDevelopmentNeedsAvailableFilters = () => {
    if (displayMockedData) {
      setSkillsStatsDevelopmentNeedsAvailableFilters({
        matrices: [
          { title: __('Finance'), id: '1' },
          { title: __('HR'), id: '2' },
          { title: __('Product manager'), id: '3' },
          { title: __('Project manager'), id: '4' },
        ],
        levels: [
          { title: __('Junior'), id: '1', matrixId: '1' },
          { title: __('Intermediate'), id: '2', matrixId: '1' },
          { title: __('Senior'), id: '3', matrixId: '1' },
          { title: __('Junior'), id: '1', matrixId: '2' },
          { title: __('Intermediate'), id: '2', matrixId: '2' },
          { title: __('Senior'), id: '3', matrixId: '2' },
          { title: __('Junior'), id: '1', matrixId: '3' },
          { title: __('Intermediate'), id: '2', matrixId: '3' },
          { title: __('Senior'), id: '3', matrixId: '3' },
          { title: __('Junior'), id: '1', matrixId: '4' },
          { title: __('Intermediate'), id: '2', matrixId: '4' },
          { title: __('Senior'), id: '3', matrixId: '4' },
        ],
      });
    } else {
      fetchSkillsStatsDevelopmentNeedsAvailableFilters();
    }
  };

  useEffect(() => {
    initializeSkillsStatsDevelopmentNeedsAvailableFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const matrixSelectValues =
    skillsStatsDevelopmentNeedsAvailableFilters.matrices.map(matrix => ({
      value: matrix.id,
      label: matrix.title,
    }));
  const levelSelectValues = skillsStatsDevelopmentNeedsAvailableFilters.levels
    .filter(level => selectedMatrixId && level.matrixId === selectedMatrixId)
    .map(level => ({ value: level.id, label: level.title }));

  return (
    <FiltersRenderer
      matrixSelectValues={matrixSelectValues}
      levelSelectValues={levelSelectValues}
      selectedMatrixId={selectedMatrixId}
      {...otherProps}
    />
  );
};

export default Filters;
