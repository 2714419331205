import React, { useState } from 'react';

import type { ModalProps } from '../';
import type { SkillsArea } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import {
  Button,
  Field,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import SkillsAreaPicker from './SkillsAreaPicker';

const ImportModal = ({ matrix, modalParams, setModalParams }: ModalProps) => {
  const [area, setArea] = useState<SkillsArea | null>(null);

  const dispatch = useAppDispatch();

  const onSave = async () => {
    await dispatch(
      post(`skills/matrices/${matrix.id}/add_area`, {
        position: modalParams?.position,
        areaId: (area as SkillsArea).id,
      })
    );

    setModalParams(null);
  };

  return (
    <ModalCard
      isActive
      onClose={() => setModalParams(null)}
      isLarge
      refreshContentOnOpening
      className="skills-form-creation-modal"
    >
      <ModalCardHead>
        <ModalCardTitle>{__('Add a skill')}</ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody>
        <div className="mb-4">
          {__(
            'You can add a skill from your skills library. To learn more about skills, %1.',
            <a href="https://docs.elevo.fr/">{__('visit our help center')}</a>
          )}
        </div>
        <Field>
          <Label>{__('Search for a skill')}</Label>

          <SkillsAreaPicker
            matrixId={matrix.id}
            selectedArea={area}
            onChange={setArea}
          />
        </Field>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={() => setModalParams(null)}>
          {__('Cancel')}
        </Button>
        <Button color="primary" disabled={!area} onClick={onSave}>
          {__('Add a skill')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default ImportModal;
