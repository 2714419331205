import Color from 'color';
import React from 'react';
import { Link } from 'react-router-dom';

import type { User } from 'models';

import colors from 'styles/colors';

import { __ } from 'helpers/i18n';
import { pathToUser } from 'helpers/paths';

import { Icon, Overlay, WithHoverableFadeinFadeoutAnimation } from 'components';

type Props = {
  report: User;
};

const IndirectReportCardOverlay = ({ report }: Props) => {
  const primaryColor = Color(colors.primaryColor);
  const lightGradientColor = primaryColor.lighten(0.1).toString();
  const darkGradientColor = primaryColor.darken(0.2).toString();

  return (
    <WithHoverableFadeinFadeoutAnimation
      className="indirect-report-overlay-card-wrapper"
      duration={250}
    >
      <Link to={pathToUser(report.id)}>
        <Overlay
          className="indirect-report-overlay-card"
          style={{
            boxShadow: `0 3px 5px 0 ${primaryColor
              .lighten(0.7)
              .alpha(0.5)
              .toString()}`,
            border: `solid 1px ${primaryColor.toString()}`,
            backgroundBlendMode: 'overlay, normal',
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(315deg, ${darkGradientColor}, ${lightGradientColor})`,
          }}
        >
          <div className="indirect-report-overlay-text">
            {__('See profile')}
          </div>
          <Icon
            style={{
              verticalAlign: 'middle',
              marginLeft: 10,
              color: 'white',
            }}
            size="small"
            name="arrow_forward"
          />
        </Overlay>
      </Link>
    </WithHoverableFadeinFadeoutAnimation>
  );
};

export default IndirectReportCardOverlay;
