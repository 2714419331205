import { sortBy } from 'lodash';
import React from 'react';

import type {
  BlockRatingOption,
  MultipleScaleQuestionBlock,
  RatingCriterion,
} from 'models';

import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { del, post, put } from 'redux/actions/api';

import { Column, Columns, TextList, Title } from 'components';

type Props = {
  reviewBlock: MultipleScaleQuestionBlock;
  ratingCriteria: Array<RatingCriterion>;
  ratingOptions: Array<BlockRatingOption>;
};

const RatingsSection = ({
  reviewBlock,
  ratingCriteria,
  ratingOptions,
}: Props) => {
  const dispatch = useAppDispatch();
  const createRatingCriterion = (label: string) =>
    dispatch(post('rating_criteria', { label, reviewBlockId: reviewBlock.id }));
  const updateRatingCriterion = (
    ratingCriterionId: string,
    options: Partial<BlockRatingOption>
  ) => dispatch(put(`rating_criteria/${ratingCriterionId}`, options));
  const deleteRatingCriterion = (ratingCriterionId: string) =>
    dispatch(del(`rating_criteria/${ratingCriterionId}`));
  const createRatingOption = (label: string) =>
    dispatch(
      post('rating_options', {
        label,
        role: 'everyone',
        reviewBlockId: reviewBlock.id,
      })
    );
  const updateRatingOption = (
    ratingOptionId: string,
    options: Partial<BlockRatingOption>
  ) => dispatch(put(`rating_options/${ratingOptionId}`, options));
  const deleteRatingOption = (ratingOptionId: string) =>
    dispatch(del(`rating_options/${ratingOptionId}`));

  const canCreateRatingCriterion = can({
    perform: 'create_rating_criterion',
    on: reviewBlock,
  });
  const canDeleteRatingCriterion = can({
    perform: 'delete_rating_criterion',
    on: reviewBlock,
  });
  const canCreateRatingOption = can({
    perform: 'create_rating_option',
    on: reviewBlock,
  });
  const canDeleteRatingOption = can({
    perform: 'delete_rating_option',
    on: reviewBlock,
  });

  return (
    <Columns className="ratings-section">
      <Column>
        <div style={{ marginBottom: 8 }}>
          <Title
            size={7}
            transformation="uppercase"
            style={{ lineHeight: '14px' }}
          >
            {__('Criteria to evaluate')}
          </Title>
          <small>{__('You can add between 3 and 8 criteria')}</small>
        </div>
        <TextList
          items={sortBy(ratingCriteria, 'position')}
          className="rating-criteria"
          testClassName="test-rating-criterion-list"
          itemTestClassName="test-rating-criterion"
          onCreate={
            canCreateRatingCriterion ? createRatingCriterion : undefined
          }
          onUpdate={updateRatingCriterion}
          onDelete={
            canDeleteRatingCriterion ? deleteRatingCriterion : undefined
          }
          creationPlaceholder={__('Add an item...')}
          emptyLabelPlaceholder={__('Notation')}
          ordered
          handleErrors
        />
      </Column>
      <Column>
        <div style={{ marginBottom: 8 }}>
          <Title
            size={7}
            transformation="uppercase"
            style={{ lineHeight: '14px' }}
          >
            {__('Notation')}
          </Title>
          <small>
            {__(
              'You can add between 3 and 5 ratings, from the lowest to the highest.'
            )}
          </small>
        </div>
        <TextList
          items={sortBy(ratingOptions, 'position')}
          className="rating-options"
          testClassName="test-rating-criterion-option-list"
          itemTestClassName="test-rating-criterion-option"
          onCreate={canCreateRatingOption ? createRatingOption : undefined}
          onUpdate={updateRatingOption}
          onDelete={canDeleteRatingOption ? deleteRatingOption : undefined}
          creationPlaceholder={__('Add a notation...')}
          emptyLabelPlaceholder={__('Notation')}
          ordered
        />
      </Column>
    </Columns>
  );
};

export default RatingsSection;
