import React from 'react';

import classNames from 'helpers/classNames';

import CurrencySelect from './CurrencySelect';
import FloatInput from './FloatInput';

type Props = {
  amount: number | undefined | null;
  currency: string;
  placeholder?: string;
  onAmountChange: (amount: number | null) => void;
  onCurrencyChange?: (currency: string) => void;
  saveOnlyOnBlur?: boolean;
  isDisabled?: boolean;
  testClassName?: string;
  className?: string;
};

const MoneyInput = ({
  amount,
  currency,
  placeholder,
  onAmountChange,
  onCurrencyChange,
  saveOnlyOnBlur,
  isDisabled,
  testClassName,
  className,
}: Props) => {
  const mergedClassName = classNames('money-input', testClassName, className);

  return (
    <div className={mergedClassName}>
      <FloatInput
        value={amount}
        placeholder={placeholder}
        onInputChange={onAmountChange}
        saveOnlyOnBlur={saveOnlyOnBlur}
        isDisabled={isDisabled}
        saveAsInteger
        className={!!onCurrencyChange ? 'rounded-r-none' : ''}
      />
      {!!onCurrencyChange ? (
        <CurrencySelect
          selectedCurrency={currency}
          onCurrencyChange={onCurrencyChange}
        />
      ) : (
        <span className="currency-symbol">{currency}</span>
      )}
    </div>
  );
};

export default MoneyInput;
