import { pick } from 'lodash';
import React, { Fragment } from 'react';

import type { TrainingSession } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import {
  Box,
  BoxList,
  BoxListItem,
  Column,
  Columns,
  FeatureFlagged,
  Text,
} from 'components';

import ParticipantItem from './ParticipantItem';
import ParticipantsTable from './ParticipantsTable';
import CalendarInvitationActions from './ParticipantsTable/CalendarInvitationActions';

type Props = {
  session: TrainingSession;
  refreshSession: () => Promise<void>;
};

const Participants = ({ session, refreshSession }: Props) => {
  const dispatch = useAppDispatch();

  const updateParticipant = async ({ id, fundingItems, ...participant }) =>
    await dispatch(
      put(`training/participants/${id}`, {
        trainingParticipant: {
          ...pick(participant, ['trainingCostCents']),
          fundingItemsAttributes: fundingItems.map(item => ({
            ...item,
            fundingSourceId: item.fundingSource.id,
          })),
        },
      })
    );

  return (
    <Fragment>
      <Box>
        <FeatureFlagged
          flag="newTrainingCostManagement"
          alternativeChildren={
            <Fragment>
              <CalendarInvitationActions session={session} />
              <BoxList style={{ marginTop: 16 }}>
                <BoxListItem>
                  <Columns isMobile>
                    <Column size={5}>
                      <Text preset="14s6" color="light">
                        {__('Participant')}
                      </Text>
                    </Column>
                    <Column size={4}>
                      <Text preset="14s6" color="light">
                        {__('Funding')}
                      </Text>
                    </Column>
                    <Column size={2}>
                      <Text preset="14s6" color="light">
                        {__('Invitation')}
                      </Text>
                    </Column>
                    <Column size={1} />
                  </Columns>
                </BoxListItem>
                {session.participants?.map(participant => (
                  <ParticipantItem
                    key={participant.id}
                    participant={participant}
                    refreshSession={refreshSession}
                    sessionName={session.name}
                    defaultCurrency={session.costCurrency}
                    saveParticipant={updateParticipant}
                    periodSlug={session.period.slug}
                  />
                ))}
                <BoxListItem style={{ paddingBottom: 16 }}>
                  {n__(
                    '%1 participant',
                    '%1 participants',
                    session.participants?.length || 0
                  )}
                </BoxListItem>
              </BoxList>
            </Fragment>
          }
        >
          <ParticipantsTable
            session={session}
            defaultPaginationParams={{ sort: { participant_name: 'asc' } }}
            paginationType="url"
          />
        </FeatureFlagged>
      </Box>
    </Fragment>
  );
};

export default Participants;
