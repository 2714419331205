/*
The Control component of bulma, see https://bulma.io/documentation/form/general
It is used to wrap inputs, selects, checkbox, radio or textarea in forms
It itself is wrapped in a Field component which helps to keep the spacing between the different fields consistent.

Typical example:
<div class="field">
  <label class="label">Email</label>
  <div class="control has-icons-left has-icons-right">
    <input class="input is-danger" type="email" placeholder="Email input" value="hello@">
    <span class="icon is-small is-left">
      <i class="fas fa-envelope"></i>
    </span>
    <span class="icon is-small is-right">
      <i class="fas fa-exclamation-triangle"></i>
    </span>
  </div>
  <p class="help is-danger">This email is invalid</p>
</div>
*/
import React, { CSSProperties, ReactNode } from 'react';

import type { BulmaSize } from 'components/types/bulma';

import classNames from 'helpers/classNames';

import Icon, { MaterialIconName } from 'components/Icon';

export type Props = {
  children: ReactNode;

  // To add icons inside the inputs or selects
  // https://bulma.io/documentation/form/general/#with-icons
  leftIcon?: MaterialIconName;
  rightIcon?: MaterialIconName;
  leftIconSize?: BulmaSize;
  rightIconSize?: BulmaSize;

  // Use in cunjunction with hasAddons on Field, makes the controled input takes all available width
  // https://bulma.io/documentation/form/general/#form-addons
  isExpanded?: boolean;

  // https://bulma.io/documentation/form/input/#states
  // Use to add a loader inside the input inside the Control
  isLoading?: boolean;

  isClearable?: boolean;
  onClear?: () => any;

  className?: string;
  style?: CSSProperties;
};

export default function Control({
  children,
  leftIcon,
  rightIcon,
  isClearable,
  onClear,
  style,
  className,
  isLoading,
  leftIconSize = 'normal',
  rightIconSize = 'normal',
}: Props) {
  const mergedClassName = classNames('control', className, {
    'has-icons-left': !!leftIcon,
    'has-icons-right': !!rightIcon || !!isClearable,
    'is-loading': isLoading,
  });

  return (
    <div className={mergedClassName} style={style}>
      {children}

      {!!leftIcon && (
        <Icon size={leftIconSize} className="is-left" name={leftIcon} />
      )}

      {!!rightIcon && (
        <Icon size={rightIconSize} className="is-right" name={rightIcon} />
      )}

      {!!isClearable && (
        <Icon
          size="small"
          className="is-right"
          onClick={e => {
            e.stopPropagation();
            onClear && onClear();
          }}
          style={{
            cursor: 'pointer',
            pointerEvents: 'inherit',
            top: 0,
            right: 0,
          }}
          name="close"
        />
      )}
    </div>
  );
}
