/* eslint-disable react/style-prop-object */
import React from 'react';

import type { Objective } from 'models';

import {
  BoxListItem,
  Column,
  Columns,
  Icon,
  Testable,
  Tooltip,
} from 'components';

import StatusDisplay from './StatusDisplay';
import {
  ObjectiveCompletedKeyResultsIndicator,
  ObjectiveCompletionText,
  ObjectiveTitle,
} from './components';

type Props = {
  objective: Objective;
  target?: string;
  locked: boolean;
  lockedReason: string | undefined | null;
};

const ObjectiveRowItem = ({
  objective,
  target,
  locked,
  lockedReason,
}: Props) => {
  return (
    <BoxListItem
      isSmall
      style={{
        position: 'relative',
        padding: '0.450rem 1.25rem',
      }}
    >
      <Testable name="test-objective-row-item">
        <StatusDisplay status={objective.status} className="mr-2" />
        <Columns style={{ paddingLeft: 10 }} isMobile contentVerticallyAligned>
          <Column ellipsisOnLineOverflow tabletSize={9} mobileSize={6}>
            <ObjectiveTitle objective={objective} target={target} />
          </Column>

          <Column
            contentIsPulledRight
            offset={objective.isLocked ? undefined : 1}
            tabletSize={1}
            mobileSize={2}
          >
            <ObjectiveCompletedKeyResultsIndicator objective={objective} />
          </Column>

          <Column contentIsPulledRight tabletSize={1} mobileSize={2}>
            <ObjectiveCompletionText completion={objective.completion} />
          </Column>

          {!!locked && lockedReason && (
            <Column contentIsPulledRight tabletSize={1} mobileSize={2}>
              <Tooltip content={lockedReason}>
                <Icon isInfo name="lock" />
              </Tooltip>
            </Column>
          )}
        </Columns>
      </Testable>
    </BoxListItem>
  );
};

export default ObjectiveRowItem;
