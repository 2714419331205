import React from 'react';

import classNames from 'helpers/classNames';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default function FieldError({ children, className }: Props) {
  if (!children) return null;

  return <p className={classNames('help is-danger', className)}>{children}</p>;
}
