import React, { ReactNode, useContext } from 'react';

import { ReviewCycle } from 'models';

import ParticipantsChooser from 'scenes/components/ParticipantsChooser';

import { DataContext } from '../../Routes';
import Import from './Import';

type Props = {
  reviewCycle: ReviewCycle;
  participantListStyle?: {};
  showBulkActions: boolean;
  renderAfter?: (refetch: () => Promise<any>) => ReactNode;
};

const ReviewCycleParticipantsChooser = ({
  reviewCycle,
  participantListStyle,
  showBulkActions,
  renderAfter,
}: Props) => {
  const { withUserMultiFilters } = useContext(DataContext);
  return (
    <ParticipantsChooser
      withUserMultiFilters={withUserMultiFilters}
      participantListStyle={participantListStyle}
      showBulkActions={showBulkActions}
      renderAfter={renderAfter}
      fetchUrl={`review_cycles/${reviewCycle.id}/participant_eligibilities`}
      updateUrl={`review_cycles/${reviewCycle.id}/planned_participants`}
      participantsCount={reviewCycle.plannedParticipantIds.length}
      renderImportButton={(refetchData, setReport, setError) => (
        <Import
          reviewCycleId={reviewCycle.id}
          refetchData={refetchData}
          onReport={setReport}
          onError={setError}
        />
      )}
    />
  );
};

export default ReviewCycleParticipantsChooser;
