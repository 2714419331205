import React, { CSSProperties } from 'react';

import {
  Box,
  Column,
  Columns,
  DesignSystem,
  Flex,
  Placeholder,
} from 'components';

import {
  ActionsPlaceholder,
  CompletionPlaceholder,
  KeyResultsSectionPlaceholder,
  Status,
  TitlePlaceholder,
} from './components';

type Props = {
  style?: CSSProperties;
};

export default function ObjectiveCardPlaceholder({ style }: Props) {
  const noop = () => Promise.resolve();

  return (
    <DesignSystem version={2} style={style}>
      <Box className="objective-card">
        <Columns className="objective-header">
          <Column size={8}>
            <Flex
              style={{
                marginTop: -1,
                marginLeft: -1,
                alignItems: 'center',
              }}
            >
              <Status status={null} onChange={noop} disabled />
              <div style={{ marginLeft: 8, flexGrow: 2 }}>
                <TitlePlaceholder />
              </div>
            </Flex>
          </Column>

          <Column>
            <Flex verticalAlign style={{ justifyContent: 'flex-end' }}>
              <div style={{ marginRight: 8 }}>
                <CompletionPlaceholder />
              </div>

              <ActionsPlaceholder />
            </Flex>
          </Column>
        </Columns>

        <Flex>
          <Placeholder style={{ marginLeft: 10 }} height={16} width={130} />
          <Placeholder style={{ marginLeft: 4 }} height={16} width={110} />
        </Flex>

        <KeyResultsSectionPlaceholder />
      </Box>
    </DesignSystem>
  );
}
