import { isEmpty } from 'lodash';
import React, { Fragment, useState } from 'react';

import type { User } from 'models/User';

import { __ } from 'helpers/i18n';

import {
  Button,
  Icon,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  SimpleTable,
  StrictlySanitizedHtml,
  Tag,
  Text,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import UserPicker from 'scenes/components/UserPicker';

type Props = {
  users: User[];
  reviewTemplateName: string;
  onSubmit: (userIds: string[]) => Promise<void>;
  onClose: () => void;
  onDelete: (userId: string) => Promise<void>;
};

const ShareModal = ({
  users,
  reviewTemplateName,
  onSubmit,
  onClose,
  onDelete,
}: Props) => {
  const [selectedUsers, setSelectedUsers] = useState([]);

  return (
    <ModalCard isActive onClose={onClose} isLarge>
      <ModalCardHead onClose={onClose}>
        <ModalCardTitle>{__('Share "%1"', reviewTemplateName)}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <StrictlySanitizedHtml
          html={__(
            'Share the template in <b>read-only</b> mode with the following users:'
          )}
        />
        <div className="flex mt-2 mb-4">
          <UserPicker
            className="flex-1 mr-4"
            value={selectedUsers}
            fetchParams={{ permission: 'share_review_template?' }}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            onChange={users => Array.isArray(users) && setSelectedUsers(users)}
            isMulti
            inModal
          />
          <Button
            color="primary"
            onClick={async () => {
              // @ts-ignore TSFIXME: Fix strictNullChecks error
              await onSubmit(selectedUsers.map(user => user.id));
              setSelectedUsers([]);
            }}
          >
            <Icon className="mr-2" name="send" />
            {__('Share')}
          </Button>
        </div>
        {!isEmpty(users) && (
          <Fragment>
            <Text preset="16bs5.5">{__('Users with access')}</Text>
            <SimpleTable
              withBorder
              columns={[
                {
                  header: __('User'),
                  cell: (user: User) => <UserAvatar user={user} withJobTitle />,
                },
                {
                  header: __('Access'),
                  cell: () => <Tag>{__('Read only')}</Tag>,
                },
                {
                  header: '',
                  cell: user => (
                    <Icon
                      className="cursor-pointer"
                      onClick={() => onDelete(user.id)}
                      name="delete"
                    />
                  ),
                  isNarrow: true,
                },
              ]}
              rows={users}
              keyFn={(user: User) => user.id}
              emptyState={<div />}
            />
          </Fragment>
        )}
      </ModalCardBody>
      <ModalCardFooter></ModalCardFooter>
    </ModalCard>
  );
};

export default ShareModal;
