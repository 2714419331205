import { uniq } from 'lodash';
import React from 'react';

import { SkillsLevel, SkillsMatrix } from 'models/skills';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post } from 'redux/actions/api';

import {
  BoxSeparator,
  ConfirmationModal,
  FetchContainer,
  Flex,
  type IOption,
  Select,
} from 'components';

import useGetAssignationCriterionLabel from '../../helpers/userAssignation/useGetAssignationCriterionLabel';

type Props = {
  onClose: () => void;
  afterSave: () => void;
  matrix: SkillsMatrix;
};

type ValuesByLevelIdType = {
  levelId: string;
  values: Array<string>;
};

type ConfigurationType = {
  availableValues: Array<string>;
  valuesByLevelId: Array<ValuesByLevelIdType>;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    configuration: ConfigurationType;
  };

type Option = IOption<string>;

const toOptionArray = (items: Array<string> | undefined) =>
  (items || []).map(item => ({ value: item, label: item }));

const AutoAssignationModal = ({
  afterSave,
  onClose,
  matrix,
  ...otherProps
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();
  const assignationCriterionLabel = useGetAssignationCriterionLabel();
  const [configuration, setConfiguration] = React.useState<ConfigurationType>({
    availableValues: [],
    valuesByLevelId: [],
  });

  const saveConfiguration = async () => {
    await dispatch(
      post(
        `/skills/matrices/${matrix.id}/user_assignation_rules/configuration`,
        { configuration },
        {
          successMessage: __(
            '<b>The assignment criteria have been successfully defined.</b><br/>Employees are being assigned to the matrix according to these criteria.<br/>The update may take a few seconds and will be visible after the page is refreshed.'
          ),
        }
      )
    );

    afterSave();
  };

  const updateConfigurationState = (
    level: SkillsLevel,
    options: Array<Option>
  ) => {
    const newSelectedValues = options.map(option => option.value);
    const oldSelectedValues =
      configuration.valuesByLevelId.find(item => item.levelId === level.id)
        ?.values || [];

    const removedValues = oldSelectedValues.filter(
      value => !newSelectedValues.includes(value)
    );

    setConfiguration({
      availableValues: uniq([
        ...configuration.availableValues,
        ...removedValues,
      ]),
      valuesByLevelId: [
        ...configuration.valuesByLevelId.filter(
          item => item.levelId !== level.id
        ),
        { levelId: level.id, values: newSelectedValues },
      ],
    });
  };

  const unusedAvailableValues = () => {
    const usedValues = configuration.valuesByLevelId
      .map(item => item.values)
      .flat();

    return configuration.availableValues
      .filter(value => !usedValues.includes(value))
      .sort();
  };

  return (
    <ConfirmationModal
      isActive={true}
      onConfirm={saveConfiguration}
      confirmDisabled={configuration.valuesByLevelId.length === 0}
      onCancel={onClose}
      cancelLabel={__('Cancel')}
      confirmLabel={__('Save the assignment criteria')}
      title={__('Automatic assignment of employees to the matrix')}
      refreshContentOnOpening
      isLarge
    >
      <p>
        {__(
          'Select the assignment criteria that will help to match employees to the levels of the matrix: %1',
          <b>{matrix.title}</b>
        )}
      </p>
      <br />
      <FetchContainer
        {...otherProps}
        render={() => {
          if (configuration.valuesByLevelId.length === 0) {
            setConfiguration(otherProps.configuration);
          }

          return matrix.levels.map(level => (
            <div key={level.id}>
              <p>
                {__(
                  'Employees will be assigned to the %1 level',
                  <b>{level.title}</b>
                )}
              </p>
              <Flex verticalAlign className="pt-2">
                <div className="w-1/3" style={{ marginRight: '2rem' }}>
                  {__('If %1 contains', <b>{assignationCriterionLabel}</b>)}
                </div>
                <Select
                  inModal
                  onChange={values =>
                    updateConfigurationState(level, values as Array<Option>)
                  }
                  placeholder={__('Select a value')}
                  isMulti
                  value={toOptionArray(
                    configuration.valuesByLevelId.find(
                      item => item.levelId === level.id
                    )?.values
                  )}
                  options={toOptionArray(unusedAvailableValues())}
                  className="w-2/3"
                  maxMenuHeight={200}
                  isClearable
                />
              </Flex>
              <BoxSeparator className="my-4 mx-1" />
            </div>
          ));
        }}
      />
    </ConfirmationModal>
  );
};

export default newDataLoader({
  fetch: ({ matrix }: Props) =>
    get(`skills/matrices/${matrix.id}/user_assignation_rules/configuration`),
  hydrate: { configuration: {} },
})(AutoAssignationModal);
