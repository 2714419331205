import React, { useEffect, useState } from 'react';
// We use this lib as a float formatter because it is behaving the way we want
// even if we don't always display currency with it
import FormattedFloatInput from 'react-currency-input-field';

import classNames from 'helpers/classNames';
import { getCurrentLocale } from 'helpers/i18n';

type Props = {
  value: number | undefined | null;
  syncWithParent?: boolean;
  placeholder?: string;
  onInputChange: (float: number | null) => void;
  saveOnlyOnBlur?: boolean;
  isDisabled?: boolean;
  decimalScale?: number;
  saveAsInteger?: boolean;
  className?: string;
};

const FloatInput = ({
  value: valueFromProps,
  syncWithParent,
  placeholder,
  onInputChange,
  saveOnlyOnBlur,
  isDisabled,
  saveAsInteger = false,
  decimalScale = 2,
  className,
}: Props) => {
  const multiplier = saveAsInteger ? 10 ** decimalScale : 1;
  const [formattedValue, setFormattedValue] = useState<string>(
    valueFromProps != null ? `${valueFromProps / multiplier}` : ''
  );
  const [inputFloat, setInputFloat] = useState<number>(
    Number(valueFromProps) / multiplier
  );

  useEffect(() => {
    if (syncWithParent) {
      setFormattedValue(
        valueFromProps != null ? `${valueFromProps / multiplier}` : ''
      );
      setInputFloat(Number(valueFromProps) / multiplier);
    }
  }, [syncWithParent, valueFromProps, multiplier]);

  const removeTrailingZeros = (amount: string): string =>
    amount.replace(/[.,]00?$/, '');

  const persistChange = (float: number) => {
    let value = float ? float * multiplier : null;
    value = saveAsInteger && value ? Math.round(value) : value;
    onInputChange(value);
  };

  const handleOnValueChange = (formattedAmount: string, float: number) => {
    setFormattedValue(formattedAmount);
    setInputFloat(float);
  };

  const locale = getCurrentLocale();

  return (
    <FormattedFloatInput
      className={classNames('input', className)}
      value={formattedValue}
      placeholder={placeholder}
      allowNegativeValue={false}
      decimalScale={2}
      intlConfig={{ locale }}
      onValueChange={(formattedAmount, _name, currencyInput) => {
        const float = currencyInput?.float || 0;
        handleOnValueChange(formattedAmount || '', float);

        if (!saveOnlyOnBlur) {
          persistChange(float);
        }
      }}
      onBlur={() => {
        setFormattedValue(removeTrailingZeros(formattedValue));
        if (saveOnlyOnBlur) {
          persistChange(inputFloat);
        }
      }}
      disabled={isDisabled}
    />
  );
};

export default FloatInput;
