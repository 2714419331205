import React from 'react';
import { compose } from 'redux';

import type { PaginatedCollection, TargetedCampaignRole, User } from 'models';

import compositeKey from 'helpers/compositeKey';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { WithPaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { del, get } from 'redux/actions/api';

import {
  Button,
  DatatableWrapper,
  FetchContainer,
  Icon,
  SimpleTable,
  Text,
  Tooltip,
} from 'components';
import {
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components/modals';

import UserAvatar from 'scenes/components/UserAvatar';

import { displayableRoleName } from './helpers';

type Props = {
  reviewCycleId: string;
  reviewCycleName: string;
  onClose: () => void;
  countPerPage: number;
  withUrlChange: boolean;
};

type AfterPaginateProps = Props & WithPaginationProps;

type AfterDataloaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    collection: PaginatedCollection<{ user: User; role: TargetedCampaignRole }>;
  };

const ListAdminsModal = ({
  reviewCycleId,
  reviewCycleName,
  onClose,
  isFetching,
  hasError,
  collection,
  refetchData,
  page,
  countPerPage,
  queryParams: { search, userFilters },
  setQueryParams,
  setPreviousPageParams,
  setNextPageParams,
}: AfterDataloaderProps) => {
  const dispatch = useAppDispatch();

  const revokeRole = async (user: User, role: TargetedCampaignRole) => {
    await dispatch(
      del(`roles/revoke`, {
        resource: { type: 'ReviewCycle', id: reviewCycleId },
        userId: user.id,
        roleName: role.name,
      })
    );
    return refetchData();
  };

  return (
    <ModalCard onClose={onClose} isActive isLarge>
      <ModalCardHead>
        <ModalCardTitle>
          {__('Users with administrators permissions on the campaign')}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <div className="mb-2">
          <div className="mb-4">
            {__(
              'Find the users with permissions on the "%1" campaign in addition to Elevo administrators.',
              reviewCycleName
            )}
          </div>

          <DatatableWrapper
            collectionInfo={collection}
            userFilters={userFilters}
            withUserMultiFilters
            isFetching={isFetching}
            hasError={hasError}
            page={page}
            search={search}
            withSearch
            countPerPage={countPerPage}
            onQueryParamsChange={setQueryParams}
            getNextPage={setNextPageParams}
            getPreviousPage={setPreviousPageParams}
            showTotalRecordCount
          >
            <FetchContainer
              isFetching={isFetching}
              hasError={hasError}
              loadingStyle="overlay"
              render={() => (
                <SimpleTable
                  columns={[
                    {
                      header: __('Employee'),
                      cell: userWithRole => (
                        <UserAvatar withJobTitle user={userWithRole.user} />
                      ),
                    },
                    {
                      header: __('Role'),
                      cell: userWithRole => (
                        <Text>
                          {displayableRoleName(userWithRole.role.name)}
                        </Text>
                      ),
                    },
                    {
                      header: '',
                      cell: userWithRole => (
                        <Tooltip
                          content={__(
                            'Revoke the role assigned to %1 for this campaign.',
                            userWithRole.user.fullName
                          )}
                        >
                          <Button
                            hasIconOnly
                            className="border-none bg-transparent"
                            onClick={() =>
                              revokeRole(userWithRole.user, userWithRole.role)
                            }
                          >
                            <Icon name="delete" />
                          </Button>
                        </Tooltip>
                      ),
                      isNarrow: true,
                    },
                  ]}
                  rows={collection?.items || []}
                  keyFn={userWithRole => userWithRole.user.id}
                  className="mb-4"
                  useSmallerYPadding
                  lineCountPerRow={2}
                />
              )}
            />
          </DatatableWrapper>
        </div>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Close')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default compose<React.ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      queryParams: { search, userFilters },
      reviewCycleId,
    }: AfterPaginateProps) =>
      get(`roles/granted_users`, {
        resource: { type: 'ReviewCycle', id: reviewCycleId },
        page,
        countPerPage,
        search,
        userFilters,
      }),
    hydrate: {
      collection: {
        items: {
          user: {},
          role: { resource: {} },
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      queryParams: { search, userFilters },
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, userFilters }),
  })
)(ListAdminsModal);
