import React, { useContext } from 'react';

import type { UserFilterChangeMethod } from 'lib/dataLoader/pagination/types';
import type { UserFilterSegment } from 'models';

import {
  formatFilterToParamsHash,
  formatParamsHashToFilterName,
} from 'helpers/filter';
import { __ } from 'helpers/i18n';

import {
  FilterBar,
  Level,
  LevelItem,
  LevelLeft,
  LevelRight,
  Notification,
} from 'components';
import FetchContainer from 'components/FetchContainer';
import { ActiveFilters } from 'components/Filters/types';

import { DataContext } from 'scenes/admin/reviewCycles/Routes';
import UserFilter from 'scenes/components/UserFilter';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

type Props = {
  isFetching: boolean;
  hasError: boolean;
  hasStats: boolean;
  filter: { user: { [key: string]: boolean } };
  onFilterChange: (filter: { user: { [key: string]: boolean } }) => void;
  userFilter?: UserFilterSegment | null;
  userFilters?: ActiveFilters;
  onUserFiltersChange?:
    | ((newQueryParams: '' | ActiveFilters) => void | undefined)
    | undefined;
  onUserFilterChange?: UserFilterChangeMethod;
  render: () => React.ReactNode;
};

export default function StatsContainer({
  render,
  isFetching,
  hasError,
  hasStats,
  filter,
  onFilterChange,
  onUserFilterChange,
  userFilter,
  userFilters,
  onUserFiltersChange,
}: Props) {
  const filters = [
    { param: 'active', label: __('Active') },
    { param: 'suspended', label: __('Suspended') },
    { param: 'all', label: __('All') },
  ];
  const activeFilter = formatParamsHashToFilterName(filter.user);

  const { withUserMultiFilters } = useContext(DataContext);

  return (
    <FetchContainer
      loadingStyle={hasStats ? 'overlay' : 'alone'}
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        if (!hasStats && !userFilter && !activeFilter) {
          return (
            <Notification kind="info">
              <p>{__('This campaign does not have any participant.')}</p>
            </Notification>
          );
        }
        return (
          <React.Fragment>
            <Level isMobile style={{ marginBottom: 16 }}>
              <LevelLeft>
                <LevelItem>
                  {withUserMultiFilters && !!onUserFiltersChange ? (
                    <UserMultiFilters
                      userFilters={userFilters}
                      onChange={({ userFilters }) =>
                        onUserFiltersChange(userFilters)
                      }
                      maxFiltersCount={5}
                    />
                  ) : (
                    // TODO: multifilters: remove this condition when FF is enabled for everyone
                    !!onUserFilterChange && (
                      <UserFilter
                        segment={userFilter}
                        onChange={onUserFilterChange}
                      />
                    )
                  )}
                </LevelItem>
              </LevelLeft>
              <LevelRight style={{ textAlign: 'end' }}>
                {filters && filters.length > 0 && (
                  <FilterBar
                    display="link"
                    leftText={__('Show')}
                    filters={filters}
                    activeFilter={activeFilter}
                    onFilterChange={param =>
                      onFilterChange({ user: formatFilterToParamsHash(param) })
                    }
                  />
                )}
              </LevelRight>
            </Level>

            {!hasStats && (
              <Notification kind="info">
                <p>{__('No participant matches your filter')}</p>
              </Notification>
            )}
            {hasStats && render()}
          </React.Fragment>
        );
      }}
    />
  );
}
