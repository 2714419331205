import React from 'react';

import classNames from 'helpers/classNames';

import { Text } from 'components';

type Props<Value extends string | number> = {
  name: string;
  values: Array<Value>;
  selectedValue: Value | null;
  onChange: (value: Value | null) => void;
  isDisabled?: boolean;
  legends?: Array<string>;
  className?: string;
};

const RadioScale = <Value extends string | number>({
  name,
  values,
  selectedValue,
  onChange,
  isDisabled,
  legends,
  className,
}: Props<Value>) => (
  <div className={classNames('radio-scale', 'test-radio-scale', className)}>
    <div className="flex justify-center items-center gap-2 md:gap-4 flex-col md:flex-row">
      {!!legends && (
        <Text preset="13s7" color="light" className="md:hidden">
          {legends[0]}
        </Text>
      )}
      {values.map(value => {
        const isChecked = value === selectedValue;
        return (
          <label
            key={value}
            className={classNames({
              'is-checked': isChecked,
              'cursor-not-allowed': isDisabled,
            })}
          >
            <input
              type="radio"
              name={name}
              value={value}
              disabled={isDisabled}
              checked={isChecked}
              onChange={() => onChange(value)}
              onClick={() => isChecked && onChange(null)}
              style={{ display: 'none' }}
            />
            {value}
          </label>
        );
      })}
      {!!legends && (
        <Text preset="13s7" color="light" className="md:hidden">
          {legends[1]}
        </Text>
      )}
    </div>
    {!!legends && (
      <div className="hidden md:flex justify-between mt-2">
        {legends.map((legend, index) => (
          <Text key={legend || index} preset="13s7" color="light">
            {legend}
          </Text>
        ))}
      </div>
    )}
  </div>
);

export default RadioScale;
