import React from 'react';

import classNames from 'helpers/classNames';

import { Column, Columns, Loading } from '../index';
import Overlay, { type Props as CommonProps } from './base';

export type BackgroundColor = 'elevo-light-background' | 'white';

type Props = CommonProps & {
  color?: string;
  backgroundColor?: BackgroundColor;
  delay?: number;
};

const LoadingOverlay = ({
  children,
  style,
  color,
  backgroundColor,
  delay,
}: Props) => (
  <Overlay
    style={style}
    className={classNames({
      'with-transparent-elevo-light-background':
        backgroundColor === 'elevo-light-background',
      'with-transparent-white-background': backgroundColor === 'white',
    })}
  >
    <Columns>
      <Column isVerticallyCentered isNarrow>
        <Loading color={color} delay={delay} containerStyle={{ padding: 15 }} />
      </Column>
      {/* `size={12}` serves no purpose here. It just fixes a display issue on Edge <16 */}
      {children && (
        <Column isVerticallyCentered size={12}>
          {children}
        </Column>
      )}
    </Columns>
  </Overlay>
);

export default LoadingOverlay;
