import React from 'react';

import classNames from 'helpers/classNames';

import { Text, Title5 } from 'components';
import DeprecatedTag from 'components/DeprecatedTag';

import Button, { TooltipProps } from './Button';

type Props = {
  svg?: string;
  emoji?: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  tag?: string;
  style?: React.CSSProperties;
  testClassName?: string;
  isActive?: boolean;
  isColumn?: boolean;
  isDisabled?: boolean;
  disabledExplanation?: string;
  tooltipProps?: TooltipProps;
  onClick?: () => Promise<void> | void;
};

// This implements something similar to
// https://canvas.hubspot.com/components/buttons-links#selectable.
// Its behavior is Button-like, but its style is slightly different.
const BigSelectableButton = ({
  svg,
  emoji,
  title,
  subtitle,
  tag,
  style,
  testClassName,
  isActive,
  isColumn,
  isDisabled,
  disabledExplanation,
  tooltipProps,
  onClick,
}: Props) => (
  <Button
    isActive={isActive}
    disabled={isDisabled}
    disabledExplanation={disabledExplanation}
    onClick={onClick}
    style={style}
    tooltipProps={tooltipProps}
    className={classNames(
      'big-selectable-button',
      testClassName,
      isColumn && 'is-column'
    )}
  >
    {tag && <DeprecatedTag color="primary">{tag}</DeprecatedTag>}

    <div className="big-selectable-button-image">
      {!!svg && (
        <img
          src={`data:image/svg+xml;base64,${window.btoa(svg)}`}
          alt="illustration"
        />
      )}
      {!!emoji && (
        <span style={{ fontSize: 47, display: 'block', marginBottom: -4 }}>
          {emoji}
        </span>
      )}
    </div>

    <div className="w-full my-auto">
      <Title5 className="big-selectable-button-title">{title}</Title5>
      {subtitle && (
        <Text className="big-selectable-button-subtitle">{subtitle}</Text>
      )}
    </div>
  </Button>
);

export default BigSelectableButton;
