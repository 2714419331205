import React from 'react';

import { Box, Placeholder } from 'components';

const PeopleGroupListItemPlaceholder = () => {
  return (
    <Box size="small" className={'people-group'}>
      <div>
        <Placeholder width={25} />
      </div>
      <div>
        <Placeholder width={130} height={24} />
      </div>
    </Box>
  );
};

export default PeopleGroupListItemPlaceholder;
