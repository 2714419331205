import React, { ReactNode } from 'react';

import { __ } from 'helpers/i18n';

import { Flex, Text } from 'components';

type WithCharacterCountWrapperProps = {
  render: (maxCountReached: boolean) => ReactNode;
  count: number;
  maxCount: number;
  onMaxCountReached: (() => any) | null | undefined;
};

export default function WithCharacterCountWrapper({
  render,
  count,
  maxCount,
  onMaxCountReached,
}: WithCharacterCountWrapperProps) {
  const maxCountReached = count >= maxCount;

  return (
    <Flex direction="column">
      {render(maxCountReached)}
      <Text
        size={7}
        className="counter-legend"
        color={maxCountReached ? 'danger' : 'info'}
        style={{ alignSelf: 'flex-end' }}
      >
        {maxCountReached && !!onMaxCountReached
          ? onMaxCountReached()
          : __('%1 / %2 characters', count, maxCount)}
      </Text>
    </Flex>
  );
}
