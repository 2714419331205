import { sortBy } from 'lodash';
import React, { Fragment } from 'react';
import { compose } from 'redux';

import type { ObjectivePeriod } from 'models';
import type { RouteComponentProps } from 'react-router-dom';
import type { AppDispatch } from 'redux/actions';

import invariant from 'helpers/invariant';
import { pathToTeamObjectives } from 'helpers/paths';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Column,
  Columns,
  FetchContainer,
  Redirect,
  Route,
  Switch,
  Testable,
} from 'components';

import TeamSideList from 'scenes/objectives/team/components/TeamSideList';

import ObjectivePeriodPicker from './ObjectivePeriodPicker';
import TeamList from './TeamList';
import TeamPage from './TeamPage';

type Props = RouteComponentProps<{ objectivePeriodSlug: string }>;

type AfterConnectProps = Props & {
  isFetching: boolean;
  hasError: boolean;
  objectivePeriod: ObjectivePeriod;
};

const ObjectivePeriodRoutes = ({
  isFetching,
  hasError,
  objectivePeriod,
  match,
}: AfterConnectProps) => {
  const { path } = match;

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const { teamObjectivePeriods } = objectivePeriod;

        const sortedTeamObjectivePeriods = sortBy(
          teamObjectivePeriods,
          top => top.team.name
        );

        return (
          <Switch>
            <Route
              path={`${path}/:teamSlug?`}
              render={routerProps => {
                // @ts-expect-error: TODO manually type Route
                const { objectivePeriodSlug, teamSlug } =
                  routerProps.match.params;
                invariant(
                  objectivePeriodSlug,
                  'Period slug must exist on this path'
                );
                return (
                  <Fragment>
                    <Testable name="test-objective-period-picker">
                      <ObjectivePeriodPicker
                        selectedObjectivePeriodSlug={objectivePeriodSlug}
                        teamSlug={teamSlug}
                      />
                    </Testable>
                    <Columns>
                      <Column className={'is-7'}>
                        <Route
                          exact
                          path={`${path}`}
                          render={() => (
                            <TeamList
                              isFetching={isFetching}
                              hasError={hasError}
                              objectivePeriod={objectivePeriod}
                              sortedTeamObjectivePeriods={
                                sortedTeamObjectivePeriods
                              }
                            />
                          )}
                        />
                        <Route
                          exact
                          path={`${path}/:teamSlug`}
                          component={TeamPage}
                        />
                      </Column>
                      {sortedTeamObjectivePeriods.length > 0 && (
                        <Column className={'is-4 is-offset-1'}>
                          <TeamSideList
                            teamObjectivePeriods={sortedTeamObjectivePeriods}
                            objectivePeriod={objectivePeriod}
                            teamSlug={teamSlug}
                          />
                        </Column>
                      )}
                    </Columns>
                  </Fragment>
                );
              }}
            />
            <Redirect to={pathToTeamObjectives()} />
          </Switch>
        );
      }}
    />
  );
};
export default compose(
  newDataLoader({
    fetch:
      ({ match }: Props) =>
      async (dispatch: AppDispatch) => {
        invariant(
          match.params.objectivePeriodSlug,
          'Objective period should be there.'
        );
        return dispatch(
          get(
            `organization_objective_periods/${match.params.objectivePeriodSlug}`
          )
        );
      },
    hydrate: {
      objectivePeriod: {
        teamObjectivePeriods: {
          team: {},
          objectives: {
            abilities: {},
          },
        },
      },
    },
    cacheKey: ({ match }: Props) => JSON.stringify(match.params),
  })
)(ObjectivePeriodRoutes) as React.ComponentType<Props>;
