import React from 'react';

import type { BlockContent } from 'models';

import { history } from 'config/history';

import { __ } from 'helpers/i18n';

import { Link, Title } from 'components';

type Props = {
  blocks: Array<BlockContent>;
};

export default function TableOfContentsSection({ blocks }: Props) {
  const sections = blocks.map(block => {
    return {
      href: block.id,
      title: block.title || '',
    };
  });

  return (
    <React.Fragment>
      <Title className="side-bar-title" size={5} weight="bold">
        {__('Sections')}
      </Title>

      {sections.map(section => (
        <Link
          key={section.href}
          className="side-bar-link"
          onClick={() => {
            history.replace(`#${section.href}`);
            const element = window.document.getElementById(section.href);
            element?.scrollIntoView();
          }}
          title={section.title}
        >
          {/* TODO: Make a smooth hash link scroller */}
          {section.title}
        </Link>
      ))}
    </React.Fragment>
  );
}
