import React, { useContext } from 'react';

import type { ReviewCycle, UserFilterSegment } from 'models';

import { Box, DesignSystem } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import { DataContext } from 'scenes/admin/reviewCycles/Routes';

import DevelopmentNeeds from './DevelopmentNeeds';

type Props = {
  reviewCycle: ReviewCycle;
  defaultUserFilter?: UserFilterSegment;
  userFilters?: ActiveFilters;
  withUserMultiFilters?: boolean;
  setUserFilters?: (ActiveFilters) => void;
};

const Skills = ({
  reviewCycle,
  defaultUserFilter,
  userFilters,
  withUserMultiFilters: withUserMultiFilterProp,
  setUserFilters,
}: Props) => {
  const withUserMultiFilters =
    useContext(DataContext).withUserMultiFilters || withUserMultiFilterProp;

  return (
    <DesignSystem version={2}>
      <Box className="mt-4">
        <DevelopmentNeeds
          reviewCycle={reviewCycle}
          defaultUserFilter={defaultUserFilter}
          userFilters={userFilters}
          withUserMultiFilters={withUserMultiFilters}
          setUserFilters={setUserFilters}
        />
      </Box>
    </DesignSystem>
  );
};
export default Skills;
