import React, { ReactNode } from 'react';

import { opacify } from 'styles/index';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import { Icon, Link, MaterialIconName, Text } from 'components';

type Props = {
  children: ReactNode;
};

type EllipsisLinkProps = {
  title: string;
  iconName: MaterialIconName;
  onClick: () => void;
};

function ToggleEllipsisLink({ title, iconName, onClick }: EllipsisLinkProps) {
  return (
    <div className="text-center relative">
      <Link onClick={onClick} className="text-[var(--primary)] underline">
        <Text preset="13s7">{title}</Text>
        <Icon className="pl-2" name={iconName} />
      </Link>
    </div>
  );
}

const TRUNCATION_THRESHOLD = 350;
export default function ReadMore({ children }: Props) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [childrenBg, setChildrenBg] = React.useState<string | null>(null);
  const [expanded, setExpanded] = React.useState(false);

  const textLength =
    (ref.current?.querySelector('trix-editor') || ref.current)?.textContent
      ?.length || 0;
  const truncationThresholdExceeded = textLength > TRUNCATION_THRESHOLD;

  React.useEffect(() => {
    if (!ref.current) return;

    let elem: HTMLElement | null = ref.current;

    // get non transparent background color
    while ((elem = elem.parentElement)) {
      const bgColor = getComputedStyle(elem).backgroundColor;
      if (bgColor !== 'rgba(0, 0, 0, 0)') {
        setChildrenBg(bgColor);
        break;
      }
    }
  }, [ref]);

  const renderToggleButton = () => {
    if (!truncationThresholdExceeded) return null;

    if (expanded)
      return (
        <ToggleEllipsisLink
          title={__('Show less')}
          iconName="keyboard_arrow_up"
          onClick={() => setExpanded(false)}
        />
      );

    return (
      <ToggleEllipsisLink
        title={__('Show more')}
        iconName="keyboard_arrow_down"
        onClick={() => setExpanded(true)}
      />
    );
  };

  const textTruncated = truncationThresholdExceeded && !expanded;
  return (
    <div>
      <div
        ref={ref}
        className={classNames(
          'relative',
          'duration-200 transition-[max-height]',
          {
            'mb-2': truncationThresholdExceeded,
            '!max-h-20 overflow-hidden': textTruncated,
          }
        )}
      >
        {children}

        {textTruncated && (
          <div
            className="py-6 absolute bottom-0 w-full pointer-events-none"
            style={{
              background: childrenBg
                ? `linear-gradient(180deg, ${opacify(
                    childrenBg,
                    0
                  )} 0%, ${childrenBg} 100%)`
                : '',
            }}
          />
        )}
      </div>

      {renderToggleButton()}
    </div>
  );
}
