import React, { useState } from 'react';

import { PeopleReviewDimensionShow } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { useForm } from 'helpers/hooks/useForm';
import { __ } from 'helpers/i18n';
import { pathToPeopleReviewDimensions } from 'helpers/paths';

import { put } from 'redux/actions/api';

import {
  Button,
  Field,
  FieldError,
  Helper,
  Icon,
  Input,
  Label,
  Text,
  Textarea,
  UnsavedModificationsGuard,
} from 'components';
import EditorHowTo from 'components/formElements/inlineEditables/EditorHowTo';

import RatingOptionInputs from './RatingOptionInputs';
import dimensionVariables from './dimensionVariables';

type RatingOptionsInput = {
  label: string;
  value: string;
};

type Props = {
  peopleReviewDimension: PeopleReviewDimensionShow;
};

const MAX_TITLE_LENGTH = 400;
const MAX_DESCRIPTION_LENGTH = 4000;

const DimensionForm = ({ peopleReviewDimension }: Props) => {
  const { label, title, description, peopleReviewRatingOptions, usedInCycle } =
    peopleReviewDimension;
  const dispatch = useAppDispatch();
  const [ratingOptions, setRatingOptions] = useState<RatingOptionsInput[]>(
    peopleReviewRatingOptions.map(option => ({
      label: option.label,
      value: option.value,
    }))
  );
  const [redirectToIndex, setRedirectToIndex] = useState<boolean>(false);

  const updateDimension = async () => {
    const params = {
      ...values,
      peopleReviewRatingOptionsAttributes: ratingOptions,
    };
    await dispatch(
      put(`people_review_dimensions/${peopleReviewDimension.id}`, params, {
        withDefaultErrorHandling: false,
        redirectTo: redirectToIndex
          ? pathToPeopleReviewDimensions()
          : undefined,
        successMessage: __('Dimension has been updated'),
      })
    );
  };

  const {
    values,
    errors,
    handleInputChange,
    handleSubmit,
    isDirty,
    setIsDirty,
  } = useForm(
    {
      title: title,
      description: description,
    },
    updateDimension
  );

  const ratingOptionsErrors = errors.peopleReviewRatingOptionsLabel as
    | string[]
    | undefined;

  const getMergeTags = () => {
    const tags = dimensionVariables().map(({ name, variable }) => ({
      name,
      tag: variable,
    }));

    return [{ trigger: '[', tags }];
  };

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        setIsDirty(false);
        handleSubmit();
      }}
    >
      <Field>
        <Label>{__('Dimension name')}</Label>
        <Input value={label} disabled />
      </Field>
      <Field>
        <Label>{__('Question title')}</Label>
        <Text preset="13s7">
          {__(
            'This is the question people review reviewers will be asked. A clear, short formulation will be more effective.'
          )}
        </Text>
        <Textarea
          value={values.title}
          richText
          mergeTags={getMergeTags()}
          className="mt-2"
          maxLength={MAX_TITLE_LENGTH}
          onMaxLengthReached={() =>
            __('Title must be shorter than %1 characters.', MAX_TITLE_LENGTH)
          }
          rows={1}
          onChange={value => handleInputChange({ value: value, name: 'title' })}
        />
        <EditorHowTo className="-mt-2" />
        <FieldError>{errors.title}</FieldError>
      </Field>
      <Field>
        <Label>{__('Question description')}</Label>
        <Textarea
          value={values.description}
          richText
          mergeTags={getMergeTags()}
          className="mt-2"
          maxLength={MAX_DESCRIPTION_LENGTH}
          onMaxLengthReached={() =>
            __(
              'Description must be shorter than %1 characters.',
              MAX_DESCRIPTION_LENGTH
            )
          }
          rows={2}
          onChange={value =>
            handleInputChange({ value: value, name: 'description' })
          }
        />
        <EditorHowTo className="-mt-2" />
        <FieldError>{errors.description}</FieldError>
      </Field>
      <Text preset="14bs6">{__('Define rating options')}</Text>

      <Helper className="mt-2 mb-2">
        {usedInCycle
          ? __(
              'This dimension is already used in a campaign. To change your rating choices, please contact Elevo support.'
            )
          : __(
              '%1 Once a campaign has been launched with this dimension, you will no longer be able to modify your rating options.',
              <strong>{__('Please note!')}</strong>
            )}
      </Helper>
      {ratingOptionsErrors?.map((error, index) => (
        <FieldError key={index}>{error}</FieldError>
      ))}
      <RatingOptionInputs
        ratingOptions={ratingOptions}
        setRatingOptions={setRatingOptions}
        usedInCycle={usedInCycle}
        setIsDirty={setIsDirty}
      />
      <div className="flex justify-center gap-2 mt-4">
        <Button color="secondary" to={pathToPeopleReviewDimensions()}>
          {__('Cancel')}
        </Button>
        <button
          className="button is-primary"
          onClick={() => setRedirectToIndex(true)}
        >
          <Icon style={{ marginRight: 8 }} name="send" />
          {__('Modify for future campaigns')}
        </button>
      </div>
      <UnsavedModificationsGuard isDirty={isDirty} onSubmit={handleSubmit} />
    </form>
  );
};

export default DimensionForm;
