import { castArray, isEmpty } from 'lodash';
import React, { Fragment } from 'react';

import type { User } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post } from 'redux/actions/api';

import { FetchContainer, Icon, SimpleTable, Text } from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import UserPicker from 'scenes/components/UserPicker';

type Props = {
  campaignId: string;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    excludedUsers: Array<User>;
  };

type ExcludedParticipantsParams = {
  excludedParticipantIds: string[];
};

const ExcludeParticipants = ({
  campaignId,
  excludedUsers,
  isFetching,
  refetchData,
  hasError,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();

  const updateExcludedParticipants = (params: ExcludedParticipantsParams) =>
    dispatch(
      post(`survey/campaigns/${campaignId}/exclude_participants`, {
        ...params,
        setupStepIndex: 2,
      })
    );

  if (!excludedUsers) return null;

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="overlay"
      render={() => (
        <Fragment>
          <div>
            <p>
              {__(
                'All employees in the organization, except those selected below, will be added to this campaign. New employees will be added automatically.'
              )}
            </p>
          </div>

          <UserPicker
            className="flex-1 mt-2 mb-4"
            fetchParams={{ permission: 'update_survey_participants?' }}
            onChange={async users => {
              if (!users) return;

              await updateExcludedParticipants({
                excludedParticipantIds: [
                  ...excludedUsers,
                  ...castArray(users),
                ].map(user => user.id),
              });
              await refetchData();
            }}
            disabledUserIds={excludedUsers?.map(user => user.id)}
            isMulti
            inModal
          />
          {!isEmpty(excludedUsers) && (
            <Fragment>
              <Text preset="16bs5.5">{__('Excluded employees')}</Text>
              <SimpleTable
                withBorder
                columns={[
                  {
                    header: __('Employee'),
                    cell: user => <UserAvatar user={user} withJobTitle />,
                  },
                  {
                    header: '',
                    cell: user => (
                      <Icon
                        className="cursor-pointer"
                        onClick={async () => {
                          await updateExcludedParticipants({
                            excludedParticipantIds: excludedUsers
                              .filter(
                                excludedUser => excludedUser.id !== user.id
                              )
                              .map(user => user.id),
                          });
                          await refetchData();
                        }}
                        name="delete"
                      />
                    ),
                    isNarrow: true,
                  },
                ]}
                rows={excludedUsers}
                keyFn={user => user.id}
                className="mt-2 mb-4"
              />
            </Fragment>
          )}
        </Fragment>
      )}
    />
  );
};

export default newDataLoader({
  fetch: ({ campaignId }: Props) =>
    get(`survey/campaigns/${campaignId}/excluded_participants`),
  hydrate: {
    excludedUsers: {},
  },
})(ExcludeParticipants) as React.ComponentType<Props>;
