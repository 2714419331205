import moment from 'moment';
import React from 'react';

import type { TrainingSession } from 'models';

import { FormErrors } from 'helpers/api';
import can from 'helpers/can';
import {
  computeTimesForFiltering,
  dateFromDefaultDateString,
  dateToDefaultDateTimeStringFormat,
} from 'helpers/date';
import { __ } from 'helpers/i18n';

import {
  DatePicker,
  Field,
  FieldError,
  Label,
  TimePicker,
  Tooltip,
} from 'components';

type Props = {
  session: TrainingSession;
  onChange: (session?: TrainingSession) => void;
  errors: FormErrors;
  disabled: boolean;
};

const EndDatePicker = ({ session, onChange, errors, disabled }: Props) => {
  const canUpdateDate = can({
    perform: 'update_dates_and_location',
    on: session,
  });

  const filterEndTime = (time: Date) => {
    if (!session.startDate) return true;

    const { referenceTime, currentTime } = computeTimesForFiltering(
      time,
      session.endDate,
      session.startDate
    );

    return referenceTime < currentTime;
  };

  const handleOnChange = (
    date: Date | null | undefined,
    timeChanged: boolean
  ) => {
    let endDate: string | null = null;

    if (date) {
      let mEndDate = moment(date);
      let mStartDate = moment(session.startDate);

      if (!session.endDate && !timeChanged) {
        mEndDate.set({ h: 17, m: 0 });
      }

      if (session.startDate && mStartDate.isAfter(mEndDate)) {
        mEndDate = mStartDate.add(
          TimePicker.defaultProps.timeIntervals,
          'minutes'
        );
      }

      endDate = dateToDefaultDateTimeStringFormat(mEndDate.toDate());
    }

    onChange({ ...session, endDate });
  };

  const tooltipContent = canUpdateDate
    ? null
    : __(
        'Dates and location of the training session cannot be edited once the calendar invitation has been sent'
      );

  return (
    <div>
      <Label>{__('End date and time')}</Label>
      <div className="training-session-datetime">
        <Field className="date-picker">
          <Tooltip content={tooltipContent}>
            <DatePicker
              value={dateFromDefaultDateString(session.endDate)}
              minDate={(session.startDate
                ? moment(session.startDate)
                : moment().add(1, 'days')
              ).toDate()}
              onChange={date => handleOnChange(date, false)}
              placeholder={__('Choose an end date')}
              disabled={disabled || !canUpdateDate}
            />
          </Tooltip>
        </Field>
        <Field className="time-picker">
          <Tooltip content={tooltipContent}>
            <TimePicker
              value={dateFromDefaultDateString(session.endDate)}
              filterTime={filterEndTime}
              onChange={date => handleOnChange(date, true)}
              disabled={disabled || !canUpdateDate}
            />
          </Tooltip>
          <FieldError>{errors.endDate}</FieldError>
        </Field>
      </div>
    </div>
  );
};

export default EndDatePicker;
