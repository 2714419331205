import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router';

import type { UserFilterChangeMethod } from 'lib/dataLoader/pagination/types';

import { SurveyCampaign, UserFilterSegment } from 'models';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { __, date } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import queryString from 'helpers/queryString';

import {
  Icon,
  Level,
  LevelItem,
  LevelLeft,
  LevelRight,
  PeriodPicker,
  StrictlySanitizedHtml,
  Text,
} from 'components';

import UserFilter from 'scenes/components/UserFilter';
import { surveyIsRecurrent } from 'scenes/surveys/helpers/survey.helpers';

import FormStats from './FormStats';
import StatsBox from './StatsBox';
import NoPeriodEmptyStateStats from './StatsBox/NoPeriodEmptyState';

type Props = {
  surveyCampaign: SurveyCampaign;
  periodId: string | null;
  userFilter: UserFilterSegment | null;
  onUserFilterChange: UserFilterChangeMethod;
};

const Overview = ({
  surveyCampaign,
  periodId,
  userFilter,
  onUserFilterChange,
}: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const surveyFilteringDisabled = featureFlags.includes(
    'surveyFilteringDisabled'
  );
  const segment = surveyFilteringDisabled ? null : userFilter;
  const history = useHistory();
  const { lastPeriodId, anonymous } = surveyCampaign;
  const [selectedPeriodId, setSelectedPeriodId] = useState(
    periodId?.toString() || lastPeriodId?.toString()
  );

  if (!lastPeriodId)
    return <NoPeriodEmptyStateStats campaign={surveyCampaign} />;

  const selectedPeriod = surveyCampaign.pollingPeriods.find(
    period => period.id === selectedPeriodId
  );
  const handlePeriodIdChange = (periodId: string) => {
    setSelectedPeriodId(periodId);
    const params = {
      ...queryString.parse(window.location.search),
      periodId: periodId,
    };
    history.replace(
      history.location.pathname + '?' + queryString.stringify(params)
    );
  };
  invariant(
    selectedPeriod,
    'Selected period must exist in campaign polling periods.'
  );

  const displayPeriodPicker = surveyIsRecurrent(surveyCampaign);
  const message = selectedPeriod.active
    ? __(
        'The evaluation of this period is under way and will close on <b>%1</b>',
        date(selectedPeriod.endDate, 'dddd LL')
      )
    : __(
        'The evaluation of this period has been closed since <b>%1</b>',
        date(selectedPeriod.endDate, 'dddd LL')
      );

  return (
    <Fragment>
      <Level className="mb-6">
        <LevelLeft>
          {displayPeriodPicker && (
            <LevelItem>
              <PeriodPicker
                period={{
                  id: selectedPeriod.id,
                  name: selectedPeriod.displayedName,
                }}
                periods={surveyCampaign.pollingPeriods.map(period => {
                  const disabled = !can({
                    perform: 'stats',
                    on: period,
                  });
                  return {
                    id: period.id,
                    name: period.displayedName,
                    disabled,
                    tooltipMessage: disabled
                      ? __(
                          'There are no collaborators from your entity within this period.'
                        )
                      : undefined,
                  };
                })}
                onChange={handlePeriodIdChange}
              />
            </LevelItem>
          )}
          {!surveyFilteringDisabled && (
            <LevelItem>
              <UserFilter
                segment={userFilter}
                onChange={onUserFilterChange}
                filteredSource={'survey_users'}
              />
            </LevelItem>
          )}
        </LevelLeft>
        <LevelRight>
          <Text color="info" preset="14s6">
            <Icon name="timer" className="mr-1" />
            <StrictlySanitizedHtml html={message} />
          </Text>
        </LevelRight>
      </Level>
      <StatsBox
        campaignId={surveyCampaign.id}
        isAnonymous={anonymous}
        periodId={selectedPeriod.id}
        segment={segment}
      />
      <FormStats
        formId={selectedPeriod.formId}
        periodId={selectedPeriod.id}
        campaignId={surveyCampaign.id}
        segment={segment}
      />
    </Fragment>
  );
};

export default Overview;
