import React from 'react';

import type { TrainingSession } from 'models';

import { hasShape, stringType } from 'types/predicates/WithShape';

import ConnectedModal from './ConnectedModal';
import Modal from './Modal';

type BaseProps = {
  onClose: () => void;
  onAfterDelete: () => void;
};

type Props =
  | (BaseProps & {
      sessionId: string;
    })
  | (BaseProps & {
      session: TrainingSession;
    });

const DeleteSessionModal = ({
  onClose,
  onAfterDelete,
  ...otherProps
}: Props) => {
  if (hasShape(otherProps, { sessionId: stringType })) {
    return (
      <ConnectedModal
        sessionId={otherProps.sessionId}
        onClose={onClose}
        onAfterDelete={onAfterDelete}
      />
    );
  }

  return (
    <Modal
      session={otherProps.session}
      onClose={onClose}
      onAfterDelete={onAfterDelete}
    />
  );
};

export default DeleteSessionModal;
