import { capitalize } from 'lodash';
import React, { ReactNode } from 'react';

import { TrainingCourse } from 'models';

import { __, getCurrentLocale, n__ } from 'helpers/i18n';
import { formatsTranslation } from 'helpers/models/trainingCourse';
import { formatMoney } from 'helpers/money';

import { Icon, Link, MaterialIconName, Text } from 'components';

type AttributeLineProps = {
  icon: MaterialIconName;
  children: ReactNode;
};

const AttributeLine = ({ icon, children }: AttributeLineProps) => {
  return (
    <div className="flex items-center gap-2">
      <Icon name={icon} className="text-text-light" />
      <div className="flex flex-col gap-1">{children}</div>
    </div>
  );
};

type AttributeProp<T> = {
  label?: string;
  data: NonNullable<T> | null;
  formatter: (data: NonNullable<T>) => ReactNode;
};

const Attribute = <T,>({ label, data, formatter }: AttributeProp<T>) => {
  const isPresent = <T,>(data: T): data is NonNullable<T> =>
    Array.isArray(data) ? data.length > 0 : data !== null;

  return (
    <Text preset="14s6" key={label} className="block m-0">
      {label && <span className="mr-2">{label}</span>}
      {isPresent(data) ? <Text preset="14bs6">{formatter(data)}</Text> : '-'}
    </Text>
  );
};

type Props = Pick<
  TrainingCourse,
  | 'costPerParticipantCents'
  | 'costPerParticipantCurrency'
  | 'durationInHours'
  | 'certifying'
  | 'availableLanguages'
  | 'trainingFormat'
  | 'location'
> & {
  organism: Pick<TrainingCourse['organism'], 'name' | 'logoUrl' | 'website'>;
};

const CourseMetadata = ({
  organism,
  costPerParticipantCents,
  costPerParticipantCurrency,
  durationInHours,
  certifying,
  availableLanguages,
  trainingFormat,
  location,
}: Props) => {
  const languageNames = new Intl.DisplayNames([getCurrentLocale()], {
    type: 'language',
  });

  const duration =
    typeof durationInHours === 'string'
      ? parseFloat(durationInHours)
      : durationInHours;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <img
            className="w-8 h-8 rounded-full"
            src={organism.logoUrl}
            alt={organism.name}
          />
          <span>{organism.name}</span>
        </div>
        {organism.website && (
          <Text className="flex items-center text-primary" preset="14s6">
            <Link
              to={organism.website}
              openInNewTab
              hasInheritedColor
              additionalClassName="flex items-center gap-1"
            >
              <span>{__('Website')}</span>
            </Link>
          </Text>
        )}
      </div>

      <div className="flex flex-col gap-3">
        <AttributeLine icon="savings">
          <Attribute
            label={__('Cost:')}
            data={costPerParticipantCents}
            formatter={cost =>
              __(
                '%1 / participant (excl. tax)',
                formatMoney(cost, costPerParticipantCurrency || '', {
                  currencyDisplay: 'symbol',
                  hideEmptyDecimal: true,
                })
              )
            }
          />
        </AttributeLine>
        <AttributeLine icon="timer">
          <Attribute
            label={__('Training duration:')}
            data={duration}
            formatter={duration => n__('%1 hour', '%1 hours', duration)}
          />
        </AttributeLine>
        <AttributeLine icon="editor_choice">
          <Attribute
            data={certifying}
            formatter={certifying =>
              certifying
                ? __('Leads to certification')
                : __('Does not lead to certification')
            }
          />
        </AttributeLine>
        <AttributeLine icon="language">
          <Attribute
            label={__('Available languages:')}
            data={availableLanguages}
            formatter={availableLanguages =>
              availableLanguages
                .map(language => capitalize(languageNames.of(language)))
                .join(', ')
            }
          />
        </AttributeLine>
      </div>

      <hr className="m-0" />

      <AttributeLine icon="distance">
        <Attribute
          label={__('Format:')}
          data={trainingFormat}
          formatter={format => formatsTranslation()[format]}
        />
        <Attribute
          label={__('Location:')}
          data={location}
          formatter={location => location}
        />
      </AttributeLine>
    </div>
  );
};

export default CourseMetadata;
