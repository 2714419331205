import React, { Fragment, useState } from 'react';

import type { SkillsLevelExpectationMapping } from 'models';

import { __ } from 'helpers/i18n';
import { assert } from 'helpers/invariant';

import { BoxListItem, Icon, Text } from 'components';

type Props = {
  domainTitle: string;
  expectationMappings: SkillsLevelExpectationMapping[];
};

const ExpectationsWithDomain = ({
  domainTitle,
  expectationMappings,
}: Props) => {
  const [expectationsVisible, setExpectationsVisible] = useState(true);
  const toggleExpectationsVisible = () =>
    setExpectationsVisible(previousState => !previousState);

  return (
    <BoxListItem className="p-0 mb-5 pt-5">
      <button
        onClick={toggleExpectationsVisible}
        className="cursor-pointer bg-transparent border-none mb-2"
        aria-expanded={expectationsVisible}
        aria-label={__(
          "Toggle visibility of the domain '%1' description",
          domainTitle
        )}
      >
        <Text preset="18bs5" color="main">
          <Icon
            name={expectationsVisible ? 'arrow_drop_down' : 'arrow_right'}
            className="mr-3"
          />
          {domainTitle}
        </Text>
      </button>
      {expectationsVisible && (
        <div className="ml-8 mr-2">
          {expectationMappings.map(expectationMapping => (
            <Fragment key={expectationMapping.id}>
              <Text preset="16bs5.5">{expectationMapping.areaTitle}</Text>
              <p className="mt-1 mb-2 whitespace-pre-wrap">
                {
                  assert(
                    expectationMapping.expectation,
                    'expectation must be defined'
                  ).content
                }
              </p>
            </Fragment>
          ))}
        </div>
      )}
    </BoxListItem>
  );
};

export default ExpectationsWithDomain;
