import React from 'react';

import classNames from 'helpers/classNames';

import { Control, Field, Input, Testable } from 'components';

type Props = {
  value: string | undefined | null;
  onChange: (value: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
};

export default function SearchBar({
  value,
  placeholder,
  onChange,
  style,
  className,
}: Props) {
  return (
    <Testable name="test-search-bar">
      <Field className={classNames('search-bar', className)} style={style}>
        <Control
          leftIcon="search"
          isClearable={!!value}
          onClear={() => onChange('')}
        >
          <Input
            value={value === null ? '' : value}
            onChange={(value: string | null) => onChange(value || '')}
            placeholder={placeholder}
            debounce={{
              wait: 300,
              leading: false,
              trailing: true,
            }}
          />
        </Control>
      </Field>
    </Testable>
  );
}
